import { gql } from "@apollo/client";

export const GET_CONTACT_NOTES = gql`
	query getContactNotes($contactId: Int!) {
		msgbox_ContactNote(
			where: { Contact: { ContactId: { _eq: $contactId } } }
		) {
			Note
			CreatedOn
			ContactNoteId
		}
	}
`;

export const ADD_CONTACT_NOTE = gql`
	mutation addContactNote($note: String!, $contactId: Int!) {
		insert_msgbox_ContactNote_one(
			object: { Note: $note, ContactId: $contactId }
		) {
			Note
			ContactNoteId
		}
	}
`;

export const DELETE_CONTACT_NOTE = gql`
	mutation deleteContactNote($contactNoteId: Int!) {
		update_msgbox_ContactNote(
			where: { ContactNoteId: { _eq: $contactNoteId } }
			_set: { IsDeleted: true }
		) {
			affected_rows
			returning {
				Note
				ContactNoteId
			}
		}
	}
`;

export const UPDATE_CONTACT_NOTE = gql`
	mutation updateContactNote($noteId: Int!, $note: String!) {
		update_msgbox_ContactNote(
			where: { ContactNoteId: { _eq: $noteId } }
			_set: { Note: $note }
		) {
			affected_rows
			returning {
				Note
				ContactNoteId
			}
		}
	}
`;
