import styled from "styled-components";

export const Image = styled.img`
	height: 35px;
	width: 35px;
	border-radius: 35px;
	order: ${(props) => (props.isIncoming === true ? 0 : 1)};
`;

export const ContactImage = styled.div`
	order: ${(props) => (props.isIncoming === true ? 0 : 1)};
	height: 35px;
	width: 35px;
	background-color: pink;
	min-width: 35px;
	border-radius: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
`;

export const StaffImage = styled.div`
	order: ${(props) => (props.isIncoming === true ? 0 : 1)};
	height: 35px;
	width: 35px;
	background-color: ${(props) => props.contactColor};
	min-width: 35px;
	border-radius: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colours.light_0};
`;

export const Container = styled.div`
	margin: 12px;
	display: flex;
	justify-content: ${(props) => props.isIncoming !== true && "flex-end"};
	small {
		:nth-child(odd) {
			margin-left: 6px;
		}
	}
`;

export const MessageBody = styled.div`
	background-color: ${(props) =>
		props.isIncoming === true
			? props.theme.colours.primaryLight
			: props.theme.colours.light_2};
	padding: 12px;
	border-radius: 24px;

	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	word-break: break-word;
`;

export const DoubleCheck = styled.i`
	color: ${(props) =>
		props.isRead === true
			? props.theme.colours.success
			: props.theme.colours.light_4};
`;

export const SingleCheck = styled.i`
	color: ${(props) => props.theme.colours.light_4};
`;

export const Paused = styled.i`
	color: ${(props) => props.theme.colours.warning};
`;

export const Fail = styled.i`
	color: ${(props) => props.theme.colours.danger};
`;

export const MessageButton = styled.button`
	margin: 6px;
	padding: 10px;
	border: none;
	color: white;
	font-weight: bold;
	background-color: ${(props) => props.theme.colours.primary};
	border-radius: 5px;
	:active,
	:focus {
		outline: none;
	}
`;

export const SmallImage = styled.img`
	display: block;
	max-width: 100%;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	margin: 0 auto;
	object-fit: cover;
	width: auto;
	height: 80px;
`;
