import styled from "styled-components";

export const EmojiPickerContainer = styled.div`
	position: absolute;
	bottom: 30px;
	left: 24px;
	transition: opacity 300ms ease-in-out;
`;

export const MessageIcons = styled.i`
	font-size: 1.5rem;
	margin-right: 6px;
	cursor: pointer;
	margin-bottom: 6px;
	color: ${(props) => props.active === true && props.theme.colours.primary};

	&:hover {
		color: ${(props) => props.theme.colours.primary};
	}
`;

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_2};
	margin: 12px;
	padding: 12px;
	border-radius: 6px;

	.attachments-container {
		display: flex;
		& .attachment {
			display: flex;
			align-items: center;
			margin: 6px;
			padding: 3px;
			border: solid 1px;
		}
	}
	.send-message-actions {
		position: relative;
	}
`;
