import React from "react";
import CountUp from "react-countup";
import { StyledDataCard } from "./styles";

const DataCard = ({ name, total, onClick, selected }) => {
	return (
		<StyledDataCard selected={selected} onClick={onClick} role="button">
			<h2>{name}</h2>
			<CountUp start={0} duration={1} end={total} delay={0}>
				{({ countUpRef }) => (
					<div>
						<h1 ref={countUpRef} />
					</div>
				)}
			</CountUp>
		</StyledDataCard>
	);
};

export default DataCard;
