import {
	GET_USER_DETAILS,
	UPDATE_USER_DETAILS,
	GET_ATTACHMENT_LOCATION,
} from "./query";
import { useQuery, useMutation } from "@apollo/client";
import { getUserId, getAccessToken } from "../../helpers/functions";
import Input from "../Input/input.component";
import { Formik } from "formik";
import Button from "../Button/button.component";
import { Container } from "./styles";
import { FormField } from "../../Styles/global";
import { ModalButton } from "../MsgBoxModal/styles";
import { useRef, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import client from "../../Apollo";
import { profileValidationSchema } from "./validation-schema";
import { InputErrorMessage } from "../../Styles/global";
import { Prompt } from "react-router";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";

const SUPPORTED_FILE_FORMATS = [
	"image/jpg",
	"image/jpeg",
	"image/gif",
	"image/png",
];

const Profile = () => {
	const { data, error, loading } = useQuery(GET_USER_DETAILS, {
		variables: {
			userId: getUserId(),
		},
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			if (data.msgbox_User[0]?.signedurl) {
				setImageUri(data.msgbox_User[0]?.signedurl.url);
			} else {
				setImageUri("/images/defaultUser.jpg");
			}
		},
	});

	const [updateUserDetails, { loading: updateLoading, error: updateError }] =
		useMutation(UPDATE_USER_DETAILS);

	const { addToast } = useToasts();

	const fileUploadRef = useRef(null);
	const [imageUri, setImageUri] = useState("");
	const [updating, setUpdating] = useState(false);
	const [dirty, setDirty] = useState(false);

	useEffect(() => {
		if (dirty) {
			window.onbeforeunload = () => true;
		}
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [dirty]);

	const submit = async (values) => {
		let imageLocation = data.msgbox_User[0]?.ImageURI;
		if (values.profileImage) {
			imageLocation = await uploadImage(values.profileImage);
			await updateUserDetails({
				variables: {
					userId: getUserId(),
					firstName: values.firstName,
					lastName: values.lastName,
					imageUri: imageLocation,
					hasImage: true,
				},
			});
		} else {
			await updateUserDetails({
				variables: {
					userId: getUserId(),
					firstName: values.firstName,
					lastName: values.lastName,
					imageUri: null,
					hasImage: false,
				},
			});
		}
		setUpdating(false);
		addToast("Profile updated", {
			appearance: "success",
			autoDismiss: true,
		});
		setDirty(false);
	};

	const uploadImage = (file) => {
		return new Promise(async (resolve, reject) => {
			try {
				setUpdating(true);
				const { name, type } = file;
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}files`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
							"content-disposition": `filename=${name}`,
							"content-type": type,
						},
						body: file,
					}
				);
				const { attachmentId } = await response.json();
				const location = await client.query({
					query: GET_ATTACHMENT_LOCATION,
					variables: {
						attachmentId: attachmentId,
					},
				});
				resolve(location.data.msgbox_Attachment[0].Location);
			} catch (error) {
				addToast("error uploading image", {
					appearance: "error",
					autoDismiss: true,
				});
				setUpdating(false);
				reject(error);
			}
		});
	};

	const onUpdateImageClick = () => fileUploadRef.current.click();

	if (error) return <p>Ooops.. something went wrong</p>;

	if (loading) return null;

	const { UserDepartments, FirstName, LastName, ImageURI, EMail, HasImage } =
		data.msgbox_User[0];
		console.log("UserDepartments:", UserDepartments)
		console.log("filtered", UserDepartments.filter((x) => x.Department !== null))
	return (
		<Container>
			<Formik
				initialValues={{
					firstName: FirstName,
					lastName: LastName,
					email: EMail,
					department:
						UserDepartments.filter((x) => x.Department !== null).length > 0
							? UserDepartments.filter((x) => x.Department !== null).map(({ Department }) => ({
									label: Department.Name,
									value: Department.value,
							  }))
							: {
									value: null,
									label: "You are not assigned to any teams",
							  },
					profileImage: null,
				}}
				onSubmit={(values) => {
					submit(values);
				}}
				validationSchema={profileValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({
					handleSubmit,
					setFieldValue,
					values,
					handleChange,
					errors,
					setFieldError,
				}) => (
					<form id="profile-form" onSubmit={handleSubmit}>
						<FormField>
							<div className="user-profile">
								<img src={imageUri} />
								<ModalButton
									type="button"
									buttonName="Update Image"
									onClick={onUpdateImageClick}
								/>
								{(HasImage || values.profileImage) && (
									<ModalButton
										type="button"
										buttonName="Delete Image"
										onClick={() => {
											setFieldValue("profileImage", null);
											setImageUri(
												"/images/defaultUser.jpg"
											);
											setDirty(true);
										}}
									/>
								)}
								<InputErrorMessage style={{ display: "block" }}>
									{errors.profileImage}
								</InputErrorMessage>
								<input
									ref={fileUploadRef}
									style={{ display: "none" }}
									onChange={(event) => {
										setDirty(true);
										if (
											event.target.files[0].size > 5000000
										) {
											setFieldError(
												"profileImage",
												"File size too large"
											);
											return;
										}
										if (
											!SUPPORTED_FILE_FORMATS.includes(
												event.target.files[0].type
											)
										) {
											setFieldError(
												"profileImage",
												"Invalid file format. File must be an .png, .jpeg or .gif file"
											);
											return;
										}
										setFieldValue(
											"profileImage",
											event.currentTarget.files[0]
										);
										const reader = new FileReader();
										reader.readAsDataURL(
											event.currentTarget.files[0]
										);
										reader.onloadend = () => {
											setImageUri(reader.result);
										};
									}}
									type="file"
									name="profileImage"
								/>
							</div>
						</FormField>
						<Input
							name="firstName"
							label="First Name"
							onChange={(event) => {
								handleChange(event);
								setDirty(true);
							}}
							value={values.firstName}
							error={errors.firstName}
							required
						/>
						<Input
							name="lastName"
							label="Last Name"
							onChange={(event) => {
								handleChange(event);
								setDirty(true);
							}}
							value={values.lastName}
							error={errors.lastName}
							required
						/>
						<Input
							name="email"
							label="Email"
							onChange={(event) => {
								handleChange(event);
								setDirty(true);
							}}
							value={values.email}
							error={errors.email}
							disabled
							readOnly
							required
						/>
						<MsgBoxSelect
							key={2}
							placeholder="Select a team"
							name="department"
							options={UserDepartments.filter((x) => x.Department !== null).map(({ Department }) => ({
								label: Department.Name,
								value: Department.value,
							}))}
							onChange={(options, action) => {
								setFieldValue("department", options);
								setDirty(true);
							}}
							label="Team"
							error={errors.department}
							value={values.department}
							isMulti={UserDepartments.length > 1 ? true : false}
							isDisabled
						/>

						<Button
							style={{ float: "right" }}
							primary
							buttonName="Save"
							type="submit"
							loading={updating || updateLoading}
							disabled={dirty === false}
						/>
						<div style={{ clear: "both" }}></div>
					</form>
				)}
			</Formik>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
		</Container>
	);
};

export default Profile;
