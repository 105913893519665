import React, { useRef, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Container } from "./styles";

/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const ViewLocation = ({ lat, long }) => {
	const map = useRef(null);
	const mapContainer = useRef(null);
	const theme = useTheme();

	useEffect(() => {
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: "mapbox://styles/mapbox/streets-v11",
			center: [long, lat],
			zoom: 14,
			showZoom: true,
		});
		map.current.addControl(new mapboxgl.FullscreenControl());
		const marker = new mapboxgl.Marker({
			color: "#DC4A3E",
			draggable: true,
		})
			.setLngLat([long, lat])
			.addTo(map.current);
		const nav = new mapboxgl.NavigationControl({
			showZoom: true,
			showCompass: false,
		});
		map.current.addControl(nav, "top-right");
	}, []);
	return (
		<Container>
			<div id="map" ref={mapContainer} className="map-container"></div>
		</Container>
	);
};

export default ViewLocation;
