import React from "react";
import { useQuery } from "@apollo/client";
import Chart from "../../../Components/Chart/chart";
import { NoData } from "./no-data";

const ContactsChart = ({ data }) => {


	if (!data?.reporting_ContactsCreatedByInbox[0]) return <NoData>No Data</NoData>;

	return (
		<Chart
			chartData={data}
			chartDataKey="reporting_ContactsCreatedByInbox"
			legendName="Contacts Added"
		/>
	);
};

export default ContactsChart;
