import styled from "styled-components";

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
`;

export const LoadingContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
`;
