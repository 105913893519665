import React from "react";
import { GET_LEAD_DATA } from "./query";
import { useQuery } from "@apollo/client";
import Pill from "../Pill/pill.component";
import { LeadDetailContainer, Container, StyledDate, Header } from "./styles";
import { FloatRight, IconButtonBorder, Box } from "../../Styles/global";

const LeadData = ({ contactId }) => {
	const { data, error, loading } = useQuery(GET_LEAD_DATA, {
		variables: {
			contactId,
		},
	});

	const handleLinkClick = (url) => {
		window.open(url, "_blank");
	};

	if (loading) return null;
	if (error) return <p>An error occured getting lead data</p>;
	if (!data.msgbox_ContactExtData[0]) return null;

	return (
		<>
			{data.msgbox_ContactExtData.map((leadData) => {
				const data = JSON.parse(leadData.Data);
				console.log("leadData", leadData);
				console.log("data:", data);
				const time = new Date(leadData.CreatedOn).toLocaleTimeString();
				const date = new Date(leadData.CreatedOn).toDateString();
				return (
					<Container>
						<LeadDetailContainer>
							<h4>Lead Source</h4>
							<FloatRight>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									{data.url && (
										<IconButtonBorder
											onClick={() => {
												handleLinkClick(data.url);
											}}
											icon="fas fa-external-link-alt"
										/>
									)}

									<Pill name={data.leadSource} />
								</div>
							</FloatRight>
						</LeadDetailContainer>
						{leadData["propertyReference:"] && (
							<LeadDetailContainer>
								<Header>Reference</Header>
								<FloatRight>
									<p>{leadData["propertyReference:"]}</p>
								</FloatRight>
							</LeadDetailContainer>
						)}
						{leadData["propertyReference"] && (
							<LeadDetailContainer>
								<Header>Reference</Header>
								<FloatRight>
									<p>{leadData["propertyReference"]}</p>
								</FloatRight>
							</LeadDetailContainer>
						)}
						{data["propertyAddress:"] && (
							<Box>
								<Header>Address</Header>
								<p>{data["propertyAddress:"]}</p>
							</Box>
						)}
						{data["propertyAddress"] && (
							<Box>
								<Header>Address</Header>
								<p>{data["propertyAddress"]}</p>
							</Box>
						)}
						{data["formData"] && (
							<Box>
								<Header>Form Data</Header>
								<p>{data["formData"]}</p>
							</Box>
						)}
						{data.propDescription && (
							<Box>
								<Header>Description</Header>
								<p>{data.propDescription}</p>
							</Box>
						)}
						{data.email && (
							<Box>
								<Header>Email</Header>
								<p>{data.email}</p>
							</Box>
						)}
						{data.comments && (
							<Box>
								<Header>Comments</Header>
								<p>{data.comments}</p>
							</Box>
						)}
						<StyledDate>{date + " " + time}</StyledDate>
						<div style={{ clear: "both" }}></div>
					</Container>
				);
			})}
		</>
	);
};

export default LeadData;
