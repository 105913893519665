import React, { useState } from "react";
import { PageContent, TabList, TabItem } from "./styles";
import TemplateMessages from "../../Components/TemplateMessages/template-messages.comonent";
import Notifications from "../../Components/Notifications/notifications.component";
import ReactGA from "react-ga4";

const BroadCast = ({ rootStore }) => {
	const [sentVisible, setSentVisible] = useState(true);
	const [templateVisible, setTemplateVisible] = useState(false);

	const toggleContent = (type) => {
		switch (type) {
			case "sent":
				setSentVisible(true);
				setTemplateVisible(false);
				break;
			case "template":
				setSentVisible(false);
				setTemplateVisible(true);
				break;
			default:
				break;
		}
	};

	ReactGA.send({ hitType: "pageview", page: "/broadcast" });

	return (
		<PageContent>
			<div>
				<TabList>
					<TabItem
						onClick={() => {
							toggleContent("sent");
						}}
						active={sentVisible}
					>
						Sent
					</TabItem>
					<TabItem
						onClick={() => {
							toggleContent("template");
						}}
						active={templateVisible}
					>
						Templates
					</TabItem>
				</TabList>
			</div>
			<div>
				{templateVisible ? <TemplateMessages rootStore = {rootStore} /> : <Notifications rootStore = {rootStore} />}
			</div>
		</PageContent>
	);
};

export default BroadCast;
