import { useState, useEffect, useContext } from "react";
import { Bot, Container } from "./styles";
import client from "../../Apollo";
import { GET_BOT_STATUS, TOGGLE_BOT } from "./query";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { $reuseableDialog, $selectedConversation } from "../../store";
import ReactGA from "react-ga4";
import { UserContext } from "../../Providers/user-provider";
import useWebSocket from "react-use-websocket";

const RobotToggle = () => {

	const selectedConversation = useReactiveVar($selectedConversation);
	const [botStatus, setBotStatus] = useState(null);
	const [updateBotStatus, { loading, data, error }] = useMutation(TOGGLE_BOT);
	const { addToast } = useToasts();
	const userContext = useContext(UserContext)

	useEffect(() => {
		if (selectedConversation) {
			fetchBotState();
		}
	}, [selectedConversation]);

	const fetchBotState = async () => {
		const response = await client.query({
			fetchPolicy: "network-only",
			query: GET_BOT_STATUS,
			variables: {
				conversationId: selectedConversation,
			},
			skip: selectedConversation != -1 ? false : true,
		});
		if (response.data.msgbox_Conversation[0]) {
			setBotStatus(response.data.msgbox_Conversation[0].IsBotActive);
		}
	};

	const { lastMessage } = useWebSocket(
		`${process.env.REACT_APP_WSAPI_URL}?orgid=${userContext.orgId}&token=${localStorage.getItem("access_token")}`,
		{
			reconnectAttempts: 100,
			reconnectInterval: (attemptNumber) =>
				Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
			share: true,
			shouldReconnect: (closeEvent) => true,
			onError: handleConnectionFailure,
		}
	);

	function handleConnectionFailure() {
		$reuseableDialog({
			visible: true,
			title: "Lost connection to the server",
			body: "You must refresh the page or no new messages will show",
			onPress: () => window.location.reload(),
			button: "Refresh",
		})
	}
	useEffect(() => {
		if (lastMessage && lastMessage.data) {
			const incomingData = JSON.parse(lastMessage.data);
			if(incomingData.conversationId == selectedConversation && incomingData.type == "conversation_update") {
				fetchBotState()
			}
		}
	}, [lastMessage]);


	const toggleBot = async () => {
		await updateBotStatus({
			variables: {
				conversationId: selectedConversation,
				status: !botStatus,
			},
		});
		if (botStatus === true) {
			addToast("Bot turned off", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Widgets",
				action: "BotDisabled",
			});
		} else {
			addToast("Bot turned on", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Widgets",
				action: "BotEnabled",
			});
		}
		setBotStatus(!botStatus);
	};

	return (
		<Container
			data-tip={botStatus === true ? "Turn bot off" : "Turn bot on"}
		>
			<Bot onClick={toggleBot} status={botStatus}>
				<img src="/images/bot.png" />
			</Bot>
		</Container>
	);
};

export default RobotToggle;
