import React, { useState, useEffect } from "react";
import {
	Container,
	ImagePreview,
	DownloadButton,
	SmallImage,
	LargeImage,
	ModalContentContainer,
	ModalButtonsContainer,
	MessageButton,
} from "./styles";
import Modal from "react-modal";
import Button from "../Button/button.component";
import { formatString } from "../../helpers/formatString";

Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		height: "90%",
		width: "90%",
		padding: "50px",
	},
	overlay: {
		backgroundColor: "rgba(0,0,0,0.7)",
	},
};

const AttachmentPreview = ({
	filename,
	location,
	type,
	caption,
	messageId,
}) => {
	const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
	const audioId = `audio-${messageId}`;

	// useEffect(() => {
	// 	if (type.includes("audio")) {
	// 		const audioControl = document.querySelector(`#${audioId}`);
	// 		if (audioControl) {
	// 			audioControl.load();
	// 		}
	// 	}
	// }, [audioId, type]);

	// setTimeout(function () {
	// 	var audio = document.getElementById(audioId);
	// 	console.log("audio", audio.duration);
	// }, 100);

	const audioOnError = (err) => {
		console.log("fired", err);
		console.log("message", messageId);

		// const audioControl = document.querySelector(`#${audioId}`);
		// if (audioControl) {
		// 	audioControl.load();
		// }
	};
	const open = () => setImagePreviewOpen(true);
	const close = () => setImagePreviewOpen(false);

	const body = formatString(caption);

	if (type.includes("image")) {
		return (
			<>
				<Modal
					isOpen={imagePreviewOpen}
					onRequestClose={close}
					style={customStyles}
				>
					<ModalContentContainer>
						<ModalButtonsContainer>
							<Button
								primary
								buttonName="Close"
								onClick={close}
							/>
							<a href={location}>
								<DownloadButton
									primary
									icon={"fas fa-download"}
								/>
							</a>
						</ModalButtonsContainer>
						<LargeImage src={location} />
					</ModalContentContainer>
				</Modal>
				<ImagePreview onClick={open}>
					<SmallImage src={location} />
					<p>
					{body.map((item, index) => {
						if (item.bold && item.italic) {
							return (
								<p
									key={index}
									style={{
										fontWeight: "bold",
										fontStyle: "italic",
									}}
								>
									{item.text}
								</p>
							);
						} else if (item.italic) {
							return (
								<p key={index} style={{ fontStyle: "italic" }}>
									{item.text}
								</p>
							);
						} else if (item.bold) {
							return (
								<p key={index} style={{ fontWeight: "bold" }}>
									{item.text}
								</p>
							);
						} else if (item.button) {
							return <MessageButton>{item.text}</MessageButton>
						}{
							return <p key={index}>{item.text}</p>;
						}
					})}
				</p>
				</ImagePreview>
			</>
		);
	} else if (type.includes("audio")) {
		return (
			<audio id={audioId} preload="none" controls>
				<source src={location} onError={audioOnError} />
				<p>
					{body.map((item, index) => {
						if (item.bold && item.italic) {
							return (
								<p
									key={index}
									style={{
										fontWeight: "bold",
										fontStyle: "italic",
									}}
								>
									{item.text}
								</p>
							);
						} else if (item.italic) {
							return (
								<p key={index} style={{ fontStyle: "italic" }}>
									{item.text}
								</p>
							);
						} else if (item.bold) {
							return (
								<p key={index} style={{ fontWeight: "bold" }}>
									{item.text}
								</p>
							);
						} else if (item.button) {
							return <MessageButton>{item.text}</MessageButton>
						}{
							return <p key={index}>{item.text}</p>;
						}
					})}
				</p>
			</audio>
		);
	} else if (type.includes("video")) {
		return (
			<div>
				<video width="250" height="200" controls src={location}></video>{" "}
				<p>
					{body.map((item, index) => {
						if (item.bold && item.italic) {
							return (
								<p
									key={index}
									style={{
										fontWeight: "bold",
										fontStyle: "italic",
									}}
								>
									{item.text}
								</p>
							);
						} else if (item.italic) {
							return (
								<p key={index} style={{ fontStyle: "italic" }}>
									{item.text}
								</p>
							);
						} else if (item.bold) {
							return (
								<p key={index} style={{ fontWeight: "bold" }}>
									{item.text}
								</p>
							);
						} else if (item.button) {
							return <MessageButton>{item.text}</MessageButton>
						}{
							return <p key={index}>{item.text}</p>;
						}
					})}
				</p>
			</div>
		);
	} else {
		return (
			<Container>
				{type === "application/pdf" && (
					<>
					<p>
						<i className="fas fa-file-pdf"></i>
					{body.map((item, index) => {
						if (item.bold && item.italic) {
							return (
								<p
									key={index}
									style={{
										fontWeight: "bold",
										fontStyle: "italic",
									}}
								>
									{item.text}
								</p>
							);
						} else if (item.italic) {
							return (
								<p key={index} style={{ fontStyle: "italic" }}>
									{item.text}
								</p>
							);
						} else if (item.bold) {
							return (
								<p key={index} style={{ fontWeight: "bold" }}>
									{item.text}
								</p>
							);
						} else if (item.button) {
							return <MessageButton>{item.text}</MessageButton>
						}{
							return <p key={index}>{item.text}</p>;
						}
					})}
					<a href={location}>{filename} (download)</a>
				</p>
					</>
				)}
				{type !== "application/pdf" && (
					<>
						<i className="far fa-file"></i>
						<a href={location}>{filename} (download)</a>
					</>
				)}
			</Container>
		);
	}
};

export default AttachmentPreview;
