import styled from "styled-components";
import { device } from "../../Styles/constants";

export const Container = styled.div`
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	padding: 24px;
	background-color: ${(props) => props.theme.colours.light_0};
	/* margin: 24px; */
	@media (max-width: ${device.tablet}) {
		width: 90% !important;
	}
	@media (min-width: ${device.desktop}) {
		width: 30% !important;
	}
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
