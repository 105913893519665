import React from "react";
import PropTypes from "prop-types";
import { InputErrorMessage, Label } from "../../Styles/global";
import { Checkbox } from "./styles";

const CheckBox = ({
	name,
	error,
	onChange,
	checked,
	label,
	required,
	...rest
}) => {
	return (
		<div>
			<div>
				<Label htmlFor={name}>{label + (required ? "*" : "")} </Label>
				<Checkbox
					name={name}
					id={name}
					type="checkbox"
					onChange={onChange}
					checked={checked}
					{...rest}
				/>
			</div>
			{error && (
				<div>
					<InputErrorMessage>{error}</InputErrorMessage>
				</div>
			)}
		</div>
	);
};

CheckBox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	error: PropTypes.string,
};

export default CheckBox;
