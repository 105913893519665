import React, { useContext, useEffect, useState } from "react";
import Input from "../Input/input.component";
import { Container } from "./styles";
import { GET_TAGS, ADD_TAG, DELETE_TAG } from "./query";
import { useQuery, useMutation } from "@apollo/client";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import Pill from "../Pill/pill.component";
import { useToasts } from "react-toast-notifications";
import { UserContext } from "../../Providers/user-provider";

const SettingsTags = () => {
	const [tagName, setTagName] = useState("");
	const { error, data, refetch } = useQuery(GET_TAGS);
	const [addTag, {}] = useMutation(ADD_TAG);
	const { addToast } = useToasts();
	const [deleteTag] = useMutation(DELETE_TAG)
	const userContext = useContext(UserContext)
	useEffect(() => {
		document.addEventListener("keypress", handleEnterKeyDown);
		return () => {
			document.removeEventListener("keypress", handleEnterKeyDown);
		};
	}, [tagName]);

	const handleEnterKeyDown = async (event) => {
		if (tagName !== "") {
			if (event.key === "Enter") {
				addTag({
					variables: { name: tagName },
					refetchQueries: [{ query: GET_TAGS }],
				});
				setTagName("");
				addToast(`${tagName} added`, {
					appearance: "success",
					autoDismiss: true,
				});
			}
		}
	};

	const handleAddTag = async () => {
		if (tagName !== "") {
			addTag({
				variables: { name: tagName },
				refetchQueries: [{ query: GET_TAGS }],
			});
			setTagName("");
			addToast(`${tagName} added`, {
				appearance: "success",
				autoDismiss: true,
			});
		}
	};

	const handleChange = (event) => {
		setTagName(event.target.value);
	};



	const handleDelete = async (tag) => {
		await deleteTag({
			variables: {
				tag: tag.TagId
		}})
		addToast(`${tag.Name} Deleted`, {
			appearance: "success",
			autoDismiss: true,
		});
		refetch()
	}

	if (error) return <p>Oops... something went wrong</p>;

	if (!data) return <LoadingSpinner />;

	return (
		<Container>
			<div className="add-tag">
				<Input
					onIconClick={handleAddTag}
					onChange={handleChange}
					value={tagName}
					placeholder="Add tag"
					hasAction
					icon="fas fa-plus"
					label=""
				/>
			</div>
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				{data.msgbox_Tag.length < 1 ? (
					<p>Use the input above to add tags</p>
				) : (
					data.msgbox_Tag.map((tag) => {
						return <Pill customStyles={{display: "flex", flexDirection: 'row'}} key={tag.Name} hasDelete={userContext.role === "orgadmin" ? true : false} onDelete={() => handleDelete(tag)} name={tag.Name} />;
					})
				)}
			</div>
		</Container>
	);
};

export default SettingsTags;
