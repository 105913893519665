import React from "react";
import Button from "../../../../Components/Button/button.component";
import { ModalDescription } from "../../../../Styles/global";
import { COLUMNS_PATTERN } from "../validation";
import { ErrorsTable, TitleWrapper } from "./styles";

const ImportErrors = ({ fileError, dataErrors, onClose }) => (
	<ModalDescription>
		<TitleWrapper>
			{fileError && (
				<>
					<i className="fas fa-exclamation-triangle"></i>
					<h2>{fileError}</h2>
					{fileError === "Too few columns" && (
						<div>
							Expected columns:{" "}
							{COLUMNS_PATTERN.map((x) => `${x}		`)}
						</div>
					)}
				</>
			)}

			{dataErrors.length > 0 && (
				<>
					<i className="fas fa-exclamation-triangle"></i>
					<h2>Your file is filled incorrectly</h2>
					<div>
						We found problems in your file, in order to upload this
						file you have to correct problems listed below.
					</div>
				</>
			)}

			{dataErrors.length > 0 && (
				<ErrorsTable>
					{dataErrors.map((err) => (
						<div>
							<div>
								{err.rowNumber} {err.rowNumber && "row"}
							</div>
							<div>{err.error}</div>
						</div>
					))}
				</ErrorsTable>
			)}
			<Button primary buttonName="OK" onClick={onClose} />
		</TitleWrapper>
	</ModalDescription>
);

export default ImportErrors;
