import { useMutation, useQuery } from "@apollo/client";
import Button from "../../Button/button.component";
import LoadingSpinner from "../../LoadingSpinner/loading-spinner.component";
import { LoadingContainer } from "../styles";
import EditInboxSettings from "./edit-inbox-settings";
import { GET_INBOX_CONFIG, INSERT_INBOX_CONFIG } from "./query";

const InboxSettings = ({ rootStore, id }) => {
	const { data, loading } = useQuery(GET_INBOX_CONFIG, {
		variables: {
			inboxId: id,
		},
	});

	const [insertNewInboxConfig, { loading: insertNewInboxLoading }] =
		useMutation(INSERT_INBOX_CONFIG, {
			variables: {
				inboxId: id,
			},
			refetchQueries: [
				{
					query: GET_INBOX_CONFIG,
					variables: {
						inboxId: id,
					},
				},
			],
		});

	if (loading) {
		return (
			<LoadingContainer>
				<LoadingSpinner />;
			</LoadingContainer>
		);
	}
	const inboxConfig = data.msgbox_InboxConfib[0];
	return (
		<div>
			{inboxConfig ? (
				<EditInboxSettings config={inboxConfig} id={id} rootStore={rootStore} />
			) : (
				<Button
					style={{ float: "left" }}
					primary
					onClick={insertNewInboxConfig}
					buttonName="Create configuration for inbox"
					loading={insertNewInboxLoading}
				/>
			)}
		</div>
	);
};

export default InboxSettings;
