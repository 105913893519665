import styled from "styled-components";

export const Container = styled.div`
	overflow-y: auto;
	height: calc(100% - 115px);
	contain: strict;

	::-webkit-scrollbar {
		width: 20px;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.colours.light_3};
		border-radius: 20px;
		border: 6px solid transparent;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: ${(props) => props.theme.colours.light_4};
	}
`;

export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100% - 165px);
`;
