export const defautOpeningHours = {
	monday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 0,
	},
	tuesday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 1,
	},
	wednesday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 2,
	},
	thursday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 3,
	},
	friday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 4,
	},
	saturday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 5,
	},
	sunday: {
		toggle: false,
		open: "",
		close: "",
		dayCode: 6,
	},
};

export const daysMap = {
	monday: 0,
	tuesday: 1,
	wednesday: 2,
	thursday: 3,
	friday: 4,
	saturday: 5,
	sunday: 6,
};

export const defaultErrors = {
	monday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
	tuesday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
	wednesday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
	thursday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
	friday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
	saturday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
	sunday: {
		openError: "",
		closeError: "",
		rangeError: "",
	},
};
