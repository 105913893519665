import { gql } from "@apollo/client";

export const ADD_WEBHOOK = gql`
	mutation addWebhook($targetUrl: String!, $type: String!) {
		insert_msgbox_ZapierWebhook(
			objects: { Target_Url: $targetUrl, Type: $type, IsZapier: false }
		) {
			affected_rows
		}
	}
`;

export const SET_WEBHOOK_DISABLED= gql`
	mutation enableWebhook($id: Int) {
		update_msgbox_ZapierWebhook(
			where: { ZapierWebHookId: { _eq: $id } }
			_set: { IsEnabled: false }
		) {
			affected_rows
		}
	}
`;

export const SET_WEBHOOK_ENABLED = gql`
	mutation enableWebhook($id: Int) {
		update_msgbox_ZapierWebhook(
			where: { ZapierWebHookId: { _eq: $id } }
			_set: { IsEnabled: true , FailureCount: 0}
		) {
			affected_rows
		}
	}
`;

export const GET_WEBHOOKS = gql`
	query getWebhooks {
		msgbox_ZapierWebhook(where: {IsZapier: {_eq: false}}, order_by: {ZapierWebHookId: desc}) {
		Target_Url
		Type
		IsZapier
		IsEnabled
		FailureCount
		ZapierWebHookId
		}
	}
`;

export const GET_ZAPIER_WEBHOOKS = gql`
	query getZapierWebhooks {
		msgbox_ZapierWebhook(where: { IsZapier: { _eq: true } }) {
			Target_Url
			Type
			IsZapier
			IsEnabled
			FailureCount
			ZapierWebHookId
		}
	}
`;

export const GET_WEBHOOK_LOGS = gql`
	query getWebhooksLogs(
		$limit: Int
		$offset: Int
		$order_by: msgbox_ZapierWebhookLog_order_by!
	) {
		msgbox_ZapierWebhookLog(
			limit: $limit
			offset: $offset
			order_by: [$order_by]
		) {
			CreatedAt
			Type
			ResultCode
			Result
			ZapierWebhook {
				Target_Url
				Type
				IsZapier
				IsEnabled
				FailureCount
			}
		}
		# msgbox_ZapierWebhookLog_aggregate {
		# 	aggregate {
		# 		count
		# 	}
		# }
	}
`;
