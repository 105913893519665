export const KEYWORD_ONLY = "KeywordOnly";
export const KEYWORD_ANYWHERE = "KeywordAnywhere";
export const KEYWORD_WORD = "KeywordWord";
export const PHRASE = "Phrase";

export const matchTypeNiceName = {
	KeywordOnly: "Keyword Only",
	KeywordAnywhere: "Keyword Anywhere",
	KeywordWord: "Keyword as word",
	Phrase: "Phrase"
};
