import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import client from "../../../../Apollo";
import {
	GET_ACCOUNT,
	GET_RESELLER_NAME,
} from "../../../../Components/APIAccountsSelect/query";
import Button from "../../../../Components/Button/button.component";
import MsgBoxSelect from "../../../../Components/MsgBoxSelect/msg-box-select.component";
import { getUserId } from "../../../../helpers/functions";
import { FormField, Label } from "../../../../Styles/global";
import { importContactsValidationSchema } from "../validation";
import { useLazyQuery } from "@apollo/client";

export const IMPORT_OPTIONS = {
	SKIP: "skip",
	REPLACE: "replace",
	MERGE: "merge",
};

const SelectAccount = ({ setSelectedAccount, rootStore }) => {
	const [accountDefault, setAccountDefault] = useState("");
	const [accounts, setAccounts] = useState([]);
	const { addToast } = useToasts();
	const inboxStore = rootStore.getInboxStore();

	const orgId = localStorage.getItem("org_id");
	const [resellerName, setResellerName] = useState("");
	const [getResellerName] = useLazyQuery(GET_RESELLER_NAME);
	const [importButtonDisabled, setImportButtonDisabled] = useState(false);
	const [filteredAccounts, setFilteredAccounts] = useState([]);
	const [err, setErr] = useState("");

	function checkBulkImportAllowed(options) {
		console.log(options, "< opts");
		const bulkImportAllowed = inboxStore.isBulkImportContactAllowed(
			options.inboxId
		);
		console.log(bulkImportAllowed);
		if (bulkImportAllowed === true) {
			setImportButtonDisabled(false);
			setErr("");
		} else {
			setImportButtonDisabled(true);
			setErr(
				`Your current plan doesn't permit bulk contact imports for this inbox. To upgrade please contact ${resellerName}`
			);
		}
	}

	const loadAccountOptions = async () => {
		try {
			const response = await client.query({
				query: GET_ACCOUNT,
				variables: {
					userId: getUserId(),
				},
			});
			const options = response.data.msgbox_User[0].UserInboxes.filter(
				(item) => item.Inbox
			).map((inbox) => ({
				label: inbox.Inbox.APIAccount.Name,
				value: inbox.Inbox.APIAccount.APIAccountId,
				inboxId: inbox.Inbox.InboxId,
			}));
			const validBulkImportInboxes = options.filter((option) => {
				return (
					inboxStore.isBulkImportContactAllowed(option.value) === true
				);
			});
			if (options.length === 1) {
				setAccountDefault(options[0]);
			}
			setFilteredAccounts(validBulkImportInboxes);
			setAccounts(options);
		} catch (error) {
			console.log("error", error);
			addToast("An error occured", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	useEffect(() => {
		loadAccountOptions();
	}, []);

	useEffect(() => {
		fetchResellerName();
	}, [orgId]);

	const fetchResellerName = async () => {
		let result = await getResellerName({
			variables: {
				orgId: orgId,
			},
		});
		setResellerName(result.data.msgbox_Organisation[0].Reseller.Name);
	};

	return (
		<>
			<div>Please select account to be able to upload contacts.</div>
			<Formik
				initialValues={{
					account: accountDefault,
					importOption: IMPORT_OPTIONS.SKIP,
				}}
				onSubmit={(values) => {
					setSelectedAccount(values);
				}}
				validationSchema={importContactsValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ handleSubmit, setFieldValue, values, errors }) => (
					<form id="select_account" onSubmit={handleSubmit}>
						<Field
							placeholder="Select Account"
							label="Select Account"
							key={1}
							name="account"
							onChange={(options) => {
								checkBulkImportAllowed(options);
								setFieldValue("account", options);
							}}
							options={accounts}
							value={values.account}
							error={errors.account}
							component={MsgBoxSelect}
							toolTipText="Select an account that you wish messages to new contact to be sent from"
							hasTooltip
							required
						/>

						<FormField>
							<Label id="import-option">
								Duplicated contacts*
							</Label>

							<div
								role="group"
								aria-labelledby="import-option"
								style={{ display: "flex" }}
							>
								<label style={{ marginRight: "24px" }}>
									<input
										type="radio"
										id={IMPORT_OPTIONS.SKIP}
										name="importOption"
										value={IMPORT_OPTIONS.SKIP}
										onChange={() =>
											setFieldValue(
												"importOption",
												IMPORT_OPTIONS.SKIP
											)
										}
										checked={
											values.importOption ===
											IMPORT_OPTIONS.SKIP
										}
									/>
									Skip contacts
								</label>
								<label style={{ marginRight: "24px" }}>
									<input
										type="radio"
										id={IMPORT_OPTIONS.REPLACE}
										name="importOption"
										value={IMPORT_OPTIONS.REPLACE}
										onChange={() =>
											setFieldValue(
												"importOption",
												IMPORT_OPTIONS.REPLACE
											)
										}
										checked={
											values.importOption ===
											IMPORT_OPTIONS.REPLACE
										}
									/>
									Replace existing tags
								</label>{" "}
								<label style={{ marginRight: "24px" }}>
									<input
										type="radio"
										id={IMPORT_OPTIONS.MERGE}
										name="importOption"
										value={IMPORT_OPTIONS.MERGE}
										onChange={() =>
											setFieldValue(
												"importOption",
												IMPORT_OPTIONS.MERGE
											)
										}
										checked={
											values.importOption ===
											IMPORT_OPTIONS.MERGE
										}
									/>
									Merge tags
								</label>
							</div>
						</FormField>
						{err === "" ? null : (
							<div style={{ color: "red", marginBottom: "15px" }}>
								{err}
							</div>
						)}
						<Button
							primary
							type="submit"
							buttonName="Start import"
							disabled={importButtonDisabled}
						/>
					</form>
				)}
			</Formik>
		</>
	);
};

export default SelectAccount;
