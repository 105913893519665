import styled from "styled-components";

export const modalCustomStyles = {
	content: {
		margin: "0 auto",
		width: "40%",
	},
	overlay: {
		backgroundColor: "rgba(0,0,0,0.5)",
	},
};
// @nathan make this a global thing for each page
export const PageContent = styled.div`
	margin: 24px;
`;

export const TabList = styled.ul`
	display: flex;
`;

export const TabItem = styled.li`
	background-color: ${(props) =>
		props.active
			? props.theme.colours.light_0
			: props.theme.colours.light_3};
	border-radius: 0 15px 0 0;
	padding: 6px 12px;
	cursor: pointer;
	color: ${(props) =>
		props.active ? props.theme.colours.primary : props.theme.colours.dark};
	font-weight: ${(props) => props.active && 600};
`;
