export const PHONE_REGEX = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d/;

function hideNumbers(num, canSeeNumbers) {
    if (!canSeeNumbers && num && num.length > 3 && PHONE_REGEX.test(num)) {
        var lastFour = num.substring(num.length - 3);
        var xString = 'x'.repeat(num.length - 3);
        return xString + lastFour;
    } else {
        return num;
    }
}

export default hideNumbers