import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import Input from "../Input/input.component";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import useOutsideAlerter from "../../hooks/detect-outside";
import PropTypes from "prop-types";
import { Container, DatePickerContainer } from "./styles";
import { useTheme } from "styled-components";
import { format, formatISO, endOfDay, startOfDay } from "date-fns";

const MsgBoxDatePicker = ({
	onDateRangeChange,
	table,
	hasTooltip,
	tooltipText,
	defaultRange,
}) => {
	const wrapperRef = useRef(null);
	const outsideClick = useOutsideAlerter(wrapperRef);
	const theme = useTheme();
	const today = new Date();
	const [datePickerVisible, toggleDatePicker] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: defaultRange ? defaultRange.start : new Date(),
		endDate: defaultRange ? defaultRange.end : new Date(),
		key: "selection",
	});
	const [preview, setPreview] = useState("");

	useEffect(() => {
		// on outside click close date range
		toggleDatePicker(false);
	}, [outsideClick]);

	/**open and closing date picker handlers */
	useEffect(() => {
		if (defaultRange) {
			const previewStart = format(defaultRange.start, "dd/MM/yyyy");
			const previewEnd = format(defaultRange.end, "dd/MM/yyyy");
			const preview = previewStart + "  to  " + previewEnd;
			setPreview(preview);
		}

		const togglePicker = (event) => {
			if (event.target.classList.value === "rdrStaticRangeLabel") {
				toggleDatePicker(false);
			}
			if (event.key === "Escape") {
				toggleDatePicker(false);
			}
		};
		document.addEventListener("click", togglePicker);
		document.addEventListener("keydown", togglePicker);
		return () => {
			document.removeEventListener("click", togglePicker);
			document.removeEventListener("keydown", togglePicker);
		};
	}, []);

	/**
	 * Set date range for this component and send formatted date range to the parent.
	 */
	const handleDateRangeChange = (selection) => {
		const range = {
			startDate: selection.selection.startDate,
			endDate: selection.selection.endDate,
		};

		// Set range and preview for this component
		setDateRange({
			...dateRange,
			...range,
		});
		const previewStart = format(range.startDate, "dd/MM/yyyy");
		const previewEnd = format(range.endDate, "dd/MM/yyyy");
		const preview = previewStart + "  to  " + previewEnd;
		setPreview(preview);

		// Range for parent
		const start = formatISO(startOfDay(range.startDate));
		const end = formatISO(endOfDay(range.endDate));
		onDateRangeChange({
			start,
			end,
		});
	};

	const clearRange = () => {
		onDateRangeChange({
			start: null,
			end: null,
		});
		setPreview("");
		toggleDatePicker(false);
	};

	return (
		<Container
			table={table}
			onClick={() => {
				toggleDatePicker(true);
			}}
			ref={wrapperRef}
		>
			<Input
				hasTooltip={hasTooltip}
				toolTipText={tooltipText}
				table={table}
				readOnly
				hasAction
				icon="fas fa-times"
				placeholder="Select..."
				label="Date Range"
				value={preview}
				onIconClick={(event) => {
					clearRange();
				}}
				table
			/>
			<DatePickerContainer>
				{datePickerVisible && (
					<DateRangePicker
						maxDate={today}
						ranges={[dateRange]}
						onChange={handleDateRangeChange}
						rangeColors={[theme.colours.primary]}
					/>
				)}
			</DatePickerContainer>
		</Container>
	);
};

MsgBoxDatePicker.defautProps = {
	hasTooltip: false,
	toolTipText: "",
	defaultRange: null,
};

MsgBoxDatePicker.propTypes = {
	onDateRangeChange: PropTypes.func.isRequired,
};

export default MsgBoxDatePicker;
