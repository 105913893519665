import styled from "styled-components";

export const StyledPill = styled.div`
	text-align: center;
	color: ${(props) =>
		props.customStyles?.color ? props.customStyles.color : "black"};
	display:  ${(props) => props.customStyles?.display ? props.customStyles?.display :  "inline-block"};
	padding: 6px 9px;
	border-radius: 4px;
	margin: 6px;
	border-color: ${(props) =>
		props.customStyles?.borderColor
			? props.customStyles.borderColor
			: props.theme.colours.textDark};
	border-style: ${(props) =>
		props.customStyles?.borderStyle
			? props.customStyles.borderStyle
			: "solid"};
	border-width: ${(props) =>
		props.customStyles?.borderWidth
			? props.customStyles.borderWidth
			: "1px"};
	flex-direction: row;
`;

export const DeleteIcon = styled.i`
	margin-left: 6px;
	&:hover {
		cursor: pointer;
	}
`;
