import styled from "styled-components";

export const Container = styled.div`
	width: 330px;
	background-color: ${(props) => props.theme.colours.light_0};
	.header {
		background-color: ${(props) => props.theme.colours.primary};
		color: ${(props) => props.theme.colours.light_0};
		padding: 18px;
		text-align: center;
		p {
			color: ${(props) => props.theme.colours.light_0};
		}
	}
	.detail {
		display: flex;
		align-items: center;
		margin-top: 3px;
		margin-bottom: 3px;
		i {
			margin-right: 6px;
			font-size: 1.5rem;
		}
	}
	.detail-container {
		padding: 12px;
		display: flex;
		gap: 8px;
	}
`;
