import { gql } from "@apollo/client";

export const ADD_MESSAGE_TO_CONVERSATION = gql`
	mutation MessageMutation(
		$bodyText: String
		$conversationId: Int!
		$attachmentId: Int
	) {
		insert_msgbox_Message(
			objects: [
				{
					BodyText: $bodyText
					ConversationId: $conversationId
					AttachmentId: $attachmentId
				}
			]
		) {
			affected_rows
			returning {
				MessageId
			}
		}
	}
`;

export const GET_API_AND_CONTACT = gql`
	query getApiAndContact($inboxId: Int!, $conversationId: Int!) {
		msgbox_Inbox(where: { InboxId: { _eq: $inboxId } }) {
			APIAccountId
		}
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			ContactId
		}
	}
`;