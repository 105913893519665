import React from "react";
import Modal from "react-modal";
import {
	ModalButtonContainer,
	ModalHeader,
	ModalDescription,
	ModalContentSeperator,
	ModalButton,
} from "./styles";
import PropTypes from "prop-types";

Modal.setAppElement("#root");

const MsgBoxModal = ({
	header,
	description,
	children,
	submit,
	cancel,
	submitButtonName,
	cancelButtonName,
	aria,
	submitId,
	hasIcon,
	icon,
	customModalContentStyles,
	customModalOverlayStyles,
	submitting,
	submitButtonDisabled,
	...reactModalProps
}) => {
	// Handle submit of the content within the modal if the content is not within a form
	const handleClick = () => {
		if (!submitId) {
			submit();
		}
	};

	return (
		<Modal
			aria={aria}
			style={{
				content: {
					overflow: "visible",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					...customModalContentStyles,
				},
				overlay: {
					backgroundColor: "rgba(0,0,0,0.7)",
					...customModalOverlayStyles,
				},
			}}
			{...reactModalProps}
		>
			<div style={{ display: "flex", alignItems: "center" }}>
				{hasIcon ? icon : null}
				<ModalHeader id={aria.labelledby}>{header}</ModalHeader>
			</div>
			<ModalDescription id={aria.describedby}>
				{description}
			</ModalDescription>
			<ModalContentSeperator />
			{children}
			<ModalButtonContainer>
				<ModalButton buttonName={cancelButtonName} onClick={cancel} />
				<ModalButton
					type="submit"
					form={submitId}
					primary
					buttonName={submitButtonName}
					onClick={handleClick}
					loading={submitting}
					disabled={submitButtonDisabled}
				/>
			</ModalButtonContainer>
		</Modal>
	);
};

MsgBoxModal.defaultProps = {
	cancelButtonName: "Cancel",
	submitButtonName: "Submit",
	hasIcon: false,
	submitting: false,
};

MsgBoxModal.propTypes = {
	header: PropTypes.string.isRequired,
	description: PropTypes.string,
	submit: PropTypes.func,
	cancel: PropTypes.func.isRequired,
	submitButtonName: PropTypes.string,
	cancelButtonName: PropTypes.string,
	aria: PropTypes.shape({
		labelledby: PropTypes.string,
		describedby: PropTypes.string,
	}).isRequired,
	customModalStyles: PropTypes.object,
	/**The id of the form that the submit button should submit */
	submitId: PropTypes.string,
	hasIcon: PropTypes.bool,
	icon: PropTypes.element,
	submitting: PropTypes.bool,
};

export default MsgBoxModal;
