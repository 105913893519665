import WaveSpinner from "../LoadingSpinner/wave-spinner";
import { Container, StaffImage, MessageBody, Image } from "./styles";

const PendingMessage = ({ user, image }) => {
	return (
		<Container isIncoming={true}>
			{image !== null && image !== undefined && image !== "" ? (
				<Image
					isIncoming={true}
					src={image ? image : "/images/defaultUser.jpg"}
				/>
			) : (
				<StaffImage isIncoming={true} contactColor={"#29278E"}>
					{user && user.FirstName[0] + user.LastName[0]}
				</StaffImage>
			)}

			<MessageBody isIncoming={true} className="message-body-container">
				<WaveSpinner />
			</MessageBody>
		</Container>
	);
};

export default PendingMessage;
