import { ConversationFiltersButton } from "../ConversationFilters/styles";
import useArchiveConversation from "./use-archive-conversation";

const Archive = () => {
	const archiveConversation = useArchiveConversation();
	return (
		<ConversationFiltersButton
			data-tip="Archive"
			style={{ float: "right" }}
			icon="fas fa-archive"
			onClick={archiveConversation}
		/>
	);
};

export default Archive;
