import { useState, useEffect, useContext } from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { UPDATE_CONTACT_NAME } from "./query";
import MsgBoxModal from "../../../Components/MsgBoxModal/msgbox-modal.component";
import Input from "../../../Components/Input/input.component";
import { PHONE_REGEX } from "../../Contacts/bulk-contact-import/validation";
import { UserContext } from "../../../Providers/user-provider";

const UpdateContactNameModal = ({ isOpen, close, name, contactId }) => {
	const context = useContext(UserContext)
	const canSeeNumbers = context.permissions.canSeeNumbers
	const [nameValue, setNameValue] = useState("");
	const [nameError, setNameError] = useState("");

	useEffect(() => {
		if(name) {
			if(canSeeNumbers && PHONE_REGEX.test(name)){
				setNameValue("")
			} else {
				setNameValue(name)
			}
		}
	}, [name])

	const [updateContact, { error, loading }] =
		useMutation(UPDATE_CONTACT_NAME);

	const submit = () => {
		if (!nameValue) {
			setNameError("Required");
		} else {
			updateContact({
				variables: {
					contactId,
					name: nameValue,
				},
			});
			close();
		}
	};

	return (
		<MsgBoxModal
			header="Edit Contact"
			isOpen={isOpen}
			submit={submit}
			cancel={close}
			description="Edit contact name"
			submitButtonName="Save"
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			onRequestClose={close}
		>
			<Input
				onChange={(e) => setNameValue(e.target.value)}
				value={nameValue}
				label="Contact name"
				error={nameError}
			/>
		</MsgBoxModal>
	);
};

export default UpdateContactNameModal;
