import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const Container = styled.div`
	position: relative;
	margin: 6px;
`;

export const ConversationFiltersButton = styled(IconButton)`
	border: solid 1px ${(props) => props.theme.colours.light_3};
	font-size: 1.4rem;
	height: 38px;
	width: 38px;
	margin-left: 3px;
	border-radius: 5px;
	margin-right: 3px;
`;

export const FlexContainer = styled.div`
	justify-content: space-evenly;
	display: flex;
`;

export const FolderListWrapper = styled.div`
	flex: 3;
	margin-right: 3px;
`;

export const ButtonsContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-evenly;
	i {
		color: grey;
	}
	.filter-count {
		position: absolute;
		top: -5px;
		right: -5px;
		font-size: 0.8rem;
		height: 12px;
		width: 12px;
		color: ${(props) => props.theme.colours.light_0};
		background-color: ${(props) => props.theme.colours.primary};
		border-radius: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 3px;
	}
	.filter {
		position: relative;
		display: flex;
		justify-content: center;
	}
`;
