import styled from "styled-components";

export const Container = styled.div`
	border: solid 1px ${(props) => props.theme.colours.light_3};
	margin: 6px;
	padding: 45px;
	border-radius: 8px;
	overflow-y: auto;
	height: calc(100% - 180px);
	::-webkit-scrollbar {
		width: 20px;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.colours.light_3};
		border-radius: 20px;
		border: 6px solid transparent;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: ${(props) => props.theme.colours.light_4};
	}
`;

export const LoadingSpinnerContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NoMessages = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;
