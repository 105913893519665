import jwt_decode from "jwt-decode";

export default function isAuthenticated() {
	// Check whether the current time is past the
	// access token's expiry time
	const token = localStorage.getItem("access_token");

	if (!token) return false;
	if (token === "undefined") return false;
	const expiresAt = jwt_decode(token).exp;
	const now = Math.round(new Date() / 1000);
	if (expiresAt) {
		return now < expiresAt;
	}
	return false;
}
