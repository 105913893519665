import { gql } from "@apollo/client";

export const GET_SENT_NOTIFICATIONS = gql`
	query getSentNotifications(
		$limit: Int
		$offset: Int
		$order_by: msgbox_BroadcastContact_order_by!
		$status: String
		$start: timestamptz
		$end: timestamptz
		$searchTerm: String
		$inboxes: [Int!]
	) {
		msgbox_BroadcastContact(
			limit: $limit
			offset: $offset
			order_by: [$order_by]
			where: {
				Status: { _eq: $status }
				CreatedOn: { _gt: $start, _lt: $end }
				Broadcast: {
					Template: { Name: { _ilike: $searchTerm } }
					APIAccount: { Inboxes: { InboxId: { _in: $inboxes } } }
				}
			}
		) {
			CreatedBy
			CreatedOn
			ContactId
			Contact {
				Name
				MobileNumber
				ContactId
				Status
			}
			BroadcastId
			Broadcast {
				AttachmentId
				TemplateId
				APIAccountId
				Message
				Template {
					Name
					TemplateId
				}
			}
			BroadcastContactStatus {
				Status
			}
		}
		msgbox_BroadcastContact_aggregate(
			where: {
				Status: { _eq: $status }
				CreatedOn: { _gt: $start, _lt: $end }
				Broadcast: {
					Template: { Name: { _ilike: $searchTerm } }
					APIAccount: { Inboxes: { InboxId: { _in: $inboxes } } }
				}
			}
		) {
			aggregate {
				count
			}
		}
	}
`;
