import { gql } from "@apollo/client";
import client from "../Apollo";

const CHECK_IF_CONTACT_EXISTS = gql`
	query getContact($searchNumber: String!, $searchAccount: Int!) {
		msgbox_Contact(
			where: {
				_and: [
					{ MobileNumber: { _eq: $searchNumber } }
					{ APIAccountId: { _eq: $searchAccount } }
				]
			}
		) {
			Name
			Status
			MobileNumber
			ContactId
			APIAccountId
		}
	}
`;
export const checkContactExists = async (searchNumber, searchAccount) => {
	const response = await client.query({
		query: CHECK_IF_CONTACT_EXISTS,
		variables: {
			searchNumber: searchNumber,
			searchAccount: searchAccount,
		},
		fetchPolicy: "network-only",
	});
	return response.data.msgbox_Contact.length > 0 ? true : false;
};

export const getExistingContact = async (searchNumber, searchAccount) => {
	const response = await client.query({
		query: CHECK_IF_CONTACT_EXISTS,
		variables: {
			searchNumber: searchNumber,
			searchAccount: searchAccount,
		},
		fetchPolicy: "network-only",
	});

	if (response.data.msgbox_Contact.length > 0) {
		return response.data.msgbox_Contact[0];
	} else {
		return null;
	}
};
