import styled from "styled-components";

export const Container = styled.div`
	margin: 6px;
	.info {
		display: flex;
		align-items: center;
		.status {
			margin-left: auto;
		}
	}
`;

export const Flag = styled.img`
	height: 30px;
	margin-left: auto;
`;

export const EditContactButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;

export const NameContainer = styled.div`
	display: flex;
	gap: 6px;
`;
