import React, { useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_CONVERSATION_AUDIT_DATA } from "./getConversationAuditData";
import { $selectedConversation } from "../../store";
import { StaffImage } from "./styles";
import ExpandInput from "./expand.component";

const Expandable = () => {
	const [isExpanded, setIsExpanded] = useState(false);

	var selectedConversation = useReactiveVar($selectedConversation);
	const { data, loading, error } = useQuery(GET_CONVERSATION_AUDIT_DATA, {
		fetchPolicy: "no-cache",
		variables: {
			conversationId: selectedConversation ? selectedConversation : -1,
		},
	});
	// console.log("Data for audit conv :", data);
	// console.log("Conversation Id :", selectedConversation);

	const handleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div>
			<ExpandInput
				placeholder="Conversation History"
				onIconClick={handleExpand}
				icon={isExpanded ? "far fa-minus-square" : "far fa-plus-square"}
				hasAction
				label="Conversation History"
				readOnly
			/>

			<div>
				{isExpanded ? (
					<div>
						{!loading &&
							data.msgbox_ConversationAudit?.map(
								(user, index) => {
									const ImageUri = user?.User?.signedurl?.url;
									const dateTime = new Date(user.CreatedOn);
									const date = dateTime.toLocaleDateString(
										"en-UK",
										{
											day: "numeric",
											month: "short",
											year: "numeric",
										}
									);
									const time = dateTime.toLocaleTimeString(
										"en-UK",
										{
											hour: "numeric",
											minute: "numeric",
										}
									);

									return (
										<div style={containerStyle}>
											<table style={tableStyle}>
												<tbody>
													<tr key={index}>
														<td
															style={
																tableCellStyle
															}
														>
															{user.User
																.HasImage &&
															ImageUri !== null &&
															ImageUri !==
																undefined ? (
																<img
																	style={
																		userImageStyle
																	}
																	src={
																		ImageUri
																			? ImageUri
																			: "/images/defaultUser.jpg"
																	}
																/>
															) : (
																<StaffImage
																	contactColor={
																		"#29278E"
																	}
																>
																	{user.User &&
																		user.User.FirstName?.substring(
																			0,
																			1
																		) +
																			user.User.LastName?.substring(
																				0,
																				1
																			)}
																</StaffImage>
															)}
														</td>
														<td
															style={
																conversationStyle
															}
														>
															{user.Description}
														</td>
														<td
															style={
																dataTimeStyle
															}
														>
															{date}, {time}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									);
								}
							)}
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default Expandable;

//Table  CSS styles

const inputStyle = {
	focusWithin: {
		outline: "none",
	},
};
const containerStyle = {
	margin: "10px 5px",
	border: "1px solid #ddd",
	borderRadius: "10px",
	overflow: "hidden",
};

const tableStyle = {
	width: "100%",
};

const tableCellStyle = {
	padding: "8px",
	border: "none",
};
const conversationStyle = {
	padding: "8px",
	border: "none",
	fontSize: "10px",
};

const dataTimeStyle = {
	paddingTop: "40px",
	border: "none",
	fontSize: "8px",
};

const userImageStyle = {
	width: "30px",
	height: "30px",
	borderRadius: "50%",
	border: "2px solid #ccc",
};
