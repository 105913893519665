import styled from "styled-components";

export const SelectButton = styled.button`
	width: 120px !important;
	:disabled {
		cursor: not-allowed;
		background: ${(props) => props.theme.colours.light_4};
	}
`;

export const LoadingWrapper = styled.div`
	> div {
		height: 20px;
		width: 20px;
		border-width: 2px;
		margin-right: auto;
		margin-left: auto;
	}
`;

export const IconButton = styled.button`
	width: 20px;
	border-left: 1px solid white !important;
	:disabled {
		cursor: not-allowed;
		background: ${(props) => props.theme.colours.light_4};
	}
`;

export const ButtonsWrapper = styled.div`
	width: 140px !important;
	height: 30px;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	border-radius: 24px;
	overflow: hidden;
	button {
		font-weight: bold;
		border-radius: 0 !important;
		height: 30px !important;
	}
`;

export const Container = styled.div`
	position: relative;
	width: 140px;
	height: 30px;
`;
