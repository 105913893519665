import styled from "styled-components";
import React from "react";

const Container = styled.div`
	display: flex;
	align-items: center;
	border-radius: 6px;
	padding-top: 12px;
	p {
		margin-left: 12px;
		text-align: center;
	}
`;

const UnsubscribedMessage = () => {
	return (
		<Container>
			<p>
				This contact has unsubscribed by using your unsubscribe keyword
				or by blocking you. You cannot send them messages or
				notifications. To find out more about WhatsApp polices{" "}
				<a
					href="https://www.whatsapp.com/legal/business-policy"
					target="_blank"
					rel="noopener noreferrer"
				>
					click here
				</a>
			</p>
		</Container>
	);
};

export default UnsubscribedMessage;
