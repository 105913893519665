import React, { useState, useEffect, useCallback, useContext } from "react";
import MsgBoxModal from "../../MsgBoxModal/msgbox-modal.component";
import { useDropzone } from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import { MAX_FILE_SIZE } from "../../../helpers/file-upload";
import Input from "../../Input/input.component";
import { DropZone, ErrorMsg } from "../../UploadFile/styles";
import { DropZoneWrapper } from "./select-file.components";
import { INVALID_FORMATS } from "../../SendDeferredMessage/gluejar";
import { UserContext } from "../../../Providers/user-provider";
import { useReactiveVar } from "@apollo/client";
import {
	$selectedInbox,
} from "../../../store";

const SelectFile = ({ isOpen, cancel, onAccept }) => {
	const [attachments, setAttachments] = useState([]);
	const [attachmentType, setAttachmentType] = useState("");
	const [caption, setCaption] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const user = useContext(UserContext);
	const selectedInbox = useReactiveVar($selectedInbox);

	const onDrop = useCallback(
		(newAttachment) => {
			if(user.newNaturalChatConfig[selectedInbox].newNaturalChatMedia === false){
				setErrorMsg("Unable to send Media, please contact your reseller");
				return
			}
			setErrorMsg("");
			setAttachments(newAttachment);
		},
		[attachments]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
	});

	useEffect(() => {
		if (attachments.length > 0) {
			const type = attachments[0].type;
			if (type === "application/pdf") setAttachmentType("pdf");
			else if (type.includes("image")) setAttachmentType("image");
			else setAttachmentType("doc");
		}
	}, [attachments]);

	const upload = async () => {
		if (attachments.length <= 0) {
			setErrorMsg("Attachment is required");
		} else if (attachments[0].size > MAX_FILE_SIZE) {
			setErrorMsg("Attachment size too large (max 100MB)");
		} else {
			try {
				setLoading(true);
				const file = attachments[0];
				if (INVALID_FORMATS.includes(file.type)) {
					setErrorMsg("File type not supported");
					setLoading(false);
					return;
				}
				onAccept(file, caption);
				destroyModal();
			} catch (error) {
				console.log("error", error);
				addToast("error uploading attachment", {
					appearance: "error",
					autoDismiss: true,
				});
				setLoading(false);
			}
		}
	};

	const destroyModal = () => {
		setAttachments([]);
		setCaption("");
		cancel();
		setErrorMsg("");
		setAttachmentType("");
		setLoading(false)
	};

	const attachmentIcon = () => {
		if (attachmentType === "pdf")
			return <i className="fas fa-file-pdf"></i>;
		if (attachmentType === "image") return <i className="far fa-image"></i>;
		return <i className="fas fa-file"></i>;
	};

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "description",
			}}
			header="Upload Attachment"
			description="Drag and drop to add an attachment below"
			isOpen={isOpen}
			cancel={() => {
				destroyModal();
			}}
			onRequestClose={() => {
				destroyModal();
			}}
			submitButtonName="Upload Attachment"
			submit={upload}
			submitting={loading}
		>
			<div>
				<DropZoneWrapper>
					<DropZone {...getRootProps({ className: "dropzone" })}>
						<input {...getInputProps()} />
						{attachments.length > 0 ? (
							<div className="placeholder">
								{attachmentIcon()}

								<p>{attachments[0].name}</p>
							</div>
						) : (
							<p>Drag a file here, or click to select a file.</p>
						)}
					</DropZone>
					{errorMsg && errorMsg.length > 0 && (
						<ErrorMsg>{errorMsg}</ErrorMsg>
					)}
				</DropZoneWrapper>
				{attachmentType === "image" && (
					<Input
						label="Caption"
						hasTooltip
						toolTipText="Add a caption to your image"
						value={caption}
						onChange={(event) => setCaption(event.target.value)}
					/>
				)}
			</div>
		</MsgBoxModal>
	);
};

export default SelectFile;
