import styled from "styled-components";

export const Text = styled.p`
	visibility: hidden;
	width: 120px;
	background-color: ${(props) => props.theme.colours.primary};
	color: white;
	text-align: center;
	padding: 5px;
	border-radius: 6px;
	position: absolute;
	z-index: 1;
	font-size: 0.8rem;
	padding: 6px;
`;

export const Container = styled.i`
	position: relative;
	color: ${(props) => props.theme.colours.primary};
	font-size: 1rem;
	margin-left: 6px;
	top: ${(props) => props.right && "3px"};
	display: inline-block;
	float: ${(props) => props.right && "right"};
	&:hover ${Text} {
		visibility: visible;
	}
`;
