import React from "react";
import styled from "styled-components";

const Container = styled.div`
	margin: 12px;
`;

const ExpandableDiagnosticComponent = ({ data }) => {
	return (
		<Container>
			<h4>{data.ZapierWebhook.IsZapier && "Zapier "}Webhook</h4>
			<p>Target Url: {data.ZapierWebhook.Target_Url}</p>
			<p>Type: {data.ZapierWebhook.Type}</p>
			<p>Failure Count: {data.ZapierWebhook.FailureCount}</p>
			<p>Is Enabled: { data.ZapierWebhook.IsEnabled ? "True":"False"}</p>
		</Container>
	);
};

export default ExpandableDiagnosticComponent;
