import React, { useState,useContext } from "react";
import Notes from "../Notes/notes.component";
import EmailEditor from "../Email/email.component";
import { ContactImgPlaceholder } from "../MessagePreview/styles";
import Tags from "../TagsFormField/tags.component";
import Pill from "../Pill/pill.component";
import { ContentDivider } from "../../Styles/global";
import { Container, Flag } from "./styles";
import { useQuery, useReactiveVar } from "@apollo/client";
import { $selectedConversation, $selectedInbox } from "../../store";
import { GET_INFO_PANEL_DATA } from "./query";
import LeadData from "../ExternalLead/lead.component";
import {
	EditContactButton,
	NameContainer,
} from "./styles";
import Expandable from "../Expandable/expandable.component";
import UpdateContactNameModal from "../../Pages/ContactDetails/EditContactName/edit-contact-name";
import hideNumbers from "../../helpers/hideNumber";
import { UserContext } from "../../Providers/user-provider";

const InfoPanel = () => {
	const [editNameModalIsOpen, setEditNameModalOpen] = useState(false);

	var selectedConversation = useReactiveVar($selectedConversation);
	const context = useContext(UserContext)
	const canSeeNumbers = context.permissions.canSeeNumbers
	const selectedInbox = useReactiveVar($selectedInbox)

	const { data, loading, error } = useQuery(GET_INFO_PANEL_DATA, {
		fetchPolicy: "no-cache",
		variables: {
			conversationId: selectedConversation ? selectedConversation : -1,
		},
	});

	if (loading) return null;

	if (error) return null;

	if (
		data &&
		data.msgbox_Conversation[0] &&
		data.msgbox_Conversation[0].Contact
	) {
		const { ContactId, MobileNumber, Name, Status } =
			data.msgbox_Conversation[0].Contact;
		return (
			<Container>
				<div className="info">
					<ContactImgPlaceholder contactColor="#EA2741">
						{Name[0]}
					</ContactImgPlaceholder>
					<div>
					<NameContainer>
						<h3 style={{margin: 6}}>{hideNumbers(Name, canSeeNumbers)}</h3>
						<EditContactButton
							onClick={() =>
								setEditNameModalOpen(true)
							}
						>
							<i className="fas fa-edit"></i>
						</EditContactButton>
						</NameContainer>
						<div className="status">
							<Pill name={Status} />
						</div>
					</div>
				
				</div>
				<ContentDivider leftOffset="-6px" rightOffset="-6px" />
				<div>
					<h4>Basic Information</h4>
					<div style={{ display: "flex", alignItems: "center" }}>
						<div>
							<p>Name: {hideNumbers(Name, canSeeNumbers)}</p>
							<p>Phone Number: {hideNumbers(MobileNumber, canSeeNumbers)}</p>
						</div>

						<Flag
							alt={
								data.msgbox_Conversation[0].Contact.CountryCode
							}
							src={`./Flags/${data.msgbox_Conversation[0].Contact.CountryCode}.svg`}
						/>
					</div>
				</div>
			
				<ContentDivider leftOffset="-6px" rightOffset="-26px" />
				<EmailEditor contactId={ContactId} />
				<Tags
					label="Assign tags to this contact"
					contactId={ContactId}
					tagContacts={true}
				/>
				<ContentDivider leftOffset="-6px" rightOffset="-6px" />
				<Notes contactId={ContactId} />
				<ContentDivider leftOffset="-6px" rightOffset="-6px" />
				<LeadData contactId={ContactId} />
				<UpdateContactNameModal
					name={data.msgbox_Conversation[0].Contact.Name}
					isOpen={editNameModalIsOpen}
					close={() => setEditNameModalOpen(false)}
					contactId={ContactId}
				/>
				<Expandable leftOffset="-6px" rightOffset="-6px"/>
			</Container>
		);
	} else return null;
};

export default InfoPanel;
