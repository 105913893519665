import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const StyledNav = styled.nav`
	background-color: ${(props) => props.theme.colours.light_0};
	transition: 0.3s;
	margin-bottom: 0px !important;
	height: 50px;
	display: flex;
	align-items: center;
	position: sticky;

	.buttons-container {
		display: flex;
		margin-right: 20px;
	}
`;

export const Logo = styled.div`
	margin-left: 12px !important;
`;

export const TopNavButton = styled(IconButton)`
	border: solid 1px ${(props) => props.theme.colours.light_3};
	font-size: 1.4rem;
	height: 38px;
	width: 38px;
	margin-right: 0;
`;
