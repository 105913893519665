import { gql } from "@apollo/client";

export const GET_INFO_PANEL_DATA = gql`
	query getInfoPanelData($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			Contact {
				ContactId
				Name
				Email
				MobileNumber
				Status
				CountryCode
				TagContacts {
					TagId
				}
			}
			ConversationId
		}
	}
`;
