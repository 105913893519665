import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
	query getNotifications($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			UserId
			NotifyMyChats
			NotifyTeamChats
			NotifyUnassignedChats
			NotifyChatsAssignedMe
			BrowserNotifyChats
			MobileNotifyMyChats
			MobileNotifyTeamChats
			MobileNotifyUnassignedChats
			NotifyIncludeContents
		}
	}
`;

export const UPDATE_NOTIFICATIONS = gql`
	mutation updateNotifications(
		$userId: Int!
		$notifyMyChats: Boolean
		$notifyTeamChats: Boolean
		$notifyUnassignedChats: Boolean
		$notifyChatsAssignedMe: Boolean
		$browserNotifyChats: Boolean
		$mobileNotifyMyChats: Boolean
		$mobileNotifyTeamChats: Boolean
		$mobileNotifyUnassignedChats: Boolean
		$NotifyIncludeContents: Boolean
	) {
		update_msgbox_User(
			where: { UserId: { _eq: $userId } }
			_set: {
				NotifyIncludeContents: $NotifyIncludeContents
				NotifyChatsAssignedMe: $notifyChatsAssignedMe
				NotifyTeamChats: $notifyTeamChats
				NotifyUnassignedChats: $notifyUnassignedChats
				NotifyMyChats: $notifyMyChats
				BrowserNotifyChats: $browserNotifyChats
				MobileNotifyMyChats: $mobileNotifyMyChats
				MobileNotifyTeamChats: $mobileNotifyTeamChats
				MobileNotifyUnassignedChats: $mobileNotifyUnassignedChats
			}
		) {
			affected_rows
			returning {
				UserId
			}
		}
	}
`;
