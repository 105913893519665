import { gql } from "@apollo/client";

export const GET_FOLDERS_IN_INBOX = gql`
	query getFoldersInInbox($inboxes: [Int!]) {
		msgbox_Folder(
			where: { InboxId: { _in: $inboxes } }
			order_by: { Order: asc }
		) {
			Name
			InboxId
			IsArchive
			IsSytemFolder
			FolderId
		}
	}
`;
