export const emailParserDelayOptions = [
	{
		label: "1 Minute",
		value: 1,
	},
	{
		label: "2 Minutes",
		value: 2,
	},
	{
		label: "3 Minutes",
		value: 3,
	},
	{
		label: "4 Minutes",
		value: 4,
	},
	{
		label: "5 Minutes",
		value: 5,
	},
	{
		label: "10 Minutes",
		value: 10,
	},
	{
		label: "15 Minutes",
		value: 15,
	},
	{
		label: "30 Minutes",
		value: 30,
	},
	{
		label: "60 Minutes",
		value: 60,
	},
];
