import { gql } from "@apollo/client";

export const GET_MESSAGE_FLAGS = gql`
	query getMessageStatusFlags($messageId: Int!) {
		msgbox_Message(where: { MessageId: { _eq: $messageId } }) {
			MessageId
			IsDelivered
			IsFailed
			IsRead
			IsSent
		}
	}
`;

export const GET_ATTACHMENT = gql`
	query MyQuery($attachmentId: Int!) {
		msgbox_Attachment(where: { AttachmentId: { _eq: $attachmentId } }) {
			Filename
			Location
			MimeType
			signedurl {
				url
			}
		}
	}
`;
