import React from "react";
import { StyledButton } from "./styles";
import PropTypes from "prop-types";

const Button = ({
	onClick,
	buttonName,
	primary,
	tooltipText,
	loading,
	...props
}) => {
	return (
		<StyledButton
			primary={primary}
			onClick={() => {
				onClick();
			}}
			{...props}
		>
			{loading === true ? (
				<i className="fas fa-spinner fa-spin"></i>
			) : (
				<>{buttonName}</>
			)}
		</StyledButton>
	);
};

Button.propTypes = {
	buttonName: PropTypes.string,
	onClick: PropTypes.func,
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	tooltipText: PropTypes.string,
	loading: PropTypes.bool,
};

Button.defaultProps = {
	onClick: () => {},
	loading: false,
};

export default Button;
