import { gql } from "@apollo/client";

export const GET_CHILD_RESPONSES = gql`
	subscription subscribeToChildResponses($parentId: Int) {
		msgbox_BotResponse(
			where: { ParentBotReplyId: { _eq: $parentId } }
			order_by: { Order: asc }
		) {
			BotResponseId
			Reply
			IsDeletable
			RequiresResponse
			Order
		}
	}
`;

export const ADD_CHILD_RESPONSE = gql`
	mutation addChildResponse(
		$order: Int!
		$parentId: Int!
		$reply: String
		$requiresResponse: Boolean!
		$captureAsEmailAddress: Boolean!
		$account: Int!
		$ListMetaData: String
		$ReplyMetaData: String
	) {
		insert_msgbox_BotResponse_one(
			object: {
				Order: $order
				ParentBotReplyId: $parentId
				Reply: $reply
				RequiresResponse: $requiresResponse
				CaptureEmailAddress: $captureAsEmailAddress
				APIAccountId: $account
				ListMetadata: $ListMetaData
				ReplyMetadata: $ReplyMetaData
			}
		) {
			BotResponseId
			Reply
			IsDeletable
			RequiresResponse
			CaptureEmailAddress
			Order
		}
	}
`;

export const DELETE_CHILD_RESPONSE = gql`
	mutation deleteChildResponse($botResponseId: Int!) {
		update_msgbox_BotResponse(where: {BotResponseId: {_eq: $botResponseId}}, _set: {IsDeleted: true, DeletedOn: "now()"}) {
		affected_rows
		returning {
			BotResponseId
			Reply
			IsDeletable
			RequiresResponse
			CaptureEmailAddress
			Order
		}
		}
	}`;

export const UPDATE_CHILD_RESPONSE = gql`
	mutation updateChildResponse(
		$botResponseId: Int!
		$reply: String!
		$requiresResponse: Boolean!
		$captureAsEmailAddress: Boolean!
		$ListMetaData: String
		$ReplyMetaData: String
	) {
		update_msgbox_BotResponse(
			where: { BotResponseId: { _eq: $botResponseId } }
			_set: {
				Reply: $reply
				RequiresResponse: $requiresResponse
				CaptureEmailAddress: $captureAsEmailAddress
				ListMetadata: $ListMetaData
				ReplyMetadata: $ReplyMetaData
			}
		) {
			affected_rows
			returning {
				BotResponseId
				Reply
				IsDeletable
				RequiresResponse
				CaptureEmailAddress
				Order
			}
		}
	}
`;

export const SUBSCRIBE_TO_PARENT_RESPONSE = gql`
	subscription subscribeToParentResponse($botResponseId: Int) {
		msgbox_BotResponse(where: { BotResponseId: { _eq: $botResponseId } }) {
			BotResponseId
			Reply
			RequiresResponse
			CaptureEmailAddress
			ReplyMetadata
			ListMetadata
		}
	}
`;

export const SUBSCRIBE_TO_CHILD_RESPONSES = gql`
	subscription subscribeToChildResponses($parentId: Int) {
		msgbox_BotResponse(
			where: { ParentBotReplyId: { _eq: $parentId } }
			order_by: { Order: asc }
		) {
			BotResponseId
			Reply
			IsDeletable
			RequiresResponse
			CaptureEmailAddress
			Order
			ReplyMetadata
			ListMetadata
		}
	}
`;

export const UPDATE_BOT_RESPONSE_ORDER = gql`
	mutation updateBotResponseOrder($order: Int!, $botResponseId: Int!) {
		update_msgbox_BotResponse(
			where: { BotResponseId: { _eq: $botResponseId } }
			_set: { Order: $order }
		) {
			returning {
				BotResponseId
				Reply
				IsDeletable
				RequiresResponse
				CaptureEmailAddress
				Order
			}
		}
	}
`;
