import styled from "styled-components";
import { device } from "../../Styles/constants";

export const ResponseTypeContainer = styled.div`
	display: flex;
`;

export const Type = styled.div`
	border: solid;
	margin-right: 12px;
	padding: 18px;
	width: 80px;
	height: 80px;
`;

export const automationModalStyles = {
	content: {
		height: "fit-content",
		overflow: "visible",
		top: "50%",
		width: "60%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	overlay: {
		backgroundColor: "rgba(0,0,0,0.7)",
	},
};

export const AddAutomationForm = styled.form`
	@media (max-width: ${device.desktop}) {
		display: block;
	}
	display: flex;
	.column-one {
		margin-right: 12px;
		flex: 1;
	}
	.column-two {
		flex: 3;
		margin-left: 12px;
	}
`;

export const Container = styled.div`
	margin: 24px;
	display: flex;
	gap: 45px;
`;

export const TableContainer = styled.div`
	flex: 5;
`;

export const IphoneContainer = styled.div`
	flex: 1;
`;
