import { gql } from "@apollo/client";

export const GET_CONVERSATION_AUDIT_DATA = gql`
    query getConversationAudit($conversationId: Int!) {
        msgbox_ConversationAudit(order_by: {CreatedOn: desc}, where: {ConversationId: {_eq: $conversationId}}) {
        Description
        CreatedOn
        User {
            FirstName
            LastName
            HasImage
            ImageURI
            signedurl {
                url
              }
            }    
        }
    }
`    
;