import React from "react";
import PropTypes from "prop-types";
import { Container, Title } from "./styles";
import IconButton from "../IconButton/icon-button.component";

export const Back = ({ onClick, title }) => {
	return (
		<Container onClick={onClick}>
			<IconButton icon="fas fa-chevron-left" />
			<Title>{title}</Title>
		</Container>
	);
};

Back.propTypes = {
	title: PropTypes.string,
	onClick: PropTypes.func,
};

Back.defaultProps = {
	onClick: () => {},
	title: "Back",
};

export default Back;
