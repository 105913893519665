import { gql } from "@apollo/client";

export const GET_MESSAGES_IN_CONVERSATION = gql`
	query GetMessagesInConversation($conversationId: Int) {
		msgbox_Message(
			order_by: { DateTime: asc }
			where: { ConversationId: { _eq: $conversationId } } # limit: 10
		) {
			MessageId
			BodyText
			ConversationId
			DateTime
			IsRead
			IsSent
			IsFailed
			SendFailureReason
			IsDelivered
			DeferredDelivery
			Latitude
			Longitude
			ContactCard
			SenderId
			OriginatingMessage
			OriginatingAttachmentId
			Attachment {
				Location
				Filename
				MimeType
				signedurl {
					url
				}
			}
			User {
				UserId
				FirstName
				LastName
				HasImage
				ImageURI
				signedurl {
					url
				}
			}
			Conversation {
				ConversationId
				Contact {
					Name
					ContactId
				}
			}
		}
	}
`;

export const GET_LATEST_CONVERSATION_DATETIME = gql`
	subscription subscribeGetLatestFolderMessageDateTime(
		$conversationId: Int!
	) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			UpdatedOn
		}
	}
`;
