import { makeVar } from "@apollo/client";

// -- Inbox vars -- //
export const $selectedFolder = makeVar(null);
export const $selectedConversation = makeVar(null);
export const $selectedInbox = makeVar(null);
export const $infoPanelIsOpen = makeVar(null);
export const $infoPanelToggleIsHidden = makeVar(null);

// -- Conversation filters -- //
export const $unassignedConversationFilter = makeVar(null);
export const $unreadConversationFilter = makeVar(null);
export const $assignedToMeConversationFilter = makeVar(null);
export const $conversationListSearchTerm = makeVar(null);
export const $assignedToTeamsConversationFilter = makeVar(null);
export const $conversationUserFilter = makeVar({});
export const $conversationTagFilter = makeVar({});
export const $conversationDepartmentFilter = makeVar({});
export const $conversationContactFilter = makeVar({});
export const $conversationInboxFilter = makeVar([]);
export const $conversationFolderFilter = makeVar("Inbox");

// -- User data -- //
export const $userInboxes = makeVar(null);
export const $unreadInboxData = makeVar(null);
export const $userFolders = makeVar({})

// -- Auth -- //
export const $isLoggedIn = makeVar(false);

// -- notifications -- //
export const $myDepartments = makeVar([]);
export const $numberNewMessages = makeVar(0);
export const $browserNotificationsEnabled = makeVar(false);
export const $browserNotificationsConfigured = makeVar(false);

// -- optimization for subscriptions, only run when active tab -- //
export const $activeTab = makeVar(true);

// -- subscription status variables //
export const $inboxSubscriptionDisconnected = makeVar(false);

// Sending
export const $isMessageSending = makeVar(false);

//for marking as unread

export const $markedAsUnread = makeVar(false)


export const $reuseableDialog = makeVar({
    visible: false,
    title: "",
    body: "",
    onPress: () => {},
    button: "",
});

