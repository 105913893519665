import { gql } from "@apollo/client";

export const GET_DEPARTMENT_ID = gql`
	query getDepartmentId($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			DepartmentId
			UserId
		}
	}
`;

export const GET_INBOX_DATA = gql`
	query getInboxData($userId: Int) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			UserId
			UserInboxes {
				Inbox {
					InboxId
					Name
					Folders (order_by: {FolderId:desc}) {
						FolderId
						Name
					}
				}
			}
		UserDepartments {
			Department {
				DepartmentId
				DepartmentInboxes (order_by:{InboxId:desc}) {
					Inbox {
						InboxId
						Name
					}
				}
				Name
			}
		}
		}
	}
`;

export const SEARCH_CONVERSATIONS_MULTISELECT = gql`
	query searchConversationsMultiSelect(
		$searchTerm: String!
		$userInboxes: [Int!]
		$userId: Int!
	) {
		msgbox_Contact(
			where: {
				APIAccount: { Inboxes: { InboxId: { _in: $userInboxes } } }
				_or: [
					{ Name: { _ilike: $searchTerm } }
					{ MobileNumber: { _ilike: $searchTerm } }
				]
			}
		) {
			ContactId
			Name
		}
		msgbox_Department(
			where: {
				UserDepartments: { UserId: { _eq: $userId } }
				Name: { _ilike: $searchTerm }
			}
		) {
			DepartmentId
			Name
		}
		msgbox_User(
			where: {
				IsBot: { _eq: false }
				IsAPI: { _eq: false }
				UserRoles: { Role: { Name: { _is_null: false } } }
				_or: [
					{ FirstName: { _ilike: $searchTerm } }
					{ LastName: { _ilike: $searchTerm } }
				]
			}
		) {
			FirstName
			UserId
			LastName
		}
		msgbox_Tag(where: { Name: { _ilike: $searchTerm } }) {
			Name
			TagId
		}
	}
`;
