import * as Yup from "yup";

export const resetPasswordValidationSchema = Yup.object().shape({
	email: Yup.string("Email is required")
		.email("Invalid email")
		.required("Email is required"),
	password: Yup.string()
		.required("Password is required")
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&"£^\*()\[\];:'~\/<>\.,-_=+])[A-Za-z\d@$!%*#?&"£^\*()\[\];:'~\/<>\.,-_=+]{8,}$/,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
		),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("password")], "Passwords must match")
		.required("Passwords must match"),
	otp: Yup.string().required("One time password is required"),
});
