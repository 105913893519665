import logo from "../Default/msgbox-logo.png";
import poweredBy from "../Default/msgbox-logo.png";
import titleImage from "../Default/titleimage.png";
import loginImage from "../Default/loginimage.jpg";
import topleftIcon from "../Default/icon.png";
import favIcon from "../Default/favicon.ico";
export const defaultTheme = {
	name: "default",
	appName: "msgboxx",
	tagline: "Connecting you with your customers instantly",
	colours: {
		primary: "#0e91f0", // msgbox blue
		primaryLight: "#eaf6ff", // light msgbox blue
		success: "#29bb89", // green
		danger: "#ff5621", // red
		dangerLight: "#ffeee9", // light red
		warning: "#f58634", //orange
		light_0: "#FFFFFF", // white
		// light_1: "#f9f9f9", // very-light-grey (background)
		light_1: "#EEEEEE",
		light_2: "#FAFAFA", // light-grey (nth child odd table rows)
		light_3: "#EEEEEE", // dark-grey (hover table rows + notes)
		light_4: "#bbbbbb",
		textLight: "#FFFFFF", // light text
		textDark: "#7f7f7f", // dark text,
		dark: "#000000", // black
		whatsApp: {
			darkGreen: "#075E54",
			lightGreen: "#128C7E",
			background: "#ECE5DD",
		},
	},
	fonts: {
		family: "nulshock",
		path: "./bd.tff",
	},
	images: {
		// paths to images
		logo,
		titleImage,
		loginImage,
		poweredBy,
		topleftIcon,
		favIcon,
	},
};
