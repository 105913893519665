import styled from "styled-components";

export const StyledButton = styled.button`
	position: relative;
	padding: 6px;
	border-radius: 5px;
	background-color: ${(props) =>
		props.primary
			? props.theme.colours.primary
			: props.theme.colours.light_0};
	color: ${(props) =>
		props.primary
			? props.theme.colours.textLight
			: props.theme.colours.textDark};
	font-size: 1rem;
	min-height: 36px;
	border: none;
	opacity: ${(props) => (props.disabled ? 0.3 : 1)};
	&:focus {
		outline: solid 2px black;
	}
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
