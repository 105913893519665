import styled from "styled-components";

export const MessageContainer = styled.div`
	display: flex;
	position: relative;
	padding: 12px;
	overflow: hidden;
	height: 90px;
	padding: 12px;
	align-items: center;
	background-color: ${(props) =>
		props.isActive && props.theme.colours.primaryLight};
	&:first-child {
		border-top: solid 1px ${(props) => props.theme.colours.light_3};
	}
	border-bottom: solid 1px ${(props) => props.theme.colours.light_3};
	&:hover {
		cursor: pointer;
		background-color: ${(props) =>
			props.isActive
				? props.theme.colours.primaryLight
				: props.theme.colours.light_1};
	}
	img {
		height: 40px;
		border-radius: 40px;
		margin-right: 6px;
	}
	p {
		overflow: hidden;
		white-space: nowrap;
		max-width: 175px;
		text-overflow: ellipsis;
		font-weight: ${(props) => props.isRead === false && "bold"};
		color: ${(props) => props.isRead === false && props.theme.colours.dark};
	}
	h5 {
		font-weight: ${(props) => props.isRead === false && "bold"};
		color: ${(props) => props.isRead === false && props.theme.colours.dark};
	}
	.active-bar {
		position: absolute;
		background-color: ${(props) => props.theme.colours.primary};
		height: 70%;
		width: 4px;
		right: 0;
		border-radius: 4px 0 0 4px;
	}
	.message-details {
		flex: 1;
		width: calc(100% - 50px);
		&__content {
			white-space: nowrap;
			display: flex;
			div {
				flex: 1;
				position: relative;
				p {
					position: absolute;
					right: 0;
				}
			}
		}
		&__body {
			display: flex;
			justify-content: space-between;
			div {
				display: flex;
				i {
					color: ${(props) => props.theme.colours.primary};
					margin-left: auto;
					font-weight: bold;
					margin-left: 8px;
				}
			}
		}
	}
`;

export const ContactImgPlaceholder = styled.div`
	height: 40px;
	width: 40px;
	flex-shrink: 0;
	border-radius: 40px;
	margin-right: 6px;
	background-color: ${(props) => props.theme.colours.primary};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colours.light_0};
`;

export const Badge = styled.span`
	display: inline-block;
	vertical-align: middle;
	padding-inline-start: 4px;
	padding-inline-end: 4px;
	font-size: 12px;
	border-radius: 4px;
	background-color: pink;
	color: black;
	height: min-content;
`;
