import { useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import Button from "../../Button/button.component";
import {
	UPDATE_JOBADDER_CONNECTED,
    UPDATE_JOBADDER_CONNECTED_INBOX
} from "./jobadderqueries";
import { GET_AUTOMATION_CONFIG  } from "../query"
import { GET_INBOX_CONFIG } from "../inboxes-setttings/query"

/// all JobAdder functionality is in here, works for global and inbox specific connections. 
export const JobAdderSettings = ({global, rootStore, values, setDirty, data, config, updateLoading}) => {
	const inboxStore = rootStore.getInboxStore();
	const { addToast } = useToasts();

    // handles the redirect across to jobadder for connecting and disconnecting.
    // passes through single api account uuid for inbox specific, or url encoded list of all api account uuids for global configuration. 
    function handleJobAdderOAuth(connect) {
        setDirty(true);
        var uri = null
        var state = null
        if (global) {
            const apiAccounts = inboxStore.getAllApiAccounts()
            const apiAccoutIds = apiAccounts.map((x) => x.apiAccountUUID)
            state = apiAccoutIds.join("%2C")
        } else {
            const inbox = inboxStore.getInbox(config.InboxId) 
            console.log("inbox:", inbox)
            const apiAccount = inboxStore.getApiAccount(inbox.apiAccountId)
            state = apiAccount.apiAccountUUID
        }
        if (connect) {
            uri = `https://id.jobadder.com/connect/authorize?response_type=code&client_id=asbhvo53c2pedafybmjplr2g2y&scope=read%20write%20offline_access&redirect_uri=https%3A%2F%2Fstitch.pro-serv.co.uk%2Fjob-adder-auth&state=` + state;
        } else {
            uri = `https://stitch.pro-serv.co.uk/job-adder-deauth?state=` + state;
        }
        window.open(uri, "_blank");
        handleJobAdderConnect(connect)
    }

    const [
		updateJobAdderConnectedGlobal,
	] = useMutation(UPDATE_JOBADDER_CONNECTED, {
		onCompleted: () => {
			addToast("JobAdder inbox configuration saved successfully", {
				appearance: "success",
				autoDismiss: true,
			});
		},
	});

    const [
		updateJobAdderConnectedInbox,
	] = useMutation(UPDATE_JOBADDER_CONNECTED_INBOX, {
		onCompleted: () => {
			addToast("JobAdder inbox configuration saved successfully", {
				appearance: "success",
				autoDismiss: true,
			});
		},
	});

    // handles the connection, stores the updated status in the database. 
    function handleJobAdderConnect(connectBool) {
        setDirty(true);
        if (global) {
            updateJobAdderConnectedGlobal({
                variables: {
                    configurationId: global ? data.msgbox_Configuration[0].ConfigurationId : null,
                    inboxId: global ? null : config.InboxId,
                    isJobAdderConnected: connectBool,
                },
                update: (cache, data) => {
                    cache.modify({
                        fields: {
                            msgbox_Configuration_mutation_response(existingResponses = []) {
                                const newResponseRef = cache.writeQuery({
                                    data: data.data.update_msgbox_Configuration,
                                    query: GET_AUTOMATION_CONFIG,
                                });
                                return [...existingResponses, newResponseRef];
                            },
                        },
                    });
                },
            });
        } else {
            updateJobAdderConnectedInbox({
                variables: {
                    inboxId: config.InboxId,
                    isJobAdderConnected: connectBool,
                },
                update: (cache, data) => {
                    cache.modify({
                        fields: {
                            msgbox_InboxConfib_mutation_response(existingResponses = []) {
                                const newResponseRef = cache.writeQuery({
                                    data: data.data.update_msgbox_InboxConfib,
                                    query: GET_INBOX_CONFIG,
                                });
                                return [...existingResponses, newResponseRef];
                            },
                        },
                    });
                },
            });
        }
        
        addToast("JobAdder Connection Updated - Please Save Changes", {
            appearance: "success",
            autoDismiss: true,
        });
        if (connectBool === false) {
            setDirty(true);
        }
    }

    // some buttons to enable and disable the job adder integration. 
    if (values.crmIntegration.value === "JobAdder") {
        return (
            <div style={{ marginBottom: "50px" }}>						
                <p style={{ marginBottom: "20px" }}>
                    Remember to click 'Save' after you connect!
                </p>
                <Button
                    style={{
                        float: "left",
                        display: "block",
                        marginRight: "10px",
                    }}
                    primary
                    buttonName="Connect"
                    type="button"
                    onClick={() => handleJobAdderOAuth(true)}
                    loading={updateLoading}
                    disabled={ config.IsJobAdderConnected }

                />
                <Button
                    style={{ float: "left", display: "block" }}
                    primary
                    buttonName="Disconnect"
                    type="submit"
                    onClick={() => handleJobAdderOAuth(false)}
                    loading={updateLoading}
                    disabled={ !config.IsJobAdderConnected }
                />
            </div>
        ) 
    } else {
        return (<></>)
    }
}
