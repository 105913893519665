import React from "react";
import { useQuery } from "@apollo/client";
import Chart from "../../../Components/Chart/chart";
import { NoData } from "./no-data";

const OutboundMessagesChart = ({ data }) => {

	if (!data?.reporting_MessagesOutboundByInbox[0]) return <NoData>No Data</NoData>;

	return (
		<Chart
			chartData={data}
			chartDataKey="reporting_MessagesOutboundByInbox"
			legendName="Outbound Messages"
		/>
	);
};

export default OutboundMessagesChart;
