import { Router, Switch } from "react-router-dom";
import { history } from "./helpers/history";
import { Redirect, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./css/iphone.css";
import "./css/style.css";
import "./css/bootstrap.min.css";
import "./css/sb-admin-2.css";

import { defaultTheme } from "./themes/Default/default.theme";
import { stitchTheme } from "./themes/Stitch/stitch.theme";
import { splicecomTheme } from "./themes/SpliceCom/splicecom.theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./Styles/global";
import { yesTechTheme } from "./themes/YesTech/yes-tech.theme";
import { kiwi } from "./themes/KiwiTheme/kiwi.theme";
import { armstrong } from "./themes/ArmstrongBell/armstrong.theme";
import { t2k } from "./themes/t2k/t2k.theme";

import { neso } from "./themes/NesoMedia/neso-theme";
import { ntaTheme } from "./themes/nta/nta.theme";
import { voiceflexTheme } from "./themes/voiceflex/voiceflex.theme";
import { stradcomTheme } from "./themes/stradcom/stradcom.theme";
import { Ai9Theme } from "./themes/Ai9/ai9.theme";
import { exposureAnalyticsTheme } from "./themes/exposureanalytics/exposureanalytics.theme";
import { voiceflexRGCTheme } from "./themes/RGC/voiceflexRGC.theme";
import { sandTheme } from "./themes/sandcommunications/sand.theme";
import { dalycomTheme } from "./themes/dalycom/dalycom.theme";
import { boundaryTheme } from "./themes/boundaryitsystems/boundary.theme";
import { skywaveTheme } from "./themes/skywave/skywave.theme";
import { ntvoiceanddataTheme } from "./themes/ntvoiceanddata/ntvoiceanddata.theme";
import { matrix247Theme } from "./themes/matrix247/matrix247.theme";
import { mjsitsTheme } from "./themes/mjsits/mjsits.theme";
import { qssTheme } from "./themes/qss/qss.theme";
import { connexisTheme } from "./themes/connexis/connexis.theme";
import { redlineTheme } from "./themes/redline/redline.theme";
import { aonixTheme } from "./themes/aonix/aonix.theme";
import { solutionsInTechnologyTheme } from "./themes/solutionsintechnology/solutionsintechnology.theme";
import { duocallTheme } from "./themes/duocall/duocall.theme";
import { bellcomTheme } from "./themes/bellcom/bellcom.theme";
import { serviceteleTheme } from "./themes/servicetele/servicetele.theme";
import { fonetechTheme } from "./themes/fonetech/fonetech.theme";
import { interacttechTheme } from "./themes/interacttech/interacttech.theme";
import { eepcTheme } from "./themes/eepc/eepc.theme";
import { alliancecomsTheme } from "./themes/alliancecoms/alliancecoms.theme";
import { bipcomTheme } from "./themes/bipcom/bipcom.theme";
import { switchvdTheme } from "./themes/switchvd/switchvd.theme";
import { mitConsultingTheme } from "./themes/mitconsulting/mitconsulting.theme";

import Login from "./Pages/Login/login.component";
import ForgottenPassword from "./Pages/ForgottenPassword/forgotten-password.component";
import ResetPassword from "./Pages/ResetPassword/reset-password.component";
import Home from "./Pages/Home/home.component";

import { ApolloProvider } from "@apollo/client";
import client from "./Apollo";
import ReactGA from "react-ga4";
import { blizzardTheme } from "./themes/blizzard/blizzard.theme";
import { viegliTheme } from "./themes/viegli/viegli.theme";
import { oceanTheme } from "./themes/ocean/ocean.theme";
import { openvoiceTheme } from "./themes/openvoice/openvoice.theme";
import { ipvoiceTheme } from "./themes/ipvoice/ipvoice.theme";
import { challengerTheme } from "./themes/challenger/challenger.theme";
import { caraTheme } from "./themes/cara/cara.theme";
import { viendoTheme } from "./themes/viendo/viendo.theme";
import { simplicityTheme } from "./themes/simplicity/simplicity.theme";
import { leapitTheme } from "./themes/leapit/leapit.theme";
import { openvoipTheme } from "./themes/openvoip/openvoip.theme";

var Bugsnag = require("@bugsnag/js");
var BugsnagPluginReact = require("@bugsnag/plugin-react");

Bugsnag.start({
	plugins: [new BugsnagPluginReact()],
	apiKey: process.env.REACT_APP_BUGSNAGKEY,
	releaseStage: process.env.REACT_APP_BUGSNAGSTAGE,
	onError: function (event) {
		//console.log("event", event);
		if (event.originalError.indexOf("ResizeObserver") !== -1) {
			//console.log("attempting to skip");
			return false;
		}
		event.setUser(localStorage.getItem("user_id"), "", "");
	},
});

const App = () => {
	ReactGA.initialize(process.env.REACT_APP_GOOGLEANALYTICS);

	history.listen((location) => {
		//console.log("changed page");
		ReactGA.set({ page: location.pathname }); // Update the user's current page
		ReactGA.send({ hitType: "pageview", page: location.pathname }); // Record a pageview for the given page
	});

	const getTheme = () => {
		const url = window.location.hostname;
		switch (url) {
			case "chat.msgbox.to":
				return defaultTheme;
			case "stitch-ai.msgbox.to":
			case "stitch-ai.test-env.msgbox.to":
			case "stitch-ai.dev-env.msgbox.to":
			case "demo.test-env.msgbox.to":
				return stitchTheme;
			case "splicecom.dev-env.msgbox.to":
			case "splicecom.msgboxx.io":
				return splicecomTheme;
			case "kiwi.msgboxx.io":
			case "kiwi.dev-env.msgbox.to":
				return kiwi;
			case "ai9.msgboxx.io":
				return Ai9Theme;
			case "yestech.dev-env.msgbox.to":
			case "yestech.msgboxx.io":
				return yesTechTheme;
			case "armstrongbell.msgboxx.io":
			case "armstrongbell.dev-env.msgbox.to":
				return armstrong;
			case "nesomedia.dev-env.msgbox.to":
			case "nesomedia.test-env.msgbox.to":
			case "nesomedia.msgboxx.io":
				return neso;
				
			case "exposureanalytics.omnichat.co.uk":
				return exposureAnalyticsTheme;
			case "nta.dev-env.msgbox.to":
			case "nta.msgboxx.io":
			case "portal.whatex.app":
				return ntaTheme;
			case "stradcom.whatex.app":
				return stradcomTheme;
			case "portal.omnichat.co.uk":
				
				return voiceflexTheme;
			case "rgc.omnichat.co.uk":			
				return voiceflexRGCTheme;
			case "sandcommunications.omnichat.co.uk":
				return sandTheme;
			case "dalycom.omnichat.co.uk":
				return dalycomTheme;
			case "boundaryit.omnichat.co.uk":
				return boundaryTheme;
			case "skywave.msgboxx.io":
				return skywaveTheme;
			case "ntvd.omnichat.co.uk":
				return ntvoiceanddataTheme;
			case "matrix247.omnichat.co.uk":
				return matrix247Theme;
			case "mjsits.omnichat.co.uk":
				return mjsitsTheme;
			case "qssit.omnichat.co.uk":
				return qssTheme;
			case "connexis.omnichat.co.uk":
				return connexisTheme;
			case "redline.omnichat.co.uk":
				return redlineTheme;
			case "t2k.omnichat.co.uk":				
				return t2k;
			case "aonix.omnichat.co.uk":
				return aonixTheme;
			case "sitgroup.omnichat.co.uk":
				return solutionsInTechnologyTheme;
			case "duocall.omnichat.co.uk":				
				return duocallTheme;
			case "bellcom.omnichat.co.uk":
				return bellcomTheme;
			case "servicetelecom.omnichat.co.uk":
				return serviceteleTheme;
			case "fonetech.omnichat.co.uk":
				return fonetechTheme;
			case "interact.omnichat.co.uk":
				return interacttechTheme;
			case "eepc.omnichat.co.uk":
				return eepcTheme;
			case "mit.omnichat.co.uk":
				return mitConsultingTheme
			case "switch.omnichat.co.uk":
				return switchvdTheme;			
			case "bipcom.omnichat.co.uk":
				return bipcomTheme;
			case "alliance.omnichat.co.uk":
				return alliancecomsTheme;
			case "blizzard.omnichat.co.uk":
				return blizzardTheme;
			case "viegli.omnichat.co.uk":
				return viegliTheme;
			case "ipvoice.omnichat.co.uk":
				return ipvoiceTheme;
			case "challenger.omnichat.co.uk":	
				return challengerTheme;
			case "openvoice.omnichat.co.uk":				
				return openvoiceTheme;
			case "ocean.omnichat.co.uk":				
				return oceanTheme;
			case "cara.omnichat.co.uk":
				return caraTheme;
			case "leapit.omnichat.co.uk":
				return leapitTheme;
			case "simplicity.omnichat.co.uk":
				return simplicityTheme
			case "viendo.omnichat.co.uk":
				return viendoTheme
			case "openvoip.omnichat.co.uk":
				return openvoipTheme
			case "localhost":				
			default:
				return stitchTheme;
		}
	};

	return (
		<ThemeProvider theme={getTheme()}>
			<Helmet>
				<link rel="icon" href={getTheme().images.favIcon} />
				<title>{getTheme().appName}</title>
			</Helmet>
			<GlobalStyle />
			<ApolloProvider client={client}>
				<Router history={history}>
					<Switch>
						{/* Public Routes */}
						<Route
							exact
							path="/"
							render={() => {
								return <Redirect to="/login" />;
							}}
						/>
						<Route exact path="/login">
							<Login />
						</Route>
						<Route exact path="/forgotten-password">
							<ForgottenPassword />
						</Route>
						<Route exact path="/reset-password">
							<ResetPassword />
						</Route>
						{/* Private Routes */}
						<Route>
							<Home />
						</Route>
					</Switch>
				</Router>
			</ApolloProvider>
		</ThemeProvider>
	);
};

export default App;
