import { useEffect, useCallback, useReducer } from "react";

export const DEFAULT_ACCEPTED_IMAGE_FORMATS = [
	// "image/gif",
	"image/png",
	"image/jpeg",
	// "image/bmp",
];

const DEFAULT_ACCEPTED_DOCS = ["application/pdf"];

const DEFAULT_ACCEPTED_VIDEOS = ["video/mp4", "video/3gpp"];

const DEFAULT_ACCEPTED_FORMATS = [
	...DEFAULT_ACCEPTED_IMAGE_FORMATS,
	...DEFAULT_ACCEPTED_DOCS,
	...DEFAULT_ACCEPTED_VIDEOS,
];

export const INVALID_FORMATS = ["image/gif"];

const _transformImageDataToBlob = (
	data,
	acceptedFiles = DEFAULT_ACCEPTED_FORMATS
) => {
	// const isValidFormat = (fileType) => acceptedFiles.includes(fileType);
	const isInvalidFormat = (fileType) => INVALID_FORMATS.includes(fileType);
	for (let i = 0; i < data.items.length; i++) {
		if (isInvalidFormat(data.items[i].type)) {
			return;
		}
		let blob = data.items[i].getAsFile();
		if (blob) {
			return { file: blob, url: window.URL.createObjectURL(blob) };
		}
	}
};

const reducer = (state, action) => {
	switch (action.type) {
		case "SET_FILE":
			return {
				...state,
				pasted: [action.file],
				error: null,
			};
		case "SET_ERROR":
			return { ...state, error: action.error };
		case "CLEAR_PASTED":
			return {
				pasted: [],
				error: null,
			};
		default:
			throw new Error("Must specify action type");
	}
};

const useGlueJarReducer = () =>
	useReducer(reducer, {
		pasted: [],
		error: null,
	});

export function usePasteHandler(
	blockPastingFiles,
	acceptedFiles = DEFAULT_ACCEPTED_FORMATS
) {
	const [state, dispatch] = useGlueJarReducer();

	const clearJar = useCallback(() => {
		dispatch({
			type: "CLEAR_PASTED",
		});
	}, [dispatch]);
	const transformImageDataToURL = useCallback(
		(data) => {
			try {
				const file = _transformImageDataToBlob(data, acceptedFiles);
				dispatch(
					file
						? { type: "SET_FILE", file }
						: {
								type: "SET_ERROR",
								error: "Something went wrong",
						  }
				);
			} catch (error) {
				dispatch({ type: "SET_ERROR", error: error.message });
			}
		},
		[dispatch, acceptedFiles]
	);

	const pasteHandler = useCallback(
		({ clipboardData }) => {
			if (clipboardData && clipboardData.items.length > 0) {
				if (!blockPastingFiles) {
					transformImageDataToURL(clipboardData);
				}
			} else {
				dispatch({
					type: "SET_ERROR",
					error: `Sorry, to bother you but there was no file pasted.`,
				});
			}
		},
		[dispatch, transformImageDataToURL, blockPastingFiles]
	);

	return [state, pasteHandler, clearJar];
}

export function useGlueJar(
	{ ref, ...options } = {
		blockPastingFiles: true || false,
		acceptedFiles: DEFAULT_ACCEPTED_FORMATS,
	}
) {
	const [state, pasteHandler, clearJar] = usePasteHandler(
		options.blockPastingFiles,
		options.acceptedFiles
	);

	useEffect(() => {
		if (ref && ref.current) {
			ref.current.addEventListener("paste", pasteHandler);
		} else {
			document.addEventListener("paste", pasteHandler);
		}

		return () => {
			if (ref && ref.current) {
				ref.current.removeEventListener("paste", pasteHandler);
			} else {
				document.removeEventListener("paste", pasteHandler);
			}
		};
	}, [ref, options]);

	return { ...state, clearJar };
}
