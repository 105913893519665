import { Wrapper, MainContent } from "./styles";
import ProtectedRoute from "../../Components/ProtectedRoute";
import InboxPage from "../../Pages/InboxPage/InboxPage";
import Contacts from "../../Pages/Contacts/contacts.component";
import Broadcast from "../../Pages/Broadcast/broadcast.component";
import Automations from "../../Pages/Automations/automations.component";
import Navbar from "../../Components/SideNav/side-nav.component";
import TopNavigation from "../../Components/TopNavigation/top-navigation.component";
import ContactDetails from "../../Pages/ContactDetails/contact-details.component";
import AnalyticsPage from "../../Pages/Analytics/analytics-page";
import {
	GET_USER_INBOXES,
	MARK_CONVERSATION_AS_READ,
	GET_FOLDER,
} from "./query";
import {
	$selectedInbox,
	$selectedConversation,
	$markedAsUnread,
} from "../../store";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import client from "../../Apollo";

const Layout = ({ rootStore }) => {
	const selectedConversation = useReactiveVar($selectedConversation);
	const markedAsUnread = useReactiveVar($markedAsUnread);
	const [oldConversationId, setOldConversationId] = useState(false);
	const [mutationMakeConversationAsRead] = useMutation(
		MARK_CONVERSATION_AS_READ
	);

	const {
		loading: inboxLoading,
		error: inboxError,
		data: inboxData,
	} = useQuery(GET_USER_INBOXES, {
		variables: {
			userId: localStorage.getItem("user_id"),
		},
		onCompleted: (data) => {
			if (
				data.msgbox_UserInbox.length > 0 &&
				data.msgbox_UserInbox[0].Inbox &&
				data.msgbox_UserInbox[0].Inbox.InboxId
			) {
				$selectedInbox(data.msgbox_UserInbox[0].Inbox.InboxId);
			}
		},
	});

	useEffect(() => {
		if (
			oldConversationId !== selectedConversation &&
			oldConversationId &&
			!markedAsUnread
		) {
			markAsRead();
		}
		$markedAsUnread(false);
		setOldConversationId(selectedConversation);
	}, [selectedConversation]);

	const markAsRead = async () => {
		let data = await client.query({
			query: GET_FOLDER,
			variables: { conversationId: oldConversationId },
		});
		if (data?.data?.msgbox_Conversation[0]?.FolderId) {
			mutationMakeConversationAsRead({
				variables: {
					conversationId: oldConversationId,
				},
			});
		}
	};

	return (
		<Wrapper>
			<Navbar />
			<MainContent id="main-content">
				<TopNavigation rootStore={rootStore} />
				<Wrapper>
					<ProtectedRoute
						exact
						path="/inbox"
						component={InboxPage}
						rootStore={rootStore}
					/>
					<ProtectedRoute
						exact
						path="/contacts"
						component={Contacts}
						rootStore={rootStore}
					/>
					<ProtectedRoute
						exact
						path="/broadcast"
						component={Broadcast}
						rootStore={rootStore}
					/>
					<ProtectedRoute
						exact
						path="/contact-details/:contactId"
						component={ContactDetails}
						rootStore={rootStore}
					/>
					<ProtectedRoute
						exact
						path="/automations"
						component={Automations}
						rootStore={rootStore}
					/>
					<ProtectedRoute
						exact
						path="/analytics"
						component={AnalyticsPage}
						rootStore={rootStore}
					/>
				</Wrapper>
			</MainContent>
		</Wrapper>
	);
};

export default Layout;
