import { useState, useRef, useEffect } from "react";
import { useMutation, useReactiveVar, useQuery } from "@apollo/client";
import { Container, MessageIcons, EmojiPickerContainer } from "./styles";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import client from "../../Apollo";
import useOutsideAlerter from "../../hooks/detect-outside";
import { stringInsert } from "../../helpers/functions";
import UploadFile from "../UploadFile/upload-file.component";
import RecordVoice from "../RecordVoice/record-voice.component";
import {
	$selectedInbox,
	$selectedConversation,
	$isMessageSending,
} from "../../store";
import { SEND_TEMPLATE_MESSAGE } from "../SendTemplateMessage/query";
import { GET_USER_DETAILS } from "../UserPreview/query";
import ReactGA from "react-ga4";
import { getUserId } from "../../helpers/functions";

import {
	ADD_MESSAGE_TO_CONVERSATION_DEFERRED,
	GET_LATEST_TEMPLATE,
	GET_INBOX_NATURAL_CHAT,
	GET_CONTACT_ID,
} from "./query";
import InputMessageWithPastingFiles from "../InputMessageWithPastingFiles/input-message-with-pasting-files";
import { GET_FOLDER, MARK_CONVERSATION_AS_READ } from "../Layout/query";

const SendDeferredMessage = () => {
	const [addMessageMutation, { error, loading }] = useMutation(
		ADD_MESSAGE_TO_CONVERSATION_DEFERRED
	);

	const [sendTemplate, { loadTemp, errTemp }] = useMutation(
		SEND_TEMPLATE_MESSAGE
	);
	const [mutationMakeConversationAsRead] = useMutation(MARK_CONVERSATION_AS_READ)
	const [message, setMessage] = useState("");
	const inputRef = useRef(null);
	const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
	const wrapperRef = useRef(null);
	const outsideClick = useOutsideAlerter(wrapperRef);
	const [cursorPosition, setCursorPosition] = useState(null);
	const [fileUploadIsOpen, setFileUploadOpen] = useState(false);
	const [audioRecordIsOpen, setAudioRecordOpen] = useState(false);
	const selectedConversation = useReactiveVar($selectedConversation);
	const selectedInbox = useReactiveVar($selectedInbox);
	const [templateId, setTemplateId] = useState(null);
	const [templateText, setTemplateText] = useState(null);
	const [accountId, setAccountId] = useState(null);
	const [contactId, setContactId] = useState(null);
	const [userArray, setUserArray] = useState(null);

	useEffect(() => {
		document.addEventListener("keydown", closeEmojiPicker);
		return () => {
			document.removeEventListener("keydown", closeEmojiPicker);
		};
	}, []);

	useEffect(() => {
		setEmojiPickerVisible(false);
	}, [outsideClick]);

	useEffect(() => {
		// Set message as "" so that when a user changes the conversation the message gets removed.
		// Drafts??
		setMessage("");
	}, [selectedConversation]);

	useEffect(() => {
		loadUserData();
		loadTemplateId();
		loadContactId();
	});

	const markAsRead = async () => {
		let data = await client.query({
			query: GET_FOLDER,
			variables: { conversationId: selectedConversation },
		});
		if (data?.data?.msgbox_Conversation[0]?.FolderId) {
			mutationMakeConversationAsRead({
				variables: {
					conversationId: selectedConversation
				},
			});
		}
	};

	const loadUserData = async () => {
		const userDetails = await client.query({
			query: GET_USER_DETAILS,
			variables: {
				userId: getUserId(),
			},
		});

		const u = userDetails.data.msgbox_User;
		//console.log("JEK", u)

		if (u.length > 0) setUserArray(u[0]);
	};

	const loadContactId = async () => {
		const getContact = await client.query({
			query: GET_CONTACT_ID,
			variables: {
				conversationId: selectedConversation,
			},
		});

		const d = getContact.data.msgbox_Conversation;

		if (d.length > 0) {
			setContactId(d[0].ContactId);
		}
	};

	const loadTemplateId = async () => {
		const getTemplate = await client.query({
			query: GET_INBOX_NATURAL_CHAT,
			variables: {
				inboxId: selectedInbox,
			},
		});
		const newApiAcct =
			getTemplate.data.msgbox_UserInbox[0].Inbox.APIAccount;
		const newTempId = newApiAcct.Templates[0].TemplateId;
		const newTempText = newApiAcct.Templates[0].TemplateText;

		setTemplateId(newTempId);
		setTemplateText(newTempText);
		setAccountId(newApiAcct.APIAccountId);
	};

	const closeEmojiPicker = (event) => {
		if (event.key === "Escape") {
			setEmojiPickerVisible(false);
		}
	};

	const sendDeferredMessage = async (sendMsg, selConvo, attach = null) => {
		let tempDate = new Date();
		tempDate.setDate(tempDate.getDate() - 1);

		const getLatestTemplate = await client.query({
			query: GET_LATEST_TEMPLATE,
			variables: {
				conversationId: selConvo,
				_gt: tempDate,
			},
		});

		// Send template message
		if (getLatestTemplate.data.msgbox_Message.length === 0) {
			//const msg = userArray.FirstName + templateText.slice(5);
			const msg = templateText.replace("{{1}}", userArray.FirstName);

			await sendTemplate({
				variables: {
					attachmentId: null,
					TemplateId: templateId,
					Message: msg,
					account: accountId,
					contacts: {
						data: [{ ContactId: contactId }],
					},
				},
			});
		}

		addMessageMutation({
			variables: {
				bodyText: sendMsg,
				conversationId: selConvo,
				attachmentId: attach,
			},
		});
		$isMessageSending(true);
		await markAsRead()
		ReactGA.event({
			category: "Messages",
			action: "SentMessage",
		});
		if (sendMsg.length > 0) {
			setMessage("");
		}
	};

	const addMessage = (e) => {
		if (message && selectedConversation) {
			sendDeferredMessage(message, selectedConversation);
		}
	};

	const sendAttachment = async (attachmentId, caption) => {
		sendDeferredMessage(caption, selectedConversation, attachmentId);

		/*
		await addMessageMutation({
			variables: {
				bodyText: caption,
				conversationId: selectedConversation,
				attachmentId,
			},
		});
		*/
	};

	const handleMessageChange = (event) => {
		setCursorPosition(event.target.selectionStart);
		setMessage(event.target.value);
	};

	const handleInputClick = (event) => {
		setCursorPosition(event.target.selectionStart);
	};

	const handleEmojiChange = (emoji) => {
		const newMessage = stringInsert(message, cursorPosition, emoji.native);
		setMessage(newMessage);
		setCursorPosition(cursorPosition + 2);
		setEmojiPickerVisible(false);
	};

	if (!selectedConversation || selectedConversation === -1) return null;
	return (
		<Container>
			<div className="send-message-actions">
				<MessageIcons
					onClick={() => {
						setFileUploadOpen(true);
					}}
					className="fas fa-paperclip"
				></MessageIcons>
				<MessageIcons
					active={emojiPickerVisible}
					onClick={() => {
						setEmojiPickerVisible(!emojiPickerVisible);
					}}
					className={
						emojiPickerVisible
							? "far fa-laugh emoji-picker"
							: "far fa-smile"
					}
				></MessageIcons>
				<MessageIcons
					onClick={() => {
						setAudioRecordOpen(true);
					}}
					className="fas fa-microphone"
				></MessageIcons>
				{emojiPickerVisible && (
					<EmojiPickerContainer ref={wrapperRef}>
						<Picker
							title="Pick your emoji…"
							emoji="point_up"
							onSelect={handleEmojiChange}
						/>
					</EmojiPickerContainer>
				)}
			</div>
			<InputMessageWithPastingFiles
				sendAttachment={sendAttachment}
				message={message}
				addMessage={addMessage}
				inputRef={inputRef}
				handleMessageChange={handleMessageChange}
				handleInputClick={handleInputClick}
				selectedConversation={selectedConversation}
			/>
			<UploadFile
				cancel={() => {
					setFileUploadOpen(false);
				}}
				isOpen={fileUploadIsOpen}
				send={sendAttachment}
			/>
			<RecordVoice
				cancel={() => {
					setAudioRecordOpen(false);
				}}
				isOpen={audioRecordIsOpen}
				send={sendAttachment}
			/>
		</Container>
	);
};

export default SendDeferredMessage;
