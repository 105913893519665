import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	margin-top: 6px;
	margin-bottom: 6px;
	padding: 12px;
	border-radius: 6px;
	position: relative;
`;

export const Icon = styled.i`
	font-size: 2em;
	color: #29bb89;
	margin-right: 12px;
`;

export const StyledDate = styled.small`
	position: absolute;
	bottom: 0;
	right: 0;
	margin-right: 6px;
	margin-top: 6px;
	font-style: italic;
`;

export const Description = styled.div`
	margin-bottom: 6px;
`;

export const Wrapper = styled.div`
	height: calc(100% - 84px);
`;

export const LoadingContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
