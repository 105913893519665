import * as Yup from "yup";

export const notificationValidationSchema = Yup.object().shape({
	responseType: Yup.object().required("this should never print"),
	keyword: Yup.string().required("Keyword is required"),
	reply: Yup.string().when("responseType", (value) => {
		if (value.value === 1) {
			// A reply is optional if the response type is "assign" or "routeTo"
			return Yup.string().required("Reply is required");
		}
	}),
	assignee: Yup.object().when("responseType", (value) => {
		if (value.value === 2) {
			return Yup.object().required("Assignee is required");
		}
	}),
	routeTo: Yup.object().when("responseType", (value) => {
		if (value.value === 3) {
			return Yup.object().required("Route to is required");
		}
	}),
	account: Yup.object().required("Account is required"),
});
