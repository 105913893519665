export const updateCache = (cache, data, query) => {
	cache.modify({
		fields: {
			msgbox_BotResponse(existingResponses = []) {
				const newResponseRef = cache.writeQuery({
					data: data.data.insert_msgbox_BotResponse_one,
					query: query,
				});
				return [...existingResponses, newResponseRef];
			},
		},
	});
};
