import { gql } from "@apollo/client";

// Get inital conversations
export const GETALL_CONVERSATIONS = gql`
	query getAllConversationsInFolder($folderId: [Int!], $isRead: Boolean, $searchTerm: String, $userIds: msgbox_Conversation_bool_exp!, $tagIds: msgbox_Contact_bool_exp!, $departmentIds: msgbox_Conversation_bool_exp!, $contactIds: msgbox_Contact_bool_exp!) {
		msgbox_Conversation: get_users_messages(where: {FolderId: {_in: $folderId}, IsRead: {_eq: $isRead}, Contact: {_and: [{Name: {_ilike: $searchTerm}}, $contactIds, $tagIds]}, _and: [$userIds, $departmentIds]}, order_by: {LastMessage: desc_nulls_last}) {
		FolderId
		ConversationId
		AssignedTo
		AssignedToDepartment
		IsRead
		LastMessage
		UpdatedOn
			Folder {
			InboxId
			}
		}
	}
	`;

export const GET_CONVERSATION = gql`
	query getConversationInFolder($conversationId:Int, $folderId: [Int!], $isRead: Boolean, $searchTerm: String, $userIds: msgbox_Conversation_bool_exp!, $tagIds: msgbox_Contact_bool_exp!, $departmentIds: msgbox_Conversation_bool_exp!, $contactIds: msgbox_Contact_bool_exp!) {
		msgbox_Conversation: get_users_conversation(
			args: {conversationid: $conversationId},
			where: {
				FolderId: {_in: $folderId},
				IsRead: {_eq: $isRead},
				Contact: {_and: [{Name: {_ilike: $searchTerm}},$contactIds, $tagIds]}, 
				_and: [$userIds, $departmentIds]
			},
		order_by: {LastMessage: desc_nulls_last}
	) 
    {
		FolderId
		ConversationId
		AssignedTo
		AssignedToDepartment
		IsRead
		LastMessage
		UpdatedOn
		Folder {
			InboxId
		}	
	}
}
`;

export const GET_LATEST_FOLDER_MESSAGE_DATETIME = gql`
	subscription subscribeGetLatestFolderMessageDateTime($folderId: Int!) {
		msgbox_Folder(where: { FolderId: { _eq: $folderId } }) {
			UpdatedOn
		}
	}
`;

export const GET_CONVERSATIONS_IN_FOLDER = gql`
	query getConversationsInFolder($folderId: Int) {
		msgbox_Conversation(
			where: { FolderId: { _eq: $folderId } }
			order_by: { Messages_aggregate: { max: { CreatedOn: desc } } }
		) {
			ConversationId
			Contact {
				Name
				ContactId
				ImageURI
				MobileNumber
				Status
				TagContacts {
					TagId
					Tag {
						Name
						TagId
					}
				}
			}
		}
	}
`;
