import { ConversationFiltersButton } from "../ConversationFilters/styles";
import { $infoPanelIsOpen } from "../../store";
import { useReactiveVar } from "@apollo/client";
import ReactGA from "react-ga4";

const InfoPanelToggle = () => {
	const infoPanelIsOpen = useReactiveVar($infoPanelIsOpen);

	const togglePanel = () => {
		if (infoPanelIsOpen) {
			$infoPanelIsOpen(false);
			localStorage.setItem("info_pannel", "closed")
			ReactGA.event({
				category: "Widgets",
				action: "InfoPanelOpened",
			});
		} else {
			$infoPanelIsOpen(true);
			localStorage.setItem("info_pannel", "open")
			ReactGA.event({
				category: "Widgets",
				action: "InfoPanelClosed",
			});
		}
	};

	return (
		<ConversationFiltersButton
			data-tip={infoPanelIsOpen ? "Close info panel" : "Open info panel"}
			onClick={togglePanel}
			icon="fas fa-info"
		/>
	);
};

export default InfoPanelToggle;
