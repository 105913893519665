import { Formik } from "formik";
import Input from "../../Components/Input/input.component";
import { Container } from "./styles";
import Button from "../../Components/Button/button.component";
import { Link } from "react-router-dom";
import { forgottenPasswordValidationSchema } from "./validation-schema";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

const ForgottenPassword = () => {
	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
	const history = useHistory();

	const submit = async ({ email }) => {
		try {
			setLoading(true);

			const response = await fetch(
				`${process.env.REACT_APP_API_URL}user/${email}/otp`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			setLoading(false);
			history.push("/reset-password");
		} catch (error) {
			console.log("error", error);
			addToast(
				"Error sending email. If the problem persists contact support",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
			setLoading(false);
		}
	};

	return (
		<Container>
			<h1>Recover password</h1>
			<Formik
				initialValues={{
					email: "",
				}}
				onSubmit={(values, actions) => {
					submit(values, actions);
				}}
				validationSchema={forgottenPasswordValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ handleSubmit, handleChange, values, errors }) => (
					<form id="forgotten-password" onSubmit={handleSubmit}>
						<Input
							label="Email"
							onChange={handleChange}
							name="email"
							value={values.email}
							error={errors.email}
							isForm={false}
							icon={<i className="far fa-envelope"></i>}
							required
						/>
						<div
							style={{ display: "flex", alignItems: "center" }}
							className="login-button-container"
						>
							<i className="fas fa-arrow-left"></i>
							<Link to="/login">Back to login</Link>
							<Button
								style={{ marginLeft: "auto" }}
								buttonName="Send Recovery Email "
								primary
								type="submit"
								loading={loading}
							/>
						</div>
					</form>
				)}
			</Formik>
		</Container>
	);
};

export default ForgottenPassword;
