import React from "react";
import {
	msgBoxSelectCustomStyles,
	FormField,
	Label,
	InputErrorMessage,
} from "../../Styles/global";
import { useTheme } from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/tooltip.component";

/**
 * Wrapper for react-select with msgbox custom styling.
 */
const MsgBoxSelect = ({
	async,
	label,
	required,
	error,
	hasTooltip,
	toolTipText,
	table,
	hasLabel,
	menuPlacement = "auto",
	form,
	...rest
}) => {
	const msgBoxTheme = useTheme();
	return (
		<FormField isForm={form} table={table}>
			{hasLabel ? <Label> {label + (required ? "*" : "")} </Label> : null}
			{hasTooltip && <Tooltip right text={toolTipText} />}
			{async ? (
				<AsyncSelect
					styles={msgBoxSelectCustomStyles(msgBoxTheme)}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: msgBoxTheme.colours.primaryLight,
							primary: msgBoxTheme.colours.primary,
						},
					})}
					{...rest}
				/>
			) : (
				<Select
					styles={msgBoxSelectCustomStyles(msgBoxTheme)}
					theme={(theme) => ({
						...theme,
						colors: {
							...theme.colors,
							primary25: msgBoxTheme.colours.primaryLight,
							primary: msgBoxTheme.colours.primary,
						},
					})}
					menuPlacement={menuPlacement}
					{...rest}
				/>
			)}
			{error && (
				<div>
					<InputErrorMessage> {error}</InputErrorMessage>
				</div>
			)}
		</FormField>
	);
};

MsgBoxSelect.propTypes = {
	label: PropTypes.string,
	async: PropTypes.bool,
	error: PropTypes.string,
	hasTooltip: PropTypes.bool,
	toolTipText: PropTypes.string,
	hasLabel: PropTypes.bool,
};

MsgBoxSelect.defaultProps = {
	required: false,
	hasTooltip: false,
	table: false,
	form: true,
	hasLabel: true,
};

export default MsgBoxSelect;
