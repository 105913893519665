import { Formik } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useState } from "react";
import { Form, FormColumn } from "./styles";
import { ADD_WEBHOOK, GET_WEBHOOKS } from "./query";

const WEBHOOK_TYPES = [
	{
		label: "Message",
		value: "message",
	},
	{
		label: "Contact",
		value: "contact",
	},
	{
		label: "API Account",
		value: "apiaccount",
	},
	{
		label: "Broadcast",
		value: "broadcast",
	},
	{
		label: "Inbox",
		value: "inbox",
	},
];
const AddWebhook = ({ isOpen, close }) => {
	const [addWebhook, { loading }] = useMutation(ADD_WEBHOOK, {
		refetchQueries: [
			{
				query: GET_WEBHOOKS,
			},
		],
	});
	const [dirty, setDirty] = useState(false);

	const { addToast } = useToasts();

	const submit = async ({ targetUrl, type }) => {
		try {
			await addWebhook({
				variables: {
					targetUrl,
					type: type.value,
				},
			});

			addToast("Successfully added webhook", {
				appearance: "success",
				autoDismiss: true,
			});
			close();
		} catch (e) {
			addToast("Something went wrong", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	const onInputChange = (event, fn) => {
		setDirty(true);
		fn(event);
	};

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			isOpen={isOpen}
			cancel={closeModal}
			header="Add Webhook"
			description="Use the form below to add a webhook"
			onRequestClose={closeModal}
			submitting={loading}
			submit={submit}
			submitId="add-webhook"
			submitButtonDisabled={dirty === false}
		>
			<Formik
				initialValues={{
					targetUrl: "",
					type: null,
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={Yup.object().shape({
					targetUrl: Yup.string().required("Target Url is required"),
					type: Yup.object().required("Type is required"),
				})}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					handleChange,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} id="add-webhook">
						<FormColumn>
							<Input
								placeholder="Target Url"
								required
								name="targetUrl"
								label="Target Url"
								onChange={(event) =>
									onInputChange(event, handleChange)
								}
								error={errors.targetUrl}
								value={values.targetUrl}
							/>
						</FormColumn>
						<FormColumn>
							<MsgBoxSelect
								key={3}
								placeholder="Select a type"
								name="type"
								options={WEBHOOK_TYPES.map((webhookType) => ({
									label: webhookType.label,
									value: webhookType.value,
								}))}
								onChange={(options) => {
									setFieldValue("type", options);
									setDirty(true);
								}}
								label="Type"
								error={errors.type}
								value={values.type}
								hasTooltip
								toolTipText="Select this webhook's type"
							/>
						</FormColumn>
					</Form>
				)}
			</Formik>
		</MsgBoxModal>
	);
};

export default AddWebhook;
