import { gql } from "@apollo/client";

export const UPDATE_CONTACT_NAME = gql`
	mutation updateContactName($contactId: Int!, $name: String!) {
		update_msgbox_Contact(
			where: { ContactId: { _eq: $contactId } }
			_set: { Name: $name }
		) {
			affected_rows
			returning {
				ContactId
				Name
			}
		}
	}
`;
