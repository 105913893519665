import { Redirect, Route } from "react-router";
import { UserContext } from "../Providers/user-provider";
import { useContext } from "react";

const AdminRoute = ({ component: Component, ...rest }) => {
	const userContext = useContext(UserContext);
	if (userContext.role !== "orgadmin") {
		return <Redirect to="/inbox" />;
	}
	return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default AdminRoute;
