import * as Yup from "yup";
import { PHONE_REGEX } from "../../Pages/Contacts/bulk-contact-import/validation";

export const templateMessageValidationSchema = Yup.object().shape({
	name: Yup.string()
		.matches(/^[0-9a-z-_]{5,}$/, "Invalid name")
		.required("Name is required"),
	category: Yup.object().required("Category is required"),
	type: Yup.object().required("Message type is required"),
	attachment: Yup.object().required(
		"Select 'No attachments' if not required"
	),
	language: Yup.object().required("Language is required"),
	text: Yup.string()
		.required("Message text is required")
		.min(5, "Template must be more than 5 characters long")
		.max(1000, "Template must be less than 1000 characters long")
		.test(
			"doesnt end or start with variable",
			"Cannot start or end with variable",
			(val) => {
				if (val.startsWith("{{")) return false;
				if (val.endsWith("}}")) return false;
				return true;
			}
		),
	account: Yup.array().min(1, "Account Required").required("Account is required"),

	exampleMessage: Yup.string()
		.required("Example message is required")
		.test(
			"are the same",
			"Message Text and Example Message do not correctly match, ensure template example is using [ ] for placeholders",
			function (example) {
				const { text } = this.parent;

				const regEx = /{{.*?}}/g;
				const newText = text.replaceAll(regEx, "X");
				const exampleRegEx = /\[.*?\]/g;
				const newExample = example.replaceAll(exampleRegEx, "X");
		  
				const numberInBracketsRegEx = /\[\d+\]/g;
				const containsNumbersInBrackets = numberInBracketsRegEx.test(example);
				if(containsNumbersInBrackets) return false
		  
				return newText === newExample;
			}
		),
		quickReplyButtons: Yup.array().test(
				"Has All Values",
				"Titles Cannot be empty",
				function (example) {
					return example.filter((item) => !item.title).length == 0
				}
			),
		callToActionButtons: Yup.array().test(
				"Has All Values",
				"Titles Cannot be empty",
				function (example) {
					return example.filter((item) => !item.title).length == 0
				}
			).test(
				"Numbers cant be false",
				"Numbers must be valid",
				function (example) {
					let invalidPhones = example.filter((item) => {
						return item.type === "phone" && !PHONE_REGEX.test(item.phone);
					});
					return invalidPhones.length === 0;
				}
			).test(
				"URLs cant be false",
				"URLs must be valid",
				function (example) {
					let invalidURLs = example.filter((item) => {
						return item.type === "staticURL" && !URL_REGEX.test(item.url);
					});
					return invalidURLs.length === 0;
			}
		)
});

export const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
