import styled from "styled-components";

export const Container = styled.div`
	margin-left: auto;
	margin-right: 0px;
	.user-info {
		margin-left: 12px;
		margin-right: 6px;
		padding: 12px;
		display: flex;
		align-items: center;
		h5 {
			margin-bottom: 0;
		}
	}
	img {
		height: 30px;
		border-radius: 5px;
		margin-right: 6px;
	}
`;
