import styled from "styled-components";

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	table {
		border-collapse: collapse;
		width: 100%;
		small {
			color: ${(props) => props.theme.colours.danger};
		}
	}
	th {
		background-color: ${(props) => props.theme.colours.primaryLight};
		color: ${(props) => props.theme.colours.primary};
		text-transform: uppercase;
		margin: 6px;
		padding: 12px;
	}
	tr {
		height: 36px;
		padding-left: 12px;
	}
	td {
		margin: 6px;
		padding: 6px;
		min-width: 60px;
	}
`;

export const OpeningHoursButtons = styled.div`
	& :first-child {
		background-color: transparent;
		border: solid 1px ${(props) => props.theme.colours.light_4};
		margin-right: 6px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
	align-items: center;
`;

export const SelectContainer = styled.div`
	margin-right: 20px;
	max-width: 400px;
	flex: 1;
`