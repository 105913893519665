import styled from "styled-components";

export const DropZone = styled.div`
	border: dashed 1px ${(props) => props.theme.colours.primary};
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.theme.colours.light_1};
	margin-bottom: 24px;
	.placeholder {
		display: flex;
		align-items: center;
		i {
			margin-right: 6px;
			font-size: 4rem;
		}
	}
	.dropzone {
		border: solid;
		height: 100%;
		width: 100%;
	}
`;

export const ErrorMsg = styled.small`
	position: absolute;
	bottom: -14px;
	color: ${(props) => props.theme.colours.danger};
`;
