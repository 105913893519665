import { gql } from "@apollo/client";

export const GET_EMAIL_PARSER_DELAY = gql`
	query getEmailParserDelay {
		msgbox_Configuration {
			EmailParserDelay
			ConfigurationId
		}
	}
`;

export const UPDATE_EMAIL_PARSER_DELAY = gql`
	mutation updateEmailParserDelay($configId: Int!, $delay: Int!) {
		update_msgbox_Configuration(
			where: { ConfigurationId: { _eq: $configId } }
			_set: { EmailParserDelay: $delay }
		) {
			affected_rows
			returning {
				EmailParserDelay
				ConfigurationId
			}
		}
	}
`;

export const GET_EMAIL_PARSER_CONFIG = gql`
	query getParserConfig($accountId: Int!) {
		msgbox_EmailParserConfig(where: { APIAccountId: { _eq: $accountId } }) {
			EmailAddress
			EmailParserConfigId
			ParserType
			Template {
				Name
				TemplateId
			}
		}
	}
`;

export const UPDATE_EMAIL_PARSER_CONFIG = gql`
	mutation updateEmailParserConfig($configId: Int!, $templateId: Int!) {
		update_msgbox_EmailParserConfig(
			where: { EmailParserConfigId: { _eq: $configId } }
			_set: { TemplateId: $templateId }
		) {
			returning {
				EmailParserConfigId
				TemplateId
			}
		}
	}
`;
