import React from "react";
import { useQuery } from "@apollo/client";
import Chart from "../../../Components/Chart/chart";
import { NoData } from "./no-data";

const InboundMessagesChart = ({ start, end, inboxes, data }) => {

	if (!data?.reporting_MessagesInboundByInbox[0]) return <NoData>No Data</NoData>;

	return (
		<Chart
			chartData={data}
			chartDataKey="reporting_MessagesInboundByInbox"
			legendName="Inbound Messages"
		/>
	);
};

export default InboundMessagesChart;
