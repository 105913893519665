import { Switch } from "react-router-dom";
import ProtectedRoute from "../../Components/ProtectedRoute";
import Settings from "../Settings/settings.component";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import Layout from "../../Components/Layout/layout.component";
import UnreadConversations from "../../Components/UnreadConversations/unread-conversations.component";
import { $reuseableDialog, $selectedInbox, $userFolders, $userInboxes } from "../../store";
import { getUserId, getAccessToken, getBrand } from "../../helpers/functions";
import jwt_decode from "jwt-decode";
import { createContext, useEffect } from "react";
import { GET_USER_EMAIL, SET_USER_EMAIL, GET_USER_INBOXES, MASK_NUMBERS } from "./query";
import UserProvider from "../../Providers/user-provider";
import { WonderPush } from "react-wonderpush";
import ReactGA from "react-ga4";
import ReuseableDialog from "../../Components/ReuseableDialog/ReuseableDialog";
import { RootStore } from "../../DataStores/rootStore";

export const UserContext = createContext(null);

const Home = () => {
	const rootStore = new RootStore(localStorage.getItem("org_id"), localStorage.getItem("user_id"));
	const inboxStore = rootStore.getInboxStore();
	const [setUserEmail] = useMutation(SET_USER_EMAIL);
	const selectedInbox = useReactiveVar($selectedInbox)

	useEffect(() => {
		const handleNewVersionAvailable = (event) => {
		$reuseableDialog({
			visible: true,
			title: "A new version is available",
			body: "You must refresh to get the latest features",
			onPress: () => window.location.reload(),
			button: "Refresh",
		});
		};
	
		window.addEventListener('newVersionAvailable', handleNewVersionAvailable);
	
		return () => {
		window.removeEventListener('newVersionAvailable', handleNewVersionAvailable);
		};
	}, []);

	const getWPToken = () => {
		const brand = getBrand();
		if (brand === "Whatex") {
			return process.env.REACT_APP_WONDERPUSHKEYWHATEX;
		}
		if (brand === "OmniChat") {
			return process.env.REACT_APP_WONDERPUSHKEYOMNICHAT;
		}
		return process.env.REACT_APP_WONDERPUSHKEY;
	};

	const { loading, error, data } = useQuery(GET_USER_EMAIL, {
		variables: {
			userId: getUserId(),
		},
		onCompleted: async (data) => {
			if (data?.msgbox_User?.[0]?.EMail === null) {
				const token = await getAccessToken();
				const decodedToken = jwt_decode(token);
				const email =
					decodedToken["https://hasura.io/jwt/claims"][
						"x-hasura-email"
					];
				setUserEmail({
					variables: {
						userId: parseInt(getUserId()),
						email: email,
					},
					update: (cache, data) => {
						cache.modify({
							fields: {
								update_msgbox_User(existingResponses = []) {
									const newResponseRef = cache.writeQuery({
										data: data.data.update_msgbox_User,
										query: GET_USER_EMAIL,
									});
									return [
										...existingResponses,
										newResponseRef,
									];
								},
							},
						});
					},
				});
			}
		},
	});

	const {
		loading: userInboxLoading,
		error: userInboxError,
		data: userInboxData,
	} = useQuery(GET_USER_INBOXES, {
		variables: {
			userId: getUserId(),
		},
		onCompleted: (data) => {
			const userInboxes = data.msgbox_UserInbox.map(
				(inbox) => inbox.InboxId
			);
			let obj = {}
			data.msgbox_UserInbox.forEach((inbox) => {
				if(!inbox.Inbox) return
				inbox.Inbox.Folders.forEach((item) => {
					let x = {FolderId: item.FolderId, Name: item.Name, inbox: inbox.InboxId}
					if(obj[item.Name]) {
						obj[item.Name] = [...obj[item.Name], x]
					} else {
						obj[item.Name] = [x]
					}
				})
			});

			// 	let departments = data.UserDepartments.flatMap((deptItem) => deptItem.Department.DepartmentInboxes.filter(i => i.Inbox.InboxId === inboxItem.Inbox.InboxId)
			// 	x.departments = departments.map(dept => dept.value.department);
			// });
			$userInboxes(userInboxes);
			$userFolders(obj)
		},
	});

	ReactGA.send({ hitType: "pageview", page: "/inbox" });

	return (
		<>
			<WonderPush
				options={{
					webKey: getWPToken(),
					userId: getUserId(),
				}}
			>
				<UserProvider>
					<UnreadConversations rootStore={rootStore}/>
					<ReuseableDialog/>
					<Switch>
						<ProtectedRoute component={Settings} path="/settings" rootStore={rootStore}/>
						<ProtectedRoute component={Layout} rootStore={rootStore} />
					</Switch>
				</UserProvider>
			</WonderPush>
		</>
	);
};

export default Home;
