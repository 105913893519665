import React, { useState, useEffect } from "react";
import { Container } from "../../Styles/global";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
	GET_EMAIL_PARSER_DELAY,
	UPDATE_EMAIL_PARSER_DELAY,
	GET_EMAIL_PARSER_CONFIG,
	UPDATE_EMAIL_PARSER_CONFIG,
} from "./query";
import APIAccountSelect from "../APIAccountsSelect/api-accounts-select.component";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { emailParserDelayOptions } from "./helper";
import Button from "../Button/button.component";
import Input from "../Input/input.component";
import { SEARCH_TEMPLATE_MESSAGES } from "../SendTemplateMessage/query";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import { HelperText as HelperContainer } from "./styles";
import { useToasts } from "react-toast-notifications";
import client from "../../Apollo";

const HelperText = ({ text }) => {
	return (
		<HelperContainer>
			<i className="fas fa-info-circle"></i>
			<p>{text}</p>
		</HelperContainer>
	);
};

const PortalResponderSettings = () => {
	const {
		data: emailParserDelay,
		error: errorFetchingEmailParserDelay,
		loading: loadingEmailParserDelay,
	} = useQuery(GET_EMAIL_PARSER_DELAY, {
		onCompleted: ({ msgbox_Configuration }) => {
			// Maps the current delay to the object that will be used in dropdown
			const delayOption = emailParserDelayOptions.filter(
				(option) =>
					option.value === msgbox_Configuration[0].EmailParserDelay
			);
			setDelay(delayOption[0]);
		},
		fetchPolicy: "network-only",
	});

	const [fetchTemplates, { loading: loadingTemplates, data: templates }] =
		useLazyQuery(SEARCH_TEMPLATE_MESSAGES, {
			fetchPolicy: "network-only",
		});

	const [updateDelay, { loading: updatingDelay, error: errorUpdatingDelay }] =
		useMutation(UPDATE_EMAIL_PARSER_DELAY);

	const [
		updateParserConfig,
		{ loading: updatingParserConfig, error: errorUpdatingParserConfig },
	] = useMutation(UPDATE_EMAIL_PARSER_CONFIG);

	const { addToast } = useToasts();

	const [selectedConfigId, setSelectedConfigId] = useState(null);
	const [dirty, setDirty] = useState(false);

	const [emailParserConfig, setEmailParserConfig] = useState([]);
	const [templateValues, setTemplateValues] = useState({});
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [delay, setDelay] = useState({});

	useEffect(() => {}, []);
	const submit = async () => {
		// Updates global / organisation delay.
		await updateDelay({
			variables: {
				configId:
					emailParserDelay?.msgbox_Configuration[0].ConfigurationId,
				delay: delay.value,
			},
		});

		if (Object.keys(templateValues).length > 0) {
			const promises = [];
			for (const configId in templateValues) {
				// template  == configurationId
				//  templateValues[template] == templateId that should be associated with this config i.e above.
				promises.push(
					updateParserConfig({
						variables: {
							configId: configId,
							templateId: templateValues[configId].value,
						},
					})
				);
			}
			await Promise.all(promises);
		}

		setDirty(false);
		addToast("Successfully updated", {
			appearance: "success",
			autoDismiss: true,
		});
	};

	// This is called onchange of the api account select so that the correct config (i.e email and template and type) will be populated.
	// Also called on init if there is only one api account. If there is only one api account preselect everything and show it.
	const setPortalResponderConfig = async (value) => {
		fetchTemplates({
			variables: {
				apiAccountId: value.value,
			},
			fetchPolicy: "no-cache",
		});

		const config = await client.query({
			query: GET_EMAIL_PARSER_CONFIG,
			variables: {
				accountId: value.value,
			},
			fetchPolicy: "no-cache",
		});

		setEmailParserConfig(config.data.msgbox_EmailParserConfig);
	};

	if (loadingEmailParserDelay) return <LoadingSpinner />;
	if (errorFetchingEmailParserDelay) return <p>something went wrong</p>;

	return (
		<Container>
			<MsgBoxSelect
				onChange={(value) => {
					setDelay(value);
					setDirty(true);
				}}
				label="Delay"
				options={emailParserDelayOptions}
				value={delay}
			/>
			<APIAccountSelect
				onChange={(value) => {
					setPortalResponderConfig(value);
					setSelectedAccount(value);
				}}
				setAccount={(accounts) => {
					setAccounts(accounts);
					if (accounts.length === 1) {
						setSelectedAccount(accounts[0]);
						setPortalResponderConfig(accounts[0]);
					}
				}}
				value={selectedAccount}
			/>
			<hr />
			{emailParserConfig.map((config) => {
				return (
					<div key={config.EmailParserConfigId}>
						<Input
							value={config.ParserType}
							label="Source"
							disabled
							readonly
						/>
						<Input
							value={config.EmailAddress}
							label="Email"
							disabled
							readonly
						/>
						<MsgBoxSelect
							// This is being done like this because the initial value needs to be set using the emailParserConfig array of data.
							// But also allow onChange to change the value.
							value={
								Object.keys(templateValues).length > 0
									? templateValues[config.EmailParserConfigId]
									: {
											label: config.Template
												? config.Template.Name
												: "",
											value: config.Template
												? config.Template.value
												: "",
									  }
							}
							onChange={(value) => {
								// Create object of values where the key is this specific configId and the value is the chosen template
								setTemplateValues({
									...templateValues,
									[config.EmailParserConfigId]: value,
								});
								setDirty(true);
							}}
							label="Template"
							options={
								templates?.msgbox_Template[0]
									? templates.msgbox_Template.map(
											(template) => ({
												label: template.Name,
												value: template.TemplateId,
											})
									  )
									: []
							}
						/>
						{!config.Template && (
							<p style={{ color: "red" }}>
								Please select a new template.
							</p>
						)}
						<hr />
					</div>
				);
			})}
			{selectedAccount === null ? (
				<HelperText text="Select an account to view configuration" />
			) : (
				emailParserConfig.length < 1 && (
					<HelperText
						text={
							"The email responder is not set up for this account. Contact support to get started"
						}
					/>
				)
			)}

			<Button
				style={{ float: "right" }}
				primary
				buttonName="Save"
				type="submit"
				loading={updatingDelay || updatingParserConfig}
				disabled={dirty === false}
				onClick={submit}
			/>
			<div style={{ clear: "both" }}></div>
		</Container>
	);
};

export default PortalResponderSettings;
