import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
	typePolicies: {
		msgbox_BotResponse: {
			keyFields: ["BotResponseId"],
		},
		msgbox_ContactNote: {
			keyFields: ["ContactNoteId"],
		},
		//msgbox_Conversation: {
		//	keyFields: ["ConversationId"],
		//},
		//msgbox_Message: {
		//	keyFields: ["MessageId"],
		//},
		msgbox_User: {
			keyFields: ["UserId"],
		},
		msgbox_Department: {
			keyFields: ["DepartmentId"],
		},
		msgbox_Configuration: {
			keyFields: ["ConfigurationId"],
		},
		msgbox_Permission: {
			keyFields: ["OrganisationId"],
		},
		msgbox_TagContact: {
			keyFields: ["TagId"],
		},
		msgbox_Tag: {
			keyFields: ["TagId"],
		},
		msgbox_Contact: {
			keyFields: ["ContactId"],
		},
		Query: {
		},
	},
});

export default cache;
