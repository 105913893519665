import styled from "styled-components";

export const Container = styled.div`
	position: relative;
	height: 200px;
	width: 330px;
	#map {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
	}
`;
