import styled from "styled-components";

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
	.add-tag {
		max-width: 300px;
		width: 100%;
	}
`;
