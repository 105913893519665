import styled from "styled-components";

export const Form = styled.form`
	display: flex;
	gap: 12px;
	align-items: center;
`;

export const FormColumn = styled.div`
	flex: 1;
`;
