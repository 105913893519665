import { gql } from "@apollo/client";

export const CHECK_IF_TAGS_EXIST = gql`
	query CheckIfTagsExist($tags: [String!]) {
		msgbox_Tag(where: { Name: { _in: $tags } }) {
			Name
			TagId
		}
	}
`;

export const ADD_TAGS = gql`
	mutation AddMultipleTags($objects: [msgbox_Tag_insert_input!]!) {
		insert_msgbox_Tag(objects: $objects) {
			affected_rows
			returning {
				TagId
				Name
			}
		}
	}
`;

export const CREATE_NEW_CONTACT = gql`
	mutation CreateNewContact(
		$name: String!
		$mobileNumber: String!
		$tags: [msgbox_TagContact_insert_input!]! = {}
		$account: Int!
		$email: String
	) {
		insert_msgbox_Contact_one(
			object: {
				Name: $name
				MobileNumber: $mobileNumber
				TagContacts: { data: $tags }
				Status: "Presubscribed"
				APIAccountId: $account
				Email: $email
			}
		) {
			ContactId
			TagContacts {
				TagId
			}
		}
	}
`;

export const ADD_NOTE_TO_CONTACT = gql`
	mutation addNoteToContact($note: String!, $contactId: Int!) {
		insert_msgbox_ContactNote_one(
			object: { Note: $note, ContactId: $contactId }
		) {
			Note
			ContactNoteId
		}
	}
`;

export const DELETE_CONTACT_TAGS = gql`
	mutation deleteContactTags($contactId: Int!) {
		delete_msgbox_TagContact(where: { ContactId: { _eq: $contactId } }) {
			affected_rows
			returning {
				TagId
				ContactId
			}
		}
	}
`;

export const ADD_CONTACT_TAGS = gql`
	mutation addtagcontact($objects: [msgbox_TagContact_insert_input!]!) {
		insert_msgbox_TagContact(objects: $objects) {
			affected_rows
			returning {
				ContactId
				TagId
			}
		}
	}
`;

export const GET_CONTACT_TAGS = gql`
	query getContactTags($contactId: Int!) {
		msgbox_Contact(where: { ContactId: { _eq: $contactId } }) {
			ContactId
			TagContacts {
				TagId
				ContactId
			}
		}
	}
`;
