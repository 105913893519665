import styled from "styled-components";
import { device } from "../../Styles/constants";

export const PaginationContainer = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 12px;
	margin-top: 45px;
	border-radius: 15px;
	display: block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	@media (min-width: ${device.tablet}) {
		display: inline-flex;
	}
`;

export const PaginationLimit = styled.select`
	border: none;
	font-size: 1rem;
	font-weight: 600;
	max-height: 36px;
`;

export const Container = styled.div`
	display: flex;
	align-items: center;
	border: solid 2px ${(props) => props.theme.colours.light_3};
	border-radius: 8px;
	margin-left: 12px;
	margin-right: 12px;
`;

export const Label = styled.label`
	color: ${(props) => props.theme.colours.dark};
	margin: 6px;
	font-size: 1rem;
	font-weight: 600;
	white-space: nowrap;
`;
