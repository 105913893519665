import { TabItem, TabList } from "../../Pages/Broadcast/styles";
import { Container } from "../../Styles/global";
import * as React from "react";
import WebhooksTable from "./webhooks-table";
import DiagnosticsTable from "./diagnostics-table";
import ZapierWebhooksTable from "./zapier-webhooks-table";

const WebhooksSettings = () => {
	const [diagnosticsVisible, setDiagnosticsVisible] = React.useState(false);

	const toggleContent = (type) => {
		switch (type) {
			case "webhooks":
				setDiagnosticsVisible(false);
				break;
			case "diagnostics":
				setDiagnosticsVisible(true);
				break;
			default:
				break;
		}
	};
	return (
		<Container>
			<div>
				<TabList>
					<TabItem
						onClick={() => {
							toggleContent("webhooks");
						}}
						active={!diagnosticsVisible}
					>
						Webhooks
					</TabItem>
					<TabItem
						onClick={() => {
							toggleContent("diagnostics");
						}}
						active={diagnosticsVisible}
					>
						Diagnostics
					</TabItem>
				</TabList>
			</div>
			<div>
				{diagnosticsVisible ? (
					<DiagnosticsTable />
				) : (
					<>
						<WebhooksTable />
						<h2>Zapier Webhook I've set up</h2>
						<ZapierWebhooksTable />
					</>
				)}
			</div>
		</Container>
	);
};

export default WebhooksSettings;
