import { Formik, Field } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import {
	GET_DEPARTMENTS,
	GET_USERS,
	REMOVE_USER_DEPARTMENTS,
	INSERT_USER_DEPARTMENTS,
} from "./query";
import { useMutation, useQuery } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useState } from "react";
import { validateBulkAssignUserToDepartment } from "./validation-schema";

const AssignUsersToDepartment = ({ isOpen, close, users }) => {
	const [assignUser, { loading, error }] = useMutation(
		INSERT_USER_DEPARTMENTS
	);
	const [removeUserDept] = useMutation(REMOVE_USER_DEPARTMENTS);

	const {
		data: departmentData,
		error: departmentError,
		loadign: departmentLoading,
	} = useQuery(GET_DEPARTMENTS);

	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);

	const submit = async ({ departments }) => {
		// If the first value is falsy the user has selected no team => remove all teams from selected users.
		if (departments[0].value) {
			const removePromises = users.map((user) => {
				return removeUserDept({
					variables: {
						userId: user.userId,
					},
				});
			});
			await Promise.all(removePromises);
			const addPromises = [];
			users.forEach((user) => {
				const data = departments.map((department) => ({
					UserId: user.userId,
					DepartmentId: department.value,
				}));
				addPromises.push(
					assignUser({
						variables: {
							userDepartments: data,
						},
						update: (cache, data) => {
							updateCache(cache, data);
						},
					})
				);
			});
		} else {
			users.forEach((user) => {
				removeUserDept({
					variables: {
						userId: user.userId,
					},
					update: (cache, data) => {
						updateCache(cache, data);
					},
				});
			});
		}
		if (!error) {
			closeModal();
		}
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	if (error) {
		addToast(`Something went wrong`, {
			appearance: "error",
			autoDismiss: true,
		});
	}

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			isOpen={isOpen}
			cancel={closeModal}
			header="Assign users to teams"
			description="Use the form below to add users to a team in bulk"
			onRequestClose={closeModal}
			submitting={loading}
			submit={submit}
			submitId="add-users-to-team"
			submitButtonDisabled={dirty === false}
		>
			<Formik
				initialValues={{
					department: null,
				}}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({ handleSubmit, values, errors, setFieldValue }) => (
					<form onSubmit={handleSubmit} id="add-users-to-team">
						<Field
							key={2}
							placeholder="Select a team"
							name="departments"
							options={
								(departmentData && departmentData.msgbox_Department.length > 0)
									? [
											{ Name: "No Team", value: null },
											...departmentData.msgbox_Department,
									  ].map((department) => ({
											label: department.Name,
											value: department.DepartmentId,
									  }))
									: null
							}
							onChange={(options, action) => {
								setDirty(true);
								setFieldValue("departments", options);
							}}
							label="Teams"
							error={errors.departments}
							value={values.departments}
							isMulti
							component={MsgBoxSelect}
							validate={validateBulkAssignUserToDepartment}
						/>
					</form>
				)}
			</Formik>
		</MsgBoxModal>
	);
};

export default AssignUsersToDepartment;

const updateCache = (cache, data) => {
	cache.modify({
		fields: {
			msgbox_User(existingResponses = []) {
				const newResponseRef = cache.writeQuery({
					data: data.data.update_msgbox_User,
					query: GET_USERS,
				});
				return [...existingResponses, newResponseRef];
			},
		},
	});
};
