import logo from "../KiwiTheme/kiwi-logo.png";
import poweredBy from "../KiwiTheme/kiwi-logo.png";
import titleImage from "../KiwiTheme/titleimage.png";
import loginImage from "../KiwiTheme/loginimage.jpg";
import topleftIcon from "../KiwiTheme/icon.png";
import favIcon from "../KiwiTheme/favicon.ico";
export const kiwi = {
	name: "kiwi",
	website: "https://kiwivoiceanddata.co.uk/",
	appName: "msgboxx",
	tagline: "Connecting you with your customers instantly",
	colours: {
		primary: "#26324f",
		success: "#29bb89", // green
		danger: "#ce1212", // red
		dangerLight: "#ffeee9", // light red
		warning: "#f58634", //orange
		light_0: "#FFFFFF", // white
		light_1: "#F7F7F7", // very-light-grey (background)
		light_2: "#FAFAFA", // light-grey (nth child odd table rows)
		light_3: "#EEEEEE", // dark-grey (hover table rows + notes)
		textLight: "#FFFFFF", // light text
		light_4: "#bbbbbb",
		textDark: "#000000", // dark text,
		dark: "#000000", // black
		primaryLight: "#eaf6ff",
		whatsApp: {
			darkGreen: "#075E54",
			lightGreen: "#128C7E",
			background: "#ECE5DD",
		},
	},
	fonts: {
		family: "nulshock",
		path: "./nulshock bd.tff",
	},
	images: {
		// paths to images
		logo,
		titleImage,
		loginImage,
		poweredBy,
		topleftIcon,
		favIcon,
	},
};
