import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { getUserId } from "../../helpers/functions";
import Button from "../Button/button.component";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import AddWebhook from "./add-webhook.component";
import { GET_WEBHOOKS, SET_WEBHOOK_DISABLED, SET_WEBHOOK_ENABLED } from "./query";
import { Container } from "./styles";
import Toggle from "react-toggle";
import { Badge } from "../MessagePreview/styles";

const WebhooksTable = () => {

	const { data, loading, error } = useQuery(GET_WEBHOOKS, {
		variables: {
			userId: getUserId(),
		},
		fetchPolicy: "network-only",
	});

	const [isOpen, setIsOpen] = React.useState(false);

	const [setWebhookIdEnabled, { loading: setWebhookLoading }] = useMutation(
		SET_WEBHOOK_ENABLED,
		{
			refetchQueries: [
				{
					query: GET_WEBHOOKS,
					variables: {
						userId: getUserId(),
					},
				},
			],
		}
	);

	const [setWebhookDisabled, { loading: webhookloading }] = useMutation(
		SET_WEBHOOK_DISABLED,
		{
			refetchQueries: [
				{
					query: GET_WEBHOOKS,
					variables: {
						userId: getUserId(),
					},
				},
			],
		}
	);

	const handleClose = () => {
		setIsOpen(false);
	};

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleSetWebhook = async (id, isEnabled) => {
		if(isEnabled){
		await setWebhookIdEnabled({
			variables: {
				id
			},
		});
	} else {
		await setWebhookDisabled({
			variables: {
				id
			},
		});
	}
	};

	if (loading) return <LoadingSpinner />;

	if (error) {
		return <p>oops... something went wrong</p>;
	}
	
	return (
		<Container>
			<table>
				<tr>
					<th>Target Url</th>
					<th>Type</th>
					<th>Failure Count</th>
					<th>Is Enabled</th>
					<th />
				</tr>
				{data.msgbox_ZapierWebhook.map((webhook) => (
					<tr key={webhook.ZapierWebHookId}> 
						<td>
							<p>{webhook.Target_Url}</p>
						</td>
						<td>
							<Badge>{webhook.Type}</Badge>
						</td>
						<td>
							<p>{webhook.FailureCount}</p>
						</td>
						<td>
							<Toggle
								id={webhook.ZapierWebHookId}
								onChange={async () => {
									await handleSetWebhook(
										webhook.ZapierWebHookId,
										!webhook.IsEnabled
									);
								}}
								disabled={setWebhookLoading || webhookloading}
								name="isEnabled"
								checked={webhook.IsEnabled === true}
							/>
						</td>
					</tr>
				))}
			</table>

			<Button
				primary
				buttonName="Add another webhook"
				onClick={handleOpen}
			/>
			<AddWebhook isOpen={isOpen} close={handleClose} />
		</Container>
	);
};

export default WebhooksTable;
