import React, { useState } from "react";
import Input from "../Input/input.component";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import {
	GET_CONTACT_NOTES,
	ADD_CONTACT_NOTE,
	DELETE_CONTACT_NOTE,
	UPDATE_CONTACT_NOTE,
} from "./query";
import {
	Container,
	StyledNote,
	NoteActions,
	TrashIcon,
	EditIcon,
} from "./styles";
import { useToasts } from "react-toast-notifications";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import TextArea from "../TextArea/text-area.component";

const Notes = ({ contactId }) => {
	const { loading, error, data, refetch } = useQuery(GET_CONTACT_NOTES, {
		variables: {
			contactId,
		},
	});
	const [deleteNote, { loading: deleteLoading, error: deleteError }] =
		useMutation(DELETE_CONTACT_NOTE);
	const [addNote, { loading: addLoading, error: addError }] =
		useMutation(ADD_CONTACT_NOTE);
	const [updateNote, { loading: updateLoading, error: updateError }] =
		useMutation(UPDATE_CONTACT_NOTE);
	const [note, setNote] = useState("");
	const [updateNoteValue, setUpdateNoteValue] = useState("");
	const [modalIsOpen, setIsOpen] = useState(false);
	const [selectedNote, setSelectedNote] = useState("");
	const { addToast } = useToasts();

	const handleAddNote = () => {
		if (note) {
			addNote({
				variables: {
					note,
					contactId,
				},
				update(cache, data) {
					cache.modify({
						fields: {
							msgbox_ContactNote(existingResponses = []) {
								const newResponseRef = cache.writeQuery({
									data: data.insert_msgbox_ContactNote_one,
									query: GET_CONTACT_NOTES,
								});
								return [...existingResponses, newResponseRef];
							},
						},
					});
				},
			});
			addToast("Successfully added note to contact", {
				appearance: "success",
				autoDismiss: true,
			});
			setNote("");
		}
	};

	const handleDeleteNote = (id) => {
		const confirmation = window.confirm(
			"Are you sure you want to delete this note?"
		);
		if (confirmation) {
			deleteNote({
				variables: { contactNoteId: id },
				update(cache, data) {
					cache.modify({
						fields: {
							msgbox_ContactNote(existingResponses = []) {
								const newResponseRef = cache.writeQuery({
									data: data.insert_msgbox_ContactNote_one,
									query: GET_CONTACT_NOTES,
								});
								return [...existingResponses, newResponseRef];
							},
						},
					});
				},
			});
			addToast("Successfully deleted note", {
				appearance: "success",
				autoDismiss: true,
			});
			setNote("");
		}
	};

	const handleEditNote = () => {
		updateNote({
			variables: {
				noteId: selectedNote.id,
				note: updateNoteValue,
			},
		});
		addToast("Successfully edited note", {
			appearance: "success",
			autoDismiss: true,
		});
		setNote("");
		closeModal();
	};

	/**
	 *
	 * @param {object} note
	 * @param {string} note.note
	 * @param {number} note.id
	 * @description - When modal opens set the selected not so handleEditNote() knows what note to edit
	 */
	const openModal = (note) => {
		const selectedNote = {
			id: note.id,
			value: note.note,
		};
		setSelectedNote(selectedNote);
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	if (data) {
		return (
			<div>
				<MsgBoxModal
					aria={{
						labelledby: "heading",
						describedby: "full_description",
					}}
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					header="Edit Note"
					description="Use the form below to edit a note"
					submit={handleEditNote}
					cancel={closeModal}
					submitButtonName="Edit Note"
				>
					<TextArea
						defaultValue={selectedNote.value}
						onChange={(event) => {
							setUpdateNoteValue(event.target.value);
						}}
						label="Edit Note"
						name="editNote"
					/>
				</MsgBoxModal>
				<Input
					value={note}
					placeholder="Add Note..."
					onIconClick={handleAddNote}
					onChange={(event) => {
						setNote(event.target.value);
					}}
					label="Add Note"
					icon="far fa-paper-plane"
					hasAction
				/>
				<Container>
					{data.msgbox_ContactNote.map((note, index) => {
						return (
							<StyledNote key={index}>
								<p style={{ margin: "6px" }}>{note.Note}</p>
								<NoteActions
									menuItems={[
										{
											name: "Edit",
											action: () => {
												openModal({
													note: note.Note,
													id: note.ContactNoteId,
												});
											},
											icon: (
												<EditIcon className="fas fa-pencil-alt" />
											),
											asLink: false,
										},
										{
											name: "Delete",
											action: () => {
												handleDeleteNote(
													note.ContactNoteId
												);
											},
											icon: (
												<TrashIcon className="far fa-trash-alt" />
											),
											asLink: false,
										},
									]}
								/>
							</StyledNote>
						);
					})}
				</Container>
			</div>
		);
	} else return null;
};

Notes.propTypes = {
	contactId: PropTypes.number,
};

export default Notes;
