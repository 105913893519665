import React from "react";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_CONVERSATIONS_CHART_DATA } from "./query";
import Chart from "../../../Components/Chart/chart";
import { NoData } from "./no-data";

const ConversationsChart = ({ data }) => {

	if (!data?.reporting_ConversationsByInbox[0]) return <NoData>No Data</NoData>;

	return (
		<Chart
			chartData={data}
			chartDataKey="reporting_ConversationsByInbox"
			legendName="Conversations Created"
		/>
	);
};

export default ConversationsChart;
