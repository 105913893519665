import { gql } from "@apollo/client";

export const GET_ALL_USER_INBOXES = gql`
	query getAllUserInboxes($userId: Int!) @cached(ttl: 300) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				InboxId
				IsPrivate
				IsTeam
				APIAccountId
				Name
				Folders(
					order_by: { Order: asc }
					where: { IsInbox: { _eq: true } }
				) {
					Name
					FolderId
					ParentFolderId
					IsSytemFolder
					IsArchive
				}
			}
		}
	}
`;
