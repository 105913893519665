import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
const ModalContainer = styled.div`
	position: fixed;
	top: 50%; /* Center vertically */
	left: 50%; /* Center horizontally */
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: ${(props) => (props.show ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	z-index: 9999;
`;

const ModalContent = styled.div`
	background-color: #fff;
	padding: 2rem;
	border-radius: 8px;
	width: 400px;
	text-align: center;
`;

const Button = styled.button`
	padding: 0.5rem 1rem;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 0.25rem;
	cursor: pointer;
	margin: 1rem 0;
	&:disabled {
		background-color: #6c757d;
	}
`;

const MessageSendingProgressModal = ({ recipients, onClose }) => {
	const [isSending, setIsSending] = useState(false);

	const sendMessage = async () => {
		setIsSending(true);

		// Simulate message sending for each recipient
		for (let i = 0; i < recipients.length; i++) {
			await sendMessageToRecipient(recipients[i]);
		}

		setIsSending(false);
	};

	const sendMessageToRecipient = (recipient) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				console.log(`Message sent to ${recipient.ContactId}`);
				resolve();
			}, 1000);
		});
	};

	useEffect(() => {
		if (!isSending) {
			sendMessage();
		}
	}, []);

	return (
		<ModalContainer show={isSending}>
			<ModalContent>
				<h3>Sending... please wait</h3>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						padding: 15,
					}}
				>
					<LoadingSpinner />
				</div>
				<Button onClick={onClose} disabled={isSending}>
					Close
				</Button>
			</ModalContent>
		</ModalContainer>
	);
};

export default MessageSendingProgressModal;
