import { gql } from "@apollo/client";

export const GET_CONTACT_DETAILS = gql`
	query getContactDetails($contactId: Int!) {
		msgbox_Contact(where: { ContactId: { _eq: $contactId } }) {
			Name
			Status
			MobileNumber
			ContactId
			TagContacts {
				TagId
				Tag {
					TagId
					Name
				}
			}
			APIAccount {
				APIAccountId
				Inboxes {
					InboxId
				}
		}
	}
	}
`;

export const DELETE_TAG_CONTACT = gql`
	mutation deleteTagContact($tagId: Int!, $contactId: Int!) {
		delete_msgbox_TagContact(
			where: { TagId: { _eq: $tagId }, ContactId: { _eq: $contactId } }
		) {
			affected_rows
			returning {
				ContactId
				TagId
			}
		}
	}
`;

export const ADD_TAG_CONTACT = gql`
	mutation addtagcontact($tagId: Int!, $contactId: Int!) {
		insert_msgbox_TagContact(
			objects: { ContactId: $contactId, TagId: $tagId }
		) {
			affected_rows
			returning {
				ContactId
				TagId
			}
		}
	}
`;
