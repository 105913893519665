import { gql } from "@apollo/client";

export const GET_SPAM = gql`
	query getSpam($inboxId: Int!) {
		msgbox_Folder(
			where: { InboxId: { _eq: $inboxId }, IsSpam: { _eq: true } }
		) {
			FolderId
			Name
		}
	}
`;

export const MOVE_CONVERSATION_TO_SPAM = gql`
	mutation moveConversationToSpam(
		$conversationId: Int!
		$spamFolderId: Int!
	) {
		update1: update_msgbox_Folder(
			where: {
				Conversations: { ConversationId: { _eq: $conversationId } }
			}
			_set: { UpdatedOn: "now()" }
		) {
			affected_rows
		}
		update_msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
			_set: { FolderId: $spamFolderId, UpdatedOn: "now()" }
		) {
			affected_rows
		}
		update2: update_msgbox_Folder(
			where: { FolderId: { _eq: $spamFolderId } }
			_set: { UpdatedOn: "now()" }
		) {
			affected_rows
		}
	}
`;
