import { gql } from "@apollo/client";

export const GET_USERS = gql`
	query getUsers(
		$limit: Int
		$offset: Int
		$order_by: msgbox_User_order_by!
		$searchTerm: String
	) {
		msgbox_User(
			where: {
				IsBot: { _eq: false }
				IsAPI: { _eq: false }
				_or: [
					{ FirstName: { _ilike: $searchTerm } }
					{ LastName: { _ilike: $searchTerm } }
				]
				UserRoles: {Role: {Name: {_is_null: false}}}
			}
			limit: $limit
			offset: $offset
			order_by: [$order_by]
		) {
			EMail
			FirstName
			LastName
			UserId
			UserDepartments {
				Department {
					Name
					DepartmentId
				}
			}
			UserRoles {
				Role {
					Description
					RoleId
				}
			}
			UserInboxes {
				Inbox {
					Name
					InboxId
				}
			}
		}
		msgbox_User_aggregate(where: { IsBot: { _eq: false } }) {
			aggregate {
				count
			}
		}
	}
`;

export const ADD_USER = gql`
	mutation addUser(
		$email: String!
		$firstName: String!
		$lastName: String!
		$departments: msgbox_UserDepartment_arr_rel_insert_input
		$role: msgbox_UserRole_arr_rel_insert_input!
		$inboxes: msgbox_UserInbox_arr_rel_insert_input!
	) {
		insert_msgbox_User(
			objects: {
				EMail: $email
				FirstName: $firstName
				LastName: $lastName
				UserDepartments: $departments
				UserRoles: $role
				UserInboxes: $inboxes
			}
		) {
			affected_rows
			returning {
				EMail
				FirstName
				LastName
				UserId
				UserDepartments {
					Department {
						Name
						DepartmentId
					}
				}
			}
		}
	}
`;

export const DELETE_USER = gql`
	mutation deleteUser($userId: Int!, $by:Int, $time:timestamptz) {
		update_msgbox_User(
			where: { UserId: { _eq: $userId } }
			_set: { IsDeleted: true, DeletedBy: $by, DeletedOn:$time }
		) {
			affected_rows
			returning {
				EMail
				LastName
				UserId
				FirstName
			}
		}
	}
`;

export const EDIT_USER = gql`
	mutation editUser(
		$userId: Int!
		$email: String
		$firstName: String
		$lastName: String
	) {
		update_msgbox_User(
			where: { UserId: { _eq: $userId } }
			_set: { EMail: $email, FirstName: $firstName, LastName: $lastName }
		) {
			affected_rows
			returning {
				EMail
				FirstName
				LastName
				UserId
				UserDepartments {
					Department {
						DepartmentId
						Name
					}
				}
				UserRoles {
					Role {
						Name
						RoleId
					}
				}
			}
		}
	}
`;

export const GET_DEPARTMENTS = gql`
	query getDepartments {
		msgbox_Department {
			Name
			DepartmentId
		}
	}
`;

export const ASSIGN_USERS_TO_DEPARTMENT = gql`
	mutation assignUsersToDept($userId: Int!, $departmentId: Int!) {
		update_msgbox_UserDepartment(
			where: { UserId: { _eq: $userId } }
			_set: { DepartmentId: $departmentId }
		) {
			affected_rows
			returning {
				Department {
					Name
					DepartmentId
				}
			}
		}
	}
`;

export const GET_EMAILS = gql`
	query getEmails {
		msgbox_User {
			EMail
			UserId
		}
	}
`;

export const REMOVE_USER_DEPARTMENTS = gql`
	mutation removeUserDept($userId: Int!) {
		delete_msgbox_UserDepartment(where: { UserId: { _eq: $userId } }) {
			affected_rows
		}
	}
`;

export const INSERT_USER_DEPARTMENTS = gql`
	mutation insertUserDepts(
		$userDepartments: [msgbox_UserDepartment_insert_input!]!
	) {
		insert_msgbox_UserDepartment(objects: $userDepartments) {
			affected_rows
			returning {
				DepartmentId
				UserId
			}
		}
	}
`;

export const GET_ROLES = gql`
	query getRoles {
		msgbox_Role {
			Name
			Description
			RoleId
		}
	}
`;

export const EDIT_ROLE = gql`
	mutation editRole($userId: Int!, $roleId: Int!) {
		update_msgbox_UserRole(
			where: { UserId: { _eq: $userId } }
			_set: { RoleId: $roleId }
		) {
			affected_rows
			returning {
				Role {
					UserRoles {
						RoleId
						UserId
					}
				}
			}
		}
	}
`;

export const DELETE_USER_INBOXES = gql`
	mutation deleteUserInboxes($userId: Int!) {
		delete_msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			affected_rows
			returning {
				InboxId
				User {
					UserId
					UserInboxes {
						Inbox {
							InboxId
							Name
						}
					}
				}
			}
		}
	}
`;

export const INSERT_USER_INBOXES = gql`
	mutation insertUserInboxes($inboxes: [msgbox_UserInbox_insert_input!]!) {
		insert_msgbox_UserInbox(objects: $inboxes) {
			affected_rows
			returning {
				InboxId
				User {
					UserId
					UserInboxes {
						Inbox {
							InboxId
							Name
						}
					}
				}
			}
		}
	}
`;
