import styled from "styled-components";
import Button from "../Button/button.component";

export const ModalHeader = styled.h2`
	text-align: left;
	color: ${(props) => props.theme.colours.dark};
`;

export const ModalDescription = styled.p`
	margin-bottom: 0.83em;
	color: ${(props) => props.theme.colours.dark};
`;

export const ModalButtonContainer = styled.div`
	margin-top: 24px;
	display: flex;
`;

export const ModalButton = styled(Button)`
	flex: 1;
	border: ${(props) =>
		props.primary ? "none" : `solid 1px ${props.theme.colours.light_4}`};
	margin: ${(props) => (props.primary ? "0 0 0 6px" : "0 6px 0 0 ")};
`;

export const ModalContentSeperator = styled.hr`
	margin-left: -20px;
	margin-right: -20px;
`;

export const defaultModalStyles = {
	content: {
		overflow: "visible",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	overlay: {
		backgroundColor: "rgba(0,0,0,0.7)",
	},
};
