import React, { useEffect, useState } from "react";
import { Container, OpeningHoursButtons } from "./styles";
import Toggle from "react-toggle";
import Button from "../Button/button.component";
import { daysMap, defautOpeningHours, defaultErrors } from "./helper";
import { Prompt } from "react-router";
import client from "../../Apollo";
import {
	GET_OPENING_HOURS,
	INSERT_OPENING_HOURS,
	DELETE_OPENING_HOURS,
	UPDATE_OPENING_HOURS,
	UPDATE_TIMEZONE,
	INSERT_INBOX_OPENING_HOURS,
	GET_INBOXES,
	UPDATE_CONFIG_TIMEZONE,
} from "./query";
import { useMutation, useQuery } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import Table from "./table";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import useInboxesOptions from "../AutomationSettings/inboxes-setttings/use-inboxes-options";
import { getUserId } from "../../helpers/functions";

const OpeningHours = () => {
	const [openingHours, setOpeningHours] = useState(defautOpeningHours);
	const [dirty, setDirty] = useState(false);
	const [errors, setErrors] = useState(defaultErrors);

	const [inboxOpeningHours, setInboxOpeningHours] = useState(defautOpeningHours);
	const [inboxDirty, setInboxDirty] = useState(false);
	const [inboxErrors, setInboxErrors] = useState(defaultErrors);

	const [selectedInbox, setSelectedInbox] = useState(null);
	const [inboxOptions, setInboxOptions] = useState([])

	const { data: inboxData } = useQuery(GET_INBOXES, {
		variables: {
			userId: getUserId(),
		},
	});

	const [
		insertOpeningHours,
		{ loading: insertLoading, data: insertData, error: insertError },
	] = useMutation(INSERT_OPENING_HOURS);

	const [
		insertInboxOpeningHours,
		{ loading: insertInboxLoading, data: insertInboxData, error: insertInboxError },
	] = useMutation(INSERT_INBOX_OPENING_HOURS);

	const [
		deleteOpeningHours,
		{ loading: deleteLoading, data: deleteData, error: deleteError },
	] = useMutation(DELETE_OPENING_HOURS);
	const [
		updateOpeningHours,
		{ loading: updateLoading, data: updateData, error: updateError },
	] = useMutation(UPDATE_OPENING_HOURS);

	const { addToast } = useToasts();

	const [
		updateTimezone
	] = useMutation(UPDATE_TIMEZONE)

	const [
		updateConfigTimezone
	] = useMutation(UPDATE_CONFIG_TIMEZONE)

	useEffect(() => {
		if (dirty || inboxDirty) {
			window.onbeforeunload = () => true;
		}
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [dirty]);

	useEffect(() => {
		if(!inboxData) return
		fetchOpeningHours();
	}, [inboxData]);

	useEffect(() => {
		console.log(111, inboxOpeningHours)
	}, [inboxOpeningHours])

	useEffect(() => {
		if (insertError || deleteError || updateError || insertInboxError) {
			addToast("An error occured", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	}, [insertError, deleteError, updateError, insertInboxError]);

	useEffect(() => {
		if(selectedInbox) {
			setInboxOpeningHours(selectedInbox.value)
			setInboxDirty(false)
		}
	}, [selectedInbox])
	
	const save = async (global) => {
		if (validate(global)) {
			const promises = [];
			let hours = global ? openingHours : inboxOpeningHours
			for (const day in hours) {
			
				const openingTime = hours[day].open;
				const closingTime = hours[day].close;
				const isOpen = hours[day].toggle;
				if (isOpen === false && hours[day].id) {
					promises.push(
						deleteOpeningHours({
							variables: {
								openingHoursId: hours[day].id,
							},
						})
					);
				} else {
					if (hours[day].id) {
						promises.push(
							updateOpeningHours({
								variables: {
									open: openingTime,
									close: closingTime,
									openingHoursId: hours[day].id,
								},
							})
						);
					} else {
						// If there is no id, insert value
						if (openingTime && closingTime) {
							if(global) {
								promises.push(
									insertOpeningHours({
										variables: {
											open: openingTime,
											close: closingTime,
											day: daysMap[day],
										},
									})
								);
							} else {
								promises.push(
									insertInboxOpeningHours({
										variables: {
											open: openingTime,
											close: closingTime,
											day: daysMap[day],
											inbox: hours.InboxId
										},
									})
								);
							}

						}
					}
				}
			}
			if(global) {
				promises.push(
					updateConfigTimezone({
						variables: {
						id: hours.configId,
						timezone: hours.Timezone.value}
					})
				)
			} else {
				promises.push(
					updateTimezone({
						variables: {
						id: hours.InboxId,
						timezone: hours.Timezone.value
						}
					})
				)	
			}
			await Promise.all(promises);
			addToast("Opening hours updated", {
				appearance: "success",
				autoDismiss: true,
			});
			if(global){
				setDirty(false);
			} else {
				setInboxDirty(false)
			}
			fetchOpeningHours()
		}
	};

	const reset = (global) => {
		if (window.confirm("Are you sure you want to reset? ")) {
			if(global) {
				setOpeningHours(defautOpeningHours);
				setErrors(defaultErrors);
				setDirty(true);
			} else {
				setInboxOpeningHours({...defautOpeningHours, InboxId: selectedInbox.value.InboxId})
				setInboxErrors(defaultErrors);
				setInboxDirty(true);
			}
		}
	};

	const validate = (global) => {
		let errors = { ...defaultErrors };
		let isValid = true;
		let hours = global ? openingHours : inboxOpeningHours
		console.log("g",hours)
		if(!hours?.Timezone?.value) isValid = false
		for (const day in hours) {
			const openingTime = hours[day].open;
			const closingTime = hours[day].close;
			if (hours[day].toggle === true) {
				if (openingTime && closingTime) {
					if (openingTime > closingTime) {
						isValid = false;
						errors[day] = {
							...errors[day],
							rangeError: `Opening time must be before closing time`,
						};
					}
				} else {
					if (!openingTime) {
						isValid = false;
						errors[day] = {
							...errors[day],
							openError: `Opening time is required on ${day}`,
						};
					}
					if (!closingTime) {
						isValid = false;
						errors[day] = {
							...errors[day],
							closeError: `Closing time is required on ${day}`,
						};
					}
				}
			}
		}
		if(global){
			setErrors(errors);
		} else {
			setInboxErrors(errors)
		}
		return isValid;
	};

	const fetchOpeningHours = async () => {
		if(!inboxData) return 
		const response = await client.query({
			query: GET_OPENING_HOURS,
			fetchPolicy: "network-only",
		});
		console.log("fetched", response)
		const currentOpeningHours = { default: {...defautOpeningHours} };
		let inboxOptions = inboxData?.msgbox_User[0]?.UserInboxes.filter((item) => item.Inbox).map((inbox) => {
			currentOpeningHours[inbox.Inbox.InboxId] = {...defautOpeningHours, InboxId: inbox.Inbox.InboxId}
			return {
				label: inbox.Inbox.APIAccount.Name,
				value: {...defautOpeningHours, InboxId: inbox.Inbox.InboxId},
			}
		});
		response.data.msgbox_OpeningHours.forEach((day) => {
			const nameOfDay = Object.keys(daysMap).find(
				(key) => daysMap[key] === day.DayOfWeek
			);
			if(day.Inbox) {
				currentOpeningHours[day.Inbox.InboxId][nameOfDay] = {
					...currentOpeningHours[day.Inbox.InboxId][nameOfDay],
					open: day.Open.slice(0, 5),
					close: day.Close.slice(0, 5),
					toggle: true,
					id: day.OpeningHoursId,
				};
				currentOpeningHours[day.Inbox.InboxId].Timezone = {value: day.Inbox.Timezone, label: day.Inbox.Timezone}	
                
				const targetOptionIndex = inboxOptions.findIndex(option => option.value.InboxId == day.Inbox.InboxId);
				if (targetOptionIndex !== -1) {
                    inboxOptions[targetOptionIndex].value = currentOpeningHours[day.Inbox.InboxId];
                }			
			
			} else {
				currentOpeningHours.default[nameOfDay] = {
					...currentOpeningHours.default[nameOfDay],
					open: day.Open.slice(0, 5),
					close: day.Close.slice(0, 5),
					toggle: true,
					id: day.OpeningHoursId,
				};
				currentOpeningHours.default.configId = day.Organisation.Configurations[0].ConfigurationId
				currentOpeningHours.default.Timezone = {value: day.Organisation.Configurations[0].Timezone, label: day.Organisation.Configurations[0].Timezone}	
			}
		});
		setOpeningHours(currentOpeningHours.default);
		setInboxOptions(inboxOptions)
	};

	return (
		<div>
			<Container>
				<h2>Global Opening Hours</h2>
				<Table openingHours={openingHours} errors={errors} save={() => save(true)} reset={() => reset(true)} setOpeningHours={setOpeningHours} loading={insertLoading || updateLoading} dirty={dirty} setDirty={setDirty}/>
			</Container>
			<Container style={{ marginTop: "24px" }}>
				<h2>Inbox Opening Hours</h2>
				<h3 style={{marginBottom: 10, marginTop: 10}}>
					Any inbox specific settings will override the global settings
					above.
				</h3>
				<MsgBoxSelect
					options={inboxOptions}
					onChange={(option) => {
						setSelectedInbox(option);
					}}
					name="selectedInbox"
					label="Select inbox"
					value={selectedInbox}
				/>
				{selectedInbox && 
					<Table openingHours={inboxOpeningHours} errors={inboxErrors} save={() => save(false)} reset={() => reset(false)} setOpeningHours={setInboxOpeningHours} loading={insertInboxLoading || updateLoading} dirty={inboxDirty} setDirty={setInboxDirty}/>
				}
			</Container>
		</div>
	);
};

export default OpeningHours;
