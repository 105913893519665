import { gql } from "@apollo/client";

export const GET_ARCHIVE = gql`
	query getArchiveFolder($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			FolderId
			Folder {
				Inbox {
					Folders(where: { IsArchive: { _eq: true } }) {
						FolderId
					}
				}
			}
		}
	}
`;

export const MOVE_CONVERSATION_TO_ARCHIVE = gql`
	mutation moveConversationToArchive(
		$conversationId: Int!
		$archiveId: Int!
	) {
		update_msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
			_set: { FolderId: $archiveId, UpdatedOn: "now()" }
		) {
			affected_rows
		}
	}
`;
