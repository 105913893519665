import React, { useState } from "react";

import Modal from "react-modal";
import {
	ModalDescription,
	ModalHeader,
	ModalContentSeperator,
} from "../MsgBoxModal/styles";
import { $reuseableDialog } from "../../store";
import { useReactiveVar } from "@apollo/client";
import Button from "../Button/button.component";

const ReuseableDialog = () => {
	const reuseableDialog = useReactiveVar($reuseableDialog)

	return (
	<Modal
		isOpen={reuseableDialog.visible}
		onRequestClose={() => {
			$reuseableDialog({
				visible: false,
				title: "",
				body: "",
				button: "",
				onPress: () => {}
			})
		}}
		style={{
			content: {
				overflow: "visible",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				
			},
			overlay: {
				backgroundColor: "rgba(0,0,0,0.7)",
			},
		}}
	>
			<ModalHeader>{reuseableDialog.title}</ModalHeader>
			{reuseableDialog.body && <ModalDescription>
			{reuseableDialog.body}		
			</ModalDescription>}
			<ModalContentSeperator />

			<div style={{display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
			<Button
				buttonName={"Cancel"}
				primary={true}
				onClick={() => {
					$reuseableDialog({
						visible: false,
						title: "",
						body: "",
						button: "",
						onPress: () => {}
					})
				}}
			/>
			<Button
				buttonName={reuseableDialog.button}
				primary={true}
				onClick={() => {
					reuseableDialog.onPress()
					$reuseableDialog({
						visible: false,
						title: "",
						body: "",
						button: "",
						onPress: () => {}
					})
				}}
			/>
			</div>
	</Modal>
	);
};

export default ReuseableDialog