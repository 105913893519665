import { useState, useRef, useEffect, useContext } from "react";
import MsgBoxTable from "../../Components/MsgBoxTable/msg-box-table.component";
import Pill from "../../Components/Pill/pill.component";
import Actions from "../../Components/Actions/actions.component";
import {
	GET_CONTACTS,
	ADD_CONTACT,
	DELETE_CONTACT,
	UNSUBSCRIBE_CONTACTS,
} from "./query";
import Input from "../../Components/Input/input.component";
import CheckBox from "../../Components/Checkbox/checkbox.component";
import Tags from "../../Components/TagsFormField/tags.component";
import MsgBoxModal from "../../Components/MsgBoxModal/msgbox-modal.component";
import { useMutation, useReactiveVar, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Formik, Field } from "formik";
import { addContactValidationSchema } from "./validationSchema";
import SendTemplateMessage from "../../Components/SendTemplateMessage/send-template-message.component";
import { ContextActionButton } from "../../Components/MsgBoxTable/styles";
import { Send } from "../../Components/Notifications/styles";
import { ContactImage, ContactErrorStyle } from "./styles";
import MsgBoxSelect from "../../Components/MsgBoxSelect/msg-box-select.component";
import client from "../../Apollo";
import { SEARCH_TAGS, SEARCH_CONTACT } from "./query";
import { GET_ACCOUNT } from "../../Components/APIAccountsSelect/query";
import { getUserId } from "../../helpers/functions";
import {
	$conversationFolderFilter,
	$isMessageSending,
	$selectedConversation,
	$selectedFolder,
	$selectedInbox,
	$userInboxes,
} from "../../store";
import { GET_API_ACCOUNT_ID } from "../../Components/24HoursPastTemplateMessage/query";
import { UserContext } from "../../Providers/user-provider";
import { TableFiltersContainer } from "../../Styles/global";
import ReactGA from "react-ga4";
import hideNumbers from "../../helpers/hideNumber";

const disabledFields = {
	searchBy: true,
	contacts: true,
	tags: true,
	templateMessages: false,
	account: true,
};

const Contacts = ({ rootStore }) => {
	const inboxStore = rootStore.getInboxStore()
	const conversationStore = rootStore.getConversationStore()

	const columns = [
		{
			name: "Name",
			selector: "name",

			sortable: true,
			cell: (row) => (
				// Once contact imgs are availible use this or their img
				<ContactImage data-tag="allowRowEvents">
					<i
						data-tag="allowRowEvents"
						className="fas fa-user-circle"
					></i>{" "}
					{row.name}
				</ContactImage>
			),
			sortField: "Name",
		},
		{
			name: "Phone Number",
			selector: "phoneNumber",
		},
		{
			name: "Tags",
			selector: "tags",
			cell: (row) =>
				row.tags.map((tag, index) => <Pill key={index} name={tag} />),
		},
		{
			name: "Created On",
			selector: "createdOn",
			sortable: true,
			sortField: "CreatedOn",
		},
		{
			name: "Account",
			selector: "apiAccount",
			cell: (row) => <div>{row.apiAccount.name}</div>,
		},
		{
			name: "Actions",
			selector: "actions",
			right: true,
			cell: (row) => <Actions menuItems={row.actions} />,
		},
	];
	const user = useContext(UserContext);
	const selectedInbox = useReactiveVar($selectedInbox);
	const userInboxes = useReactiveVar($userInboxes);
	const [addModalIsOpen, setAddRowModalOpen] = useState(false);
	const [sendTemplateModalIsOpen, setSendTemplateModal] = useState(false);
	const [formIsDirty, setFormDirty] = useState(false);
	const history = useHistory();
	const { addToast } = useToasts();
	const [addContact, { loading, data }] = useMutation(ADD_CONTACT);
	const [deleteContact, { deleting, deleted }] = useMutation(DELETE_CONTACT);
	const [contacts, setContacts] = useState([]);
	const [contactError, setContactError] = useState("");
	const [contactPhoneNumberHelperText, setContactPhoneNumberHelperText] =
		useState("");
	const tableRef = useRef();
	const canSeeNumbers = user.permissions.canSeeNumbers;
	/**
	 * For api accounts dropdown menu
	 */
	const [accounts, setAccounts] = useState([]);
	/**
	 * For api accounts dropdown menu when only one option is avaliable
	 */
	const [accountDefault, setAccountDefault] = useState("");

	const [filters, setFilters] = useState({
		condition: {
			APIAccount: { Inboxes: { InboxId: { _in: userInboxes } } },
		},
	});

	const [contactUnsubscribe] = useMutation(UNSUBSCRIBE_CONTACTS);

	const [selectedRows, setSelectedRows] = useState([]);
	/**
	 * Set the api account id for the send template message component.
	 * Different depending on the contact(s) that have been selected
	 */
	const [selectedApiAccountId, setApiAccountId] = useState(null);

	const {
		loading: accountLoading,
		error: accountError,
		data: accountData,
	} = useQuery(GET_API_ACCOUNT_ID, {
		variables: {
			inboxId: selectedInbox,
		},
	});

	useEffect(() => {
		loadAccountOptions();
	}, []);

	const handleAddContact = async (values, actions) => {
		const formattedTags = values.tags.map((tag) => ({ TagId: tag.id }));

		const contactExists = await searchForContact(
			values.phoneNumber,
			values.account.value
		);
		console.log("contactExists:", contactExists);
		if (contactExists) {
			setContactError(
				"This contact already exists -" + contactExists + ""
			);
			addToast("Contact Already Exists", {
				appearance: "error",
				autoDismiss: true,
			});
			return;
		}
		await addContact({
			variables: {
				name: values.name,
				mobileNumber: values.phoneNumber,
				tags: formattedTags,
				account: values.account.value,
				email: values.email,
			},
		});
		closeModal();
		addToast("Contact Added", { appearance: "success", autoDismiss: true });
		if (tableRef.current) tableRef.current.refetchData();
		ReactGA.event({
			category: "Contacts",
			action: "ContactAdded",
		});
	};

	const handleDeleteRows = async (rows) => {
		const promises = rows.map((row) =>
			deleteContact({ variables: { id: row.id } })
		);
		await Promise.all(promises);
		addToast("Contacts deleted", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
		ReactGA.event({
			category: "Contacts",
			action: "ContactDeleted",
		});
	};

	const searchForContact = (searchNumber, searchAccount) => {
		return new Promise(async (resolve, reject) => {
			const response = await client.query({
				query: SEARCH_CONTACT,
				variables: {
					searchNumber: searchNumber,
					searchAccount: searchAccount,
				},
				fetchPolicy: "network-only",
			});
			console.log("response.data:", response.data);
			const contactExists =
				response.data.msgbox_Contact.length > 0
					? response.data.msgbox_Contact[0].Name
					: null;
			resolve(contactExists);
		});
	};

	const loadTagsOptions = (searchTerm) => {
		return new Promise(async (resolve, reject) => {
			const response = await client.query({
				query: SEARCH_TAGS,
				variables: {
					searchTerm: searchTerm + "%",
				},
				fetchPolicy: "network-only",
			});
			const options = response.data.msgbox_Tag.map((tag) => ({
				label: tag.Name,
				value: tag.TagId,
			}));
			resolve(options);
		});
	};

	const loadAccountOptions = async () => {
		try {
			const apiAccounts = inboxStore.apiAccounts
			const options = apiAccounts.map((item) => ({
				label: item.name,
				value: item.apiAccountId
			}))
			if (options.length === 1) {
				setAccountDefault(options[0]);
			}
			setAccounts(options);
		} catch (error) {
			console.log("error", error);
			addToast("An error occured", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const handleSearchChange = (searchTerm) => {
		const newFilters = {
			condition: {
				...filters.condition,
				...{
					_or: [
						{
							Name: {
								_ilike:
									searchTerm === ""
										? null
										: "%" + searchTerm + "%",
							},
						},
						{
							MobileNumber: {
								_ilike:
									searchTerm === ""
										? null
										: "%" + searchTerm + "%",
							},
						},
					],
				},
			},
		};
		setFilters(newFilters);
	};

	const handleTagFilterChange = (name, values) => {
		if (values.length === 0) {
			const newFilters = {
				condition: {
					...filters.condition,
					TagContacts: {},
				},
			};
			setFilters(newFilters);
		} else {
			const tags = values.map((tag) => tag.value);
			const condition = {
				...filters.condition,
				TagContacts: { TagId: { _in: tags } },
			};
			setFilters({
				condition,
			});
		}
	};

	const handleAccountFilterChange = (name, option) => {
		const newFilters = {
			condition: {
				...filters.condition,
				APIAccountId: { _eq: option.value },
			},
		};
		setFilters(newFilters);
	};

	const openSendTemplateMessageModal = () => {
		const accountId = selectedRows[0].apiAccount.id;
		let allowSendTemplate = true;
		for (const row of selectedRows) {
			if (row.apiAccount.id !== accountId) {
				allowSendTemplate = false;
				break;
			}
		}
		if (allowSendTemplate) {
			setApiAccountId(accountId);

			setSendTemplateModal(true);
		} else {
			alert(
				"You can only send templates in bulk to contacts in the same account. Filter the contacts by account and try again"
			);
		}
	};

	const goToContactDetails = (row) => {
		history.push(`/contact-details/${row.id}`);
	};

	const openModal = () => {
		setAddRowModalOpen(true);
	};

	const closeModal = () => {
		setContactError("");
		setContactPhoneNumberHelperText("");
		setAddRowModalOpen(false);
		setFormDirty(false);
	};

	const formatData = (data) => {
		const contacts = data.msgbox_Contact.map((contact) => {
			let dontShow = canSeeNumbers;
			const apiAccount = inboxStore.getApiAccount(contact.APIAccountId)
			const conv = conversationStore.getConversationForContact(contact.ContactId)
			//console.log("conv:". conv)
			const folder = conv ? inboxStore.getFolder(conv.folderId): null
			const inbox = folder ? inboxStore.getInbox(folder.inboxId) : null
			return {
				id: contact.ContactId,
				phoneNumber: hideNumbers(contact.MobileNumber, dontShow),
				name: hideNumbers(contact.Name, dontShow),
				lastName: contact.LastName,
				createdOn: new Date(contact.CreatedOn).toDateString(),
				tags: contact.TagContacts.map((tag) => tag.Tag.Name),
				apiAccount: {
					name: apiAccount?.name,
					id: apiAccount?.apiAccountId,
				},
				status: contact.Status,
				actions: [
					{
						name: "Go to conversation",
						disabled: conv === null,
						asLink: false,
						icon: <Send className="far fa-comments"></Send>,
						action: () => {
							$selectedConversation(conv.conversationId);
							$isMessageSending(false);
							$selectedFolder(conv.folderId);
							const actionFolder = inboxStore.getFolder(conv.folderId)
							$conversationFolderFilter(actionFolder.name);
							$selectedInbox(actionFolder.inboxId);
							//console.log("contact.contactId:", contact.ContactId)
							//console.log("conversation.conversationId:", conv.conversationId)
							//console.log("conversation.folderId:", conv.folderId)
							//console.log("actionFolder.name:", actionFolder.name)
							//console.log("actionFolder.inboxId:", actionFolder.inboxId)
							history.push(`/inbox`);
						},
					},
					{
						name: "Send template message",
						icon: <Send className="far fa-paper-plane"></Send>,
						disabled: contact.Status === "Unsubscribed",
						asLink: false,
						action: () => {
							// Set contacts here as well because on click of this action will not trigger "handleSelectedRowChange".
							// Which would otherwise set the contacts for the send template message.
							const _contacts = [
								{
									label: contact.Name,
									value: contact.ContactId,
								},
							];
							// This is being set here so the send template message modal account field is prepopulated with the correct apiAccountId
							setApiAccountId(apiAccount.apiAccountId);
							setContacts(_contacts);
							setSendTemplateModal(true);
						},
					},
					{
						name: "Unsubscribe contact",
						disabled: contact.Status === "Unsubscribed",
						asLink: false,
						icon: <Send className="far fa-comments"></Send>,
						action: () => {
							unsubscribeContacts([contact.ContactId]);
						},
					},
				],
			};
		});

		return [contacts, data.msgbox_Contact_aggregate.aggregate.count];
	};

	const unsubscribeContacts = async (ids) => {
		await contactUnsubscribe({
			variables: {
				contacts: ids,
			},
		});
		addToast("Contacts Unsubscribed", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
	};

	const handleSelectedRowChange = (rows) => {
		// Generate initial values for the contacts field within the send template message component.
		const contacts = rows.map((row) => ({
			label: row.name + " " + `(${row.phoneNumber})`,
			value: row.id,
		}));
		setContacts(contacts);
		setSelectedRows(rows);
	};

	const onInputChange = (event, fn) => {
		fn(event);
		setFormDirty(true);
	};

	ReactGA.send({ hitType: "pageview", page: "/contacts" });

	return (
		<div
			style={{
				margin: "24px",
			}}
		>
			<SendTemplateMessage
				rootStore={rootStore}
				disableSearchBy={true}
				isOpen={sendTemplateModalIsOpen}
				close={() => {
					setSendTemplateModal(false);
				}}
				contacts={contacts}
				account={selectedApiAccountId}
				disabledFields={disabledFields}
			/>
			<MsgBoxModal
				aria={{
					labelledby: "heading",
					describedby: "full_description",
				}}
				description={"Use the form below to add a contact"}
				header={"Add Contact"}
				isOpen={addModalIsOpen}
				cancel={closeModal}
				onRequestClose={closeModal}
				submitButtonName="Add Contact"
				submitId="add_contact"
				submitting={loading}
				submitButtonDisabled={formIsDirty === false}
			>
				<div>
					<Formik
						initialValues={{
							account: accountDefault,
							name: "",
							tags: [],
							phoneNumber: "",
							consent: false,
							email: "",
						}}
						onSubmit={(values, actions) => {
							handleAddContact(values, actions);
						}}
						validationSchema={addContactValidationSchema}
						validateOnChange={false}
						validateOnBlur={false}
					>
						{({
							handleSubmit,
							handleChange,
							setFieldValue,
							values,
							errors,
						}) => (
							<form id="add_contact" onSubmit={handleSubmit}>
								<Field
									placeholder="Select Account"
									label="Select Account"
									key={1}
									name="account"
									onChange={(options, action) => {
										setFieldValue("account", options);
										setFormDirty(true);
									}}
									options={accounts}
									value={values.account}
									error={errors.account}
									component={MsgBoxSelect}
									toolTipText="Select an account that you wish this message to be sent from"
									hasTooltip
									required
								/>
								<Input
									required
									id="name"
									label="Name"
									name="name"
									type="text"
									value={values.name}
									placeholder="Name"
									onChange={(event) => {
										onInputChange(event, handleChange);
									}}
									error={errors.name}
								/>
								<Input
									id="email"
									label="Email"
									name="email"
									type="text"
									value={values.email}
									placeholder="Email"
									onChange={(event) => {
										onInputChange(event, handleChange);
									}}
									error={errors.email}
								/>
								<Input
									required
									label="Phone Number"
									id="phone-number"
									name="phoneNumber"
									type="text"
									value={values.phoneNumber}
									onChange={(event) => {
										const number = event.target.value;
										if (number.substring(0, 4) === "+440") {
											setContactPhoneNumberHelperText(
												"Are you sure this is correct? If you entered the country code you may omit the first 0."
											);
										} else {
											setContactPhoneNumberHelperText("");
										}
										onInputChange(event, handleChange);
									}}
									placeholder="Phone Number"
									error={errors.phoneNumber}
									helperText={contactPhoneNumberHelperText}
								/>
								{contactError && (
									<p style={ContactErrorStyle}>
										{" "}
										{contactError}{" "}
									</p>
								)}
								<Tags
									label="Tags"
									onChange={(options, action) => {
										setFieldValue("tags", options);
										setFormDirty(true);
									}}
									hasTooltip
									toolTipText="Add tags to or this text explains wot2du"
									right
								/>
								<CheckBox
									label="Confirm you have consent to contact this person on WhatsApp"
									name="consent"
									onChange={(event) => {
										onInputChange(event, handleChange);
									}}
									checked={values.consent}
									error={errors.consent}
									required
								/>
							</form>
						)}
					</Formik>
				</div>
			</MsgBoxModal>
			<MsgBoxTable
				rootStore={rootStore}
				showBulkContactImport={true}
				searchPlaceHolder="Search contact name..."
				handleSearchChange={handleSearchChange}
				ref={tableRef}
				rowClick={goToContactDetails}
				openModal={openModal}
				openModalButtonName="Add contact"
				defaultOrderBy={{
					field: "Name",
					sortDirection: "asc",
				}}
				filters={filters}
				customContextActions={[
					<ContextActionButton
						title="Unsubscribe Contacts"
						primary
						icon="far fa-bell-slash"
						onClick={() => {
							let arr = selectedRows
								.filter((item) => {
									if (item.status == "Unsubscribed")
										return false;
									return true;
								})
								.map((item) => item.id);
							unsubscribeContacts(arr);
						}}
					/>,
					<ContextActionButton
						title="Send Template Messages"
						primary
						icon="far fa-paper-plane"
						onClick={openSendTemplateMessageModal}
					/>,
				]}
				subHeaderComponent={[
					<TableFiltersContainer>
						<MsgBoxSelect
							key={1}
							onChange={(options) => {
								handleTagFilterChange("tags", options);
							}}
							name="tags"
							label="Tags"
							loadOptions={loadTagsOptions}
							table
							async
							isMulti
						/>
						<MsgBoxSelect
							key={2}
							onChange={(options) => {
								handleAccountFilterChange("account", options);
							}}
							name="account"
							label="Account"
							table
							options={[
								...accounts,
								{ label: "Any", value: null },
							]}
						/>
					</TableFiltersContainer>,
				]}
				hasDelete={user.permissions.canDeleteContacts}
				subHeader
				subHeaderAlign="left"
				selectedRowsChange={handleSelectedRowChange}
				defaultLimit={10}
				formatData={formatData}
				fetchRows={GET_CONTACTS}
				queryVariables={{ userId: parseInt(getUserId()) }}
				deleteRows={handleDeleteRows}
				columns={columns}
				title="Contacts"
				paginationServer
				pagination
				selectableRows
				pointerOnHover
				sortServer
				hasExport={user.permissions.canDownloadContacts}
			/>
		</div>
	);
};

export default Contacts;
