import { useMutation, useReactiveVar } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import ReactGA from "react-ga4";

import { GET_SPAM, MOVE_CONVERSATION_TO_SPAM } from "./query";
import { $selectedInbox, $selectedConversation } from "../../store";
import client from "../../Apollo";

const useMoveConversationToSpam = () => {
	const { addToast } = useToasts();
	const selectedInbox = useReactiveVar($selectedInbox);
	const selectedConversation = useReactiveVar($selectedConversation);

	const [moveToSpam] = useMutation(MOVE_CONVERSATION_TO_SPAM);
	const moveConversationToSpam = async () => {
		try {
			const spamFolderId = await fetchSpam();
			await moveToSpam({
				variables: {
					conversationId: selectedConversation,
					spamFolderId: spamFolderId,
				},
			});
			$selectedConversation(null);
			addToast("Conversation move to spam", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Widgets",
				action: "ConversationMovedToSpam",
			});
		} catch (error) {
			addToast(
				"Something went wrong. Please try again later. If the problem persists, contact support.",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
		}
	};

	const fetchSpam = async () => {
		const { data } = await client.query({
			query: GET_SPAM,
			variables: {
				inboxId: selectedInbox,
			},
		});
		return data.msgbox_Folder[0].FolderId;
	};

	return moveConversationToSpam;
};

export default useMoveConversationToSpam;
