import { ConversationActionButton } from "./styles";
import { MARK_CONVERSATION_AS_UNREAD } from "./query";
import { useMutation, useReactiveVar } from "@apollo/client";
import { $selectedConversation, $selectedFolder, $markedAsUnread } from "../../store";
import ReactGA from "react-ga4";

const MarkAsUnreadButton = () => {
	const selectedConversation = useReactiveVar($selectedConversation);
	const selectedFolder = useReactiveVar($selectedFolder);
	const [markAsUnreadMutation, { data, error, loading }] = useMutation(
		MARK_CONVERSATION_AS_UNREAD
	);
	const handleMarkAsUnreadClick = async () => {
		await markAsUnreadMutation({
			variables: {
				conversationId: selectedConversation
			},
		});
		ReactGA.event({
			category: "Widgets",
			action: "ConversationMarkedUnred",
		});
		$markedAsUnread(true)
	};

	return (
		<ConversationActionButton
			data-tip="Mark as unread"
			style={{ float: "right" }}
			icon="fas fa-envelope"
			onClick={handleMarkAsUnreadClick}
		/>
	);
};

export default MarkAsUnreadButton;
