import React, { useState } from "react";
import { useMutation, useSubscription } from "@apollo/client";
import {
	GET_CHILD_RESPONSES,
	ADD_CHILD_RESPONSE,
	DELETE_CHILD_RESPONSE,
	UPDATE_CHILD_RESPONSE,
	SUBSCRIBE_TO_CHILD_RESPONSES,
	SUBSCRIBE_TO_PARENT_RESPONSE,
	UPDATE_BOT_RESPONSE_ORDER,
} from "./query";
import IconButton from "../../../Components/IconButton/icon-button.component";
import { Container, MessageButton } from "./styles";
import Button from "../../../Components/Button/button.component";
import AddResponseModal from "../AddResponse/add-response.component";
import EditResponseModal from "../EditResponse/edit-child-bot-response.component";
import { updateCache } from "./helper";
import { LoadingContainer, ButtonContainer } from "./styles";
import LoadingSpinner from "../../../Components/LoadingSpinner/loading-spinner.component";
import { useToasts } from "react-toast-notifications";
import { formatString } from "../../../helpers/formatString";

const BotResponsesList = ({ parentResponse }) => {
	const [addResponseModalIsOpen, setAddResponseModalOpen] = useState(false);
	const [editResponseModalIsOpen, setEditResponseModalOpen] = useState(false);
	const [editModaldata, setEditModalData] = useState({
		reply: "",
		requiresResponse: null,
		captureAsEmailAddress: null,
		botResponseId: null,
		ReplyMetadata: null,
		ListMetadata: null
		});
	const { addToast } = useToasts();

	const {
		loading: parentLoading,
		error: parentError,
		data: parentData,
	} = useSubscription(SUBSCRIBE_TO_PARENT_RESPONSE, {
		variables: {
			botResponseId: parentResponse.id,
		},
	});

	const {
		loading: childLoading,
		error: childError,
		data: childData,
	} = useSubscription(SUBSCRIBE_TO_CHILD_RESPONSES, {
		variables: {
			parentId: parentResponse.id,
		},
	});

	const [
		addResponse,
		{ loading: addResponseLoading, error: addResponseError },
	] = useMutation(ADD_CHILD_RESPONSE, {
		update(cache, data) {
			updateCache(cache, data, GET_CHILD_RESPONSES);
		},
	});

	const [
		deleteResponse,
		{ loading: deleteResponseLoading, error: deleteResponseError },
	] = useMutation(DELETE_CHILD_RESPONSE, {
		update(cache, data) {
			updateCache(cache, data, GET_CHILD_RESPONSES);
		},
	});

	const [
		editResponse,
		{ loading: editResponseLoading, error: editResponseError },
	] = useMutation(UPDATE_CHILD_RESPONSE);

	const [
		editOrderMutation,
		{ error: errorUpdatingOrder, loading: updateingOrder },
	] = useMutation(UPDATE_BOT_RESPONSE_ORDER);

	const handleDeleteResponse = async (botResponseId) => {
		if (window.confirm("Are you sure you want to delete this response?")) {
			await deleteResponse({
				variables: {
					botResponseId,
				},
			});
			const dataToBeUpdated = childData.msgbox_BotResponse
				.filter((x) => x.BotResponseId !== botResponseId)
				.map((x, key) =>
					editOrderMutation({
						variables: {
							order: key + 1,
							botResponseId: x.BotResponseId,
						},
					})
				);
			await Promise.all(dataToBeUpdated);
		}
	};

	const handleEditResponse = (
		reply,
		requiresResponse,
		captureAsEmailAddress,
		botResponseId,
		featureType,
		featureInfo
	) => {
		let ReplyMetaData = null
		let ListMetaData = null
		if(featureType.value === "Quick Reply"){
			let temp = []
			if(featureInfo[0] && featureInfo[0] !== "") temp.push({"postbackText": "dev-defined-postback1", "type":"text","title":featureInfo[0] }) 
			if(featureInfo[1] && featureInfo[1] !== "") temp.push({"postbackText": "dev-defined-postback2","type":"text","title":featureInfo[1] }) 
			if(featureInfo[2] && featureInfo[2] !== "") temp.push({"postbackText": "dev-defined-postback3","type":"text","title":featureInfo[2] }) 
			if(temp !== []) ReplyMetaData = JSON.stringify(temp)
		}
		if(featureType.value === "List"){
			let temp = []
			if(featureInfo[0] && featureInfo[0] !== "") temp.push({"postbackText": `${featureInfo[0]} postback payload`, "type":"text","title":featureInfo[0]}) 
			if(featureInfo[1] && featureInfo[1] !== "") temp.push({"postbackText": `${featureInfo[1]} postback payload`, "type":"text","title":featureInfo[1]}) 
			if(featureInfo[2] && featureInfo[2] !== "") temp.push({"postbackText": `${featureInfo[2]} postback payload`, "type":"text","title":featureInfo[2]}) 
			if(featureInfo[3] && featureInfo[3] !== "") temp.push({"postbackText": `${featureInfo[3]} postback payload`, "type":"text","title":featureInfo[3]}) 
			if(featureInfo[4] && featureInfo[4] !== "") temp.push({"postbackText": `${featureInfo[4]} postback payload`, "type":"text","title":featureInfo[4]}) 
			if(featureInfo[5] && featureInfo[5] !== "") temp.push({"postbackText": `${featureInfo[5]} postback payload`, "type":"text","title":featureInfo[5]}) 
			if(featureInfo[6] && featureInfo[6] !== "") temp.push({"postbackText": `${featureInfo[6]} postback payload`, "type":"text","title":featureInfo[6]}) 
			if(featureInfo[7] && featureInfo[7] !== "") temp.push({"postbackText": `${featureInfo[7]} postback payload`, "type":"text","title":featureInfo[7]}) 
			if(featureInfo[8] && featureInfo[8] !== "") temp.push({"postbackText": `${featureInfo[8]} postback payload`, "type":"text","title":featureInfo[8]}) 
			if(featureInfo[9] && featureInfo[9] !== "") temp.push({"postbackText": `${featureInfo[9]} postback payload`, "type":"text","title":featureInfo[9]}) 
			if(temp !== []) ListMetaData = JSON.stringify([{"title": "Select An Option", "options": temp}])
		}
		editResponse({
			variables: {
				requiresResponse,
				captureAsEmailAddress,
				reply,
				botResponseId,
				ListMetaData, 
				ReplyMetaData
			},
		});
	};

	const handleAddResponse = (
		reply,
		requiresResponse,
		captureAsEmailAddress,
		featureType,
		featureInfo
	) => {
		// If the length of the data is zero or less the user is adding the first response
		// So if there is no data set the orderOfLastResponse to 0 becuase top level/parent responses
		// order is set to 0
		let orderOfLastResponse = 0;
		if (childData.msgbox_BotResponse.length > 0) {
			const index = childData.msgbox_BotResponse.length - 1;
			orderOfLastResponse = childData.msgbox_BotResponse[index].Order;
		}
		let ReplyMetaData = null
		let ListMetaData = null
		if(featureType.value === "Quick Reply"){
			let temp = []
			if(featureInfo[0] && featureInfo[0] !== "") temp.push({"postbackText": "dev-defined-postback1", "type":"text","title":featureInfo[0] }) 
			if(featureInfo[1] && featureInfo[1] !== "") temp.push({"postbackText": "dev-defined-postback2","type":"text","title":featureInfo[1] }) 
			if(featureInfo[2] && featureInfo[2] !== "") temp.push({"postbackText": "dev-defined-postback3","type":"text","title":featureInfo[2] }) 
			if(temp !== []) ReplyMetaData = JSON.stringify(temp)
		}
		if(featureType.value === "List"){
			let temp = []
			if(featureInfo[0] && featureInfo[0] !== "") temp.push({"postbackText": `${featureInfo[0]} postback payload`, "type":"text","title":featureInfo[0]}) 
			if(featureInfo[1] && featureInfo[1] !== "") temp.push({"postbackText": `${featureInfo[1]} postback payload`, "type":"text","title":featureInfo[1]}) 
			if(featureInfo[2] && featureInfo[2] !== "") temp.push({"postbackText": `${featureInfo[2]} postback payload`, "type":"text","title":featureInfo[2]}) 
			if(featureInfo[3] && featureInfo[3] !== "") temp.push({"postbackText": `${featureInfo[3]} postback payload`, "type":"text","title":featureInfo[3]}) 
			if(featureInfo[4] && featureInfo[4] !== "") temp.push({"postbackText": `${featureInfo[4]} postback payload`, "type":"text","title":featureInfo[4]}) 
			if(featureInfo[5] && featureInfo[5] !== "") temp.push({"postbackText": `${featureInfo[5]} postback payload`, "type":"text","title":featureInfo[5]}) 
			if(featureInfo[6] && featureInfo[6] !== "") temp.push({"postbackText": `${featureInfo[6]} postback payload`, "type":"text","title":featureInfo[6]}) 
			if(featureInfo[7] && featureInfo[7] !== "") temp.push({"postbackText": `${featureInfo[7]} postback payload`, "type":"text","title":featureInfo[7]}) 
			if(featureInfo[8] && featureInfo[8] !== "") temp.push({"postbackText": `${featureInfo[8]} postback payload`, "type":"text","title":featureInfo[8]}) 
			if(featureInfo[9] && featureInfo[9] !== "") temp.push({"postbackText": `${featureInfo[9]} postback payload`, "type":"text","title":featureInfo[9]}) 
			if(temp !== []) ListMetaData = JSON.stringify([{"title": "Select An Option", "options": temp}])
		}
		addResponse({
			variables: {
				ListMetaData,
				ReplyMetaData,
				order: orderOfLastResponse + 1,
				parentId: parentResponse.id,
				reply: reply,
				requiresResponse: requiresResponse,
				captureAsEmailAddress: captureAsEmailAddress,
				account: parentResponse.account.value,
			},
		});
	};

	const openAddResponseModal = () => {
		setAddResponseModalOpen(true);
	};

	const closeAddResponseModal = () => {
		setAddResponseModalOpen(false);
	};

	const openEditResponseModal = (
		requiresResponse,
		captureAsEmailAddress,
		botResponseId,
		reply,
		ReplyMetadata,
		ListMetadata
	) => {
		setEditModalData({
			requiresResponse,
			captureAsEmailAddress,
			botResponseId,
			reply,
			ReplyMetadata,
			ListMetadata
		});
		setEditResponseModalOpen(true);
	};

	const closeEditResponseModal = () => {
		setEditResponseModalOpen(false);
	};

	// Order = order of selected response
	// botResponseId = id of selected response
	// direction = up / down
	const editOrder = async (order, botResponseId, direction) => {
		const botResponseAbove = childData.msgbox_BotResponse.find(
			(botResponse) => botResponse.Order === order - 1
		);
		const botResponseBelow = childData.msgbox_BotResponse.find(
			(botResponse) => botResponse.Order === order + 1
		);
		// Check if the reply can be moved up
		if (direction === "up" && botResponseAbove) {
			// Swap the selected reply with the reply above
			await editOrderMutation({
				variables: {
					order: botResponseAbove.Order,
					botResponseId,
				},
			});
			await editOrderMutation({
				variables: {
					order,
					botResponseId: botResponseAbove.BotResponseId,
				},
			});
			// Check if the reply can be moved down
		} else if (direction === "down" && botResponseBelow) {
			// Swap the selected reply with the reply below
			await editOrderMutation({
				variables: {
					order: botResponseBelow.Order,
					botResponseId,
				},
			});
			await editOrderMutation({
				variables: {
					order,
					botResponseId: botResponseBelow.BotResponseId,
				},
			});
		}
	};

	function getMetadata(item) {
		let arr = []
		let text = ""
		if(item.ReplyMetadata){
			let temp = JSON.parse(item.ReplyMetadata)
			arr = temp.map((listItem) => listItem.title)
		}
		if(item.ListMetadata){
			let temp = JSON.parse(item.ListMetadata)
			arr = temp[0].options.map((listItem) => listItem.title)
		}
		if(arr.length == 0) return text
		arr.forEach((arrItem) => {
			text += ` | [${arrItem}]`
		})
		return text
	}

	if (childError || parentError) {
	}

	if (childError || parentError) return <p>Oops.. something went wrong</p>;

	return (
		<Container>
			{parentData?.msgbox_BotResponse?.length === 1 ? (
				childLoading || parentLoading ? (
					<LoadingContainer>
						<LoadingSpinner />
					</LoadingContainer>
				) : (
					<>
						<div className="response child-response">
							<p>{formatString(parentData.msgbox_BotResponse[0].Reply + getMetadata(parentData.msgbox_BotResponse[0])).map((item, index) => {
								if (item.bold && item.italic) {
									return (
										<p
											key={index}
											style={{
												fontWeight: "bold",
												fontStyle: "italic",
											}}
										>
											{item.text}
										</p>
									);
								} else if (item.italic) {
									return (
										<p key={index} style={{ fontStyle: "italic" }}>
											{item.text}
										</p>
									);
								} else if (item.bold) {
									return (
										<p key={index} style={{ fontWeight: "bold" }}>
											{item.text}
										</p>
									);
								} else if (item.button) {
									return <MessageButton>{item.text}</MessageButton>
								} else {
									return <p key={index}>{item.text}</p>;
								}
							})}</p>
							<div className="response-actions">
								<IconButton
									onClick={() => {
										openEditResponseModal(
											parentData.msgbox_BotResponse[0]
												.RequiresResponse,
											parentData.msgbox_BotResponse[0]
												.CaptureEmailAddress,
											parentData.msgbox_BotResponse[0]
												.BotResponseId,
											parentData.msgbox_BotResponse[0]
												.Reply,
											parentData.msgbox_BotResponse[0]
												.ReplyMetadata,
											parentData.msgbox_BotResponse[0]
												.ListMetadata
										);
									}}
									icon="far fa-edit"
								/>
							</div>
						</div>
						{parentData.msgbox_BotResponse[0].RequiresResponse ===
						true ? (
							<div className="response client-response">
								<p>Client response</p>
							</div>
						) : null}

						{childData.msgbox_BotResponse.map((response, index) => {
							return (
								<div key={response.BotResponseId}>
									<div className="response child-response">
										<p>{formatString(response.Reply + getMetadata(response)).map((item, index) => {
											if (item.bold && item.italic) {
												return (
													<p
														key={index}
														style={{
															fontWeight: "bold",
															fontStyle: "italic",
														}}
													>
														{item.text}
													</p>
												);
											} else if (item.italic) {
												return (
													<p key={index} style={{ fontStyle: "italic" }}>
														{item.text}
													</p>
												);
											} else if (item.bold) {
												return (
													<p key={index} style={{ fontWeight: "bold" }}>
														{item.text}
													</p>
												);
											} else if (item.button) {
												return <MessageButton>{item.text}</MessageButton>
											} else {
												return <p key={index}>{item.text}</p>;
											}
										})}</p>
										<div className="response-actions">
											{response.IsDeletable && (
												<IconButton
													onClick={async () => {
														await handleDeleteResponse(
															response.BotResponseId
														);
													}}
													icon={"fas fa-trash-alt"}
												/>
											)}

											<IconButton
												onClick={() => {
													openEditResponseModal(
														response.RequiresResponse,
														response.CaptureEmailAddress,
														response.BotResponseId,
														response.Reply,
														response.ReplyMetadata,
														response.ListMetadata
													);
												}}
												icon="far fa-edit"
											/>
										</div>

										<div
											style={{
												display: "flex",
												flexDirection: "column",
												marginLeft: "auto",
												justifyContent: "center",
											}}
										>
											{index !== 0 && (
												<IconButton
													icon="fas fa-chevron-up"
													onClick={() => {
														editOrder(
															response.Order,
															response.BotResponseId,
															"up"
														);
													}}
												></IconButton>
											)}
											{index !==
												childData.msgbox_BotResponse
													.length -
													1 && (
												<IconButton
													icon="fas fa-chevron-down"
													onClick={() => {
														editOrder(
															response.Order,
															response.BotResponseId,
															"down"
														);
													}}
												></IconButton>
											)}
										</div>
									</div>
									{response.RequiresResponse ? (
										<div className="response client-response">
											<p>Client response</p>
										</div>
									) : null}
								</div>
							);
						})}
						<ButtonContainer>
							<Button
								primary
								buttonName="add response"
								onClick={openAddResponseModal}
							/>
						</ButtonContainer>
						<AddResponseModal
							isOpen={addResponseModalIsOpen}
							submit={handleAddResponse}
							close={closeAddResponseModal}
						/>
						<EditResponseModal
							isOpen={editResponseModalIsOpen}
							submit={handleEditResponse}
							close={closeEditResponseModal}
							data={editModaldata}
						/>
					</>
				)
			) : (
				<LoadingContainer>
					<p>Click on a row in the table to start</p>
				</LoadingContainer>
			)}
		</Container>
	);
};

export default BotResponsesList;
