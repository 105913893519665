import { gql } from "@apollo/client";

export const GET_BOT_STATUS = gql`
	query getBotStatus($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			IsBotActive
			ConversationId
		}
	}
`;

export const TOGGLE_BOT = gql`
	mutation toggleBot($conversationId: Int!, $status: Boolean!) {
		update_msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
			_set: { IsBotActive: $status }
		) {
			affected_rows
		}
	}
`;
