import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { DropZone, Delete } from "./styles";
import { FormField, Label } from "../../Styles/global";
import Button from "../Button/button.component";
import PropTypes from "prop-types";

const FileUpload = ({ required, label, handleFileChange }) => {
	const [myFiles, setMyFiles] = useState([]);

	useEffect(() => {
		handleFileChange(myFiles);
	}, [myFiles]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			setMyFiles([...myFiles, ...acceptedFiles]);
		},
		[myFiles]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	const removeFile = (file) => () => {
		const newFiles = [...myFiles];
		newFiles.splice(newFiles.indexOf(file), 1);
		setMyFiles(newFiles);
	};

	const removeAll = () => {
		setMyFiles([]);
	};

	const files = myFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes{" "}
			<Delete icon="fas fa-trash-alt" onClick={removeFile(file)}>
				Remove File
			</Delete>
		</li>
	));

	return (
		<FormField>
			<Label>{required ? label + "*" : label}</Label>
			<DropZone {...getRootProps()}>
				<input {...getInputProps()} />
				<p>Drag a file here, or click to select a file.</p>
			</DropZone>
			<aside>
				<ul>{files}</ul>
			</aside>
			{files.length > 2 && (
				<Button primary buttonName="Delete all" onClick={removeAll} />
			)}
		</FormField>
	);
};

FileUpload.propTypes = {
	required: PropTypes.bool,
	label: PropTypes.string,
	handleFileChange: PropTypes.func,
};

FileUpload.defaultProps = {
	required: false,
	label: "Files",
};

export default FileUpload;
