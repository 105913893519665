import styled from "styled-components";

export const Container = styled.div`
	margin: 6px;
	height: 38px;
	display: flex;
	.chat-toggles {
		display: flex;
		margin-left: auto;
	}
`;
