import { useEffect, useState, useRef, useContext } from "react";
import { autorun } from "mobx"
import { Helmet } from "react-helmet";
import { useTheme } from "styled-components";

const UnreadConversations = ({ rootStore }) => {
	const conversationStore = rootStore.getConversationStore()
	const theme = useTheme();
	const [title, setTitle] = useState(theme.appName);
	let count = useRef(null);

	// Handle title ticking
	useEffect(() =>
            autorun(() => {
				const unreadCount = conversationStore.getInboxUnreadCount();
				if (unreadCount > 0) {
					setTitle(`(${unreadCount}) New Message Arrived`);
				} else {
					setTitle(theme.appName);
				}
			}),
        []
    )

	return (
		<Helmet defer={false}>
			<title>{title}</title>
		</Helmet>
	);

};

export default UnreadConversations;
