import styled from "styled-components";
import { headerLeft } from "../../Styles/global";
import { device } from "../../Styles/constants";

export const StyledContactDetails = styled.div`
	display: flex;
	@media (max-width: ${device.mobile}) {
		display: block;
	}
`;
export const Details = styled.div`
	flex: 5;
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
	margin-top: 6px;
	margin-bottom: 6px;
	@media (min-width: ${device.mobile}) {
		margin-right: 12px;
	}
`;

export const Activity = styled.div`
	flex: 3;
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
	margin-top: 6px;
	margin-bottom: 6px;
	@media (min-width: ${device.mobile}) {
		margin-left: 12px;
	}
`;

export const Container = styled.div`
	margin: 24px;
`;

export const SubHeader = styled.h2`
	${headerLeft};
	i {
		font-size: 12px;
	}
`;

export const NameContainer = styled.div`
	display: flex;
	gap: 6px;
`;

export const ContentHeader = styled.h3`
	${headerLeft};
`;

export const ContactImage = styled.img`
	height: 50px;
	margin-right: 12px;
`;

export const Divider = styled.hr`
	margin-left: -24px;
	margin-right: -24px;
`;

export const SubHeaderContainer = styled.div`
	min-height: 60px;
	display: flex;
	align-items: center;
`;

export const Status = styled.span`
	padding: 6px 12px;
	border: solid 1px
		${(props) =>
			props.status === "Subscribed"
				? `${props.theme.colours.success}`
				: `${props.theme.colours.danger}`};
	border-radius: 5px;
	color: ${(props) =>
		props.status === "Subscribed"
			? `${props.theme.colours.success}`
			: `${props.theme.colours.danger}`};
	display: inline-block;
`;

export const EditContactButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;
