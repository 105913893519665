import React from "react";
import PropTypes from "prop-types";
import { StyledIconButton, Span, Container } from "./styles";

const IconButton = ({
	buttonName,
	icon,
	onClick,
	hasTooltip,
	tooltipText,
	left,
	...props
}) => {
	return (
		<Container>
			<StyledIconButton
				onClick={(event) => {
					onClick(event);
				}}
				{...props}
			>
				<i className={icon}></i>
			</StyledIconButton>
		</Container>
	);
};

IconButton.propTypes = {
	buttonName: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	hasTooltip: PropTypes.bool,
	tooltipText: PropTypes.string,
	left: PropTypes.bool,
};

IconButton.defaultProps = {
	onClick: () => {},
	hasTooltip: false,
	left: false,
};

export default IconButton;
