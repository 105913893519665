import React from "react";
import { Container } from "./styles";

const ContactCard = ({
	addresses,
	birthday,
	emails,
	ims,
	name,
	org,
	phones,
	urls,
}) => {
	return (
		<Container>
			<div className="header">
				<h4>{(name ? name.formatted_name : '')}</h4>
				<p>{(urls && urls.length > 0) ? urls[0].url : ''}</p>
			</div>
			<div className="detail-container">
				<div>
					<div>
						{phones && phones.map((phoneNumber, index) => {
							return (
								<div className="detail" key={index}>
									<i className="fas fa-phone-square"></i>
									<p>{phoneNumber.phone}</p>
								</div>
							);
						})}
					</div>
					<div>
						{emails && emails.map((email, index) => {
							return (
								<div className="detail" key={index}>
									<i className="fas fa-envelope-square"></i>
									<a href={`mailto: ${email.email}`}>
										{email.email}
									</a>
								</div>
							);
						})}
					</div>
				</div>
				<div>
					{addresses && addresses.length > 0 && addresses[0].street && <p>{addresses[0].street}</p>}
					{addresses && addresses.length > 0 && addresses[0].city && <p>{addresses[0].city}</p>}
					{addresses && addresses.length > 0 && addresses[0].zip && <p>{addresses[0].zip}</p>}
					{addresses && addresses.length > 0 && addresses[0].country && <p>{addresses[0].country}</p>}
				</div>
			</div>
		</Container>
	);
};

export default ContactCard;
