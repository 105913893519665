import React from "react";
import { WaveSpin, WaveSpinnerContainer } from "./styles";

const WaveSpinner = () => {
	return (
		<WaveSpinnerContainer>
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
			<WaveSpin />
		</WaveSpinnerContainer>
	);
};

export default WaveSpinner;
