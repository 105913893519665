import React, {useEffect, useState} from "react";
import { ButtonsContainer, Container, OpeningHoursButtons, SelectContainer } from "./styles";
import Toggle from "react-toggle";
import Button from "../Button/button.component";
import { Prompt } from "react-router";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useQuery } from "@apollo/client";
import { GET_TIMEZONES } from "./query";

const Table = ({openingHours, setOpeningHours, errors, save, reset, loading, dirty, setDirty}) => {

	const [timezoneOptions, setTimezoneOptions] = useState([])

	const {data} = useQuery(GET_TIMEZONES)
	
	useEffect(() => {
		if(!data) return
		let arr = data.msgbox_Timezone.map((item) => {
			return {
				label: item.Name,
				value: item.Name
			}
		})
		setTimezoneOptions(arr)
	}, [data])

	const handleChange = (event, type) => {
		setDirty(true);
		switch (type) {
			case "toggle":
				{
					setOpeningHours({
						...openingHours,
						[event.target.name]: {
							...openingHours[event.target.name],
							toggle: event.target.checked,
							open:
								event.target.checked === false
									? ""
									: openingHours[event.target.name].open,
							close:
								event.target.checked === false
									? ""
									: openingHours[event.target.name].close,
						},
					});
				}
				break;
			case "open":
				{
					setOpeningHours({
						...openingHours,
						[event.target.name]: {
							...openingHours[event.target.name],
							open: event.target.value,
						},
					});
				}
				break;
			case "close":
				{
					setOpeningHours({
						...openingHours,
						[event.target.name]: {
							...openingHours[event.target.name],
							close: event.target.value,
						},
					});
				}
			break;
			case "timezone":
				{
					setOpeningHours({
						...openingHours,
						Timezone: event
					});
				}
				break;
			default:
				break;
		}
	};

	return (
		<div className="opening-hours">
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>				
				<table>
					<tr>
						<th>Day</th>
						<th>Is Open</th>
						<th>Opening time</th>
						<th>Closing Time</th>
					</tr>
					<tr>
						<td>
							<label htmlFor="monday">Monday</label>
						</td>
						<td>
							<Toggle
								id="monday"
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="monday"
								checked={openingHours.monday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.monday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									<input
										name="monday"
										onChange={(event) => {
											handleChange(event, "open");
										}}
										type="time"
										value={openingHours.monday.open}
									/>
									<small>{errors.monday.openError}</small>
									<small>{errors.monday.rangeError}</small>
								</>
							)}
						</td>
						<td>
							{openingHours.monday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="monday"
										value={openingHours.monday.close}
										onChange={(event) => {
											handleChange(event, "close");
										}}
										type="time"
									/>
									<small>{errors.monday.closeError}</small>
									<small>{errors.monday.rangeError}</small>
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>
							<label htmlFor="tuesday">Tuesday</label>
						</td>
						<td>
							<Toggle
								id="tuesday"
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="tuesday"
								checked={openingHours.tuesday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.tuesday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									<input
										name="tuesday"
										value={openingHours.tuesday.open}
										type="time"
										onChange={(event) => {
											handleChange(event, "open");
										}}
									/>
									<small>{errors.tuesday.openError}</small>
									<small>
										{errors.tuesday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
						<td>
							{openingHours.tuesday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									<input
										name="tuesday"
										value={openingHours.tuesday.close}
										type="time"
										onChange={(event) => {
											handleChange(event, "close");
										}}
									/>
									<small>{errors.tuesday.closeError}</small>
									<small>
										{errors.tuesday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>
							<label htmlFor="wednesday">Wednesday</label>
						</td>
						<td>
							<Toggle
								id="wednesday"
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="wednesday"
								checked={openingHours.wednesday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.wednesday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="wednesday"
										value={openingHours.wednesday.open}
										type="time"
										onChange={(event) => {
											handleChange(event, "open");
										}}
									/>
									<small>{errors.wednesday.openError}</small>
									<small>
										{errors.wednesday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
						<td>
							{openingHours.wednesday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="wednesday"
										value={openingHours.wednesday.close}
										type="time"
										onChange={(event) => {
											handleChange(event, "close");
										}}
									/>
									<small>{errors.wednesday.closeError}</small>
									<small>
										{errors.wednesday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>
							<label htmlFor="thursday">Thursday</label>
						</td>
						<td>
							<Toggle
								id="thursday"
								defaultChecked={false}
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="thursday"
								checked={openingHours.thursday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.thursday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="thursday"
										value={openingHours.thursday.open}
										onChange={(event) => {
											handleChange(event, "open");
										}}
										type="time"
									/>
									<small>{errors.thursday.openError}</small>
									<small>
										{errors.thursday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
						<td>
							{openingHours.thursday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="thursday"
										value={openingHours.thursday.close}
										onChange={(event) => {
											handleChange(event, "close");
										}}
										type="time"
									/>
									<small>{errors.thursday.closeError}</small>
									<small>
										{errors.thursday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>
							<label htmlFor="friday">Friday</label>
						</td>
						<td>
							<Toggle
								id="friday"
								defaultChecked={false}
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="friday"
								checked={openingHours.friday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.friday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="friday"
										value={openingHours.friday.open}
										onChange={(event) => {
											handleChange(event, "open");
										}}
										type="time"
									/>
									<small>{errors.friday.openError}</small>
									<small>
										{errors.friday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
						<td>
							{openingHours.friday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="friday"
										value={openingHours.friday.close}
										onChange={(event) => {
											handleChange(event, "close");
										}}
										type="time"
									/>
									<small>{errors.friday.closeError}</small>
									<small>
										{errors.friday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>
							<label htmlFor="saturday">Saturday</label>
						</td>
						<td>
							<Toggle
								id="saturday"
								defaultChecked={false}
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="saturday"
								checked={openingHours.saturday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.saturday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="saturday"
										value={openingHours.saturday.open}
										onChange={(event) => {
											handleChange(event, "open");
										}}
										type="time"
									/>
									<small>{errors.saturday.openError}</small>
									<small>
										{errors.saturday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
						<td>
							{openingHours.saturday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="saturday"
										value={openingHours.saturday.close}
										onChange={(event) => {
											handleChange(event, "close");
										}}
										type="time"
									/>
									<small>{errors.saturday.closeError}</small>
									<small>
										{errors.saturday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
					</tr>
					<tr>
						<td>
							<label htmlFor="sunday">Sunday</label>
						</td>
						<td>
							<Toggle
								id="sunday"
								defaultChecked={false}
								onChange={(event) => {
									handleChange(event, "toggle");
								}}
								name="sunday"
								checked={openingHours.sunday.toggle === true}
							/>
						</td>
						<td>
							{openingHours.sunday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="sunday"
										value={openingHours.sunday.open}
										onChange={(event) => {
											handleChange(event, "open");
										}}
										type="time"
									/>
									<small>{errors.sunday.openError}</small>
									<small>
										{errors.sunday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
						<td>
							{openingHours.sunday.toggle === false ? (
								<p>Closed</p>
							) : (
								<>
									{" "}
									<input
										name="sunday"
										value={openingHours.sunday.close}
										onChange={(event) => {
											handleChange(event, "close");
										}}
										type="time"
									/>
									<small>{errors.sunday.closeError}</small>
									<small>
										{errors.sunday.rangeError}
									</small>{" "}
								</>
							)}
						</td>
					</tr>
				</table>
				<ButtonsContainer>
					<SelectContainer>
						<MsgBoxSelect
							options={timezoneOptions}
							onChange={(options) => {
								handleChange(options, "timezone")
							}}
							name="Timezone"
							label="Timezone"
							value={
								openingHours.Timezone
							}
						/>
					</SelectContainer>
					<OpeningHoursButtons>
						<Button onClick={reset} buttonName="Reset All" />
						<Button
							loading={loading}
							onClick={save}
							buttonName="Save"
							primary
							disabled={dirty === false}
						/>
					</OpeningHoursButtons>
				</ButtonsContainer>
		</div>
	);
};

export default Table;
