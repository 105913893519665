import React, { useState } from "react";
import { Label, InputErrorMessage, FormField } from "../../Styles/global";
import { StyledTextArea } from "./styles";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/tooltip.component";

const TextArea = ({
	label,
	error,
	required,
	hasTooltip,
	toolTipText,
	...rest
}) => {
	return (
		<FormField>
			<Label>{required ? label + "*" : label}</Label>
			{hasTooltip && <Tooltip right text={toolTipText} />}
			<StyledTextArea {...rest}></StyledTextArea>
			{error && (
				<div>
					<InputErrorMessage>{error}</InputErrorMessage>
				</div>
			)}
		</FormField>
	);
};

TextArea.propTypes = {
	label: PropTypes.string,
	error: PropTypes.string,
	hasTooltip: PropTypes.bool,
	toolTipText: PropTypes.string,
};

TextArea.defautProps = {
	required: false,
	hasTooltip: false,
};

export default TextArea;
