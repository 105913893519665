import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const Container = styled.div`
	display: flex;
	align-items: center;
	i {
		font-size: 2.2rem;
		margin-right: 6px;
		margin-left: 6px;
	}
	a {
		text-decoration: none;
		color: ${(props) => props.theme.colours.dark};
		&:hover {
			text-decoration: underline;
			color: ${(props) => props.theme.colours.primary};
		}
	}
`;

export const ImagePreview = styled.div`
	width: 150px;
`;

export const DownloadButton = styled(IconButton)`
	background-color: ${(props) => props.theme.colours.primary};
	color: ${(props) => props.theme.colours.light_0};
	height: 36px;
	width: 36px;
	border-radius: 5px;
`;

export const ModalContentContainer = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
`;

export const ModalButtonsContainer = styled.div`
	display: flex;
	position: absolute;
	right: 6px;
	top: 6px;
`;

export const LargeImage = styled.img`
	height: auto;
	max-height: 100%;
`;

export const SmallImage = styled.img`
	cursor: pointer;
`;

export const MessageButton = styled.button`
	margin: 6px;
	padding: 10px;
	border: none;
	color: white;
	font-weight: bold;
	background-color: ${(props) => props.theme.colours.primary};
	border-radius: 5px;
	:active, :focus {
		outline: none;
	}
`