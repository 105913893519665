import { gql } from "@apollo/client";
import { NEW_NATURAL_CHAT_TEMPLATES } from "../Components/new-natural-chat/constants";

export const GET_INBOX_QUICK_REPLY = gql`
	query getQuickReply($userId: Int!) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				APIAccount {
					APIAccountId
					Templates(where: { Name: { _eq: "quick_reply" } }) {
						TemplateId
					}
				}
				InboxId
				Name
			}
		}
	}
`;

export const GET_INBOX_NATURAL_CHAT = gql`
	query getNaturalChat($userId: Int!) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				APIAccount {
					APIAccountId
					Templates(
						where: {
							Name: { _eq: "natural_chat" }
							Status: { _eq: Approved }
						}
					) {
						TemplateId
					}
				}
				InboxId
				Name
			}
		}
	}
`;

export const GET_INBOX_NEW_NATURAL_CHAT = gql`
	query getNewNaturalChat($userId: Int!) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				APIAccount {
					APIAccountId
					Templates(where: {Name: {_in: [${NEW_NATURAL_CHAT_TEMPLATES}]}, Status: {_eq: Approved}}) {
						TemplateId
						Name
					}
				}
				InboxId
				Name
			}
		}
	}
`;
