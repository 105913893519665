import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px;
	outline: solid 1px ${(props) => props.theme.colours.light_3};
	&:focus-within {
		outline: solid 2px ${(props) => props.theme.colours.primary};
	}
	textarea {
		border: none;
		width: 100%;
		background: transparent;
		outline: none;
	}
	button {
		width: 36px;
		height: 36px;
		background-color: ${(props) => props.theme.colours.primary};
		color: ${(props) => props.theme.colours.light_0};
		border-radius: 36px;
		border: none;
	}
	.pasted-container {
		width: 120px;
		position: relative;
		button {
			position: absolute;
			top: -10px;
			right: -10px;
		}
		div {
			background-color: ${(props) => props.theme.colours.primaryLight};
			padding: 12px;
			border-radius: 24px;
			color: black;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			i {
				font-size: 1.5rem;
				margin-right: 6px;
			}
		}
	}
	.text-row {
		display: flex;
		flex-direction: row;
		button {
			margin-left: auto;
		}
	}
`;
