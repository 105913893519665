import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const AlertsButton = styled(IconButton)`
	border: solid 1px ${(props) => props.theme.colours.light_3};
	font-size: 1.4rem;
	height: 38px;
	width: 38px;
	margin-right: 0;
	position: relative;
`;

export const Container = styled.div`
	position: relative;
	span {
		position: absolute;
		font-size: 8px;
		padding: 3px 6px;
		top: 0;
		right: 0;
		border-radius: 50%;
		background: ${(props) => props.theme.colours.danger};
		color: ${(props) => props.theme.colours.light_0};
	}
`;
