import { gql } from "@apollo/client";

export const GET_LEAD_DATA = gql`
	query getLeadData($contactId: Int!) {
		msgbox_ContactExtData(
			where: { ContactId: { _eq: $contactId } }
			order_by: { CreatedOn: desc }
		) {
			Data
			CreatedOn
		}
	}
`;
