import styled from "styled-components";

export const ContactImage = styled.div`
	display: flex;
	align-items: center;
	i {
		margin-right: 6px;
		font-size: 22px;
		color: ${(props) => props.theme.colours.light_4};
	}
`;

export const ContactErrorStyle = {
	color: "#FF0000",
	fontSize: "9.6px",
	marginBottom: "10px",
	transform: "translate(0px, -14px)",
}