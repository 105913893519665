import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
	Container,
	Icon,
	StyledDate,
	Description,
	Wrapper,
	LoadingContainer,
} from "./styles";
import { GET_CONTACT_ACTIVITY } from "./query";
import { useSubscription } from "@apollo/client";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";

const ContactActivity = ({ contactId }) => {
	const { loading, error, data } = useSubscription(GET_CONTACT_ACTIVITY, {
		variables: {
			contactId,
		},
	});

	if (error) {
		return <p>Oops... something went wrong</p>;
	}

	return (
		<Wrapper>
			{loading === false && data.msgbox_ContactAudit.length === 0 && (
				<LoadingContainer>
					<h3>No activity yet :(</h3>
				</LoadingContainer>
			)}

			{loading === true ? (
				<LoadingContainer>
					<LoadingSpinner />
				</LoadingContainer>
			) : (
				data?.msgbox_ContactAudit.map((item) => {
					const time = new Date(item.CreatedOn).toLocaleTimeString();
					return (
						<Container>
							<div>
								<Icon className="fab fa-whatsapp"></Icon>
							</div>
							<Description>
								<p>{item.Description}</p>
							</Description>
							<StyledDate>
								{new Date(item.CreatedOn).toDateString() +
									" " +
									time}
							</StyledDate>
						</Container>
					);
				})
			)}
		</Wrapper>
	);
};

ContactActivity.propTypes = {
	contactId: PropTypes.string,
};

export default ContactActivity;
