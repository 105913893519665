const localStorageFiltersKeys = {
	SELECTED_INBOX: "selected_inbox",
	INBOX_FILTERS: "inbox_filters",
};

export const setSelectedInbox = (inboxId) => {
	localStorage.setItem(
		localStorageFiltersKeys.SELECTED_INBOX,
		JSON.stringify(inboxId)
	);
};
export const deleteSelectedInbox = () => {
	setSelectedInbox(null);
};
export const getSelectedInbox = () => {
	try {
		const selectedInbox = JSON.parse(
			localStorage.getItem(localStorageFiltersKeys.SELECTED_INBOX) ??
				"null"
		);

		if (selectedInbox === null) {
			return null;
		}

		return selectedInbox;
	} catch (err) {
		console.error(`Error parsing user token ${err.message}`);
		deleteSelectedInbox();
		return null;
	}
};

export const setInboxFilters = (value, checked) => {
	let inboxesFilters = getInboxFilters();

	if (checked === true) {
		inboxesFilters.push(value);
	} else {
		inboxesFilters = inboxesFilters.filter(
			(x) => x !== value
		);
	}

	localStorage.setItem(
		localStorageFiltersKeys.INBOX_FILTERS,
		JSON.stringify(inboxesFilters)
	);
};

export const getInboxFilters = () => {
	try {
		const inboxFilters = JSON.parse(
			localStorage.getItem(localStorageFiltersKeys.INBOX_FILTERS) ??
				"[]"
		);

		return inboxFilters
	} catch (err) {
		console.error(`Error parsing user token ${err.message}`);
		return [];
	}
};
