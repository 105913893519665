import * as Yup from "yup";
import client from "../../Apollo";
import { GET_EMAILS } from "./query";
import jwtDecode from "jwt-decode";
import { getAccessToken } from "../../helpers/functions";

export const addUserValidationSchema = Yup.object().shape({
	firstName: Yup.string().required("First name is required"),
	email: Yup.string("Email is required")
		.email("Invalid email")
		.required("Email is required")
		.nullable(),
	lastName: Yup.string().required("Last name is required"),
	inboxes: Yup.array()
		.min(1, "You must select at least one inbox")
		.required("You must select at least one inbox"),
});

export const validateEmail = async (enteredEmail) => {
	try {
		const token = jwtDecode(await getAccessToken());
		const email = token["https://hasura.io/jwt/claims"]["x-hasura-email"];
		if (email === enteredEmail) return "Email is already in use";

		const response = await client.query({
			query: GET_EMAILS,
		});

		let found = false;
		for (const email of response.data.msgbox_User) {
			if (email.EMail?.toLowerCase() === enteredEmail?.toLowerCase()) {
				found = true;
				break;
			}
		}
		if (found) {
			return "Email is already in use";
		}
	} catch (error) {
		console.log("err", error);
	}
};

export const validateBulkAssignUserToDepartment = (departments) => {
	if (departments) {
		if (departments.length > 1) {
			for (const department of departments) {
				if (!department.value) {
					return "You cannot select 'No Team' along with other teams. A user can either be assigned to a team or not";
				}
			}
		} else return null;
	} else return null;
};
