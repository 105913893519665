import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
	query getUserDetails($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			EMail
			FirstName
			ImageURI
			UserId
			LastName
			HasImage
			UserDepartments {
				Department {
					Name
					DepartmentId
				}
			}
			signedurl {
				url
			}
		}
	}
`;

export const UPDATE_USER_DETAILS = gql`
	mutation updateUserDetails(
		$userId: Int!
		$firstName: String
		$lastName: String
		$imageUri: String
		$hasImage: Boolean
	) {
		update_msgbox_User(
			where: { UserId: { _eq: $userId } }
			_set: {
				FirstName: $firstName
				ImageURI: $imageUri
				LastName: $lastName
				HasImage: $hasImage
			}
		) {
			affected_rows
			returning {
				FirstName
				LastName
				ImageURI
				UserId
				HasImage
			}
		}
	}
`;

export const GET_ATTACHMENT_LOCATION = gql`
	query getAttachmentLocation($attachmentId: Int!) {
		msgbox_Attachment(where: { AttachmentId: { _eq: $attachmentId } }) {
			AttachmentId
			Location
		}
	}
`;
