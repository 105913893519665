import styled from "styled-components";
import { device } from "../../Styles/constants";

export const MessageListWrapper = styled.div`
	flex: 3;
	position: relative;
	background-color: ${(props) => props.theme.colours.light_0};
	margin: 12px 6px 12px 12px;
	height: calc(100% - 12px);
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;

	@media (max-width: ${device.mobile}) {
		flex-basis: 100%;
	}
`;
export const ChatWrapper = styled.div`
	height: 100%;
	margin: 12px 12px 12px 6px;
	flex: 1;
	background-color: ${(props) => props.theme.colours.light_0};
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	@media (max-width: ${device.mobile}) {
		flex-basis: 100%;
	}
`;

export const ChatSideWrapper = styled.div`
	height: calc(100% - 12px);
	flex: 6;
	display: flex;
	flex-direction: row;
`;

export const InfoPanelWrapper = styled.div`
	margin: 12px 12px 12px 6px;
	background-color: ${(props) => props.theme.colours.light_0};
	position: relative;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	border-radius: 6px;
	height:  100%;
	width: 33%;
	overflow-y: auto;
	overflow-x: hidden;
	::-webkit-scrollbar {
		width: 20px;
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: ${(props) => props.theme.colours.light_3};
		border-radius: 20px;
		border: 6px solid transparent;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: ${(props) => props.theme.colours.light_4};
	}

	@media (max-width: ${device.mobile}) {
		flex-basis: 100%;
	}
	@media (max-width: ${device.desktop}) {
		width: 0px;
	}
`;

export const StyledInboxPage = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: calc(100vh - 75px);
`;
