import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_OUTBOUND_MESSAGE_TABLE_DATA } from "./query";
import { ChartContainer } from "../../../Components/Chart/styles";
import DataTable from "react-data-table-component";
import useDefaultTableStyles from "../../../Components/MsgBoxTable/use-default-table-styles";

const OutboundMessagesTable = ({ start, end, inboxes }) => {
	const [formattedData, setFormattedData] = useState([]);
	const { data, error, loading } = useQuery(GET_OUTBOUND_MESSAGE_TABLE_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
	});
	const defaultTableStyles = useDefaultTableStyles();

	useEffect(() => {
		if (data && data.msgbox_Message && data.msgbox_Message.length > 0) {
			const returnArray = [];
			data.msgbox_Message.map((message) => {
				if (message.User) {
					const index = returnArray.findIndex(
						(x) => x.userId === message.User.UserId
					);
					if (index >= 0) {
						returnArray[index].count = returnArray[index].count + 1;
					} else {
						returnArray.push({
							userId: message.User.UserId,
							firstName: message.User.FirstName,
							lastName: message.User.LastName,
							count: 1,
						});
					}
				}
			});
			setFormattedData(returnArray);
		}
	}, [data]);

	if (error) return null;

	if (loading) return null;

	const columns = [
		{
			name: "First Name",
			selector: "firstName",
		},
		{
			name: "Last Name",
			selector: (row) => row.lastName,
		},

		{
			name: "Outbound Messages sent",
			selector: "count",
		},
	];

	return (
		<ChartContainer>
			<DataTable
				customStyles={defaultTableStyles}
				data={formattedData}
				columns={columns}
				title="Outbound Messages sent by User"
			/>
		</ChartContainer>
	);
};

export default OutboundMessagesTable;
