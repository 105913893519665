import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { MenuItem, MenuButton, ControlledMenu } from "@szhsin/react-menu";

const Actions = ({ menuItems, asLink }) => {
	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);

	return (
		<div style={{ marginLeft: "auto" }}>
			<MenuButton
				styles={{ position: "relative", border: "none" }}
				onClick={() => setOpen(true)}
				ref={ref}
			>
				<i className="fas fa-ellipsis-v"></i>
			</MenuButton>
			<ControlledMenu
				anchorRef={ref}
				isOpen={isOpen}
				onClose={(requestClose) => setOpen(requestClose)}
			>
				{menuItems.map((item, index) => {
					return (
						<MenuItem
							disabled={item.disabled ? true : false}
							onClick={() => {
								if (item.disabled) return;
								item.action(item.name);
							}}
							key={index}
						>
							{item.name}
						</MenuItem>
					);
				})}
			</ControlledMenu>
		</div>
	);
};

Actions.propTypes = {
	menuItems: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.object,
			name: PropTypes.string,
			link: PropTypes.string,
			action: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
				.isRequired,
			asLink: PropTypes.bool,
		})
	),
};

export default Actions;
