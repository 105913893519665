import styled from "styled-components";
import { device } from "../../Styles/constants";

export const defaultModalStyles = {
	content: {
		height: "fit-content",
		overflow: "visible",
		width: "fit-content",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	overlay: {
		backgroundColor: "rgba(0,0,0,0.7)",
	},
};

export const TemplateMessageForm = styled.form`
	@media (max-width: ${device.desktop}) {
		display: block;
	}
	display: flex;
	gap: 24px;
	.column-one {
		flex: 3;
	}
	.column-two {
		flex: 8;
	}
	.column-three {
		flex: 6;
		display: flex;
		flex-direction: column;
	}
	textarea {
		/* height: 330px; */
	}
`;

export const DropZone = styled.div`
	border: dashed 1px ${(props) => props.theme.colours.primary};
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.theme.colours.light_1};
	margin-bottom: 24px;
	.placeholder {
		display: flex;
		align-items: center;
		i {
			margin-right: 6px;
			font-size: 4rem;
		}
	}
	.dropzone {
		border: solid;
		height: 100%;
		width: 100%;
	}
`;

export const ErrorMsg = styled.small`
	position: absolute;
	bottom: -14px;
	color: ${(props) => props.theme.colours.danger};
`;

export const Checkbox = styled.input`
	outline-color: ${(props) => props.theme.colours.primary};
	accent-color: ${(props) => props.theme.colours.primary};
	margin-right: 12px;
	width: 2rem;
	height: 2rem;
`;

export const CheckboxLabel = styled.p`
margin: 0;
color: ${(props) => props.theme.colours.dark};
`

export const MessageButton = styled.button`
	margin: 6px;
	padding: 10px;
	border: none;
	color: white;
	font-weight: bold;
	background-color: ${(props) => props.theme.colours.primary};
	border-radius: 5px;
	:active, :focus {
		outline: none;
	}
`