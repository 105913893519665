import React, { useEffect, useState, useRef } from "react";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import PropTypes from "prop-types";
import { AudioActionsButton, ButtonsContainer, Container } from "./styles";
import { getAccessToken } from "../../helpers/functions";
import { useToasts } from "react-toast-notifications";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTheme } from "styled-components";

const options = { mimeType: "audio/ogg" };
const workerOptions = {
	OggOpusEncoderWasmPath:
		"https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm",
	WebMOpusEncoderWasmPath:
		"https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm",
};

const RecordVoice = ({ isOpen, cancel, send }) => {
	const chunks = useRef([]);
	const stream = useRef(null);
	const recorder = useRef(null);
	const timer = useRef(null);

	const [audioFile, setAudioFile] = useState(null);
	const [time, setTime] = useState(0);
	const [status, setStatus] = useState("STOPPED");
	const [key, setKey] = useState(0);
	const [record, setRecord] = useState(false);
	const [loading, setLoading] = useState(false);

	const { addToast } = useToasts();
	const theme = useTheme();

	useEffect(() => {
		return () => {
			destroyModal();
		};
	}, []);

	useEffect(() => {
		if (time === 30) stopRecording();
	}, [time]);

	useEffect(() => {
		if (record === true) startTimer();
		if (record === false) stopTimer();
	}, [record]);

	const start = async () => {
		stream.current = await navigator.mediaDevices.getUserMedia({
			audio: true,
		});
		startRecording();
		startTimer();
		recorder.current = new MediaRecorder(
			stream.current,
			options,
			workerOptions
		);
		recorder.current.start(50);

		recorder.current.ondataavailable = (e) => {
			if (e.data && e.data.size > 0) {
				chunks.current.push(e.data);
			}
		};

		recorder.current.onstop = function (e) {
			var blob = new Blob(chunks.current, {
				type: "audio/mp3",
			});
			setAudioFile(blob);
			chunks.current = [];
			stream.current.getTracks().forEach((track) => track.stop());
		};
	};

	const stop = () => {
		recorder.current.stop();
		stopRecording();
		stopTimer();
		recorder.current = null;
	};

	const destroyModal = () => {
		if (recorder.current) {
			recorder.current.stop();
			recorder.current = null;
		}
		if (stream.current) {
			stream.current.getTracks().forEach((track) => track.stop());
		}
		clearInterval(timer.current);
		chunks.current = [];
		timer.current = null;
		setAudioFile(null);
		setTime(0);
		setRecord(false);
		setStatus("STOPPED");
		cancel();
	};

	const startTimer = () => {
		if (!timer.current) {
			timer.current = setInterval(() => {
				setTime((currentTime) => currentTime + 1);
			}, 1000);
		}
	};

	const stopTimer = () => {
		clearInterval(timer.current);
		timer.current = null;
		setTime(0);
		setKey(key + 1);
	};

	const startRecording = () => {
		setRecord(true);
		setStatus("RECORDING");
	};

	const stopRecording = () => {
		setRecord(false);
		setStatus("STOPPED");
	};

	const upload = async () => {
		if (!audioFile) {
			addToast(
				"No recording found. If the problem persists, contact support.",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
		} else {
			try {
				setLoading(true);
				const audioFileToUpload = new File([audioFile], "voice-clip", {
					type: "audio/mp3",
					lastModified: Date.now(),
				});
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}voiceclips`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
							"content-disposition": `filename=voice-clip`,
							"content-type": audioFileToUpload.type,
						},
						body: audioFileToUpload,
					}
				);
				const { attachmentId } = await response.json();
				await send(attachmentId);
				setLoading(false);
				destroyModal();
			} catch (error) {
				console.log("error", error);
				addToast("error uploading attachment", {
					appearance: "error",
					autoDismiss: true,
				});
				setLoading(false);
			}
		}
	};

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "description",
			}}
			header="Record voice clip"
			description="To start recording a voice clip click the record button. Once you have finished, click the stop button and then click send recording"
			isOpen={isOpen}
			cancel={() => {
				destroyModal();
			}}
			onRequestClose={() => {
				destroyModal();
			}}
			submitButtonName="Send recording"
			submit={upload}
			submitting={loading}
		>
			<Container>
				<CountdownCircleTimer
					isPlaying={status === "RECORDING"}
					duration={30}
					initialRemainingTime={30}
					colors={theme.colours.primary}
					strokeWidth={6}
					onComplete={() => {
						return [false, 0];
					}}
					key={key}
				>
					{({ remainingTime }) => (
						<div>
							<ButtonsContainer>
								{status === "STOPPED" && audioFile !== null && (
									<AudioActionsButton
										icon="fas fa-play"
										hasTooltip
										tooltipText="Preview recording"
										onClick={() => {
											const reader = new FileReader();
											reader.onload = function (event) {
												const audio = new Audio(
													event.target.result
												);
												audio.play();
											};
											reader.readAsDataURL(audioFile);
										}}
									></AudioActionsButton>
								)}
								{status === "RECORDING" && (
									<AudioActionsButton
										hasTooltip
										tooltipText={"Stop recording"}
										onClick={stop}
										icon="fas fa-stop"
									/>
								)}
								{status === "STOPPED" && (
									<AudioActionsButton
										hasTooltip
										tooltipText={
											audioFile
												? "Start a new recording"
												: "Start recording"
										}
										onClick={start}
										icon="fas fa-dot-circle"
										record={true}
									/>
								)}
							</ButtonsContainer>
						</div>
					)}
				</CountdownCircleTimer>
			</Container>
		</MsgBoxModal>
	);
};

RecordVoice.propTypes = {
	isOpen: PropTypes.bool,
	cancel: PropTypes.func,
	send: PropTypes.func,
};

export default RecordVoice;
