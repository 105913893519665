import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const AudioActionsButton = styled(IconButton)`
	height: 36px;
	width: 36px;
	border-radius: 6px;
	border: solid 1px;
	i {
		color: ${(props) =>
			props.record === true
				? `${props.theme.colours.danger}`
				: `${props.theme.colours.primary}`};
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	.visualizer {
		display: none;
	}
`;
