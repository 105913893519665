import styled from "styled-components";

export const Form = styled.form`
	display: flex;
	gap: 12px;
	align-items: center;
`;

export const FormColumn = styled.div`
	flex: 1;
`;

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
	table {
		border-collapse: collapse;
		width: 100%;
		small {
			color: ${(props) => props.theme.colours.danger};
		}
	}
	th {
		background-color: ${(props) => props.theme.colours.primaryLight};
		color: ${(props) => props.theme.colours.primary};
		text-transform: uppercase;
		margin: 6px;
		padding: 12px;
	}
	tr {
		height: 36px;
		padding-left: 12px;
	}
	td {
		margin: 6px;
		padding: 6px;
		min-width: 60px;
	}

	td:first-child {
		width: 50%;
	}
`;
