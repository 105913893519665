import React, { useState, useRef } from "react";
import { StyledSearchBar, StyledInput, SearchIcon } from "./styles";
import PropTypes from "prop-types";

const SearchBar = (props) => {
	const [searchTerm, setSearchTerm] = useState("");
	const inputElement = useRef(null);

	const onInputChange = (event) => {
		setSearchTerm(event.target.value);
		props.onSearchChange(event.target.value);
	};

	const applyFocus = () => {
		inputElement.current.focus();
	};

	return (
		<StyledSearchBar onClick={applyFocus}>
			<SearchIcon className="fas fa-search"></SearchIcon>
			<StyledInput
				ref={inputElement}
				value={searchTerm}
				onChange={onInputChange}
				placeholder={props.placeholder}
			></StyledInput>
		</StyledSearchBar>
	);
};

SearchBar.propTypes = {
	placeholder: PropTypes.string,
	onSearchChange: PropTypes.func,
};

SearchBar.defaultProps = {
	placeholder: "Search here...",
	onSearchChange: () => {},
};

export default SearchBar;
