import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../Styles/constants";
import { lighten } from "polished";

export const Circle = styled.div`
	height: 35px;
	width: 35px;
	border-radius: 0 0 100px 0;
	background-color: white;
	opacity: 0.2;
	z-index: 50;
	position: absolute;
	top: 0;
	left: 0;
`;

export const StyledNavBar = styled.div`
	position: fixed;
	height: 100%;
	z-index: 100;
	background-color: ${(props) => props.theme.colours.primary};
	width: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	.selected {
		background-color: ${(props) =>
			lighten(0.1, props.theme.colours.primary)};
	}
	ul {
		width: 100%;
		li {
			&:hover {
				background-color: ${(props) =>
					lighten(0.1, props.theme.colours.primary)};
			}
		}
	}
	@media (max-width: ${device.mobile}) {
		width: 60vw;
		z-index: 1000;
		text-align: left;
		left: ${(props) => (props.visible ? 0 : "-60vw")};
		transition: left 0.1s ease-in-out;
	}
`;
export const StyledNavLink = styled(NavLink)`
	color: ${(props) => props.theme.colours.textLight} !important;
	padding: 12px;
	text-decoration: none !important;
	display: block;
	position: relative;
	&:hover {
		background-color: lighten(
			1,
			${(props) => props.theme.colours.primaryLight}
		);
		cursor: pointer;
		opacity: 0.9;
	}
	small {
		position: absolute;
		top: 2px;
		right: 2px;
		padding: 3px 6px;
		border-radius: 50%;
		background: ${(props) => props.theme.colours.danger};
		color: ${(props) => props.theme.colours.light_0};
	}
`;
export const Icon = styled.i`
	color: white;
	text-align: center;
	font-size: 1.7em;
	@media (max-width: ${device.mobile}) {
		margin-left: 24px;
	}
`;

export const Logo = styled.div`
	height: 50px;
	display: none;
	align-items: center;
	justify-content: center;
	/* border-bottom: 2px solid ${(props) =>
		props.theme.colours.primaryLight}; */
	color: ${(props) => props.theme.colours.light_0};

	width: 100%;
	@media (min-width: ${device.mobile}) {
		display: flex;
	}
`;

export const MobileLogo = styled.div`
	width: 100%;
	height: 75px;
	display: none;
	align-items: center;
	justify-content: space-around;
	border-bottom: 2px solid ${(props) => props.theme.colours.primaryLight};
	color: ${(props) => props.theme.colours.light_0};
	@media (max-width: ${device.mobile}) {
		display: flex;
	}
	& i {
		font-size: 1.2em;
	}
`;

export const LogoutIcon = styled.i`
	position: absolute;
	bottom: 24px;
	font-size: 1.7em;
	color: ${(props) => props.theme.colours.light_0};
	text-align: center;
	cursor: pointer;
`;
