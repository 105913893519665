import { gql } from "@apollo/client";

export const GET_ALL_INBOXES = gql`
	query getInboxes {
		msgbox_Inbox {
			InboxId
			Name
		}
	}
`;

export const GET_USER_INBOXES = gql`
	query getUserInboxes($userId: Int!) {
		msgbox_UserInbox(where: { UserId: { _eq: $userId } }) {
			Inbox {
				Name
				InboxId
			}
		}
	}
`;
 
export const MARK_CONVERSATION_AS_READ = gql`
mutation markAsRead($conversationId: Int!) {
	update_msgbox_Conversation(
		where: { ConversationId: { _eq: $conversationId } }
		_set: { IsRead: true, UpdatedOn: "now()" }
	) {
		affected_rows
		returning {
			ConversationId
			IsRead
		}
	}
}
`;

export const GET_FOLDER = gql`
	query getUserInboxes($conversationId: Int!) {
		msgbox_Conversation (where: {ConversationId: {_eq:$conversationId}}){
			FolderId
		}
	}
`