import { ApolloClient } from "@apollo/client";
import cache from "./cache";
import link from "./link";

const client = new ApolloClient({
	connectToDevTools: true,
	link: link,
	cache: cache,
});

export default client;
