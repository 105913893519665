import { ConversationFiltersButton } from "../ConversationFilters/styles";
import client from "../../Apollo";
import { GET_FOLDER, MOVE_CONVERSATION_BACK_TO_INBOX } from "./query";
import { useMutation, useReactiveVar } from "@apollo/client";
import { $selectedInbox, $selectedConversation } from "../../store";
import { useToasts } from "react-toast-notifications";
import ReactGA from "react-ga4";

const MoveBackToInbox = ({ isArchived = true }) => {
	const selectedInbox = useReactiveVar($selectedInbox);
	const selectedConversation = useReactiveVar($selectedConversation);
	const { addToast } = useToasts();

	const [moveConversationBackToInbox] = useMutation(
		MOVE_CONVERSATION_BACK_TO_INBOX
	);

	const handleMoveConversationBackClick = async () => {
		const mainSysFolder = await fetchFolder();
		await moveConversationBackToInbox({
			variables: {
				conversationId: selectedConversation,
				folderId: mainSysFolder,
			},
		});
		if (isArchived) {
			addToast("Conversation unarchived", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Widgets",
				action: "ConversationUnarchived",
			});
		} else {
			addToast("Conversation moved to inbox", {
				appearance: "success",
				autoDismiss: true,
			});
			ReactGA.event({
				category: "Widgets",
				action: "ConversationMovedToInbox",
			});
		}
		$selectedConversation(null);
	};

	const fetchFolder = async () => {
		try {
			const { data } = await client.query({
				query: GET_FOLDER,
				variables: {
					conversationId: selectedConversation,
				},
			});
			if (
				data.msgbox_Conversation[0] &&
				data.msgbox_Conversation[0]["Folder"] &&
				data.msgbox_Conversation[0]["Folder"]["Inbox"]["Folders"] &&
				data.msgbox_Conversation[0]["Folder"]["Inbox"]["Folders"][0]
			) {
				return data.msgbox_Conversation[0]["Folder"]["Inbox"][
					"Folders"
				][0].FolderId;
			}
			return null;
		} catch (error) {
			console.log("error", error);
		}
	};

	return (
		<ConversationFiltersButton
			data-tip={isArchived ? "Remove from archive" : "Move back to inbox"}
			style={{ float: "right" }}
			icon="fas fa-undo"
			onClick={handleMoveConversationBackClick}
		/>
	);
};

export default MoveBackToInbox;
