import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const Container = styled.div`
	/* margin: 24px; */
	/* padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 6px;
	padding-top: 6px;
	background-color: ${(props) => props.theme.colours.light_0}; */
`;

export const ContextActionButton = styled(IconButton)`
	width: 36px;
	height: 36px;
	background-color: ${(props) => props.theme.colours.light_0};
`;
