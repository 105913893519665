import { Formik, Field } from "formik";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import Input from "../Input/input.component";
import {
	EDIT_USER,
	GET_DEPARTMENTS,
	REMOVE_USER_DEPARTMENTS,
	INSERT_USER_DEPARTMENTS,
	GET_ROLES,
	EDIT_ROLE,
	DELETE_USER_INBOXES,
	INSERT_USER_INBOXES,
} from "./query";
import { useMutation, useQuery } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import {
	addUserValidationSchema,
	validateBulkAssignUserToDepartment,
} from "./validation-schema";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useState } from "react";
import UserInboxSelect from "../UserInboxSelect/user-inbox-select.component";
import { Form, FormColumn } from "./styles";

const EditUser = ({ isOpen, close, users, rootStore }) => {
	const [editUser, { loading: editLoading, error }] = useMutation(EDIT_USER);
	const [removeUserDepartment] = useMutation(REMOVE_USER_DEPARTMENTS);
	const [removeUserInboxes] = useMutation(DELETE_USER_INBOXES);
	const [insertUserInboxes] = useMutation(INSERT_USER_INBOXES);
	const [addUserDepartment, { loading: editDeptLoading }] = useMutation(
		INSERT_USER_DEPARTMENTS
	);
	const [editRole, { loading: editRoleLoading }] = useMutation(EDIT_ROLE);
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);

	const {
		data: departmentData,
		error: departmentError,
		laoding: departmentLoading,
	} = useQuery(GET_DEPARTMENTS);

	const {
		data: userRoles,
		error: roleError,
		loading: roleLoading,
	} = useQuery(GET_ROLES);

	const submit = async ({
		firstName,
		lastName,
		email,
		departments,
		role,
		inboxes,
	}) => {
		if (inboxes.length > 0) {
			await removeUserInboxes({
				variables: {
					userId: users[0].userId,
				},
			});
			const data = inboxes.map((inbox) => ({
				UserId: users[0].userId,
				InboxId: inbox.value,
			}));
			await insertUserInboxes({
				variables: {
					inboxes: data,
				},
			});
		} else {
			await removeUserInboxes({
				variables: {
					userId: users[0].userId,
				},
			});
		}
		if (departments) {
			await removeUserDepartment({
				variables: {
					userId: users[0].userId,
				},
			});
			const data = departments.map((department) => ({
				DepartmentId: department.value,
				UserId: users[0].userId,
			}));
			await addUserDepartment({
				variables: {
					userDepartments: data,
				},
			});
		}
		if (role) {
			await editRole({
				variables: {
					userId: users[0].userId,
					roleId: role.value,
				},
			});
		}
		editUser({
			variables: {
				firstName,
				lastName,
				email,
				userId: users[0].userId,
			},
		});
		addToast("Successfully updated user", {
			appearance: "success",
			autoDismiss: true,
		});
		closeModal();
	};

	const closeModal = () => {
		close();
		setDirty(false);
	};

	const onInputChange = (event, fn) => {
		setDirty(true);
		fn(event);
	};

	if (error) {
		console.log("err", error);
	}

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			isOpen={isOpen}
			cancel={closeModal}
			header="Edit User"
			description="Use the form below to edit a user"
			onRequestClose={closeModal}
			submitting={editLoading || editDeptLoading || editRoleLoading}
			submit={submit}
			submitId="edit-user"
			submitButtonDisabled={dirty === false}
		>
			<Formik
				initialValues={{
					firstName: users[0]?.firstName,
					lastName: users[0]?.lastName,
					email: users[0]?.email,
					departments:
						users[0]?.departments?.length > 0
							? 
							users[0].departments.filter((dept)=>{
								if( dept?.Department?.Name){
									return dept
								}
							}).map((department) => ({
									label: department.Department.Name,
									value: department.Department.DepartmentId,
							}))
							: null,
					role:
						users[0]?.role.length > 0
							? {
									label: users[0]?.role[0].Role.Description,
									value: users[0]?.role[0].Role.RoleId,
							  }
							: "",
					inboxes:
						users[0]?.inboxes?.length > 0
							? users[0]?.inboxes.map(({ Inbox }) => ({
									label: Inbox.Name,
									value: Inbox.InboxId,
							  }))
							: "",
				}}
				validateOnBlur={false}
				validateOnChange={false}
				validationSchema={addUserValidationSchema}
				onSubmit={(values) => {
					submit(values);
				}}
			>
				{({
					handleSubmit,
					values,
					errors,
					handleChange,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} id="edit-user">
						<FormColumn>
							<Input
								placeholder="First Name"
								required
								name="firstName"
								label="First Name"
								onChange={(event) =>
									onInputChange(event, handleChange)
								}
								error={errors.firstName}
								value={values.firstName}
							/>
							<Input
								placeholder="last Name"
								required
								name="lastName"
								label="Last Name"
								onChange={(event) =>
									onInputChange(event, handleChange)
								}
								error={errors.lastName}
								value={values.lastName}
							/>
							<Field
								placeholder="Email"
								required
								name="email"
								label="Email"
								readOnly
								onChange={(event) => {
									setFieldValue("email", event.target.value);
									setDirty(true);
								}}
								error={errors.email}
								value={values.email}
								component={Input}
							/>
						</FormColumn>
						<FormColumn>
							<Field
								key={2}
								placeholder="Select a team"
								name="departments"
								options={
									(departmentData && departmentData.msgbox_Department.length > 0)
										? [
												...departmentData.msgbox_Department,
										  ].map((department) => ({
												label: department.Name,
												value: department.DepartmentId,
										  }))
										: null
								}
								onChange={(options, action) => {
									setDirty(true);
									setFieldValue("departments", options);
								}}
								label="Team"
								error={errors.departments}
								value={values.departments}
								isMulti
								component={MsgBoxSelect}
								validate={validateBulkAssignUserToDepartment}
							/>
							<MsgBoxSelect
								key={3}
								placeholder="Select a role"
								name="role"
								options={userRoles.msgbox_Role.map((role) => ({
									label: role.Description,
									value: role.RoleId,
								}))}
								onChange={(options, action) => {
									setFieldValue("role", options);
									setDirty(true);
								}}
								label="Role"
								error={errors.role}
								value={values.role}
							/>
							<UserInboxSelect
								name="inboxes"
								placeholder="Select an inbox"
								key={4}
								handleChange={(options, action) => {
									setFieldValue("inboxes", options);
									setDirty(true);
								}}
								label="inboxes"
								hasTooltip
								toolTipText="Select the inboxes you would like this user to have access to"
								error={errors.inboxes}
								value={values.inboxes}
								rootStore={rootStore}
							/>
						</FormColumn>
					</Form>
				)}
			</Formik>
		</MsgBoxModal>
	);
};

export default EditUser;
