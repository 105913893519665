import styled from "styled-components";

export const ErrorBadge = styled.div`
	margin-top: 16px;
	width: 100%;
	display: inline-block;
	white-space: nowrap;
	vertical-align: middle;
	padding: 16px;
	font-size: 12px;
	border-radius: 4px;
	background-color: pink;
	color: black;
	font-weight: 14px;
	i {
		font-size: 1.3rem;
		margin-right: 8px;
	}
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	> i {
		font-size: 36px;
		color: ${(props) => props.theme.colours.danger};
		margin-bottom: 20px;
	}
	> h2 {
		font-size: 30px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	> div {
		color: var(--secondary);
		font-size: 16px;
		margin-bottom: 20px;
	}
	> form {
		width: 100%;
	}
`;

export const ErrorsTable = styled.div`
	width: 100%;
	font-size: 16px;
	border: 1px solid;
	border-color: var(--secondary);
	border-radius: 4px;
	> div {
		display: flex;
		flex-direction: row;
		> div {
			font-weight: 600;
			padding: 12px;
		}
		> div:first-of-type {
			width: 30%;
			text-align: center;
			color: ${(props) => props.theme.colours.danger};
		}
		> div:last-of-type {
			width: 70%;
		}
	}
	> div:not(:last-of-type) {
		border-bottom: 1px solid var(--secondary);
	}
`;
