import { useMutation, useReactiveVar } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import ReactGA from "react-ga4";

import { GET_ARCHIVE, MOVE_CONVERSATION_TO_ARCHIVE } from "./query";
import { $selectedInbox, $selectedConversation } from "../../store";
import client from "../../Apollo";

const useArchiveConversation = () => {
	const { addToast } = useToasts();
	const selectedInbox = useReactiveVar($selectedInbox);
	const selectedConversation = useReactiveVar($selectedConversation);

	const [moveConversationToArchive] = useMutation(
		MOVE_CONVERSATION_TO_ARCHIVE
	);
	const archiveConversation = async () => {
		try {
			const archiveFolderId = await fetchArchive();
			if (archiveFolderId) {
				await moveConversationToArchive({
					variables: {
						conversationId: selectedConversation,
						archiveId: archiveFolderId,
					},
				});
				$selectedConversation(null);
				addToast("Conversation archived", {
					appearance: "success",
					autoDismiss: true,
				});
				ReactGA.event({
					category: "Widgets",
					action: "ConversationArchived",
				});
			} else {
				addToast(
					"Failed to archive, if the problem persists, contact support.",
					{
						appearance: "error",
						autoDismiss: true,
					}
				);
				ReactGA.event({
					category: "Widgets",
					action: "ConversationFailedToArchived",
				});
			}
		} catch (error) {
			addToast(
				"Something went wrong. Please try again later. If the problem persists, contact support.",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
		}
	};

	const fetchArchive = async () => {
		const { data } = await client.query({
			query: GET_ARCHIVE,
			variables: {
				conversationId: selectedConversation,
			},
		});
		if (
			data.msgbox_Conversation[0] &&
			data.msgbox_Conversation[0]["Folder"] &&
			data.msgbox_Conversation[0]["Folder"]["Inbox"]["Folders"] &&
			data.msgbox_Conversation[0]["Folder"]["Inbox"]["Folders"][0]
		) {
			return data.msgbox_Conversation[0]["Folder"]["Inbox"]["Folders"][0]
				.FolderId;
		}
		return null;
	};

	return archiveConversation;
};

export default useArchiveConversation;
