import React from "react";
import PropTypes from "prop-types";
import { Container, Text } from "./styles";

const Tooltip = ({ text, right }) => {
	return (
		<Container right={right} className="far fa-question-circle">
			<Text>{text}</Text>
		</Container>
	);
};

Tooltip.propTypes = {
	text: PropTypes.string,
	right: PropTypes.bool,
};

Tooltip.defaultProps = {
	right: false,
};

export default Tooltip;
