import { Container } from "./styles";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { useQuery } from "@apollo/client";
import {
	GET_PERMISSION_TYPES,
	GET_PERMISSIONS,
	UPDATE_PERMISSIONS,
} from "./query";
import { Formik } from "formik";
import Button from "../Button/button.component";
import { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UserContext } from "../../Providers/user-provider";
import { useToasts } from "react-toast-notifications";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import { LoadingContainer } from "../AutomationSettings/styles";
import { Prompt } from "react-router";

const Permissions = () => {
	const [dirty, setDirty] = useState(false);
	const user = useContext(UserContext);
	const { addToast } = useToasts();

	const {
		data: permissionTypes,
		loading: loadingPermissionTypes,
		error: errorFetchingPermissionTypes,
	} = useQuery(GET_PERMISSION_TYPES);

	const {
		data: permissions,
		loading: loadingPermissions,
		error: errorFetchingPermissions,
	} = useQuery(GET_PERMISSIONS);

	const [
		updatePermissions,
		{ error: errorUpdatingPermissions, loading: updatingPermissions },
	] = useMutation(UPDATE_PERMISSIONS, {
		onCompleted: () => {
			addToast("Permissions updated successfully", {
				appearance: "success",
				autoDismiss: true,
			});
			setDirty(false);
		},
		onError: () => {
			addToast(
				"An error occured whilst updating permissions. if the problem persists, contact support",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
			setDirty(false);
		},
	});

	useEffect(() => {
		if (dirty) {
			window.onbeforeunload = () => true;
		}
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [dirty]);

	const submit = ({
		assignedConversationVisibility,
		contactsDeleteContacts,
		automationsSeeAutomations,
		analyticsSeeAnalytics,
		ContactsDownloadContacts,
		ContactsSeePhoneNumbers,
		TemplatesAddEditTemplates,
		TemplatesDeleteTemplates,
		BroadcastBulkBroadcasts,
		InboxSeeUnassignedConversations
	}) => {
		updatePermissions({
			variables: {
				orgId: user.orgId,
				assignedConversationVisibility: assignedConversationVisibility.value,
				contactsDeleteContacts: contactsDeleteContacts.value,
				automationsSeeAutomations: automationsSeeAutomations.value,
				analyticsSeeAnalytics: analyticsSeeAnalytics.value,
				ContactsDownloadContacts: ContactsDownloadContacts.value,
				TemplatesAddEditTemplates: TemplatesAddEditTemplates.value,
				TemplatesDeleteTemplates: TemplatesDeleteTemplates.value,
				InboxSeeUnassignedConversations: InboxSeeUnassignedConversations.value,
				ContactsSeePhoneNumbers: ContactsSeePhoneNumbers.value,
				BroadcastBulkBroadcasts: BroadcastBulkBroadcasts.value
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_Permission(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Permission,
								query: GET_PERMISSIONS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
	};

	if (loadingPermissions) {
		return (
			<LoadingContainer>
				<LoadingSpinner />;
			</LoadingContainer>
		);
	}

	if (errorFetchingPermissions) {
		return <p>Ooops... something went wrong..</p>;
	}

	return (
		<Container>
			<Formik
				initialValues={{
					assignedConversationVisibility: {
						label:
							permissions.msgbox_Permission[0]
								.InboxSeeOthersAssignedConversations === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.InboxSeeOthersAssignedConversations ===
								  2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.InboxSeeOthersAssignedConversations,
					},
					contactsDeleteContacts: {
						label:
							permissions.msgbox_Permission[0]
								.ContactsDeleteContacts === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.ContactsDeleteContacts === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.ContactsDeleteContacts,
					},
					automationsSeeAutomations: {
						label:
							permissions.msgbox_Permission[0]
								.AutomationsSeeAutomations === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.AutomationsSeeAutomations === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.AutomationsSeeAutomations,
					},
					analyticsSeeAnalytics: {
						label:
							permissions.msgbox_Permission[0]
								.AnalyticsSeeAnalytics === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.AnalyticsSeeAnalytics === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.AnalyticsSeeAnalytics,
					},
					ContactsDownloadContacts: {
						label:
							permissions.msgbox_Permission[0]
								.ContactsDownloadContacts === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.ContactsDownloadContacts === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.ContactsDownloadContacts,
					},

					TemplatesAddEditTemplates: {
						label:
							permissions.msgbox_Permission[0]
								.TemplatesAddEditTemplates === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.TemplatesAddEditTemplates === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.TemplatesAddEditTemplates,
					},
					TemplatesDeleteTemplates: {
						label:
							permissions.msgbox_Permission[0]
								.TemplatesDeleteTemplates === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.TemplatesDeleteTemplates === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.TemplatesDeleteTemplates,
					},
					
					InboxSeeUnassignedConversations: {
						label:
							permissions.msgbox_Permission[0]
								.InboxSeeUnassignedConversations === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.InboxSeeUnassignedConversations === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.InboxSeeUnassignedConversations,
					},
					ContactsSeePhoneNumbers: {
						label:
							permissions.msgbox_Permission[0]
								.ContactsSeePhoneNumbers === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.ContactsSeePhoneNumbers === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.ContactsSeePhoneNumbers,
					},
					
					BroadcastBulkBroadcasts: {
						label:
							permissions.msgbox_Permission[0]
								.BroadcastBulkBroadcasts === 1
								? "Everyone"
								: permissions.msgbox_Permission[0]
										.BroadcastBulkBroadcasts === 2
								? "Administrators"
								: "Administrators & Team Leaders",
						value: permissions.msgbox_Permission[0]
							.BroadcastBulkBroadcasts,
					},
				}}
				onSubmit={(values, actions) => {
					submit(values);
				}}
			>
				{({ handleSubmit, setFieldValue, values }) => (
					<form id="permissions-settings" onSubmit={handleSubmit}>
						<h3>Inbox</h3>

						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"assignedConversationVisibility",
									options
								);
								setDirty(true);
							}}
							name="assignedConversationVisibility"
							label="Select who can view assigned conversations"
							value={values.assignedConversationVisibility}
						/>

						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue("InboxSeeUnassignedConversations", options);
								setDirty(true);
							}}
							name="InboxSeeUnassignedConversations"
							label="Select who can view unassigned conversations"
							value={values.InboxSeeUnassignedConversations}
						/>

						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"ContactsSeePhoneNumbers",
									options
								);
								setDirty(true);
							}}
							name="ContactsSeePhoneNumbers"
							label="Select who can see phone numbers"
							value={values.ContactsSeePhoneNumbers}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"contactsDeleteContacts",
									options
								);
								setDirty(true);
							}}
							name="contactsDeleteContacts"
							label="Select who can delete contacts"
							value={values.contactsDeleteContacts}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"ContactsDownloadContacts",
									options
								);
								setDirty(true);
							}}
							name="ContactsDownloadContacts"
							label="Select who can download contacts"
							value={values.ContactsDownloadContacts}
						/>
						
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"BroadcastBulkBroadcasts",
									options
								);
								setDirty(true);
							}}
							name="BroadcastBulkBroadcasts"
							label="Select who can send bulk broadcasts to more than 10 people"
							value={values.BroadcastBulkBroadcasts}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"TemplatesAddEditTemplates",
									options
								);
								setDirty(true);
							}}
							name="TemplatesAddEditTemplates"
							label="Select who can add & edit templates"
							value={values.TemplatesAddEditTemplates}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"TemplatesDeleteTemplates",
									options
								);
								setDirty(true);
							}}
							name="TemplatesDeleteTemplates"
							label="Select who can delete templates"
							value={values.TemplatesDeleteTemplates}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue(
									"automationsSeeAutomations",
									options
								);
								setDirty(true);
							}}
							name="automationsSeeAutomations"
							label="Select who can administer automations"
							value={values.automationsSeeAutomations}
						/>
						<MsgBoxSelect
							options={[
								{
									label: "Everyone",
									value: 1,
								},
								{
									label: "Administrators",
									value: 2,
								},
								{
									label: "Administrators & Team Leaders",
									value: 3,
								},
							]}
							onChange={(options) => {
								setFieldValue("analyticsSeeAnalytics", options);
								setDirty(true);
							}}
							name="analyticsSeeAnalytics"
							label="Select who can view analytics"
							value={values.analyticsSeeAnalytics}
						/>

						<Button
							style={{ float: "right" }}
							primary
							buttonName="Save"
							type="submit"
							loading={null}
							disabled={dirty === false}
						/>
						<div style={{ clear: "both" }}></div>
					</form>
				)}
			</Formik>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
		</Container>
	);
};

export default Permissions;
