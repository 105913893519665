import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
	query getUserDetails($userId: Int!) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			UserDepartments {
				Department {
					Name
					DepartmentId
				}
			}
			UserId
			FirstName
			LastName
			ImageURI
			signedurl {
				url
			}
		}
	}
`;
