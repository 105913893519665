import styled from "styled-components";
import { Box } from "../../Styles/global";

export const LeadDetailContainer = styled(Box)`
	display: flex;
	align-items: center;
`;

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_1};
	border-radius: 6px;
	padding: 3px;
	margin-top: 6px;
	margin-bottom: 6px;
`;

export const StyledDate = styled.small`
	float: right;
	font-style: italic;
`;

export const Header = styled.h6`
	font-weight: bold;
`;
