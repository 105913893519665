import { Formik } from "formik";
import Input from "../../Components/Input/input.component";
import { Container } from "../ForgottenPassword/styles";
import Button from "../../Components/Button/button.component";
import { Link } from "react-router-dom";
import { resetPasswordValidationSchema } from "./validation-schema";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
	const history = useHistory();

	const submit = async ({ otp, password, email }) => {
		try {
			setLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}user/${email}/lostpassword`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						otp,
						password,
					}),
				}
			);
			if(response.status === 400) {
				addToast(
					"Invalid OTP supplied",
					{
						appearance: "error",
						autoDismiss: true,
					}
				);
				setLoading(false);
				return
			}
			setLoading(false);
			history.push("/login");
		} catch (error) {
			console.log("error", error);
			addToast(
				"Error resetting password. If the problem persists contact support",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
			setLoading(false);
		}
	};

	return (
		<Container>
			<h1>Reset password</h1>
			<Formik
				initialValues={{
					email: "",
					otp: "",
					password: "",
					confirmPassword: "",
				}}
				onSubmit={(values, actions) => {
					submit(values, actions);
				}}
				validationSchema={resetPasswordValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
			>
				{({ handleSubmit, handleChange, values, errors }) => (
					<form id="forgotten-password" onSubmit={handleSubmit}>
						<Input
							label="Email"
							onChange={handleChange}
							name="email"
							value={values.email}
							error={errors.email}
							isForm={false}
							icon={<i className="fas fa-envelope"></i>}
							required
						/>
						<Input
							label="One time password"
							onChange={handleChange}
							name="otp"
							value={values.otp}
							error={errors.otp}
							isForm={false}
							type="text"
							required
						/>
						<Input
							label="New Password"
							onChange={handleChange}
							name="password"
							value={values.password}
							error={errors.password}
							isForm={false}
							icon={<i className="fas fa-lock"></i>}
							type="password"
							required
						/>
						<Input
							label="Confirm New Password"
							onChange={handleChange}
							name="confirmPassword"
							value={values.confirmPassword}
							error={errors.confirmPassword}
							isForm={false}
							icon={<i className="fas fa-lock"></i>}
							type="password"
							required
						/>
						<div style={{ display: "flex", alignItems: "center" }}>
							<i className="fas fa-arrow-left"></i>
							<Link to="/login">Back to login</Link>
							<Button
								style={{ marginLeft: "auto" }}
								buttonName="Reset password"
								primary
								type="submit"
								loading={loading}
							/>
						</div>
					</form>
				)}
			</Formik>
		</Container>
	);
};

export default ResetPassword;
