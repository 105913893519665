import React from "react";
import PropTypes from "prop-types";
import { StyledPill } from "./styles";
import IconButton from "../IconButton/icon-button.component";

const Pill = (props) => {
	const remove = (event) => {
		props.onDelete(event);
	};

	return (
		<StyledPill customStyles={props.customStyles} onClick={props.onClick}>
			<span data-tag="allowRowEvents">
				{props.asTag ? "#" + props.name : props.name}
			</span>
			{props.hasDelete && (
				<IconButton
					onClick={remove}
					icon="fas fa-times-circle"
				></IconButton>
			)}
		</StyledPill>
	);
};

Pill.defaultProps = {
	onClick: () => {},
	onDelete: () => {},
	hasDelete: false,
	asTag: true,
};

Pill.propTypes = {
	onClick: PropTypes.func,
	name: PropTypes.string,
	customStyles: PropTypes.object,
	onDelete: PropTypes.func,
	hasDelete: PropTypes.bool,
	asTag: PropTypes.bool,
};

export default Pill;
