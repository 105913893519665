import React from "react";
import IconLinksComponent from "../IconLinks/icon-links.component";

const ShareNumberCard = ({ index, item }) => {
	return (
		<div
			key={index}
			style={{
				display: "flex",
				justifyContent: "space-between",
				flexDirection: "row",
				alignItems: "center",
				marginBottom: 0,
				padding: "30px",
				border: "1px solid #ddd",
				borderRadius: "8px",
				boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
				margin: "10px",
				backgroundColor: "#fff",
			}}
		>
			<h3>
				{item.Name.includes(" - ")
					? item.Name
					: `${item.Name} - Personal`}
			</h3>
			<div style={{}}>
				<IconLinksComponent url={item.url} index={index} />
			</div>
		</div>
	);
};

export default ShareNumberCard;
