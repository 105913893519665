import React, { useState, useEffect, useCallback } from "react";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import { useDropzone } from "react-dropzone";
import { DropZone, ErrorMsg } from "./styles";
import Input from "../Input/input.component";
import { getAccessToken } from "../../helpers/functions";
import { useToasts } from "react-toast-notifications";
import { MAX_FILE_SIZE } from "../../helpers/file-upload";
import { useLazyQuery } from "@apollo/client";
import { CHECK_MIME_TYPE } from "../SendTemplateMessage/query";
import ConvertingModal from "../ConvertingModal/convertingModal";

const UploadFile = ({ isOpen, cancel, send }) => {
	const [attachments, setAttachments] = useState([]);
	const [attachmentType, setAttachmentType] = useState("");
	const [caption, setCaption] = useState("");
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);
	const TIMEOUT_DURATION = 1200000;
	const [converting, setConverting] = useState(false);
	const [converted, setIsConverted] = useState();

	const [checkMimeType] = useLazyQuery(CHECK_MIME_TYPE);

	const onDrop = useCallback(
		(newAttachment) => {
			setAttachments(newAttachment);
		},
		[attachments]
	);



	async function checkAttachment(attachmentId, caption) {
		setConverting(true);
		const startTime = Date.now();
		async function retryQuery() {
			if (Date.now() - startTime >= TIMEOUT_DURATION) {
				setConverting(false);
				console.warn("Request timed out.");
				return Promise.resolve();
			}

			try{

			
			const data = await checkMimeType({
				variables: { attachmentId },
				fetchPolicy: "network-only",
			});
			console.log(data.data.msgbox_Attachment[0].MimeType)
			if (data.data.msgbox_Attachment[0].MimeType === "video/mp4") {
				setIsConverted(true);
				setConverting(false);
				await send(attachmentId, caption);
				return Promise.resolve()
			} else {
				console.log("retrying.. ");
				// setTimeout(retryQuery, 1000); // Retry after 1 second
				await new Promise(resolve => setTimeout(() => resolve(), 1000)); // Wait for 1 second
				retryQuery();
			}
			}
			catch(e){
				console.log(e)
				setIsConverted(true);
				setConverting(false);
				return Promise.resolve()
			}
		}
		retryQuery();
	}

	useEffect(()=>{
		if(converted===true){
			setConverting(false)
		}
	},[converted]);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	useEffect(() => {
		if (attachments.length > 0) {
			const type = attachments[0].type;
			if (type === "application/pdf") setAttachmentType("pdf");
			else if (type.includes("image")) setAttachmentType("image");
			else setAttachmentType("doc");
		}
	}, [attachments]);

	const upload = async () => {
		if (attachments.length <= 0) {
			setError(true);
			setErrorMsg("Attachment is required");
		} else if (attachments[0].size > MAX_FILE_SIZE) {
			setError(true);
			setErrorMsg("Attachment size too large (max 100MB)");
		} else {
			try {
				setLoading(true);
				const file = attachments[0];
				const { name, type } = file;

				const token = await getAccessToken();
				const signedUrlResponse = await fetch(
					`${process.env.REACT_APP_API_URL}filessignedurl`,
					{
						method: "POST",
						headers: {
							"Content-Disposition": `filename=${name}`,
							Authorization: `Bearer ${token}`,
							"Content-Type": type,
						},
					}
				);
				const { attachmentId, presignedUrl } =
					await signedUrlResponse.json();
				const attachmentResponse = await fetch(presignedUrl, {
					method: "PUT",
					headers: {
						"Content-Disposition": `filename=${encodeURIComponent(name)}`,
						"Content-Type": type,
					},
					body: file,
				});

				if (
					attachmentResponse.status === 200 ||
					attachmentResponse.status === 201
				) {
					if(type.includes("video")){
						//checks the mime type *and* sends
						await checkAttachment(attachmentId, caption)
					}
					else{
						await send(attachmentId, caption);
					}

				} else {
					addToast("error uploading attachment", {
						appearance: "error",
						autoDismiss: true,
					});
					console.error("failed:", attachmentResponse);
				}
				setLoading(false);
				destroyModal();
			} catch (error) {
				console.log("error", error);
				addToast("error uploading attachment", {
					appearance: "error",
					autoDismiss: true,
				});
				setLoading(false);
			}
		}
	};

	const destroyModal = () => {
		setAttachments([]);
		setCaption("");
		cancel();
		setError(false);
		setAttachmentType("");
	};

	const attachmentIcon = () => {
		if (attachmentType === "pdf")
			return <i className="fas fa-file-pdf"></i>;
		if (attachmentType === "image") return <i className="far fa-image"></i>;
		return <i className="fas fa-file"></i>;
	};

	return (
		<>
		{converting ?
		<ConvertingModal isOpen={converting}></ConvertingModal>
		: null }
		
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "description",
			}}
			header="Upload Attachment"
			description="Drag and drop to add an attachment below"
			isOpen={isOpen}
			cancel={() => {
				destroyModal();
			}}
			onRequestClose={() => {
				destroyModal();
			}}
			submitButtonName="Upload Attachment"
			submit={upload}
			submitting={loading}
		>
			<div>
				<div style={{ position: "relative" }}>
					<DropZone {...getRootProps({ className: "dropzone" })}>
						<input {...getInputProps()} />
						{attachments.length > 0 ? (
							<div className="placeholder">
								{attachmentIcon()}

								<p>{attachments[0].name}</p>
							</div>
						) : (
							<p>Drag a file here, or click to select a file.</p>
						)}
					</DropZone>
					{error && <ErrorMsg>{errorMsg}</ErrorMsg>}
				</div>
				{attachmentType === "image" && (
					<Input
						label="Caption"
						hasTooltip
						toolTipText="Add a caption to your image"
						value={caption}
						onChange={(event) => setCaption(event.target.value)}
					/>
				)}
			</div>
		</MsgBoxModal>
		</>
	);
};

export default UploadFile;
