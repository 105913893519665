import { gql } from "@apollo/client";

export const GET_LATEST_INBOUND_MESSAGE = gql`
	query getLatestInboundMessage($conversationId: Int!) {
		msgbox_Message(
			where: {
				ConversationId: { _eq: $conversationId }
				SenderId: { _is_null: true }
			}
			limit: 1
			order_by: { DateTime: desc }
		) {
			DateTime
			MessageId
			Conversation {
				Contact {
					ContactId
					Status
				}
			}
		}
	}
`;

export const SUBSCRIBE_TO_LATEST_INBOUND_MESSAGE = gql`
	subscription subscribeToLatestInboundMessage($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			ConversationId
			Messages(
				where: { SenderId: { _is_null: true } }
				limit: 1
				order_by: { DateTime: desc }
			) {
				DateTime
				MessageId
			}
			Contact {
				Name
				ContactId
				ImageURI
				MobileNumber
				Status
				TagContacts {
					TagId
					Tag {
						Name
						TagId
					}
				}
			}
		}
	}
`;
