import client from "../Apollo";

export const setAccessToken = (accessToken) => {
	localStorage.setItem("access_token", accessToken);
};

export const setIdToken = (idToken) => {
	localStorage.setItem("id_token", idToken);
};

export const setTokenExpiry = (tokenExpiry) => {
	localStorage.setItem("expires_at", tokenExpiry);
};

export const setUserId = (userId) => {
	localStorage.setItem("user_id", userId);
};
export const setOrgId = (orgId) => {
	localStorage.setItem("org_id", orgId);
};
export const getUserId = () => {
	return localStorage.getItem("user_id");
};
export const getOrgId = () => {
	return localStorage.getItem("org_id");
};
export const setBrand = (brand) => {
	localStorage.setItem("brand", brand);
};

export const getBrand = () => {
	return localStorage.getItem("brand");
};

export const removeAccessToken = () => {
	localStorage.removeItem("access_token");
};

export const removeIdToken = () => {
	localStorage.removeItem("id_token");
};

export const removeTokenExpiry = () => {
	localStorage.removeItem("expires_at");
};

export const removeUserId = () => {
	localStorage.removeItem("user_id");
};

export function randomInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**Add value at index */
export function stringInsert(str, index, value) {
	return str.substr(0, index) + value + str.substr(index);
}

export const getAccessToken = async () => {
	const token = localStorage.getItem("access_token");
	if (!token) {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}oauth/token`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
					credentials: "include",
				}
			);
			client.clearStore();
			removeAccessToken();
			removeIdToken();
			removeTokenExpiry();
			removeUserId();
			document.location.href =
				"https://" + window.location.hostname + "/login";
		} catch (error) {
			console.log("err", error);
		}
	} else {
		return token;
	}
};
