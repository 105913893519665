import styled, { css } from "styled-components";
import { device } from "../../Styles/constants";

const Backdrop = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	opacity: 0;
	z-index: 1000;
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0.4);
	transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1) !important;
	${(props) =>
		props.visible &&
		css`
			opacity: 1;
			pointer-events: all;
		`}
	@media(min-width: ${device.mobile}) {
		opacity: 0;
		pointer-events: none;
		display: none;
	}
`;

export default Backdrop;
