import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { QRCodeCanvas } from "qrcode.react";
import { faLink, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { StyledButton } from "./styles";
const IconLinksComponent = ({ url, index }) => {
	const handleCopyClick = () => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				alert("URL copied to clipboard");
			})
			.catch((err) => {
				console.error("Failed tp copy: ", err);
			});
	};
	const handleQrCodeDownload = () => {
		const canvas = document.getElementById(`qr-code-${index}`);
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = "msgboxx-qr-code.png";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	return (
		<div style={{ display: "flex", alignItems: "row" }}>
			<div
				style={{
					marginRight: "40px",
					display: "flex",
					flexDirection: "Column",
					alignItems: "center",
				}}
			>
				<span
					style={{
						fontSize: "small",
						display: "block",
						marginBottom: "5px",
					}}
				>
					Copy Link
				</span>
				<StyledButton primary style={{ width: "50px", height: "50px" }}>
					<FontAwesomeIcon
						icon={faLink}
						style={{
							color: "#f0f0f0",
							width: "28px",
							height: "28px",
						}}
						onClick={handleCopyClick}
					/>
				</StyledButton>
			</div>
			<div
				onClick={handleQrCodeDownload}
				style={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					flexDirection: "Column",
				}}
			>
				<span
					style={{
						fontSize: "small",
						display: "block",
						marginBottom: "5px",
					}}
				>
					Download QR
				</span>
				<StyledButton primary style={{ width: "50px", height: "50px" }}>
					<FontAwesomeIcon
						icon={faQrcode}
						style={{
							color: "#f0f0f0",
							width: "28px",
							height: "28px",
						}}
					/>
				</StyledButton>
				<div>
					<QRCodeCanvas
						value={url}
						style={{ display: "none" }}
						id={`qr-code-${index}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default IconLinksComponent;
