import { gql } from "@apollo/client";

export const GET_CONTACT_EMAIL = gql`
	query getInfoPanelData($contactId: Int!) {
		msgbox_Contact(where: { ContactId: { _eq: $contactId } }) {
			ContactId
			Email
		}
	}
`;

export const UPDATE_CONTACT_EMAIL = gql`
	mutation UpdateContactEmail($email: String, $contactId: Int!) {
		update_msgbox_Contact(
			where: { ContactId: { _eq: $contactId } }
			_set: { Email: $email }
		) {
			affected_rows
		}
	}
`;
