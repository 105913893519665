import { gql } from "@apollo/client";

// Get inital conversations
export const GET_CONVERSATIONDETAILS = gql`
query getConversationDetailsQL($conversationId: Int!) {
	msgbox_Message(limit: 1, order_by: {DateTime: desc}, where: {ConversationId: {_eq: $conversationId}, CreatedByAPI: {_eq: false}, IsBulkBroadcast: {_eq: false}}) {
	  MessageId
	  BodyText
	  DateTime

	  Conversation {
		Folder {
		  Inbox {
			Name
		  }
		}
		AssignedTo
		AssignedToDepartment
		User {
		  LastName
		  FirstName
		}
		Department {
		  Name
		}
		Contact {
		  Name
		}
	  }
	}
  }
`;

export const UPDATE_CONVERSATIONHIDDEN = gql`
	mutation updateConversationIsHidden($conversationId: Int!, $isHidden: Boolean!) {
		update_msgbox_Conversation(where: {ConversationId: {_eq: $conversationId}}, _set: {IsHidden: $isHidden}) {
		affected_rows
		}
	}`;
