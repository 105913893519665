import React, { useEffect, useState } from "react";
import { GET_OPTOUT_CONFIG, UPDATE_OPTOUT_CONFIG } from "./query";
import { useQuery, useMutation } from "@apollo/client";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { Container } from "./styles";
import TextArea from "../TextArea/text-area.component";
import Button from "../Button/button.component";
import { useToasts } from "react-toast-notifications";
import { Prompt } from "react-router";

const Unsubscribe = () => {
	const { loading, error, data } = useQuery(GET_OPTOUT_CONFIG);
	const [updateOptOutConfig, { loading: updateLoading, error: updateError }] =
		useMutation(UPDATE_OPTOUT_CONFIG);
	const [reply, setReply] = useState("");
	const [replyError, setReplyError] = useState("");
	const { addToast } = useToasts();
	const [dirty, setDirty] = useState(false);

	useEffect(() => {
		if (data) {
			setReply(data.msgbox_Configuration[0].OptoutReply);
		}
	}, [data]);

	useEffect(() => {
		if (dirty) {
			window.onbeforeunload = () => true;
		}
		return () => {
			window.onbeforeunload = undefined;
		};
	}, [dirty]);

	const handleReplyChange = (event) => {
		setDirty(true);
		setReply(event.target.value);
	};

	const handleSave = async (configId) => {
		if (reply === "") {
			setReplyError("Reply is required");
		} else {
			setReplyError("");
			await updateOptOutConfig({
				variables: {
					configId,
					reply,
				},
			});
			addToast("Reply updated", {
				appearance: "success",
				autoDismiss: true,
			});
			setDirty(false);
		}
	};

	if (error) return <p>Ooops... somethign went wrong</p>;

	if (loading) return <p>loading...</p>;

	const { OptoutKeyword, ConfigurationId } = data.msgbox_Configuration[0];

	return (
		<Container>
			<Prompt
				when={dirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/>
			<MsgBoxSelect
				required
				isDisabled
				label="Keyword"
				defaultValue={{ label: OptoutKeyword, value: ConfigurationId }}
			/>
			<TextArea
				value={reply}
				onChange={handleReplyChange}
				label="Reply"
				error={replyError}
				required
			/>
			<div style={{ display: "flex" }}>
				<Button
					style={{ marginLeft: "auto" }}
					buttonName="Save"
					primary
					onClick={() => {
						handleSave(ConfigurationId);
					}}
					loading={updateLoading}
					disabled={dirty === false}
				/>
			</div>
		</Container>
	);
};

export default Unsubscribe;
