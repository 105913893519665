import styled from "styled-components";

export const Span = styled.span`
	display: inline-block;
	position: absolute;
	background-color: black;
	padding: 5px 10px;
	right: ${(props) => props.left && "-8px"};
	border-radius: 5px;
	margin-top: auto;
	white-space: nowrap;
	font-size: 0.8rem;
	visibility: hidden;
	opacity: 0;
	top: -30px;
	transition: opacity 200ms ease-in-out;
	z-index: 100;
	color: ${(props) => props.theme.colours.light_0};
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		bottom: -4px;
		transform: rotate(45deg);
		width: 8px;
		height: 8px;
		background-color: inherit;
	}
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	&:hover ${Span} {
		visibility: visible;
		opacity: 0.8;
	}
	position: relative;
`;

export const StyledIconButton = styled.button`
	color: ${(props) =>
		props.primary
			? props.theme.colours.primary
			: props.theme.colours.textDark};
	border-radius: 6px;
	border: none;
	background: none;
	margin-left: 6px;
	margin-right: 6px;
`;
