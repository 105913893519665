import React from "react";
import styled from "styled-components";
import { formatString } from "../../helpers/formatString";
import { MessageButton } from "./styles";

const Container = styled.div`
	margin: 12px;
`;

const ExpandableTemplateComponent = ({ data }) => {
	return (
		<Container>
			<h4>Template Message</h4>
			<p>
			{formatString(data.messageText).map((item, index) => {
				if (item.bold && item.italic) {
					return (
						<p
							key={index}
							style={{
								fontWeight: "bold",
								fontStyle: "italic",
							}}
						>
							{item.text}
						</p>
					);
				} else if (item.italic) {
					return (
						<p key={index} style={{ fontStyle: "italic" }}>
							{item.text}
						</p>
					);
				} else if (item.bold) {
					return (
						<p key={index} style={{ fontWeight: "bold" }}>
							{item.text}
						</p>
					);
				} else if (item.button) {
					return <MessageButton>{item.text}</MessageButton>
				} else {
					return <p key={index}>{item.text}</p>;
				}
			})}
		</p>
		</Container>
	);
};

export default ExpandableTemplateComponent;
