import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const DropZone = styled.div`
	outline: dotted 2px ${(props) => props.theme.colours.light_3};
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.colours.light_1};
	&:focus-within {
		outline: dotted 2px ${(props) => props.theme.colours.primary};
	}
`;

export const Delete = styled(IconButton)`
	color: ${(props) => props.theme.colours.danger};
`;
