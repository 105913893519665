import { gql } from "@apollo/client";

export const GETALL_CONVERSATIONS = gql`
    query getAllConversationsInFolder{
        msgbox_Conversation: get_users_messages(order_by: {LastMessage: desc_nulls_last}) {
        Contact {
            ContactId
        }
        FolderId
        ConversationId
        ContactId
        AssignedTo
        AssignedToDepartment
        IsRead
        IsHidden
        LastMessage
        UpdatedOn
        }
    }`;
