import styled from "styled-components";
import Button from "../Button/button.component";

export const Container = styled.div`
	margin: 6px;
	.twenty-four-hour-message {
		padding-top: 12px;
		padding-bottom: 12px;
	}
`;

export const SendTemplateButton = styled(Button)`
	border: ${(props) =>
		props.primary ? "none" : `solid 1px ${props.theme.colours.light_4}`};
	margin: ${(props) => (props.primary ? "0 0 0 6px" : "0 6px 0 0 ")};
	width: 100%;
`;
