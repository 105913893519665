import { gql } from "@apollo/client";

export const GET_PHONE_NUMBERS = gql`
query getNumbers ($userId: Int){
msgbox_User(where: { UserId: { _eq: $userId } }) {
	UserId
    FirstName
    LastName
    UserDepartments {
		Department {
			DepartmentId
			Name
			DepartmentInboxes {
				Inbox {
					Name
					APIAccount {
						PhoneNumber
					}
				}
			}
			}
      }
			UserInboxes {
        Inbox {
          Name
          APIAccount {
            PhoneNumber
          }
        }
      }
		}
}
`;

