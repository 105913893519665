import { gql } from "@apollo/client";

export const GET_PERMISSION_TYPES = gql`
	query getPermissionsTypes {
		msgbox_PermissionType {
			Description
			Name
		}
	}
`;

export const GET_PERMISSIONS = gql`
	query getPermissions {
		msgbox_Permission {
			AnalyticsSeeAnalytics
			AutomationsSeeAutomations
			ContactsDeleteContacts
			InboxAssignConversation
			InboxSeeOthersAssignedConversations
			InboxSeeUnassignedConversations
			OrganisationId
			ContactsDeleteContacts
			ContactsSeePhoneNumbers
			BroadcastBulkBroadcasts
			ContactsDownloadContacts
			TemplatesAddEditTemplates
			TemplatesDeleteTemplates
			AutomationsSeeAutomations
			InboxSeeUnassignedConversations
		}
	}
`;

export const UPDATE_PERMISSIONS = gql`
	mutation updatePermissions(
		$orgId: Int!
		$assignedConversationVisibility: Int
		$contactsDeleteContacts: Int
		$automationsSeeAutomations: Int
		$analyticsSeeAnalytics: Int
		$ContactsDownloadContacts: Int
		$TemplatesAddEditTemplates: Int
		$TemplatesDeleteTemplates: Int
		$InboxSeeUnassignedConversations: Int
		$ContactsSeePhoneNumbers: Int
		$BroadcastBulkBroadcasts: Int
	) {
		update_msgbox_Permission(
			_set: {
				InboxSeeOthersAssignedConversations: $assignedConversationVisibility
				ContactsDeleteContacts: $contactsDeleteContacts
				AutomationsSeeAutomations: $automationsSeeAutomations
				AnalyticsSeeAnalytics: $analyticsSeeAnalytics
				ContactsDownloadContacts: $ContactsDownloadContacts
				TemplatesAddEditTemplates: $TemplatesAddEditTemplates
				TemplatesDeleteTemplates: $TemplatesDeleteTemplates
				InboxSeeUnassignedConversations: $InboxSeeUnassignedConversations
				ContactsSeePhoneNumbers: $ContactsSeePhoneNumbers
				BroadcastBulkBroadcasts: $BroadcastBulkBroadcasts
			}
			where: { OrganisationId: { _eq: $orgId } }
		) {
			affected_rows
			returning {
				InboxAssignConversation
				InboxSeeOthersAssignedConversations
				AnalyticsSeeAnalytics
				OrganisationId
			}
		}
	}
`;
