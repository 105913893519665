import PropTypes from "prop-types";
import { useEffect, useContext, useState } from "react";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { GET_CONVERSATIONDETAILS, UPDATE_CONVERSATIONHIDDEN } from "./query";
import { MessageContainer, ContactImgPlaceholder, Badge } from "./styles";
import { format, parseISO, setDate } from "date-fns";
import getAssignee from "../../helpers/getConversationAssignee";
import hideNumbers from "../../helpers/hideNumber";
import { UserContext } from "../../Providers/user-provider";

const MessagePreview = ({
	isActive,
	onClick,
	isRead,
	conversationId,
	updatedOn,
	lastMessage,
	removeConversation,
}) => {
	const { data, loading, refetch } = useQuery(GET_CONVERSATIONDETAILS, {
		fetchPolicy: "no-cache",
		variables: {
			conversationId: conversationId,
		},
	});
	const [message, setMessage] = useState(false)
	const context = useContext(UserContext)
	const canSeeNumbers = context.permissions.canSeeNumbers
	const [
		mutationMakeConversationHidden,
		{ loading: markAHiddenLoading, error: markAsHiddenError },
	] = useMutation(UPDATE_CONVERSATIONHIDDEN);

	useEffect(() => {
		if(lastMessage && lastMessage.Messages && lastMessage.Messages[0]){ 
			let obj = {Conversation: {}}
			obj.MessageId = lastMessage.Messages[0].MessageId
			obj.DateTime = lastMessage.Messages[0].DateTime
			obj.BodyText = lastMessage.Messages[0].BodyText
			obj.Conversation.Folder = lastMessage.Folder
			obj.Conversation.AssignedTo = lastMessage.AssignedTo
			obj.Conversation.AssignedToDepartment = lastMessage.AssignedToDepartment
			obj.Conversation.User = lastMessage.User
			obj.Conversation.Department = lastMessage.Department
			obj.Conversation.Contact = lastMessage.Contact
			setMessage(obj)
		}
	}, [lastMessage]);

	useEffect(() => {
		if(loading || (lastMessage && lastMessage.Messages && lastMessage.Messages[0]) || message) return 
		if(data && data.msgbox_Message.length > 0) {
			setMessage(data.msgbox_Message[0])
		} else {
			setMessage(null)
		}
	}, [data, loading])

	if (message === false) return null;

	if (message === null) {
		if (conversationId) {
			mutationMakeConversationHidden({
				variables: {
					conversationId: conversationId,
					isHidden: true,
				},
			});
			removeConversation(conversationId);
		}
		return null;
	}

	function getPreviewBody(str) {
		if(!str || str === '') {
			return (
			<p>Multimedia message</p>
		)
		} else {
			let withItalics = str.replace(
				/_([^*\n\r]|\*(?=[^*\n\r]))*_/g,
				(match) => {
					return "<em>" + match.slice(1, -1) + "</em>";
				}
			);

			let withBold = withItalics.replace(
				/\*([^*\n\r]|\*(?=[^*\n\r]))*\*/g,
				(match) => {
					return "<strong>" + match.slice(1, -1) + "</strong>";
				}
			);

			const withSpaces = withBold.replace(/ /g, "&nbsp;");

			return <p dangerouslySetInnerHTML={{ __html: withSpaces }} />;
		}
	}

	const date = format(
		parseISO(message.DateTime),
		"dd/MM/yyyy"
	);
	var name =
	!message.Conversation?.Contact?.Name
			? message.User.FirstName +
			  " " +
			  message.User.LastName
			: message.Conversation.Contact.Name ;

	return (
		<MessageContainer isRead={isRead} isActive={isActive} onClick={onClick}>
			{isActive ? <div className="active-bar"></div> : null}
			<ContactImgPlaceholder contactColor={"#29278E"}>
				{name[0]}
			</ContactImgPlaceholder>
			<div className="message-details">
				<div className="message-details__body">
					<h5>{hideNumbers(name, canSeeNumbers)}</h5>
					<div>
					<Badge style={{backgroundColor: 'lightblue'}}>{message.Conversation?.Folder?.Inbox?.Name}</Badge>
					</div>
				</div>

				<div className="message-details__body">
					{getPreviewBody(message.BodyText)}
					<div>
						<Badge>{ getAssignee(message.Conversation).label }</Badge>
						{isRead === false && (
							<i className="far fa-comments"></i>
						)}
					</div>
				</div>
				<div className="message-details__body">
					<p style={{fontSize: "0.9em", marginTop: 5}}>{date}</p>	
				</div>
			</div>
		</MessageContainer>
	);
};

MessagePreview.propTypes = {
	isActive: PropTypes.bool,
	isOnline: PropTypes.bool,
	imageUri: PropTypes.string,
	onClick: PropTypes.func,
	isRead: PropTypes.bool,
	name: PropTypes.string,
	conversationId: PropTypes.number,
	updatedOn: PropTypes.string,
	removeConversation: PropTypes.func,
};

export default MessagePreview;
