import logo from "../SpliceCom/splicecom-logo.png";
import poweredBy from "../SpliceCom/splicecom-logo.png";
import titleImage from "../SpliceCom/titleimage.png";
import loginImage from "../SpliceCom/loginimage.jpg";
import topleftIcon from "../SpliceCom/icon.png";
import favIcon from "../SpliceCom/favicon.ico";
export const splicecomTheme = {
	name: "splicecom",
	website: "https://www.splicecom.com/",
	appName: "msgboxx",
	tagline: "Connecting you with your customers instantly",
	colours: {
		primary: "#f49204",
		success: "#29bb89",
		danger: "#ce1212",
		dangerLight: "#ffeee9",
		warning: "#f58634", //orange
		light_0: "#FFFFFF", // white
		light_1: "#f9f9f9", // very-light-grey (background)
		light_2: "#FAFAFA", // light-grey (nth child odd table rows)
		light_3: "#EEEEEE", // dark-grey (hover table rows + notes)
		textLight: "#FFFFFF", // light text
		light_4: "#bbbbbb",
		textDark: "#000000", // dark text,
		dark: "#000000", // black
		primaryLight: "#fef5e7",
		whatsApp: {
			darkGreen: "#075E54",
			lightGreen: "#128C7E",
			background: "#ECE5DD",
		},
	},
	fonts: {
		family: "nulshock",
		path: "./nulshock bd.tff",
	},
	images: {
		// paths to images
		// logo: "https://splicecom.com/img/splicecom-logo-black.png",#
		logo,
		titleImage,
		loginImage,
		poweredBy,
		topleftIcon,
		favIcon,
	},
};
