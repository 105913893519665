import React, { useEffect } from "react";

const IPhone = ({ children }) => {
	return (
		<div className="d-flex justify-content-center ">
			<div className="temp-wrapper">
				<div className="px">
					<div className="px__body">
						<div className="px__body__cut"></div>
						<div className="px__body__speaker"></div>
						<div className="px__body__sensor"></div>

						<div className="px__body__mute"></div>
						<div className="px__body__up"></div>
						<div className="px__body__down"></div>
						<div className="px__body__right"></div>
					</div>
					<div className="px__screen">
						<div className="px__screen__">
							<div className="px__screen__frame">{children}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default IPhone;
