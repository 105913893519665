import { useQuery } from "@apollo/client";
import Toggle from "react-toggle";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
import { Badge } from "../MessagePreview/styles";
import { GET_ZAPIER_WEBHOOKS } from "./query";
import { Container } from "./styles";

const ZapierWebhooksTable = () => {
	const { data, loading, error } = useQuery(GET_ZAPIER_WEBHOOKS);

	if (loading) return <LoadingSpinner />;

	if (error) {
		return <p>oops... something went wrong</p>;
	}

	return (
		<Container>
			<table>
				<tr>
					<th>Target Url</th>
					<th>Type</th>
					<th>Failure Count</th>
					<th>Is Enabled</th>
				</tr>
				{data.msgbox_ZapierWebhook.map((webhook) => (
					<tr>
						<td>
							<p>{webhook.Target_Url}</p>
						</td>
						<td>
							<Badge>{webhook.Type}</Badge>
						</td>
						<td>
							<p>{webhook.FailureCount}</p>
						</td>
						<td>
							<Toggle
								id={webhook.ZapierWebHookId}
								disabled={true}
								name="isEnabled"
								checked={webhook.IsEnabled === true}
							/>
						</td>
					</tr>
				))}
			</table>
		</Container>
	);
};

export default ZapierWebhooksTable;
