import styled from "styled-components";

export const HelperText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.3rem;
	i {
		margin-right: 12px;
		font-size: 2rem;
	}
`;
