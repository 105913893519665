import { gql } from "@apollo/client";

export const ADD_MESSAGE_TO_CONVERSATION_DEFERRED = gql`
	mutation MessageMutation(
		$bodyText: String
		$conversationId: Int!
		$attachmentId: Int
	) {
		insert_msgbox_Message(
			objects: [
				{
					BodyText: $bodyText
					ConversationId: $conversationId
					AttachmentId: $attachmentId
					DeferredDelivery: true
				}
			]
		) {
			affected_rows
			returning {
				MessageId
			}
		}
	}
`;

export const GET_LATEST_TEMPLATE = gql`
	query GetLatestTemplate($conversationId: Int!, $_gt: timestamptz = "") {
		msgbox_Message(
			where: {
				ConversationId: { _eq: $conversationId }
				IsTemplate: { _eq: true }
				DateTime: { _gt: $_gt }
			}
		) {
			MessageId
		}
	}
`;

export const GET_INBOX_NATURAL_CHAT = gql`
	query getNaturalChat($inboxId: Int!) {
		msgbox_UserInbox(where: { InboxId: { _eq: $inboxId } }) {
			Inbox {
				APIAccount {
					APIAccountId
					Templates(where: { Name: { _eq: "natural_chat" } }) {
						TemplateId
						TemplateText
					}
				}
				InboxId
				Name
			}
		}
	}
`;

export const GET_CONTACT_ID = gql`
	query getContactId($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			ContactId
			ConversationId
		}
	}
`;
