import { ConversationFiltersButton } from "../ConversationFilters/styles";
import useMoveConversationToSpam from "./use-move-conversation-to-spam";

const SpamButton = () => {
	const moveConversationToSpam = useMoveConversationToSpam();
	return (
		<ConversationFiltersButton
			data-tip="Move to Spam"
			style={{ float: "right" }}
			icon="fas fa-exclamation-circle"
			onClick={moveConversationToSpam}
		/>
	);
};

export default SpamButton;
