const getAssignee = (data) => {
	if (data.User && data.AssignedTo !== null) {
		return {
			value: data.AssignedTo,
			label: data.User.FirstName + " " + data.User.LastName,
		};
	} else if (data.AssignedToDepartment !== null && data.Department) {
		// Assigned to department
		if (data.Department && data.Department.Name) {
			return {
				value: data.AssignedToDepartment,
				label: `${data.Department?.Name}`,
			};
		} else {
			return {
				value: data.AssignedToDepartment,
				label: `Deleted team`,
			};
		}
	} else {
		return {
			value: null,
			label: "Unassigned",
		};
	}
};

export default getAssignee;
