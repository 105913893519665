import styled from "styled-components";

export const DatePickerContainer = styled.div`
	position: absolute;
	z-index: 100;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
		rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

export const Container = styled.div`
	position: relative;
	margin-bottom: ${(props) => props.table && "6px"};
	margin-top: ${(props) => props.table && "6px"};
	flex: 1;
`;
