import { useQuery } from "@apollo/client";
import {useMemo} from "react";
import { GET_USER_DETAILS } from "../Components/UserPreview/query";
import { getUserId } from "./functions";

const useCurrentUserDetails = () => {
	const { data, loading: currentUserLoading } = useQuery(GET_USER_DETAILS, {
		variables: {
			userId: getUserId(),
		},
	});

	const currentUser = useMemo(() => {
		const userArray = data && data.msgbox_User;
		if (userArray && userArray.length > 0) {
			return userArray[0];
		} else {
			return null;
		}
	}, [data]);

	return { currentUser, currentUserLoading };
};

export default useCurrentUserDetails;
