export const device = {
	mobile: "480px",
	tablet: "768px",
	desktop: "1200px",
};

export const contactColourPalette = [
	"#29278E",
	"#E8C95A",
	"#B9E83A",
	"#DB69A7",
	"#C393F9",
	"#F788B2",
	"#EA2741",
	"#EDBAA6",
	"#E0A482",
	"#E5FF89",
	"#AB63DD",
	"#73A0F4",
	"#E5C592",
	"#59D6B2",
	"#95EDE1",
];
