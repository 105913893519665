import logo from "./omnichat-logo.png";
import poweredBy from "./ipvoice-logo.png";
import titleImage from "./omnichat-logo.svg";
import loginImage from "./loginimage2.png";
import favIcon from "./favicon.ico";
import manifest from "./manifest.json";

export const ipvoiceTheme = {
	name: "OpenVoice",
	website: "https://ipvoice.uk/",
	appName: "OmniChat",
	tagline: "Engaging with your customers to grow your business.",
	colours: {
		primary: "#3d8ac4", // customer supplied
		success: "#29bb89", // green
		danger: "#ce1212", // red
		dangerLight: "#ffeee9", // light red
		warning: "#f58634", //orange
		light_0: "#FFFFFF", // white
		light_1: "#F7F7F7", // very-light-grey (background)
		light_2: "#FAFAFA", // light-grey (nth child odd table rows)
		light_3: "#EEEEEE", // dark-grey (hover table rows + notes)
		textLight: "#FFFFFF", // light text
		light_4: "#bbbbbb",
		textDark: "#000000", // dark text,
		dark: "#000000", // black
		primaryLight: "#e8f4fe",
		whatsApp: {
			darkGreen: "#075E54",
			lightGreen: "#128C7E",
			background: "#ECE5DD",
		},
	},
	fonts: {
		family: "nulshock",
		path: "./nulshock bd.tff",
	},
	manifest,
	images: {
		// paths to images
		logo,
		titleImage,
		loginImage,
		favIcon,
		poweredBy,
	},
};
//9169AD
//6F2B8C
