import styled from "styled-components";

export const Spinner = styled.div`
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	border: 8px solid #f3f3f3;
	border-radius: 50%;
	border-top: 8px solid ${(props) => props.theme.colours.primary};
	width: 60px;
	height: 60px;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
`;

export const WaveSpin = styled.div`
	@keyframes wave {
		0% {
			transform: scale(2.2);
			background: #29278e;
		}
		50% {
			transform: scale(0.2);
			background: #fafafa;
		}
		100% {
			transform: scale(2);
			background: #29278e;
		}
	}
	width: 8px;
	height: 8px;
	background: #fafafa;
	margin: 4px;
	animation: wave 2.2s linear infinite;
`;

export const WaveSpinnerContainer = styled.div`
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	${WaveSpin}:nth-child(2) {
		animation-delay: 0.1s;
	}
	${WaveSpin}:nth-child(3) {
		animation-delay: 0.2s;
	}
	${WaveSpin}:nth-child(4) {
		animation-delay: 0.3s;
	}
	${WaveSpin}:nth-child(5) {
		animation-delay: 0.4s;
	}
	${WaveSpin}:nth-child(6) {
		animation-delay: 0.5s;
	}
	${WaveSpin}:nth-child(7) {
		animation-delay: 0.6s;
	}
	${WaveSpin}:nth-child(8) {
		animation-delay: 0.7s;
	}
	${WaveSpin}:nth-child(9) {
		animation-delay: 0.8s;
	}
	${WaveSpin}:nth-child(10) {
		animation-delay: 0.9s;
	}
`;
