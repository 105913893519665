import styled from "styled-components";
import Actions from "../Actions/actions.component";

export const Container = styled.div`
	border-radius: 6px;
`;

export const StyledNote = styled.div`
	display: flex;
	align-items: center;
	border-radius: 6px;
	background-color: ${(props) => props.theme.colours.light_1};
	min-height: 38px;
	margin-bottom: 6px;
`;

export const NoteActions = styled(Actions)`
	margin-right: 18px;
`;

export const TrashIcon = styled.i`
	color: ${(props) => props.theme.colours.danger};
`;

export const EditIcon = styled.i`
	color: ${(props) => props.theme.colours.primary};
`;
