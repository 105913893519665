import { useTheme } from "styled-components";

const useDefaultTableStyles = () => {
	const theme = useTheme();

	return {
		table: {
			style: {
				fontSize: "12px",
			},
		},
		header: {
			style: {
				fontSize: "22px",
				minHeight: "60px",
				borderBottom: "solid 1px rgba(0,0,0,.12)",
				paddingLeft: "12px",
			},
		},
		headRow: {
			style: {
				borderBottom: "solid 1px rgba(0,0,0,.12)",
			},
		},
		head: {
			style: {},
		},
		subHeader: {
			style: {
				minHeight: "62px",
				borderBottom: "solid 1px rgba(0,0,0,.12)",
				paddingLeft: "12px",
			},
		},
		cells: {
			style: {
				flexWrap: "wrap",
				minHeight: "40px",
			},
		},
		contextMenu: {
			style: {
				backgroundColor: theme.colours.primaryLight,
				color: theme.colours.primary,
				fontSize: "12px",
				zIndex: 1,
			},
		},
		rows: {
			style: {
				fontSize: "12px",
			},
			highlightOnHoverStyle: {
				boxShadow:
					"rgba(17, 17, 26, 0.1) 0px 1px 2px, rgba(17, 17, 26, 0.1) 0px 8px 5px, rgba(17, 17, 26, 0.1) 0px 24px 80px;",
				backgroundColor: "white",
				zIndex: 12,
				borderBottom: "none",
			},
		},
	};
};

export default useDefaultTableStyles;
