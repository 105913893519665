import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { getUserId } from "../../../helpers/functions";
import { GET_ACCOUNT } from "../../APIAccountsSelect/query";

const useInboxesOptions = () => {
	const { data, loading } = useQuery(GET_ACCOUNT, {
		variables: {
			userId: getUserId(),
		},
	});

	const inboxesOptions = useMemo(() => {
		if (data && data.msgbox_User && data.msgbox_User[0].UserInboxes) {
			return data.msgbox_User[0].UserInboxes.filter((item) => item.Inbox).map((inbox) => ({
				label: inbox.Inbox.APIAccount.Name,
				value: inbox.Inbox.InboxId,
			}));
		} else {
			return [];
		}
	}, [data]);

	return { inboxesOptions, loading };
};

export default useInboxesOptions;
