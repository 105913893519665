import * as Yup from "yup";
import { IMPORT_OPTIONS } from "./import-modal/select-account";

export const importContactsValidationSchema = Yup.object().shape({
	account: Yup.object().required(
		"You must select an API account to import contacts"
	),
	importOption: Yup.string()
		.oneOf(
			[IMPORT_OPTIONS.SKIP, IMPORT_OPTIONS.REPLACE, IMPORT_OPTIONS.MERGE],
			"Required"
		)
		.required("Required"),
});

export const COLUMNS_PATTERN = ["name", "mobile", "tags", "email", "note"];
export const PHONE_REGEX =
	/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{8,14}$/;
export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
