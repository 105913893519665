import React from "react";
import LoadingSpinner from "../LoadingSpinner/loading-spinner.component";
 
const ConvertingModal = ({ isOpen }) => {
  if (!isOpen) return null;
 
  const modalStyles = {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    textAlign: "center",
    alignItems: "center",
    gap: 15
  };
 
  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  };
 
  return (
<div style={overlayStyles}>
<div style={modalStyles}>
<h2>Converting Video...</h2>
<LoadingSpinner></LoadingSpinner>
</div>
</div>
  );
};
 
export default ConvertingModal;