import styled from "styled-components";

export const StyledSearchBar = styled.div`
	display: flex;
	border-radius: 4px;
	align-items: center;
	background-color: ${(props) => props.theme.colours.light_1};
	padding: 12px;
	max-height: 36px;
	&:focus-within {
		outline: 2px solid ${(props) => props.theme.colours.primary};
	}
`;

export const StyledInput = styled.input`
	font-size: 1.2rem;
	border: none;
	background: none;
	color: ${(props) => props.theme.colours.textDark};
	&:focus {
		outline: none;
	}
`;

export const SearchIcon = styled.i`
	font-size: 1.2rem;
	color: ${(props) => props.theme.colours.textDark};
`;
