import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	.response-actions {
		margin-left: auto;
	}
	.response {
		p {
			width: 70%;
			overflow-wrap: break-word;
		}
		width: 75%;
		padding: 12px;
		margin: 12px;
		margin-left: 24px;
		margin-right: 24px;
		margin-top: 12px;
		margin-bottom: 12px;
		border-radius: 12px;
		display: flex;
		&-actions {
			display: flex;
		}
	}
	.client-response {
		margin-left: auto;
		position: relative;
		background-color: ${(props) => props.theme.colours.light_3};
		&::before {
			content: "";
			position: absolute;
			visibility: visible;
			top: 0;
			right: -8px;
			border: 10px solid transparent;
			border-top: 10px solid ${(props) => props.theme.colours.light_3};
		}
	}
	.child-response {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			visibility: visible;
			top: 0;
			left: -8px;
			border: 10px solid transparent;
			border-top: 10px solid
				${(props) => props.theme.colours.primaryLight};
		}
		background-color: ${(props) => props.theme.colours.primaryLight};
	}
`;

export const LoadingContainer = styled.div`
	margin: auto;
	h3 {
		padding: 12px;
	}
`;

export const ButtonContainer = styled.div`
	margin-right: 24px;
	margin-left: auto;
`;

export const MessageButton = styled.button`
	margin: 6px;
	padding: 10px;
	border: none;
	color: white;
	font-weight: bold;
	background-color: ${(props) => props.theme.colours.primary};
	border-radius: 5px;
	:active, :focus {
		outline: none;
	}
`