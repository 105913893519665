import React, { useRef } from "react";
import {
	StyledInput,
	Container,
	StyledInputContainer,
	ActionButton,
} from "./styles";
import {
	Label,
	InputErrorMessage,
	FormField,
	InputHelperMessage,
} from "../../Styles/global";
import PropTypes from "prop-types";
import Tooltip from "../Tooltip/tooltip.component";

const Input = ({
	label,
	error,
	style,
	icon,
	onIconClick,
	required,
	hasTooltip,
	toolTipText,
	onContainerClick,
	table,
	hasIcon,
	isForm,
	hasAction,
	helperText,
	...rest
}) => {
	const inputElement = useRef(null);

	const handleEnterKeyPress = (event) => {
		// What was this for??
		// if (event.key === "Enter") {
		// 	onIconClick();
		// }
	};

	return (
		<FormField isForm={isForm} onClick={onContainerClick} table={table}>
			<Label> {label + (required ? "*" : "")} </Label>
			{hasTooltip && <Tooltip right text={toolTipText} />}
			<StyledInputContainer
				onClick={() => {
					inputElement.current.focus();
				}}
			>
				{!hasAction && icon}

				<StyledInput
					onKeyDown={handleEnterKeyPress}
					ref={inputElement}
					style={style ? style : null}
					{...rest}
				/>
				{hasAction && (
					<ActionButton
						onClick={(event) => {
							onIconClick(event);
							event.stopPropagation();
						}}
						icon={icon}
					/>
				)}
			</StyledInputContainer>
			{!error && helperText && (
				<div>
					<InputHelperMessage>{helperText}</InputHelperMessage>
				</div>
			)}
			{error && (
				<div>
					<InputErrorMessage> {error}</InputErrorMessage>
				</div>
			)}
		</FormField>
	);
};

Input.defaultProps = {
	hasTooltip: false,
	onContainerClick: () => {},
	hasAction: false,
	isForm: true,
};

Input.propTypes = {
	label: PropTypes.string,
	error: PropTypes.string,
	style: PropTypes.object,
	Icon: PropTypes.string,
	onIconClick: PropTypes.func,
	required: PropTypes.bool,
	hasTooltip: PropTypes.bool,
	toolTipText: PropTypes.string,
	onContainerClick: PropTypes.func,
	hasAction: PropTypes.bool,
	isForm: PropTypes.bool,
};

export default Input;
