import { useState, useEffect } from "react";
import { FieldArray, useFormikContext, Field } from "formik";
import Input from "../Input/input.component";
import FileUpload from "../FileUpload/file-upload.component";
import TextArea from "../TextArea/text-area.component";
import get from "lodash.get";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import Pill from "../Pill/pill.component";
import Button from "../Button/button.component";
import styled from "styled-components";
import { InputErrorMessage } from "../../Styles/global";

const TagButton = styled(Button)`
	border: ${(props) =>
		props.primary ? "none" : `solid 1px ${props.theme.colours.light_4}`};
	height: 36px;
`;

const Step2 = () => {
	const [variableFieldStatus, setVariableFieldStatus] = useState(null);
	const { setFieldValue, values, errors } = useFormikContext();
	const [mainText, setMainText] = useState('');

	const renderErrorMsg = (fieldName, errors, path) => {
		let error = get(errors, path);
		if (error) {
			error = fieldName + " " + error;
		}
		return error;
	};

	useEffect(()=>{
		//start by wiping any prev./freeform attachments from step1
		setFieldValue("attachments", [])
	},[])

	useEffect(()=>{
		console.log(values.attachments, "< attach from inside step 2")
	},[values.attachments])

	const handleFileChange = (file) => {
		setFieldValue("attachments", file);
	};

	const renderButtonText = (valueToCheck) => {
		if (!variableFieldStatus) {
			return "Add tag";
		} else if (variableFieldStatus.valueToCheck === "tag") {
			return "Remove tag";
		} else {
			return "Remove tag";
		}
	};

	useEffect(() => {
		setMainText(values.templateMessages.value.text)
	}, [values.templateMessages.value.text])

	useEffect(() => {
		let temp = values.templateMessages.value.text
		values.variables.forEach((variable)=>{
			if(variable.value.length > 0) temp = temp.replace(`{{${variable.label}}}`, variable.value);
		})
		setMainText(temp)
	}, [values])

	return (
		<div>
			<div>
				<TextArea
					value={mainText}
					name="templateMessage"
					placeholder="Template Message"
					label="Template Message"
					readOnly
					disabled
				/>
				<FieldArray
					render={(helpers) => {
						return (
							values.variables.length > 0 &&
							values.variables.map((item, index) => (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "6px",
									}}
								>
									{variableFieldStatus &&
									variableFieldStatus[
										[`variables.${index}.value`]
									] === "tag" ? (
										<MsgBoxSelect
											options={[
												{
													label: "Contact Name",
													value: "contactname",
												},
												{
													label: "Salute As",
													value: "saluteas",
												},
												{
													label: "Organisation Name",
													value: "orgname",
												},
												{
													label: "Inbox Name",
													value: "inboxname",
												},
												{
													label: "My First Name",
													value: "firstname",
												},
												{
													label: "My Last Name",
													value: "lastname",
												},
												{
													label: "My Full Name",
													value: "fullname",
												},
											]}
											onChange={(options) => {
												setFieldValue(
													`variables.${index}.value`,
													options.value
												);
											}}
											name="tag"
											label={`Add dynamic variable for "${item.label}"`}
											error={renderErrorMsg(
												item.label,
												errors,
												`variables.${index}.value`
											)}
										/>
									) : (
										<Field
											key={item.label}
											label={item.label}
											name={`variables.${index}.value`}
											placeholder={`Enter a value for "${item.label}"`}
											component={Input}
											onChange={(event) => {
												setFieldValue(
													`variables.${index}.value`,
													event.target.value
												);
											}}
											error={renderErrorMsg(
												item.label,
												errors,
												`variables.${index}.value`
											)}
											value={
												values.variables[index].value
											}
										/>
									)}

									<TagButton
										type="button"
										buttonName={
											variableFieldStatus &&
											variableFieldStatus[
												[`variables.${index}.value`]
											] === "tag"
												? "Remove variable"
												: "Add variable"
										}
										onClick={() => {
											let status;
											if (!variableFieldStatus) {
												status = "tag";
											} else if (
												variableFieldStatus[
													`variables.${index}.value`
												] === "tag"
											) {
												status = "text";
												setFieldValue(
													`variables.${index}.value`,
													""
												);
											} else {
												status = "tag";
											}
											setVariableFieldStatus({
												...variableFieldStatus,
												[`variables.${index}.value`]:
													status,
											});
										}}
									/>
								</div>
							))
						);
					}}
					name="variables"
				/>

				{values.templateMessages.value?.attachment &&
					values.templateMessages.value?.attachment !== "None" && (
						<div>
							<InputErrorMessage style={{ position: "relative" }}>{errors.attachments}</InputErrorMessage>
						<FileUpload
							label="Attachment*"
							handleFileChange={handleFileChange}
						/>
						</div>
					)}
			</div>
		</div>
	);
};

export default Step2;
