import MsgBoxTable from "../MsgBoxTable/msg-box-table.component";
import { GET_WEBHOOK_LOGS } from "./query";
import * as React from "react";
import ExpandableDiagnosticComponent from "./expandable-diagnostic.component";

const DiagnosticsTable = () => {
	const columns = [
		{
			name: "Created At",
			selector: "dateSent",
			sortable: true,
			sortField: "CreatedAt",
			grow: 1,
		},
		{
			name: "Type",
			selector: "Type",
			grow: 1,
		},
		{
			name: "Result Code",
			selector: "ResultCode",
			grow: 1,
		},

		{
			name: "Result",
			selector: "Result",
			grow: 1,
		},
	];
	const tableRef = React.useRef();

	const formatData = (data) => {
		const formattedData = data.msgbox_ZapierWebhookLog.map(
			(broadcastContact, index) => {
				const time = new Date(
					broadcastContact.CreatedAt
				).toLocaleTimeString();
				const date = new Date(
					broadcastContact.CreatedAt
				).toDateString();
				return {
					id: index,

					dateSent: date + " " + time,
					targetUrl: broadcastContact.ZapierWebhook.Target_Url,
					...broadcastContact,
				};
			}
		);
		return [formattedData, 7];
	};

	return (
		<MsgBoxTable
			ref={tableRef}
			columns={columns}
			fetchRows={GET_WEBHOOK_LOGS}
			defaultLimit={100}
			defaultOrderBy={{
				field: "CreatedAt",
				sortDirection: "desc",
			}}
			formatData={formatData}
			hasAddButton={false}
			expandOnRowClicked
			expandableRows
			expandableRowsComponent={<ExpandableDiagnosticComponent />}
			hasDelete={false}
			filterIgnore={["data"]}
			sortServer
			hasSearch={false}
		/>
	);
};

export default DiagnosticsTable;
