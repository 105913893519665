import MsgBoxTable from "../MsgBoxTable/msg-box-table.component";
import { GET_USERS, DELETE_USER } from "./query";
import { useState } from "react";

import AddUser from "./add-user.component";
import EditUser from "./edit-user.component";
import AssignUsersToDepartment from "./assign-user-to-team.component";
import Pill from "../Pill/pill.component";

import { useMutation } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { ContextActionButton } from "../MsgBoxTable/styles";

const UserManagement = ({rootStore}) => {
	const [deleteUser, { loading, error }] = useMutation(DELETE_USER);
	/**
	 * Modal controls
	 */
	const [addUserModalOpen, setAddUserModalOpen] = useState(false);
	const [editUserModalOpen, setEditUserModalOpen] = useState(false);
	const [assignUserModalOpen, setAssignUserModalOpen] = useState(false);
	/**
	 * Selected users to be used inside of the edit users modal
	 */
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [filters, setFilters] = useState("");
	const { addToast } = useToasts();
	const columns = [
		{
			name: "First Name",
			selector: "firstName",
			sortable: true,
			sortField: "FirstName",
		},
		{
			name: "Last Name",
			selector: "lastName",
			sortable: true,
			sortField: "LastName",
		},
		{
			name: "Inboxes",
			selector: "inboxes",
			cell: (row) => {
				if (row.inboxes.length > 0) {
					return row.inboxes.map(({ Inbox }) => (
						<Pill asTag={false} name={Inbox.Name} />
					));
				} else {
					return "N/A";
				}
			},
		},
		{
			name: "Email",
			selector: "email",
		},
		{
			name: "Teams",
			selector: "departments",
			cell: (row) =>
				row.departments?.length > 0
					? row.departments.filter((x) => x.Department?.Name != null).map((department) => (
							<Pill
								name={department.Department?.Name}
								asTag={false}
							/>
					  ))
					: "N/A",
		},
		{
			name: "Role",
			selector: "role",
			cell: (row) => <div>{row.role[0].Role.Description}</div>,
		},
	];

	const formatData = (data) => {
		const users = data.msgbox_User.map((user) => ({
			id: user.UserId,
			firstName: user.FirstName,
			lastName: user.LastName,
			email: user.EMail,
			departments: user.UserDepartments,
			userId: user.UserId,
			role: user.UserRoles,
			inboxes: user.UserInboxes.filter((item) => item.Inbox),
		}));
		console.log(data.msgbox_User[0])
		return [users, data.msgbox_User_aggregate.aggregate.count];
	};

	const deleteUsers = async (rows) => {
		const promises = rows.map((row) => {
			deleteUser({
				variables: {
					userId: row.userId,
					time: new Date(Date.now()).toISOString(),
					by: localStorage.getItem("user_id")
				},
				update: (cache, data) => {
					cache.modify({
						fields: {
							msgbox_User(existingResponses = []) {
								const newResponseRef = cache.writeQuery({
									data: data.data.update_msgbox_User,
									query: GET_USERS,
								});
								return [...existingResponses, newResponseRef];
							},
						},
					});
				},
			});
		});
		await Promise.all(promises);
		addToast(`Successfully deleted users`, {
			appearance: "success",
			autoDismiss: true,
		});
	};

	const handleRowChange = (rows) => setSelectedUsers(rows);

	const handleSearchChange = (searchTerm) => {
		setFilters({ searchTerm: "%" + searchTerm + "%" });
	};

	// -- Add modal -- //
	const openAddUserModal = () => setAddUserModalOpen(true);
	const closeAddUserModal = () => setAddUserModalOpen(false);
	// -- Edit modal -- //
	const openEditUserModal = () => setEditUserModalOpen(true);
	const closeEditUserModal = () => setEditUserModalOpen(false);
	// -- Assign user -- //
	const openAssignUserModal = () => setAssignUserModalOpen(true);
	const closeAssignUserModal = () => setAssignUserModalOpen(false);

	return (
		<div>
			<MsgBoxTable
				title="Users"
				searchPlaceHolder="Search by name..."
				columns={columns}
				fetchRows={GET_USERS}
				formatData={formatData}
				openModal={openAddUserModal}
				deleteRows={deleteUsers}
				selectedRowsChange={handleRowChange}
				editRows={openEditUserModal}
				handleSearchChange={handleSearchChange}
				defaultOrderBy={{
					field: "FirstName",
					sortDirection: "asc",
				}}
				customContextActions={[
					selectedUsers.length > 1 && (
						<ContextActionButton
							title="Assign to team"
							primary
							icon="fas fa-user-plus"
							onClick={openAssignUserModal}
						/>
					),
				]}
				filters={filters}
				defaultLimit={10}
				selectableRows
				paginationServer
				pagination
				hasEdit
			/>
			<AddUser isOpen={addUserModalOpen} close={closeAddUserModal} rootStore={rootStore}/>
			<EditUser
				users={selectedUsers}
				isOpen={editUserModalOpen}
				close={closeEditUserModal}
				rootStore={rootStore}
			/>
			<AssignUsersToDepartment
				isOpen={assignUserModalOpen}
				close={closeAssignUserModal}
				users={selectedUsers}
			/>
		</div>
	);
};

export default UserManagement;
