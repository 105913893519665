import React, { useEffect, useState } from "react";
import MsgBoxModal from "../../../Components/MsgBoxModal/msgbox-modal.component";
import Toggle from "react-toggle";
import TextArea from "../../../Components/TextArea/text-area.component";
import MsgBoxSelect from "../../../Components/MsgBoxSelect/msg-box-select.component";

const EditResponseModal = ({ isOpen, submit, close, data }) => {
	const [reply, setReply] = useState("");
	const [requiresResponse, setRequiresResponse] = useState(null);
	const [captureAsEmailAddress, setCaptureAsEmailAddress] = useState(null);
	const [replyError, setReplyError] = useState("");
	const [featureType, setFeatureType] = useState({
		label: "None",
		value: false,
	});
	const [featureInfo, setFeatureInfo] = useState([]);
	const [flexBasis, setFlexBasis] = useState([]);

	useEffect(() => {
		let columns = 1;
		if (featureType.value === "Quick Reply") {
			columns = 2;
		} else if (featureType.value === "List") {
			columns = 3;
		}
		setFlexBasis(`${100 / columns}%`);
	}, [featureType]);

	const handleEditReply = () => {
		if (validate()) {
			submit(
				reply,
				requiresResponse,
				captureAsEmailAddress,
				data.botResponseId,
				featureType,
				featureInfo
			);
			destroyModal();
		}
	};

	const handleReplyChange = (event) => {
		setReply(event.target.value);
	};

	const handleRequiresResponseChange = (event) => {
		setRequiresResponse(event.target.checked);
	};

	const handleCaptureEmailResponseChange = (event) => {
		setCaptureAsEmailAddress(event.target.checked);
	};

	const destroyModal = () => {
		setReply("");
		setRequiresResponse(false);
		setCaptureAsEmailAddress(false);
		setReplyError("");
		close();
	};

	const validate = () => {
		if (!reply) {
			setReplyError("Reply is required");
			return false;
		} else {
			return true;
		}
	};

	useEffect(() => {
		setReply(data.reply);
		setRequiresResponse(data.requiresResponse);
		setCaptureAsEmailAddress(data.captureAsEmailAddress);
		if (data.ListMetadata) {
			setFeatureType({
				label: "List",
				value: "List",
			});
			let temp = JSON.parse(data.ListMetadata)[0]?.options.map(
				(item) => item.title
			);
			setFeatureInfo(temp);
		} else if (data.ReplyMetadata) {
			setFeatureType({
				label: "Quick Reply",
				value: "Quick Reply",
			});
			let temp = JSON.parse(data.ReplyMetadata).map((item) => item.title);
			setFeatureInfo(temp);
		}
	}, [data]);

	return (
		<MsgBoxModal
			header="Edit Reply"
			isOpen={isOpen}
			submit={handleEditReply}
			close={destroyModal}
			cancel={destroyModal}
			description="Use the form below to edit a reply"
			submitButtonName="Edit reply"
			aria={{
				labelledby: "heading",
				describedby: "full_description",
			}}
			onRequestClose={destroyModal}
			customModalContentStyles={{ width: "60%" }}
		>
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div style={{ flexBasis: flexBasis }}>
					<TextArea
						rows={"5"}
						cols="200"
						onChange={handleReplyChange}
						value={reply}
						required
						label="Reply"
						error={replyError}
					/>
					<div>
						<MsgBoxSelect
							label="Feature"
							onChange={(option) => {
								setFeatureType(option);
								setFeatureInfo([]);
							}}
							options={[
								{
									label: "None",
									value: false,
								},
								{
									label: "Quick Reply",
									value: "Quick Reply",
								},
								{
									label: "List",
									value: "List",
								},
							]}
							value={featureType}
							toolTipText="Add a message feature"
							hasTooltip
						/>
					</div>
					<div>
						<label
							style={{ display: "block" }}
							htmlFor="edit-reply-required"
						>
							Requires Reply?
						</label>
						<Toggle
							id="edit-reply-required"
							onChange={handleRequiresResponseChange}
							name="requires-reply"
							checked={requiresResponse}
						/>
					</div>
					<div>
						<label
							style={{ display: "block" }}
							htmlFor="edit-reply-required"
						>
							Capture Response as Email Address
						</label>
						<Toggle
							disabled={!requiresResponse}
							id="capture-as-email"
							onChange={handleCaptureEmailResponseChange}
							name="capture-email"
							checked={captureAsEmailAddress}
						/>
					</div>
				</div>
				{featureType.value === "Quick Reply" ? (
					<div style={{ flexBasis: flexBasis, marginLeft: 20 }}>
						<TextArea
							name="quickReply1"
							label="Button 1 Text"
							placeholder="Text For Button 1"
							value={featureInfo[0]}
							onChange={(event) => {
								if (event.target.value.length > 20) return;
								setFeatureInfo((prev) => {
									const newArr = [...prev];
									newArr[0] = event.target.value;
									return newArr;
								});
							}}
						/>
						<TextArea
							name="quickReply2"
							label="Button 2 Text"
							placeholder="Text For Button 2"
							value={featureInfo[1]}
							onChange={(event) => {
								if (event.target.value.length > 20) return;
								setFeatureInfo((prev) => {
									const newArr = [...prev];
									newArr[1] = event.target.value;
									return newArr;
								});
							}}
						/>
						<TextArea
							name="quickReply3"
							label="Button 3 Text"
							placeholder="Text For Button 3"
							value={featureInfo[2]}
							onChange={(event) => {
								if (event.target.value.length > 20) return;
								setFeatureInfo((prev) => {
									const newArr = [...prev];
									newArr[2] = event.target.value;
									return newArr;
								});
							}}
						/>
					</div>
				) : featureType.value === "List" ? (
					<>
						<div style={{ flexBasis: flexBasis, marginLeft: 20 }}>
							<TextArea
								name="list1"
								label="List Item 1"
								placeholder="Text For Item 1"
								value={featureInfo[0]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[0] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list2"
								label="List Item 2"
								placeholder="Text For Item 2"
								value={featureInfo[1]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[1] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list3"
								label="List Item 3"
								placeholder="Text For Item 3"
								value={featureInfo[2]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[2] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list4"
								label="List Item 4"
								placeholder="Text For Item 4"
								value={featureInfo[3]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[3] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list5"
								label="List Item 5"
								placeholder="Text For Item 5"
								value={featureInfo[4]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[4] = event.target.value;
										return newArr;
									});
								}}
							/>
						</div>
						<div style={{ flexBasis: flexBasis, marginLeft: 20 }}>
							<TextArea
								name="list6"
								label="List Item 6"
								placeholder="Text For Item 6"
								value={featureInfo[5]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[5] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list7"
								label="List Item 7"
								placeholder="Text For Item 7"
								value={featureInfo[6]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[6] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list8"
								label="List Item 8"
								placeholder="Text For Item 8"
								value={featureInfo[7]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[7] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list9"
								label="List Item 9"
								placeholder="Text For Item 9"
								value={featureInfo[8]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[8] = event.target.value;
										return newArr;
									});
								}}
							/>
							<TextArea
								name="list10"
								label="List Item 10"
								placeholder="Text For Item 10"
								value={featureInfo[9]}
								onChange={(event) => {
									if (event.target.value.length > 24) return;
									setFeatureInfo((prev) => {
										const newArr = [...prev];
										newArr[9] = event.target.value;
										return newArr;
									});
								}}
							/>
						</div>
					</>
				) : null}
			</div>
		</MsgBoxModal>
	);
};

export default EditResponseModal;
