import { useQuery, useReactiveVar } from "@apollo/client";
import { Container } from "./styles";

import InfoPanelToggle from "../../Components/ToggleInfoPanel/info-panel-toggle.component";
import RobotToggle from "../../Components/RobotToggle/robot-toggle.component";
import UserAssign from "../../Components/AssignUser/assign-user.component";
import Archive from "../../Components/ArchiveButton/archive-button.component";
import MoveBackToInbox from "../../Components/MoveBackToInbox/move-back-to-inbox";
import MarkAsUnreadButton from "../../Components/MarkAsUnread/mark-as-unread.component";
import { GET_FOLDER_TYPE } from "./query";
import {
	$selectedFolder,
	$infoPanelToggleIsHidden,
	$selectedConversation,
} from "../../store";
import SpamButton from "../../Components/SpamButton/spam-button.component";

const ChatHeaderActions = () => {
	const selectedFolder = useReactiveVar($selectedFolder);
	const infoPanelToggleIsHidden = useReactiveVar($infoPanelToggleIsHidden);
	const selectedConversation = useReactiveVar($selectedConversation);
	const { data, error, loading } = useQuery(GET_FOLDER_TYPE, {
		variables: {
			folderId: selectedFolder,
		},
	});

	if (loading) return null;
	if (error) return null;
	if (!data.msgbox_Folder[0]) return null;
	if (selectedFolder === -1 || selectedConversation === -1) return null;
	return (
		<Container>
			{!selectedConversation ? null : (
				<>
					<UserAssign />
					<div className="chat-toggles">
						<RobotToggle />
						<MarkAsUnreadButton />
						{data.msgbox_Folder[0].IsSpam && (
							<MoveBackToInbox isArchived={false} />
						)}
						{data.msgbox_Folder[0].IsArchive && (
							<MoveBackToInbox isArchived={true} />
						)}
						{!data.msgbox_Folder[0].IsSpam &&
							!data.msgbox_Folder[0].IsArchive && (
								<>
									<Archive />
									<SpamButton />
								</>
							)}
						{infoPanelToggleIsHidden === true ? null : (
							<InfoPanelToggle />
						)}
					</div>
				</>
			)}
		</Container>
	);
};

export default ChatHeaderActions;
