import { makeAutoObservable, runInAction, reaction } from "mobx"
import client from "../Apollo";
import { RootStore } from "./rootStore";
import { GETALL_CONVERSATIONS } from "./conversationQueries";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

export class ConversationStore {
    rootStore
    conversations: Conversation[] = []
    isLoading = true

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.rootStore = rootStore;
    }

    async init() {
        //console.log("loading stuff")
        this.isLoading = true
        const response = await client.query({
            query: GETALL_CONVERSATIONS,
            fetchPolicy: "network-only",
        });
        runInAction(() => {
            if (response && response.data) {
                response.data.msgbox_Conversation.forEach((conv: any) => {
                    // if we dont have a contact then it must have been deleted, so we dont add it to the array. 
                    if (conv.Contact) {
                        var conversation = { folderId: conv.FolderId, conversationId: conv.ConversationId, contactId: conv.ContactId, assignedTo: conv.AssignedTo, assignedToDepartment: conv.AssignedToDepartment, isRead: conv.IsRead, isHidden: conv.IsHidden, lastMessage: new Date(conv.LastMessage), updatedOn: new Date(conv.UpdatedOn) }
                        //console.log("item:", conversation)
                        this.conversations.push(conversation)    
                    }
                })
            }
        })
        this.isLoading = false
        //console.log("finished loading stuff")
        //console.log("conversations:", this.conversations)

        const socket = new WebSocket(`${process.env.REACT_APP_WSAPI_URL}?&token=${localStorage.getItem("access_token")}`)
        socket.addEventListener("open", event => {
            socket.send("Connection established")
          });
          
          // Listen for messages
          socket.addEventListener("message", event => {          
            //console.log("Message from server ", event.data)
            var incomingItem = JSON.parse(event.data);
            //console.log("incomingItem:", incomingItem)
            if (incomingItem && incomingItem.type === "conversation_update") { 
                //console.log("conversation updated")
                const existingItemIndex: number = this.conversations.findIndex(x => x.conversationId === incomingItem.conversationId)
                //console.log("existingItemIndex:", existingItemIndex)
                if (existingItemIndex !== -1) {
                    runInAction(() => {
                        var updatedConversation = { folderId: incomingItem.folderId, conversationId: incomingItem.conversationId, contactId: incomingItem.item.ContactId, assignedTo: incomingItem.item.AssignedTo, assignedToDepartment: incomingItem.item.AssignedToDepartment, isRead: incomingItem.item.IsRead, isHidden: incomingItem.item.IsHidden, lastMessage: new Date(incomingItem.item.LastMessage), updatedOn: new Date(incomingItem.item.UpdatedOn) }
                        this.conversations.splice(existingItemIndex, 1, updatedConversation)
                        //console.log("item updated:", updatedConversation)
                      })
                    //console.log("updated, now how long:", this.conversations.filter(x => x.isRead === false)?.length )
                } else {
                    runInAction(() => {
                        const newItem: Conversation = { folderId: incomingItem.folderId, conversationId: incomingItem.conversationId, contactId: incomingItem.item.ContactId, assignedTo: incomingItem.item.AssignedTo, assignedToDepartment: incomingItem.item.AssignedToDepartment, isRead: incomingItem.item.IsRead, isHidden: incomingItem.item.IsHidden, lastMessage: new Date(incomingItem.item.LastMessage), updatedOn: new Date(incomingItem.item.UpdatedOn) }
                        this.conversations.push(newItem)
                    })
                    //console.log("new item added")
                }
            }
            //console.log("conversation length:", this.conversations.length)
          }); 
          return () => {
            socket.close(); // Close the WebSocket connection when component unmounts
          }; 
    }

    getConversation(conversationId: number) {
        return this.conversations.filter(x => x.conversationId === conversationId) 
    }


    getConversationCount() {
        return this.conversations.length;
    }

    getUnreadConversations() {
        const inboxStore = this.rootStore.getInboxStore();
        const inboxFolders = inboxStore.getAllInboxFolderIds();
        return this.conversations.filter(x => x.isRead === false && x.isHidden === false && inboxFolders.includes(x.folderId)) 
    }

    getInboxUnreadCount() {
        const inboxStore = this.rootStore.getInboxStore();
        const inboxFolders = inboxStore.getAllInboxFolderIds();
        return this.conversations.filter(x => x.isRead === false && x.isHidden === false && inboxFolders.includes(x.folderId))?.length 
    }

    getConversationForContact(contactId: number) {
        //console.log("getting conversation for contactid:", contactId)
        var conversation = this.conversations.filter(x => x.contactId === contactId)
        if (conversation && conversation[0]) {
            return conversation[0]
        }
        return null
    }
}

export class Conversation {
    folderId: number
    conversationId: number
    contactId: number
    assignedTo: number
    assignedToDepartment: number
    isRead: boolean
    isHidden: boolean
    lastMessage: Date
    updatedOn: Date

    constructor(folderId: number, conversationId: number, contactId: number, assignedTo: number, assignedToDepartment: number, isRead: boolean, isHidden: boolean, lastMessage: Date, updatedOn: Date) {
        makeAutoObservable(this, {}, { autoBind: true });
        this.folderId = folderId
        this.conversationId = conversationId
        this.contactId = contactId
        this.assignedTo = assignedTo
        this.assignedToDepartment = assignedToDepartment
        this.isRead = isRead
        this.isHidden = isHidden
        this.lastMessage = lastMessage
        this.updatedOn = updatedOn
    }
}


