import React, { useState, useEffect } from "react";
import Input from "../Input/input.component";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CONTACT_EMAIL, UPDATE_CONTACT_EMAIL } from "./query";
import { useToasts } from "react-toast-notifications";

const Email = ({ contactId }) => {
	const [newEmail, setNewEmail] = useState("");
	const { addToast } = useToasts();
	const { loading, error, data, refetch } = useQuery(GET_CONTACT_EMAIL, {
		fetchPolicy: "network-only",
		variables: {
			contactId,
		},
	});
	const [addEditEmail, { loading: addLoading, error: addError }] =
		useMutation(UPDATE_CONTACT_EMAIL);

	useEffect(() => {
		if (data && data["msgbox_Contact"] && data["msgbox_Contact"][0]) {
			//console.log(
			//	"new email address got:",
			//	data["msgbox_Contact"][0].Email
			//);
			if (data["msgbox_Contact"][0].Email) {
				setNewEmail(data["msgbox_Contact"][0].Email);
			} else {
				setNewEmail("");
			}
		} else {
			setNewEmail("");
		}
	}, [data]);

	const handleAddEditEmail = () => {
		console.log("handle fired");
		addEditEmail({
			variables: {
				email: newEmail !="" ? newEmail : null,
				contactId,
			},
			update: (cache, data) => {
				cache.modify({
					fields: {
						update_msgbox_Contact(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.update_msgbox_Contact,
								query: GET_CONTACT_EMAIL,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		addToast("Successfully saved email address", {
			appearance: "success",
			autoDismiss: true,
		});
	};

	return (
		<div>
			<Input
				value={newEmail}
				placeholder="Add Email..."
				onIconClick={handleAddEditEmail}
				onChange={(event) => {
					setNewEmail(event.target.value);
				}}
				label="Email"
				icon="far fa-save"
				hasAction
			/>
		</div>
	);
};

Email.propTypes = {
	contactId: PropTypes.number,
};

export default Email;
