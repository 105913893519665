import { gql } from "@apollo/client";

export const UPDATE_JOBADDER_CONNECTED_INBOX = gql`
	mutation updateInboxConfig(
		$inboxId: Int!
		$isJobAdderConnected: Boolean
	) {
		update_msgbox_InboxConfib(
			where: { InboxId: { _eq: $inboxId } }
			_set: {
				IsJobAdderConnected: $isJobAdderConnected
			}
		) {
			affected_rows
			returning {
				IsJobAdderConnected
			}
		}
	}
`;

export const UPDATE_JOBADDER_CONNECTED = gql`
	mutation updateAutomationConfig(
		$configurationId: Int!
		$isJobAdderConnected: Boolean
	) {
		update_msgbox_Configuration(
			where: { ConfigurationId: { _eq: $configurationId } }
			_set: {
				IsJobAdderConnected: $isJobAdderConnected
			}
		) {
			affected_rows
			returning {
				ConfigurationId
				IsJobAdderConnected
			}
		}
	}
`;