import styled from "styled-components";
import { device } from "../../Styles/constants";

export const Wrapper = styled.div`
	height: 100%;
`;
export const MainContent = styled.div`
	margin-left: 50px;
	@media (max-width: ${device.mobile}) {
		margin-left: 0px;
	}
`;
