import { QuickSendButton } from "./styles";
import { useQuery, useMutation, useReactiveVar } from "@apollo/client";
import { GET_QUICKSEND_TEMPLATE, GET_CONTACT } from "./query";
import { SEND_TEMPLATE_MESSAGE } from "../SendTemplateMessage/query";
import { $selectedConversation } from "../../store";

const QuickSend = (account) => {
	const currentConversation = useReactiveVar($selectedConversation);
	const {
		data: template,
		loading: templateLoading,
		error: templateError,
	} = useQuery(GET_QUICKSEND_TEMPLATE, {
		variables: { apiAccountId: account.account },
	});

	const {
		data: contact,
		loading: contactLoading,
		error: contactError,
	} = useQuery(GET_CONTACT, {
		variables: {
			conversationId: currentConversation,
		},
	});

	const [
		sendTemplate,
		{ error: errorSendingTemplate, loading: sendingTemplate },
	] = useMutation(SEND_TEMPLATE_MESSAGE);

	const send = async () => {
		let message = template.msgbox_Template[0].TemplateText;

		message = message.replace("{{2}}", "{{orgname}}");

		message = message.replace("{{1}}", "{{firstname}}");

		sendTemplate({
			variables: {
				Message: message,
				TemplateId: template.msgbox_Template[0].TemplateId,
				account: template.msgbox_Template[0].APIAccount,
				contacts: {
					data: [
						{ ContactId: contact.msgbox_Conversation[0].ContactId },
					],
				},
			},
		});
	};

	return (
		<QuickSendButton
			loading={sendingTemplate}
			onClick={send}
			buttonName="Quick Send"
		/>
	);
};

export default QuickSend;
