import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	background-color: ${(props) => props.theme.colours.primary};
	height: 100%;
	width: 175px;
	color: ${(props) => props.theme.colours.light_0};
	font-size: 1.2rem;
	ul {
		width: 175px;
	}
	a {
		color: ${(props) => props.theme.colours.light_0};
		flex: 5;
	}
	li {
		position: relative;
		display: flex;
		align-items: center;
	}
	i {
		margin-right: 12px;
		margin-left: 12px;
		flex: 1;
	}

	.settings-header {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 18px;
		i {
			font-size: 1.7rem;
		}
	}
`;
