// --- options for settings dropdowns --- //

export const automationOptions = [
	{
		label: "1 hour",
		value: 1,
	},
	{
		label: "12 hours",
		value: 12,
	},
	{
		label: "1 day",
		value: 24,
	},
	{
		label: "5 days",
		value: 120,
	},
	{
		label: "7 days",
		value: 168,
	},
	{
		label: "1 Month",
		value: 720,
	},
	{
		label: "3 Months",
		value: 2160,
	},
	{
		label: "6 Months",
		value: 4320,
	},
	{
		label: "1 Year",
		value: 8640,
	},
];

//specifically for the away reply delay
export const automationAwayOptions = [
	{
		label: "Always Send",
		value: 0,
	},
	{
		label: "1 hour",
		value: 1,
	},
	{
		label: "12 hours",
		value: 12,
	},
	{
		label: "1 day",
		value: 24,
	},
	{
		label: "5 days",
		value: 120,
	},
	{
		label: "7 days",
		value: 168,
	},
	{
		label: "1 Month",
		value: 720,
	},
	{
		label: "3 Months",
		value: 2160,
	},
	{
		label: "6 Months",
		value: 4320,
	},
	{
		label: "1 Year",
		value: 8640,
	},
];

export const botToggleOptions = [
	{
		label: "Yes",
		value: true,
	},
	{
		label: "No",
		value: false,
	},
];

export const autoConversationAssignOptions = [
	{
		label: "Yes",
		value: true,
	},
	{
		label: "No",
		value: false,
	},
];

export const autoConversation2AssignOptions = [
	{
		label: "Yes",
		value: true,
	},
	{
		label: "No",
		value: false,
	},
];

export const treatPresubscribersAsSubscribersOptions = [
	{
		label: "Yes",
		value: true,
	},
	{
		label: "No",
		value: false,
	},
];
export const autoMoveToInboxOptions = [
	{
		label: "Yes",
		value: true,
	},
	{
		label: "No",
		value: false,
	},
];

export const hoursDaysMap = {
	0: "Always Send",
	1: "1 hour",
	12: "12 hours",
	24: "1 day",
	120: "5 days",
	168: "7 days",
	720: "1 Month",
	2160: "3 Months",
	4320: "6 Months",
	8640: "1 Year",
};

export const crmIntegrationOptions = [
    {
        label: "None",
        value: null,
    },
    {
        label: "Bullhorn CRM",
        value: "Bullhorn",
    },
    {
        label: "Email Audit",
        value: "Email Audit",
    },
    {
        label: "Generic Email Forwarding",
        value: "Generic Email Forward",
    },
    {
        label: "Hubspot CRM",
        value: "Hubspot",
    },
	{
        label: "JobAdder",
        value: "JobAdder",
    },
    {
        label: "Reapit Cloud",
        value: "Reapit",
    },
    {
        label: "Rex Sales CRM",
        value: "Rex",
    },
    {
        label: "Vincere",
        value: "Vincere",
    },
];

export const crmIntegrationMap = {
	null: "None",
	Rex: "Rex Sales CRM",
	Bullhorn: "Bullhorn CRM",
	Hubspot: "Hubspot CRM",
	Reapit: "Reapit Cloud",
	"Generic Email Forward": "Generic Email Forwarding",
	"Email Audit": "Email Audit",
	Vincere: "Vincere",
	JobAdder: "JobAdder"
};

export const crmFrequencyOptions = [
	{
		label: "First In/Out per day only",
		value: "FIFO",
	},
	{
		label: "Every message in/out",
		value: "ALL",
	},
];

export const crmFrequencyMap = {
	FIFO: "First In/Out per day only",
	ALL: "Every message in/out",
};

export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
