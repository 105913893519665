import { Redirect, Route } from "react-router";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "../Auth/query";

const ProtectedRoute = ({ component: Component, rootStore, ...rest }) => {

	const { data, loading } = useQuery(IS_LOGGED_IN);
	if (loading) return null;
	return (
		<Route
			{...rest}
			render={(props) =>
				data?.isLoggedIn === true ? (
					<Component rootStore={rootStore} {...props} />
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};

export default ProtectedRoute;
