import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
	GET_TAGS,
	ADD_TAG,
	DELETE_TAG_CONTACT,
	ADD_TAG_CONTACT,
	GET_CONTACT_TAGS,
} from "./query";
import AsyncCreatableSelect from "react-select/async-creatable";
import PropTypes from "prop-types";
import { Label, msgBoxSelectCustomStyles } from "../../Styles/global";
import { Container } from "./styles";
import { useTheme } from "styled-components";
import Tooltip from "../Tooltip/tooltip.component";
import { useToasts } from "react-toast-notifications";
import client from "../../Apollo";

const Tags = ({
	label,
	hasTooltip,
	toolTipText,
	contactId,
	onChange,
	tagContacts,
}) => {
	const [tags, setTags] = useState([]);

	const myTheme = useTheme();
	const { addToast } = useToasts();

	const [addTag] = useMutation(ADD_TAG);

	const [
		deleteTagContact,
		{ loading: deleteTagLoading, error: deleteTagError },
	] = useMutation(DELETE_TAG_CONTACT);

	const [addTagContact, { loading: addTagLoading, error: addTagError }] =
		useMutation(ADD_TAG_CONTACT);

	const fetchTagContacts = async () => {
		const response = await client.query({
			query: GET_CONTACT_TAGS,
			variables: {
				contactId,
			},
			fetchPolicy: "network-only",
		});
		const tags = response.data.msgbox_Contact[0].TagContacts.map((tag) => {
			return {
				value: tag.Tag.Name,
				label: tag.Tag.Name,
				id: tag.Tag.TagId,
			};
		});
		setTags(tags);
	};

	useEffect(() => {
		if (tagContacts === true) {
			fetchTagContacts();
		}
	}, [contactId]);

	/**
	 * Handles tag contacts changing
	 */
	const handleTagChange = async (tags, action) => {
		if (tagContacts === false) {
			// If this component is being used to add only tags (not tagContacts) prevent below
			onChange(tags, action);
		} else {
			let message;
			switch (action.action) {
				case "clear":
					const promises = action.removedValues.map((tag) =>
						deleteTagContact({
							variables: { contactId, tagId: tag.id },
						})
					);
					await Promise.all(promises);
					message = "Tags deleted";
					break;
				case "remove-value":
				case "pop-value":
					if(!action?.removedValue?.id) return 
					await deleteTagContact({
						variables: {
							contactId,
							tagId: action.removedValue.id,
						},
					});
					message = "Tag deleted successfuly";
					break;
				case "select-option":
				case "create-option":
					await addTagContact({
						variables: {
							contactId,
							tagId: action.option.id,
						},
						update: (cache, data) => {},
					});
					message = "Tag added successfuly";
					break;
				default:
					break;
			}
			await fetchTagContacts();
			addToast(message, { appearance: "success", autoDismiss: true });
		}
		setTags(tags);
	};

	const loadOptions = (inputValue) => {
		return new Promise(async (resolve, reject) => {
			const response = await client.query({
				query: GET_TAGS,
				variables: {
					tag: "%" + inputValue + "%",
				},
				fetchPolicy: "network-only",
			});
			const options = response.data.msgbox_Tag.map((tag) => ({
				value: tag.Name,
				label: tag.Name,
				id: tag.TagId,
			}));
			resolve(options);
		});
	};

	const handleCreateTag = async (inputValue) => {
		// Add tag
		const result = await addTag({
			variables: { name: inputValue },
			update: (cache, data) => {
				cache.modify({
					fields: {
						msgbox_User(existingResponses = []) {
							const newResponseRef = cache.writeQuery({
								data: data.data.insert_msgbox_Tag,
								query: GET_TAGS,
							});
							return [...existingResponses, newResponseRef];
						},
					},
				});
			},
		});
		const insertedId = result.data.insert_msgbox_Tag.returning[0].TagId;
		const createdTag = {
			value: inputValue,
			label: inputValue,
			id: insertedId,
		};
		setTags([...tags, createdTag]);
		// if this component is not being used to add tags to contacts call onChange prop to let parent know
		if (tagContacts === false) {
			onChange([...tags, createdTag], {
				action: "create-option",
				name: undefined,
				option: createdTag,
			});
		} else {
			handleTagChange([...tags, createdTag], {
				action: "create-option",
				name: undefined,
				option: createdTag,
			});
		}
	};

	return (
		<Container>
			<Label>{label}</Label>
			{hasTooltip && <Tooltip right text={toolTipText} />}
			<AsyncCreatableSelect
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={msgBoxSelectCustomStyles(myTheme)}
				loadOptions={loadOptions}
				onChange={handleTagChange}
				onCreateOption={handleCreateTag}
				value={tags}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary25: myTheme.colours.primaryLight,
						primary: myTheme.colours.primary,
					},
				})}
				placeholder="Search Tags..."
				isMulti
			/>
		</Container>
	);
};

Tags.defaultProps = {
	label: "Tags",
	hasTooltip: false,
	tagContacts: false,
};

Tags.propTypes = {
	onChange: PropTypes.func,
	label: PropTypes.string,
	hasTooltip: PropTypes.bool,
	toolTipText: PropTypes.string,
	contactId: PropTypes.number,
	tagContacts: PropTypes.bool,
};

export default Tags;
