import React, { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import {
	PaginationContainer,
	PaginationLimit,
	Container,
	Label,
} from "./styles";

const Pagination = ({
	rowsPerPage,
	rowCount,
	onChangePage,
	onChangeRowsPerPage,
	currentPage,
	limitOptions,
}) => {
	const selectElement = useRef(null);
	const [limit, setLimit] = useState(10);

	/**
	 * Called when a the selected page changes
	 * Tells parent table component the new page to navigate to
	 */
	const handlePageClick = ({ selected }) => {
		// +1 becasue selected starts at 0
		onChangePage(selected + 1);
	};

	/**
	 * Lets parent table component know the new limit
	 */
	const handleLimitChange = (event) => {
		setLimit(event.target.value);
		onChangeRowsPerPage(parseInt(event.target.value), currentPage);
	};

	return (
		<PaginationContainer>
			<ReactPaginate
				previousLabel={"Previous"}
				nextLabel={"Next"}
				breakLabel={"..."}
				breakClassName={"break-me"}
				pageCount={Math.ceil(rowCount / rowsPerPage)}
				marginPagesDisplayed={1}
				pageRangeDisplayed={2}
				onPageChange={handlePageClick}
				containerClassName={"pagination"}
				// page box numbers
				pageClassName="pagination-page__list-item" // li
				pageLinkClassName="pagination-page__link" // a
				// Prev + next buttons
				previousClassName="pagination-navigation-prev" // li
				nextClassName="pagination-navigation-next" // li
				previousLinkClassName="pagination-previous__link" // a
				nextLinkClassName="pagination-next__link" // a
				// state
				activeClassName="pagination-page__list-item-active" // active li
				activeLinkClassName="pagination-page__link-active" // active a
				containerClassName="pagination"
			/>
			<Container
				onClick={() => {
					selectElement.current.focus();
				}}
			>
				<Label htmlFor="page-limit">Rows per page</Label>
				<PaginationLimit
					ref={selectElement}
					id="page-limit"
					onChange={handleLimitChange}
					value={limit}
				>
					{limitOptions.map((limit) => (
						<option key={limit} value={limit}>
							{limit}
						</option>
					))}
				</PaginationLimit>
			</Container>
		</PaginationContainer>
	);
};

Pagination.propTypes = {
	rowsPerPage: PropTypes.number.isRequired,
	rowCount: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	onChangeRowsPerPage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	limitOptions: PropTypes.arrayOf(PropTypes.number),
};

Pagination.defaultProps = {
	limitOptions: [5, 10, 20, 30, 40, 50],
};

export default Pagination;
