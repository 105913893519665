import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_OUTBOUND_MESSAGE_TABLE_DATA } from "./query";
import { ChartContainer } from "../../../Components/Chart/styles";
import DataTable from "react-data-table-component";
import useDefaultTableStyles from "../../../Components/MsgBoxTable/use-default-table-styles";

const BotResponsesTable = ({ data }) => {
	const [formattedData, setFormattedData] = useState([]);

	const defaultTableStyles = useDefaultTableStyles();

	useEffect(() => {
		
		if (data && data.reporting_BotResponsesByInbox && data.reporting_BotResponsesByInbox.length > 0) {

	        let responseCounts = [...data.reporting_BotResponsesByInbox].reduce((acc, item) => {
				let response = item.BotResponse + item.Inbox
				if (!acc[response]) {
					acc[response] = { Count: 0, BotResponse: item.BotResponse, Inbox: item.Inbox };
				}
				acc[response].Count += item.Count;
				return acc;
			}, {});

        	let arr = Object.values(responseCounts).sort((a, b) => b.count - a.count);
			setFormattedData(arr)
		} else {
			setFormattedData([])
		}
	}, [data]);

	const columns = [
		{
			name: "Inbox",
			selector: "Inbox"
		},
		{
			name: "Response",
			selector: "BotResponse",
			format: (row) => row.BotResponse || "Conversational Replies"
		},
		{
			name: "Count",
			selector: "Count",
		},
	];

	return (
		<ChartContainer>
			<DataTable
				customStyles={defaultTableStyles}
				data={formattedData}
				columns={columns}
				title="Bot Responses"
			/>
		</ChartContainer>
	);
};

export default BotResponsesTable;
