import styled, { createGlobalStyle, css } from "styled-components";
import { device } from "./constants";
import IconButton from "../Components/IconButton/icon-button.component";

export const PaginationButtons = css`
	padding-right: 24px;
	padding-left: 24px;
	border-radius: 8px;
	height: 36px;
	font-size: 1rem;
	display: flex;
	align-items: center;
`;

export const ModalHeader = styled.h2`
	text-align: left;
	color: ${(props) => props.theme.colours.textDark};
`;

export const ModalDescription = styled.p`
	margin-bottom: 0.83em;
`;

export const ModalButtonContainer = styled.div`
	float: right;
	margin-top: 12px;
`;

export const boxShadow = css`
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
		rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`;

export const headerLeft = css`
	color: ${(props) => props.theme.colours.dark};
	text-align: left;
`;

export const headerRight = css`
	color: ${(props) => props.theme.colours.dark};
	text-align: right;
`;

export const headerCenter = css`
	color: ${(props) => props.theme.colours.textDark};
	text-align: center;
`;

export const Label = styled.label`
	margin: 0;
	color: ${(props) => props.theme.colours.dark};
`;

export const InputErrorMessage = styled.small`
	color: red;
	position: absolute;
`;

export const InputHelperMessage = styled.small`
	color: ${(props) => props.theme.colours.textDark};
	position: absolute;
	font-weight: bold;
`;

export const FloatRight = styled.div`
	margin-left: auto;
`;

export const Box = styled.div`
	margin: 12px;
`;

export const IconButtonBorder = styled(IconButton)`
	border: solid 1px ${(props) => props.theme.colours.textDark};
	font-size: 1.4rem;
	width: 38px;
	padding: 3px 3px;
	margin-left: 3px;
	border-radius: 5px;
	margin-right: 3px;
	color: inherit;
`;

export const FlexAlignCenter = styled.div`
	display: flex;
	align-items: center;
`;

export const FormField = styled.div`
	margin-bottom: ${(props) => (props.table ? "6px" : "18px")};
	margin-top: ${(props) => props.table && "6px"};
	flex: 1;
`;

export const PageHeader = styled.h1`
	color: ${(props) => props.theme.colours.dark};
	text-align: left;
`;

export const ContentDivider = styled.hr`
	margin-left: ${(props) => props.leftOffset};
	margin-right: ${(props) => props.rightOffset};
`;

export const TableFiltersContainer = styled.div`
	display: flex;
	align-items: center;
	@media (max-width: ${device.tablet}) {
		min-width: 80%;
	}
	@media (max-width: ${device.mobile}) {
		display: block;
	}
	margin-right: 6px;
	gap: 12px;
	min-width: 50%;
`;

// TODO: Lots of componetns need this...
export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
`;

export const GlobalStyle = createGlobalStyle`
	
	@font-face {
		font-family: ${(props) => props.theme.fonts.family};
		src: url(${(props) => props.theme.fonts.path});
	}

	body, html, #root {
		margin: 0;
		padding: 0;
		box-sizing: border-box !important;
		background-color: ${(props) => props.theme.colours.light_1};
		font-family: ${(props) => props.theme.fonts.family};
		font-size: 12px;
	}
	
	li {
		list-style: none;
	  	text-decoration: none;
	}
	
	p {
		margin: 0;
		padding:0;
		color: ${(props) => props.theme.colours.textDark};
		white-space: pre-line;
	}
	
	ul {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none;
	}
	// ------ React modal --------
	.ReactModal__Overlay {
		opacity: 0;
		transition: all 200ms ease-in-out;
		z-index: 200;
	}

	.ReactModal__Overlay--after-open {
  		opacity: 1;
	}
	
	.ReactModal__Content {
		z-index: 100;
		height: fit-content;
		height: -moz-max-content;
		max-height: calc(100vh);
		@media (max-width: ${device.tablet}) {
			width: 90% !important;
		}
		@media (max-width: ${device.desktop}) {
			width: 75% !important;
		}
	}

	// ----- React Data Table ------ 

	.rdt_TableFooter {
		background-color: "blue !important"
	}

	.rdt_TableHeader {
		z-index: 0;
	}

	.rdt_TableBody {

	}

	// ---- React date Range ------
	
	.rdrStaticRange{
		.rdrStaticRangeLabel{
			&:hover{
				background: ${(props) => props.theme.colours.primaryLight}
			}
		}
	}

	.rdrDateDisplayWrapper {
		background: ${(props) => props.theme.colours.primaryLight}
	}
	
	.rdrDayToday .rdrDayNumber span{
		&:after{
			background: ${(props) => props.theme.colours.primary};
		}
	}

	// ---- React menu -----
	
	.rc-menu-button {
		border: solid 1px ${(props) => props.theme.colours.light_3};
		font-size: 1.4rem;
		height: 38px;
		width: 38px;
		margin-left: 3px;
		margin-right: 3px;
		background-color: transparent;
	}
	
	// ------ react paginate -------

	.pagination {
		&-page__list-item {
			border: solid 2px ${(props) => props.theme.colours.light_3};
			height: 36px;
			width: 36px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 6px;
			margin-right: 6px;
			color: ${(props) => props.theme.colours.dark};
			font-size: 1rem;
    		font-weight: 600;
			&-active {
				border: solid 2px ${(props) => props.theme.colours.primary} ;
			}
		}
		&-navigation {
			&-prev {
				${PaginationButtons};
				color: ${(props) => props.theme.colours.danger};
				background-color: ${(props) => props.theme.colours.dangerLight};
			}
			&-next {
				${PaginationButtons};
				color: ${(props) => props.theme.colours.light_0};
				background-color: ${(props) => props.theme.colours.primary};
			}
		}
	}
	
	.tooltip {
		padding: 4px 11px !important;
	}

	// --------- React Toggle CSS -----------
	.react-toggle {
		touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
	}

	.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	}

	.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
	}

	.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4D4D4D;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
	}

	.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color:  #4D4D4D;
	}

	.react-toggle--checked .react-toggle-track {
	background-color: ${(props) => props.theme.colours.primary};
	}

	.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
		background-color: ${(props) => props.theme.colours.primary};
	}

	.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
	}

	.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
	}

	.react-toggle-track-x {
		display: none
	}

	.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
	}

	.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4D4D4D;
	border-radius: 50%;
	background-color: #FAFAFA;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
	}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: ${(props) => props.theme.colours.primary};
}

// ---- Auth0 -----

.auth0-lock-header-bg{
	background-color: ${(props) =>
		props.theme.name === "stitch" &&
		props.theme.colours.primary} !important;

}
.auth0-lock-header{
	background-color: ${(props) =>
		props.theme.name === "stitch" &&
		props.theme.colours.primary} !important;
}

// Removes login / signup
.auth0-lock-tabs-container {
	display: none;
}
// Removes social login options
.auth0-lock-social-buttons-container {
	display: none;
}
.auth0-lock-body-content {
	p {
		display: none;
	} 
}
`;

/// ------ React Select --------

/**
 *
 * @param {*} theme - theme object from styled componets.
 * theme for react select
 */
export const msgBoxSelectCustomStyles = (theme) => {
	return {
		control: (base, state) => ({
			...base,
			minHeight: "35px",
			margin: 0,
			padding: 0,
			background: theme.colours.light_1,
			borderRadius: "4px",
			boxShadow: "none",
			border: "none",
			outline: state.isFocused
				? `2px solid ${theme.colours.primary}`
				: null,
		}),
		option: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: data.color
					? data.color
					: styles.backgroundColor,
			};
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: data.color
					? data.color
					: styles.backgroundColor,
			};
		},
	};
};

export const msgBoxSelectButtonCustomStyles = () => {
	return {
		control: (base) => ({
			...base,
			width: "130px",
			minHeight: "30px",
			margin: 0,
			padding: 0,
			background: "#29278E",
			borderRadius: "24px",
			boxShadow: "none",
			border: "none",
		}),
		singleValue: (base) => ({
			...base,
			display: "none",
		}),
		indicatorSeparator: () => ({
			display: "none",
		}),
		valueContainer: (provided) => ({
			...provided,
			height: "30px",
		}),
		indicatorsContainer: (provided) => ({
			...provided,
			height: "30px",
		}),
		option: (provided) => ({
			...provided,
			fontWeight: "bold",
		}),
		menu: (provided) => ({
			...provided,
			borderRadius: "12px",
		}),
		menuList: (provided) => ({
			...provided,
			paddingTop: "10px",
			paddingBottom: "10px",
		}),
	};
};
