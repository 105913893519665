import { gql } from "@apollo/client";

export const SEND_TEMPLATE_MESSAGE = gql`
	mutation sendTemplateMessage(
		$attachmentId: Int
		$Message: String
		$TemplateId: Int!
		$contacts: msgbox_BroadcastContact_arr_rel_insert_input!
		$account: Int!
		$followOnMessage: String
	) {
		insert_msgbox_Broadcast(
			objects: {
				Message: $Message
				TemplateId: $TemplateId
				BroadcastContacts: $contacts
				APIAccountId: $account
				AttachmentId: $attachmentId
				FollowOnMessage: $followOnMessage
			}
		) {
			affected_rows
			returning {
				Message
				BroadcastContacts {
					ContactId
				}
			}
		}
	}
`;

export const GET_TEMPLATE_MESSAGE_BY_EXACT_NAME = gql`
	query getTemplateMessagesByName($name: String!, , $userInboxes: [Int!]!) {
	msgbox_Template(
			limit: 1, 
			where: {
				Name:{_eq: $name}
				aPIAccountByApiaccount: {
					Inboxes: { InboxId: { _in: $userInboxes } }
				}
		}) {
		TemplateId
	}
	}
`;

export const GET_TEMPLATE_MESSAGE_BY_NAME = gql`
	query getTemplateMessagesByName($name: String!, $userInboxes: [Int!]!) {
		msgbox_Template(
			limit: 1
			where: {
				Name: { _ilike: $name }
				aPIAccountByApiaccount: {
					Inboxes: { InboxId: { _in: $userInboxes } }
				}
				Status: { _eq: Approved }
			}
		) {
			Name
			Status
			TemplateText
			TemplateId
			Attachment
			RejectionReason
			TemplateCategoryId
			TemplateTypeId
			TemplateCategory {
				Name
				TemplateCategoryId
			}
			TemplateType {
				Description
			}
		}
	}
`;

export const GET_TEMPLATE_MESSAGE_BY_NAME_ACC_ID = gql`
	query getTemplateMessagesByNameAccId($name: String!, $accountId: Int!) {
		msgbox_UserInbox(
			where: { Inbox: { APIAccountId: { _eq: $accountId } } }
		) {
			Inbox {
				APIAccount {
					APIAccountId
					Templates(where: { Name: { _ilike: $name },Status: {_eq: Approved} }) {
						TemplateId
						TemplateText
					}
				}
				InboxId
				Name
			}
		}
	}
`;

export const ADD_MESSAGE_TO_CONVERSATION_DEFERRED = gql`
	mutation MessageMutation(
		$bodyText: String
		$conversationId: Int!
		$attachmentId: Int
	) {
		insert_msgbox_Message(
			objects: [
				{
					BodyText: $bodyText
					ConversationId: $conversationId
					AttachmentId: $attachmentId
					DeferredDelivery: true
				}
			]
		) {
			affected_rows
			returning {
				MessageId
			}
		}
	}
`;