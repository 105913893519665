import { useState } from "react";
import { useQuery } from "@apollo/client";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { GET_CONTACTS_BARCHART_DATA } from "./query";
import { ChartContainer } from "../../../Components/Chart/styles";

const ContactsBarchart = ({ start, end, inboxes }) => {
	const [barchartData, setBarchartData] = useState([]);
	const [noData, setNoData] = useState(true);

	const { loading, error } = useQuery(GET_CONTACTS_BARCHART_DATA, {
		variables: {
			start,
			end,
			inboxes,
		},
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			const output = [];
			let hasData = false;
			data.msgbox_Inbox.forEach((inbox) => {
				let obj = {};
				let totalSubscribed = 0;
				let totalUnsubscribed = 0;
				let totalPresubscribed = 0;
				obj.name = inbox.Name;
				inbox.APIAccount.Contacts.forEach((contact) => {
					switch (contact.Status) {
						case "Presubscribed":
							hasData = true;
							totalPresubscribed += 1;
							break;
						case "Subscribed":
							hasData = true;
							totalSubscribed += 1;
							break;
						case "Unsubscribed":
							hasData = true;
							totalUnsubscribed += 1;
						default:
							break;
					}
				});
				obj.Subscribed = totalSubscribed;
				obj.Presubscribed = totalPresubscribed;
				obj.Unsubscribed = totalUnsubscribed;
				output.push(obj);
				obj = {};
				totalSubscribed = 0;
				totalUnsubscribed = 0;
				totalPresubscribed = 0;
			});
			setBarchartData(output);
			setNoData(hasData);
		},
	});

	if (loading) return null;

	if (error) return null;

	if (!noData) return null;

	return (
		<ChartContainer>
			<ResponsiveContainer height={500} width="95%">
				<BarChart data={barchartData}>
					<CartesianGrid />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Legend verticalAlign="top" height={36} />
					<Bar dataKey="Subscribed" fill="#2FDD92" />
					<Bar dataKey="Presubscribed" fill="#E59934" />
					<Bar dataKey="Unsubscribed" fill="#F90716" />
				</BarChart>
			</ResponsiveContainer>
		</ChartContainer>
	);
};

export default ContactsBarchart;
