import { useState, createContext, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { getAccessToken } from "../helpers/functions";
import { GET_PERMISSIONS } from "../Components/Permissions/query";
import {
	GET_INBOX_NATURAL_CHAT,
	GET_INBOX_NEW_NATURAL_CHAT,
	GET_INBOX_QUICK_REPLY,
} from "./query";
import client from "../Apollo";
import { 
	NATURAL_CHAT_TEMPLATE_TEXT_NAME,
	NATURAL_CHAT_TEMPLATE_MEDIA_NAME
} from "../Components/new-natural-chat/constants";

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	useEffect(() => {
		try {
			const setUserPermissions = async () => {
				const token = await getAccessToken();
				const decodedToken = jwt_decode(token);
				const role =
					decodedToken["https://hasura.io/jwt/claims"][
						"x-hasura-default-role"
					];

				const {
					data: { msgbox_Permission },
				} = await client.query({
					query: GET_PERMISSIONS,
					fetchPolicy: "network-only",
				});

				var {
					data: { msgbox_UserInbox },
				} = await client.query({
					query: GET_INBOX_QUICK_REPLY,
					fetchPolicy: "network-only",
					variables: {
						userId: decodedToken["https://hasura.io/jwt/claims"][
							"x-hasura-msgbox-id"
						],
					},
				});
				const quickSendConfig = formatQuickSendData(msgbox_UserInbox);

				var {
					data: { msgbox_UserInbox },
				} = await client.query({
					query: GET_INBOX_NATURAL_CHAT,
					fetchPolicy: "network-only",
					variables: {
						userId: decodedToken["https://hasura.io/jwt/claims"][
							"x-hasura-msgbox-id"
						],
					},
				});
				const naturalchatConfig = formatQuickSendData(msgbox_UserInbox);

				var {
					data: { msgbox_UserInbox },
				} = await client.query({
					query: GET_INBOX_NEW_NATURAL_CHAT,
					fetchPolicy: "network-only",
					variables: {
						userId: decodedToken["https://hasura.io/jwt/claims"][
							"x-hasura-msgbox-id"
						],
					},
				});
				const newNaturalChatConfig = formatNewNaturalChatData(msgbox_UserInbox);
				//console.log("naturalChatConfig:", naturalchatConfig);
				const userPermissions = {
					// If the permission is 1 then everyone has access to that permission.
					// If the permission is 2 then only for admins
					// If the permission is 3 then for admins or team leads
					canAssignConversations: msgbox_Permission[0].InboxAssignConversation === 1 ? true :
						msgbox_Permission[0].InboxAssignConversation === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),

					canViewUnassignedConversations: msgbox_Permission[0].InboxSeeUnassignedConversations === 1 ? true :
						 msgbox_Permission[0].InboxSeeUnassignedConversations === 2 ? role === "orgadmin" :
						 (role === "teamlead" || role == "orgadmin"),

					canViewConversationsAssignedToOthers: msgbox_Permission[0].InboxSeeOthersAssignedConversations === 1 ? true :
						msgbox_Permission[0].InboxSeeOthersAssignedConversations === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),

					canDeleteContacts: msgbox_Permission[0].ContactsDeleteContacts === 1 ? true :
						msgbox_Permission[0].ContactsDeleteContacts === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),

					canViewAutomations: msgbox_Permission[0].AutomationsSeeAutomations === 1 ? true :
						msgbox_Permission[0].AutomationsSeeAutomations === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),
							
					canViewAnalytics: msgbox_Permission[0].AnalyticsSeeAnalytics === 1 ? true :
						msgbox_Permission[0].AnalyticsSeeAnalytics === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),	

					canAddEditTemplates: msgbox_Permission[0].TemplatesAddEditTemplates === 1 ? true :
						msgbox_Permission[0].TemplatesAddEditTemplates === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),	

					canDownloadContacts: msgbox_Permission[0].ContactsDownloadContacts === 1 ? true :
						msgbox_Permission[0].ContactsDownloadContacts === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),				
						
						
					canDeleteTemplates: msgbox_Permission[0].TemplatesDeleteTemplates === 1 ? true :
						msgbox_Permission[0].TemplatesDeleteTemplates === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),	

					canSeeNumbers: msgbox_Permission[0].ContactsSeePhoneNumbers === 1 ? true :
						msgbox_Permission[0].ContactsSeePhoneNumbers === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),	

					canBulkBroadcast: msgbox_Permission[0].BroadcastBulkBroadcasts === 1 ? true :
						msgbox_Permission[0].BroadcastBulkBroadcasts === 2 ? role === "orgadmin" :
						(role === "teamlead" || role == "orgadmin"),	
					// canBulkBroadcast: false
				};

				const data = {
					email: decodedToken["https://hasura.io/jwt/claims"][
						"x-hasura-email"
					],
					userId: decodedToken["https://hasura.io/jwt/claims"][
						"x-hasura-msgbox-id"
					],
					orgId: decodedToken["https://hasura.io/jwt/claims"][
						"x-hasura-org-id"
					],
					role,
					permissions: userPermissions,
					quickSendConfig,
					naturalchatConfig,
					newNaturalChatConfig
				};
				setUser(data);
				setLoading(false);
			};
			setUserPermissions();
		} catch (error) {
			console.log("error", error);
			setError(true);
		}
	}, []);

	const formatQuickSendData = (userInboxes) => {
		// Check each of the inboxes and return an object of key values.
		// Where the key is the inboxId and the value is a boolean (true if quicksend exists and false if it doesnt)
		let config = {};
		userInboxes.forEach(({ Inbox }) => {
		if(Inbox) {
			if (Inbox.APIAccount.Templates[0]) {
				config[Inbox.InboxId] = true;
			} else {
				config[Inbox.InboxId] = false;
			}
		}});
		return config;
	};

	const formatNewNaturalChatData = (userInboxes) => {
		// Check each of the inboxes and return an object of key values.
		// Where the key is the inboxId and the value is a boolean (true if quicksend exists and false if it doesnt)
		let config = {};
		userInboxes.forEach(({ Inbox }) => {
			if(Inbox) {
			let obj = {newNaturalChatText: false, newNaturalChatMedia: false}
			Inbox.APIAccount.Templates.forEach((template) => {
				if(template.Name == NATURAL_CHAT_TEMPLATE_TEXT_NAME) obj.newNaturalChatText = true
				if(template.Name == NATURAL_CHAT_TEMPLATE_MEDIA_NAME) obj.newNaturalChatMedia = true
			})
			config[Inbox.InboxId] = obj;		
		}});
		return config;
	};

	if (loading) return null;
	if (!user) return null;
	if (error) return <p>Oops... something went wrong</p>;

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserProvider;
