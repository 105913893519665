import React, { useEffect } from "react";
import { $activeTab } from "../../store";

// User has switched back to the tab
const onFocus = () => {
	//console.log("Tab is in focus");
};

// User has switched away from the tab (AKA tab is hidden)
const onBlur = () => {
	//console.log("Tab is blurred");
};

const onHandleActivity = () => {
	if (document.hidden) {
		$activeTab(false);
		//console.log("hidden");
	} else {
		$activeTab(true);
		//console.log("not hidden");
	}
	//document.hidden ? console.log("hidden") : console.log("not hidden");
};

const WindowFocusHandler = () => {
	useEffect(() => {
		//console.log("WindowFocusHandler Initiated");
		document.addEventListener("visibilitychange", onHandleActivity);
		document.addEventListener("blur", onBlur);
		window.addEventListener("focus", onFocus);
		window.addEventListener("blur", onBlur);
		document.addEventListener("focus", onFocus);
		// Calls onFocus when the window first loads
		onFocus();
		// Specify how to clean up after this effect:
		return () => {
			//console.log("WindowFocusHandler Cleanup");
			document.addEventListener("focus", onFocus);
			window.removeEventListener("focus", onFocus);
			window.removeEventListener("blur", onBlur);
			document.removeEventListener("focus", onBlur);
			document.removeEventListener("visibilitychange", onHandleActivity);
		};
	}, []);

	return <></>;
};

export default WindowFocusHandler;
