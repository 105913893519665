import * as Yup from "yup";

export const generateValidationScheme = (
	disabledFields,
	naturalChatTemplate,
	newNaturalChatTemplate,
	maxBroadcasts,
	preSubAsSub,
	preSubCount,
	tagsContactsCount,
	resellerName
) => {
	const stepOneValidationSchema = Yup.object().shape(
		{
			searchBy: Yup.string(),
			contacts: disabledFields.contacts
				? undefined
				: Yup.array().when("searchBy", {
						is: "search-contacts",
						then: Yup.array()
						.min(1, "Contacts are required")
						.test(
							'within-limit-presub-false',
							`Your current setup limits you to sending to 10 presubscribers (*), please remove some to continue to send this broadcast.`, 
							(value) => {
								//here value is referring to 'contacts', which is an array (hence contacts array.length)
								if((value && value.length <= maxBroadcasts) && preSubAsSub===false && preSubCount>10 )
									{return false}
								return true
							}
						)
						.test(
							'breach-limit-presub-false',
							`Your current setup limits you to sending to ` + maxBroadcasts +` contacts of which 10 can be presubscribers (*), please remove some contacts to continue to send this broadcast.`, 
							(value) => {
								//here value is referring to 'contacts', which is an array (hence contacts array.length)
								if((value && value.length > maxBroadcasts) && preSubAsSub===false )
									{return false}
								return true
							}
						)
						.test(
							'breach-limit-presub-true',
							`Your current plan permits ` + maxBroadcasts +` contacts per broadcast. To upgrade please contact `+ resellerName, 
							(value) => {
								//here value is referring to 'contacts', which is an array (hence contacts array.length)
								if((value && value.length > maxBroadcasts) && preSubAsSub===true )
									{return false}
								return true
							}
						)
				  }),
			tags: disabledFields.tags
				? undefined
				: Yup.array().when("searchBy", {
						is: "search-tags",
						then: Yup.array()
						.min(1, "Tags are required")
						.test(
							'within-tag-limit-presub-false',
							``, 
							//^ this error message is handled in the label for removing contact-tags
							//still need the validation to prevent form submission
							(value) => {
								if(tagsContactsCount <= maxBroadcasts && preSubAsSub===false && preSubCount>10)
									{return false}
								return true
							}
						  )
						.test(
						'breach-tag-limit-presub-false',
						`Your current plan permits ` + maxBroadcasts + ` contacts per broadcast. To upgrade please contact ` + resellerName, 
						(value) => {
							if(tagsContactsCount > maxBroadcasts && preSubAsSub===false)
								{return false}
							return true
						}
						)
						.test(
							'breach-tag-limit-presub-true',
							`Your current plan permits ` + maxBroadcasts +` contacts per broadcast. To upgrade please contact ` + resellerName, 
							(value) => {
								if(tagsContactsCount > maxBroadcasts && preSubAsSub===true)
									{return false}
								return true
							}
							)
				  }),
			templateMessages: disabledFields.templateMessages
				? undefined
				: Yup.object().when("message", (message) => {
						return !message ||
							!(naturalChatTemplate || newNaturalChatTemplate)
							? Yup.object().required(
									"Template message is required"
							  )
							: Yup.object();
				  }),
			account: disabledFields.account
				? undefined
				: Yup.object().required("Account is required"),
			message: Yup.string().when(
				"templateMessages",
				(templateMessages) => {
					return !templateMessages
						? Yup.string().required("Message is required")
						: Yup.string();
				}
			),
		},
		[["templateMessages", "message"]]
	);

	const stepTwoValidationSchema = Yup.object().shape({
		variables: Yup.array().of(
			Yup.object().shape({
				value: Yup.string().required("is required"),
				label: Yup.string(),
			})
		),
		attachments: Yup.array()
		.test(
		  'attachment-check',
		  'Attachment is required',
		  function(value) {
			const { templateMessages } = this.parent; 
			if (
			  templateMessages?.value?.attachment && 
			  templateMessages.value.attachment !== "None" && 
			  (!value || value.length === 0)
			) {
				console.log("fallsslslsl")
			  return false;
			}
			return true;
		  }
		)
		.test(
			'file-consistency-check',
			function(value){
				const { templateMessages } = this.parent;
				let testPass = true
				if(templateMessages.value.attachment==="Video"){
					testPass = value.every((attachment)=>{
						return attachment.type.includes("video")
					})
					if (!testPass) {
						return this.createError({
						  message: `Incorrect file type - expected video format.`
						});
					}
				}
				if(templateMessages.value.attachment==="Image"){
					testPass = value.every((attachment)=>{
						return attachment.type==="image/jpeg" || attachment.type==="image/png" 
					})
					if (!testPass) {
						return this.createError({
						  message: `Unsupported ${templateMessages.value.attachment} format. Supported file types are
						  \n .jpeg and .png`
						});
					}	
				}
				if(templateMessages.value.attachment==="Document"){
					testPass = value.every((attachment)=>{
						return (
							attachment.type==="text/plain" ||
							attachment.type==="application/vnd.ms-excel" ||						
							attachment.type==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
							attachment.type==="application/msword" ||
							attachment.type==="application/vnd.openxmlformats-officedocument.wordprocessingml.document"	||
							attachment.type==="application/vnd.ms-powerpoint" ||
							attachment.type==="application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
							attachment.type==="application/pdf"
						)
					})
					if (!testPass) {
						return this.createError({
						  message: `Unsupported ${templateMessages.value.attachment} format. Supported file types are
						  \n .txt, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .pdf`
						});
					}	
				}
				if (!testPass) {
					return this.createError({
					  message: `Incorrect file type - expected ${templateMessages.value.attachment} format.`
					});
				}		  
				// else, passed
				return true;
			}
		  )
		// .test(
		// 	'mp4-check',
		// 	'All video files must be in MP4 format',
		// 	function(value) {
		// 	  if (!Array.isArray(value)) return true;
		// 	  let testPass = value.every((attachment) => {
		// 		return !attachment.type.startsWith("video") || attachment.type === "video/mp4";
		// 	  });	  
		// 	  return testPass;
		// 	}
		//   )
	});

	return [stepOneValidationSchema, stepTwoValidationSchema];
};
