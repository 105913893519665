import { gql } from "@apollo/client";



export const GET_OUTBOUND_MESSAGE_TABLE_DATA = gql`
	query getOutboundMessageTableData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String]
	) {
		msgbox_Message(
			where: {
				IsFailed: { _eq: false }
				SenderId: { _is_null: false }
				CreatedOn: { _gt: $start, _lt: $end }
				BroadcastContacts: {
					Broadcast: {
						APIAccount: { Inboxes: { Name: { _in: $inboxes } } }
					}
				}
			}
			order_by: { User: { UserId: asc } }
		) {
			User {
				FirstName
				LastName
				UserId
			}
			CreatedOn
		}
	}
`;



export const GET_TEMPLATES_TABLE_DATA = gql`
	query getTemplatesTableData($start: timestamptz, $end: timestamptz, $inboxes: [String]) {
		msgbox_BroadcastContact(where: {CreatedOn: {_gt: $start, _lt: $end}, Status: {_in: ["Delivered", "Read"]}, Broadcast: {APIAccount: {Name: {_in: $inboxes}}}}) {
			User{
				FirstName
				LastName
				UserId
			}
		}
	}
`;

export const GET_CONTACTS_BARCHART_DATA = gql`
	query getContactsBarChartData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String]
	) {
		msgbox_Inbox(where: { Name: { _in: $inboxes } }) {
			InboxId
			Name
			APIAccount {
				Contacts(where: { CreatedOn: { _gt: $start, _lt: $end } }) {
					ContactId
					CreatedOn
					Status
				}
			}
		}
	}
`;

export const GET_TEMPLATES_BARCHART_DATA = gql`
	query getTemplatesBarchartData(
		$start: timestamptz
		$end: timestamptz
		$inboxes: [String]
	) {
		msgbox_BroadcastContact(
			where: {
				Contact: {
					APIAccount: { Inboxes: { Name: { _in: $inboxes } } }
				}
				Message: { IsTemplate: { _eq: true }, IsFailed: { _eq: false } }
				CreatedOn: { _gt: $start, _lt: $end }
			}
		) {
			Broadcast {
				APIAccount {
					Inboxes {
						InboxId
						Name
					}
				}
				Template {
					TemplateId
					Name
				}
			}
		}
	}
`;
