import { gql } from "@apollo/client";

export const GET_OPENING_HOURS = gql`
	query getOpeningHours {
		msgbox_OpeningHours {
			Open
			OpeningHoursId
			DayOfWeek
			Close
			Inbox {
        		InboxId
				Timezone
			}
			Organisation {
				Configurations {
					ConfigurationId
					Timezone
				}
			}
		}
	}
`;

export const GET_TIMEZONES = gql`
	query getTimeZones {
	msgbox_Timezone {
		Name
	}
	}
`

export const INSERT_OPENING_HOURS = gql`
	mutation insertOpeningHours($open: timetz!, $close: timetz!, $day: Int!) {
		insert_msgbox_OpeningHours(
			objects: { Close: $close, Open: $open, DayOfWeek: $day }
		) {
			affected_rows
		}
	}
`;

export const INSERT_INBOX_OPENING_HOURS = gql`
	mutation insertOpeningHours($inbox: Int!, $open: timetz!, $close: timetz!, $day: Int!) {
		insert_msgbox_OpeningHours(
			objects: { Close: $close, Open: $open, DayOfWeek: $day, InboxId: $inbox }
		) {
			affected_rows
		}
	}
`;

export const DELETE_OPENING_HOURS = gql`
	mutation deleteOpeningHours($openingHoursId: Int!) {
		delete_msgbox_OpeningHours(
			where: { OpeningHoursId: { _eq: $openingHoursId } }
		) {
			affected_rows
		}
	}
`;

export const UPDATE_OPENING_HOURS = gql`
	mutation updateOpeningHours(
		$openingHoursId: Int!
		$open: timetz!
		$close: timetz!
	) {
		update_msgbox_OpeningHours(
			where: { OpeningHoursId: { _eq: $openingHoursId } }
			_set: { Close: $close, Open: $open }
		) {
			affected_rows
		}
	}
`;


export const UPDATE_CONFIG_TIMEZONE = gql`
	mutation msgbox_Configuration($id: Int, $timezone: String){
		update_msgbox_Configuration (where: { ConfigurationId: {_eq: $id} }, _set: {
		Timezone: $timezone
		}){
		affected_rows
	}
	}
`

export const UPDATE_TIMEZONE = gql`
	mutation msgbox_Configuration($id: Int, $timezone: String){
			update_msgbox_Inbox (where: { InboxId: {_eq: $id} }, _set: {
				Timezone: $timezone
			}){
			affected_rows
		}
	}
`
export const GET_INBOXES = gql`
	query getAccount($userId: Int) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			UserId
			UserInboxes {
				Inbox {
					InboxId
					APIAccount {
						Name
					}
				}
			}
		}
	}
`;
