import styled from "styled-components";
import Button from "../../Components/Button/button.component";

export const Container = styled.div`
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
	min-height: 80%;
	min-width: 75%;
	.msgbox-logo {
	}
	a {
		text-align: center;
		justify-self: center;
		margin-left: auto;
		text-decoration: none;
	}
	.login-button-container {
		display: flex;
		gap: 3px;
		margin-top: 42px;
	}
	.login-form {
		.container {
			padding: 36px 18px;
		}
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		h1 {
			color: ${(props) => props.theme.colours.primary};
			font-weight: bold;
			text-align: inherit !important;
			margin-bottom: 24px;
			margin-left: 0 !important;
		}
		h2 {
			text-align: left;
			font-weight: 300;
			margin-bottom: 24px;
		}
	}
	.image {
		flex: 1;
		position: relative;
		img {
			border-radius: 0 12px 12px 0;
			height: 100%;
		}
		.welcome-message {
			position: absolute;
			bottom: 24px;
			margin-left: 12px;
			h4,
			h5 {
				color: white;
			}
		}
	}
`;

export const LoginButton = styled(Button)`
	margin-left: auto;
	flex: 1;
`;

export const ForgottenPasswordButton = styled(Button)`
	flex: 1;
	border: ${(props) =>
		props.primary ? "none" : `solid 1px ${props.theme.colours.light_4}`};
	margin: ${(props) => (props.primary ? "0 0 0 6px" : "0 6px 0 0 ")};
`;
