import { useEffect, useState, useRef } from "react";
import FolderList from "../FolderList/folder-list.component";
import {
	Container,
	ConversationFiltersButton,
	FlexContainer,
	FolderListWrapper,
	ButtonsContainer,
} from "./styles";
import { useQuery, useReactiveVar } from "@apollo/client";
import { MenuItem, MenuButton, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { getUserId } from "../../helpers/functions";
import SendTemplateMessage from "../SendTemplateMessage/send-template-message.component";
import {
	GET_INBOX_DATA,
	SEARCH_CONVERSATIONS_MULTISELECT,
} from "./query";
import {
	$conversationListSearchTerm,
	$userInboxes,
	$conversationUserFilter,
	$conversationTagFilter,
	$conversationDepartmentFilter,
	$conversationContactFilter,
	$selectedInbox,
	$assignedToMeConversationFilter,
	$unassignedConversationFilter,
	$unreadConversationFilter,
	$assignedToTeamsConversationFilter,
	$conversationInboxFilter
} from "../../store";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import client from "../../Apollo";
import { getInboxFilters, setInboxFilters } from "./persistent-filters-storage";

const ConversationFilters = ({rootStore}) => {
	const [isOpen, setOpen] = useState(false);
	const userInboxes = useReactiveVar($userInboxes);
	const selectedInbox = useReactiveVar($selectedInbox);

	const [inboxFilters, setFilters] = useState(getInboxFilters())

	const [filterCount, setFilterCount] = useState(0);

	const [sendTemplateMessageIsOpen, setTemplateMessageModalOpen] = useState(false);
	const ref = useRef(null);
	const [menuItems, setMenuItems] = useState([])
	const {
		loading,
		error,
		data: inboxData,
	} = useQuery(GET_INBOX_DATA, {
		variables: {
			userId: getUserId(),
		},
	});

	useEffect(() => {
		if (inboxData) {
			let result = [];
			inboxData.msgbox_User[0].UserInboxes.forEach((inboxItem) => {
				if(!inboxItem.Inbox) return
				let inboxEntry = {
					label: `${inboxItem.Inbox.Name} - Inbox`,
					value: {
						inbox: inboxItem.Inbox.InboxId,
						folders: inboxItem.Inbox.Folders,
						departments: [] 
					}
				};
				result.push(inboxEntry);

				let departments = inboxData.msgbox_User[0].UserDepartments.flatMap((deptItem) => {
					return deptItem.Department?.DepartmentInboxes.filter(i => (i.Inbox && i.Inbox.InboxId === inboxItem.Inbox.InboxId))
						.map((i) => {
							let departmentEntry = {
								label: `  -  ${deptItem.Department.Name}`,
								value: { department: deptItem.Department.DepartmentId, inboxId: i.Inbox.InboxId }
							};
							result.push(departmentEntry);
							return departmentEntry;
						});
				});
				inboxEntry.value.departments = departments.map(dept => dept?.value?.department);
			});		
			inboxData.msgbox_User[0].UserDepartments.forEach((deptItem) => {
				 if(deptItem.Department?.DepartmentInboxes.length ==0){
					result.push({
						label: `${deptItem.Department.Name} - Team`,
						value: { department: deptItem.Department.DepartmentId, inboxId: null }
					})
				 }
			});
			setMenuItems(result);
		}
	}, [inboxData]);
	
	
	
	useEffect(() => {
		const filters = getInboxFilters();
		setFilters(filters);
	  }, [selectedInbox]);

	useEffect(() => {
		if (inboxFilters) {
			setFilterCount(inboxFilters.length);
			if (inboxFilters.includes("assignedToMe")) {
				const userId = getUserId();
				$assignedToMeConversationFilter(parseInt(userId));
			} else {
				$assignedToMeConversationFilter(null);
			}
			if (inboxFilters.includes("unassigned")) {
				$unassignedConversationFilter(true);
			} else {
				$unassignedConversationFilter(null);
			}
			if (inboxFilters.includes("unread")) {
				$unreadConversationFilter(true);
			} else {
				$unreadConversationFilter(null);
			}		
			
			const departmentFilters = inboxFilters.filter((x) =>{
				if(x == "unread" || x == "unassigned" || x == "assignedToMe") return false
				return JSON.parse(x)?.department
			}).map((x) => JSON.parse(x))	

			if (departmentFilters) {
				$assignedToTeamsConversationFilter(departmentFilters);
			} else {
				$assignedToTeamsConversationFilter(null);
			}
			
			const iFilters = inboxFilters.filter((x) =>{
				if(x == "unread" || x == "unassigned" || x == "assignedToMe") return false
				return JSON.parse(x)?.inbox
			}
			).map((x) =>JSON.parse(x))
			if (iFilters && iFilters.length > 0) {
				$conversationInboxFilter(iFilters);
			} else {
				$conversationInboxFilter([]);
			}


			[...iFilters, ...departmentFilters].filter((item) => {

				let inMenuItems = menuItems.filter((mi) => {
					return JSON.stringify(mi.value) === JSON.stringify(item)
				})

				if(inMenuItems.length == 0 && menuItems.length > 0){ 
					setInboxFilters(JSON.stringify(item), false);
				}
			})

			let f = getInboxFilters();
			if(JSON.stringify(inboxFilters) !== JSON.stringify(f)) setFilters(f);

		} else {
			setFilterCount(0);
			$assignedToMeConversationFilter(null);
			$unassignedConversationFilter(null);
			$conversationInboxFilter([])
			$unreadConversationFilter(null);
			$assignedToTeamsConversationFilter(null);
		}
	}, [inboxFilters, menuItems]);

	const handleFilterChange = (filter, stringify = false) => {
		setInboxFilters(stringify? JSON.stringify(filter.value): filter.value, filter.checked);
		let filters = getInboxFilters() ;
		if(filters && filter.value.department && filter.value.inboxId && filter.checked){
			let departmentsInbox = menuItems.find((item) => item.value.inbox == filter.value.inboxId)
			if(!filters.includes(JSON.stringify(departmentsInbox.value))){
				console.log(departmentsInbox)
				setInboxFilters(JSON.stringify(departmentsInbox.value), true)
			}
		}
		if(filters && filter.value.inbox && !filter.checked){
			let deps = filters.filter((x) => {
				if(x == "unread" || x == "unassigned" || x == "assignedToMe") return false
				return JSON.parse(x)?.department
			})
			deps.forEach((dep) => {
				if(JSON.parse(dep).inboxId == filter.value.inbox){
					setInboxFilters(dep, false)
				}
			})
		}
		filters = getInboxFilters();
		setFilters(filters);
	};

	const handleFilterClose = (requestClose) => {
		switch (requestClose.reason) {
			case "cancel":
			case "blur":
				setOpen(false);
				break;
			case "click": 
				break;
			default:
				setOpen(false);
				break;
		}
	};

	const loadTagsOptions = (searchTerm) => {
		return new Promise(async (resolve, reject) => {
			const groups = [];

			if (searchTerm.length > 2) {
				groups.push({
					label: "Search for",
					options: [
						{
							label: searchTerm,
							value: { searchTerm: `%${searchTerm}%` },
						},
					],
				});

				const resp = await client.query({
					query: SEARCH_CONVERSATIONS_MULTISELECT,
					variables: {
						searchTerm: "%" + searchTerm + "%",
						userId: getUserId(),
						userInboxes: userInboxes,
					},
					fetchPolicy: "network-only",
				});

				const options = resp.data.msgbox_Tag.map((tag) => ({
					label: tag.Name,
					value: { tagId: tag.TagId },
				}));

				groups.push({
					label: "Tags",
					options: options,
				});

				const options1 = resp.data.msgbox_User.map((user) => ({
					label: user.FirstName + " " + user.LastName,
					value: { userId: user.UserId },
					color: "pink",
				}));
				groups.push({
					label: "Users",
					options: options1,
				});

				const options2 = resp.data.msgbox_Department.map(
					(department) => ({
						label: department.Name,
						value: { departmentId: department.DepartmentId },
						color: "pink",
					})
				);
				groups.push({
					label: "Departments",
					options: options2,
				});

				let arr = []
				resp.data.msgbox_Contact.forEach((contact) => {
					let existingItem = arr.find(item => item.label == contact.Name);
					if (!existingItem) {
						arr.push({
							label: contact.Name,
							value: { contacts: [contact.ContactId] }
						});
					} else {
						existingItem.value.contacts.push(contact.ContactId);
					}
				});				
				groups.push({
					label: "Contacts",
					options: arr,
				});
			}
			resolve(groups);
		});
	};

	const handleTagFilterChange = (values) => {
		const userFilter = values
			.filter((x) => x.value.userId)
			.map((x) => x.value.userId);
		const tagFilter = values
			.filter((x) => x.value.tagId)
			.map((x) => x.value.tagId);

		const departmentFilter = values
			.filter((x) => x.value.departmentId)
			.map((x) => x.value.departmentId);

		const contactFilter = values
			.filter((x) => x.value.contacts)
			.flatMap((x) => x.value.contacts)

		const searchFilter = values
			.filter((x) => x.value.searchTerm)
			.map((x) => x.value.searchTerm);

		$conversationListSearchTerm(searchFilter[0]);
		$conversationUserFilter(
			userFilter.length > 0
				? { User: { UserId: { _in: userFilter } } }
				: {}
		);
		$conversationTagFilter(
			tagFilter.length > 0
				? { TagContacts: { TagId: { _in: tagFilter } } }
				: {}
		);

		$conversationDepartmentFilter(
			departmentFilter.length > 0
				? {
						Department: {
							DepartmentId: {
								_in: departmentFilter,
							},
						},
				  }
				: {}
		);

		$conversationContactFilter(
			contactFilter.length > 0
				? {
						ContactId: {
							_in: contactFilter,
						},
				  }
				: {}
		);
	};

	if (loading) return null;

	if (error) return null;

	return (
		<Container>
			<FlexContainer>
				<FolderListWrapper>
					<FolderList />
				</FolderListWrapper>
				<ButtonsContainer>
					<div className="filter">
						<MenuButton
							styles={{ position: "relative" }}
							onClick={() => setOpen(true)}
							ref={ref}
							data-tip="Filter conversations"
						>
							<i className="fas fa-filter"></i>
							{filterCount !== 0 ? (
								<div className="filter-count">
									{filterCount}
								</div>
							) : null}
						</MenuButton>
						<ControlledMenu
							anchorRef={ref}
							isOpen={isOpen}
							onClose={(requestClose) =>
								handleFilterClose(requestClose)
							}
						>
							<MenuItem
								type="checkbox"
								value="unread"
								checked={
									inboxFilters &&
									inboxFilters.includes("unread")
								}
								onClick={handleFilterChange}
							>
								Unread
							</MenuItem>
							<MenuItem
								type="checkbox"
								value="assignedToMe"
								checked={
									inboxFilters &&
									inboxFilters.includes(
										"assignedToMe"
									)
								}
								onClick={handleFilterChange}
							>
								Assigned To Me
							</MenuItem>	
							<MenuItem
								type="checkbox"
								value="unassigned"
								checked={
									inboxFilters &&
									inboxFilters.includes("unassigned")
								}
								onClick={handleFilterChange}
							>
								Unassigned
							</MenuItem>
							{
								menuItems.map((item) => {
									return(
									<MenuItem
										key={JSON.stringify(item.value)}
										type="checkbox"
										value={item.value}
										checked={
											inboxFilters &&
											inboxFilters.includes(
												JSON.stringify(item.value)
											)
										}
										onClick={(f) => {
											
											handleFilterChange(f, true)
										}}
									>
										{item.label}
									</MenuItem>
								)})
							}
			
						</ControlledMenu>
					</div>

					<ConversationFiltersButton
						id="send-template-message"
						data-tip="Send a notification"
						onClick={() => {
							setTemplateMessageModalOpen(true);
						}}
						icon="far fa-edit"
					/>
				</ButtonsContainer>
			</FlexContainer>
			<MsgBoxSelect
				key={1}
				onChange={(options) => {
					handleTagFilterChange(options);
				}}
				name="tags"
				hasLabel={false}
				loadOptions={loadTagsOptions}
				table
				async
				isMulti
				placeholder="Search..."
			/>
			<SendTemplateMessage
				rootStore={rootStore}
				isOpen={sendTemplateMessageIsOpen}
				close={() => {
					setTemplateMessageModalOpen(false);
				}}
			/>
		</Container>
	);
};

export default ConversationFilters;
