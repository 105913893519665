import React from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { lighten, modularScale } from "polished";

const ColorSquare = styled.div`
	height: 12px;
	width: 12px;
	background-color: ${(props) => props.color};
	margin: 3px;
`;

const ColorContainer = styled.div`
	display: flex;
	align-items: center;
`;

const Legend = () => {
	const theme = useTheme();
	const danger = lighten(0.3, theme.colours.danger);
	const success = lighten(0.5, theme.colours.success);
	const warn = lighten(0.3, theme.colours.warning);

	return (
		<div>
			<ColorContainer>
				{/* <ColorSquare color={success} /> */}
				<p>Success: Templates that have been approved by WhatsApp</p>
			</ColorContainer>
			<ColorContainer>
				{/* <ColorSquare color={warn} /> */}
				<p>
					Pending: Templates are awaitng approval from WhatsApp this
					should take 2 hours but can take longer.
				</p>
			</ColorContainer>
			<ColorContainer>
				{/* <ColorSquare color={danger} /> */}
				<p>
					Rejected: Templates that have been rejected. See the
					rejection reason column to find out why{" "}
				</p>
			</ColorContainer>
		</div>
	);
};

export default Legend;
