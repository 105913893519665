import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
	query getContacts(
		$limit: Int
		$offset: Int
		$order_by: msgbox_Contact_order_by!
		$condition: msgbox_Contact_bool_exp
	) {
		msgbox_Contact(
			limit: $limit
			offset: $offset
			order_by: [$order_by]
			where: $condition
		) {
			Name
			MobileNumber
			CreatedOn
			ContactId
			APIAccountId
			Status
			TagContacts {
				TagId
				Tag {
					Name
					TagId
				}
			}
		}
		msgbox_Contact_aggregate(where: $condition) {
			aggregate {
				count
			}
		}
	}
`;

export const DELETE_CONTACT = gql`
	mutation delete_contact($id: Int!) {
		update_msgbox_Contact(
			where: { ContactId: { _eq: $id } }
			_set: { IsDeleted: true, DeletedOn: "now()" }
		) {
			affected_rows
		}
	}
`;

export const ADD_CONTACT = gql`
	mutation addcontact(
		$name: String!
		$mobileNumber: String!
		$tags: [msgbox_TagContact_insert_input!]! = {}
		$account: Int!
		$email: String
	) {
		insert_msgbox_Contact_one(
			object: {
				Name: $name
				MobileNumber: $mobileNumber
				TagContacts: { data: $tags }
				Status: "Presubscribed"
				APIAccountId: $account
				Email: $email
			}
		) {
			ContactId
			TagContacts {
				TagId
			}
		}
	}
`;

export const SEARCH_TAGS = gql`
	query searchTags($searchTerm: String!) {
		msgbox_Tag(where: { Name: { _ilike: $searchTerm } }) {
			Name
			TagId
		}
	}
`;

export const SEARCH_CONTACT = gql`
	query getContact($searchNumber: String!, $searchAccount: Int!) {
		msgbox_Contact(
			where: {
				_and: [
					{ MobileNumber: { _eq: $searchNumber } }
					{ APIAccountId: { _eq: $searchAccount } }
				]
			}
		) {
			Name
			Status
			MobileNumber
			ContactId
			APIAccountId
		}
	}
`;

export const UNSUBSCRIBE_CONTACTS = gql`
	mutation unsubscribeContacts($contacts: [Int!]) {
		update_msgbox_Contact(
			where: { ContactId: { _in: $contacts } }
			_set: { Status: "Unsubscribed" }
		) {
			affected_rows
		}
	}
`;
