import React from "react";
import styled from "styled-components";

const Container = styled.div`
	margin: 12px;
`;

const ExpandableMessageComponent = ({ data }) => {
	return (
		<Container>
			<h4>Sent Message Text</h4>
			<p>{data.message}</p>
		</Container>
	);
};

export default ExpandableMessageComponent;
