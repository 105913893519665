import { gql } from "@apollo/client";

export const GET_TAGS = gql`
	query SearchTags($tag: String!) {
		msgbox_Tag(where: { Name: { _ilike: $tag } }) {
			Name
			TagId
		}
	}
`;

export const ADD_TAG = gql`
	mutation TagMutation($name: String!) {
		insert_msgbox_Tag(objects: [{ Name: $name }]) {
			affected_rows
			returning {
				TagId
				Name
			}
		}
	}
`;

export const GET_CONTACT_TAGS = gql`
	query getContactDetails($contactId: Int!) {
		msgbox_Contact(where: { ContactId: { _eq: $contactId } }) {
			ContactId
			TagContacts {
				TagId
				Tag {
					TagId
					Name
				}
			}
		}
	}
`;

export const DELETE_TAG_CONTACT = gql`
	mutation deleteTagContact($tagId: Int!, $contactId: Int!) {
		delete_msgbox_TagContact(
			where: { TagId: { _eq: $tagId }, ContactId: { _eq: $contactId } }
		) {
			affected_rows
			returning {
				TagId
				ContactId
			}
		}
	}
`;

export const ADD_TAG_CONTACT = gql`
	mutation addtagcontact($tagId: Int!, $contactId: Int!) {
		insert_msgbox_TagContact(
			objects: { ContactId: $contactId, TagId: $tagId }
		) {
			affected_rows
			returning {
				TagId
				ContactId
				Tag {
					Name
					TagId
				}
			}
		}
	}
`;
