import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

export const Title = styled.p`
	margin-left: 6px;
	margin-bottom: 0;
`;
