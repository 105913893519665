import { gql } from "@apollo/client";

// Do not delete this.
export const GET_ACCOUNT = gql`
	query getAccount($userId: Int) {
		msgbox_User(where: { UserId: { _eq: $userId } }) {
			UserId
			UserInboxes {
				Inbox {
					APIAccount {
						APIAccountId
						Name
						PhoneNumber
					}
					InboxId
				}
			}
		}
	}
`;

export const GET_ALL_API_ACCOUNTS = gql`
	query getAllApiAccounts {
		msgbox_APIAccount {
			APIAccountId
			Name
		}
	}
`;



export const GET_RESELLER_NAME = gql`
query MyQuery ($orgId: Int!) {
  msgbox_Organisation(where: {OrganisationId: {_eq: $orgId}}) {
    Reseller {
      Brand
      Name
    }
  }
}
`;