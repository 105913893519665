import { useState, useRef, useEffect, useContext } from "react";
import SendTemplateMessage from "../SendTemplateMessage/send-template-message.component";
import MsgBoxTable from "../MsgBoxTable/msg-box-table.component";
import { GET_SENT_NOTIFICATIONS } from "./query";
import Pill from "../Pill/pill.component";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import MsgBoxDatePicker from "../DatePicker/date-picker.component";
import Actions from "../Actions/actions.component";
import { Send } from "./styles";
import { SEND_TEMPLATE_MESSAGE } from "../SendTemplateMessage/query";
import { GET_ACCOUNT } from "../../Components/APIAccountsSelect/query";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { ContextActionButton } from "../MsgBoxTable/styles";
import client from "../../Apollo";
import { getUserId } from "../../helpers/functions";
import ExpandableMessageComponent from "../../Pages/Broadcast/show-message.component";
import {
	$selectedFolder,
	$selectedConversation,
	$selectedInbox,
	$userInboxes,
	$conversationFolderFilter,
} from "../../store";
import { useHistory } from "react-router-dom";
import { TableFiltersContainer } from "../../Styles/global";
import hideNumbers from "../../helpers/hideNumber";
import { UserContext } from "../../Providers/user-provider";
import MessageSendingProgressModal from "../MessageSendingProgressModal/MessageSendingProgressModal";

const Notifications = ({ rootStore }) => {
	const inboxStore = rootStore.getInboxStore();
	const conversationStore = rootStore.getConversationStore();

	const columns = [
		{
			name: "Name",
			selector: "name",
			grow: 1,
		},
		{
			name: "Phone Number",
			selector: "phoneNumber",
			grow: 1,
		},
		{
			name: "Template Name",
			selector: "template",
			grow: 1,
		},
		{
			name: "Status",
			selector: "status",
			cell: (row) => <Pill key={row.id} name={row.status} />,
			grow: 1,
		},
		{
			name: "Date/Time Sent",
			selector: "dateSent",
			sortable: true,
			sortField: "CreatedOn",
			grow: 2,
		},

		{
			name: "Sent From",
			selector: "account",
			grow: 2,
		},
		{
			name: "Actions",
			selector: "actions",
			right: true,
			cell: (row) => <Actions asLink={false} menuItems={row.actions} />,
			grow: 1,
		},
	];
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const userInboxes = useReactiveVar($userInboxes);

	const [filters, setFilters] = useState({
		status: null,
		start: null,
		end: null,
		searchTerm: null,
		inboxes: userInboxes,
	});
	const [status, setStatus] = useState([]);
	const [selectedRows, setSelectedRows] = useState([]);
	const [sendMessage, { loading, error }] = useMutation(
		SEND_TEMPLATE_MESSAGE
	);

	const context = useContext(UserContext);
	const canSeeNumbers = context.permissions.canSeeNumbers;
	/**
	 * For api accounts dropdown menu
	 */
	const [accounts, setAccounts] = useState([]);
	/**
	 * For api accounts dropdown menu when only one option is avaliable
	 */
	const [accountDefault, setAccountDefault] = useState("");

	const { addToast } = useToasts();
	const tableRef = useRef();
	const history = useHistory();

	useEffect(() => {
		loadAccountOptions();
	}, []);

	const loadAccountOptions = async () => {
		try {
			const inboxes = inboxStore.inboxes;
			const options = inboxes.map((item) => ({
				label: item.name,
				value: item.inboxId,
			}));
			if (options.length === 1) {
				setAccountDefault(options[0]);
			}
			setAccounts(options);
		} catch (error) {
			console.log("error", error);
			addToast("An error occured", {
				appearance: "error",
				autoDismiss: true,
			});
		}
	};

	const formatData = (data) => {
		const formattedData = data.msgbox_BroadcastContact.map(
			(broadcastContact, index) => {
				const apiAccount = inboxStore.getApiAccount(
					broadcastContact.Broadcast?.APIAccountId
				);
				const conv = conversationStore.getConversationForContact(
					broadcastContact.ContactId
				);
				const folder = conv
					? inboxStore.getFolder(conv.folderId)
					: null;
				const inbox = folder
					? inboxStore.getInbox(folder.inboxId)
					: null;

				let data = null;
				let disabled = true;
				// Data here will be used to send resend notification. Not displayed in the table.
				// If null dont allow resend
				if (broadcastContact.Broadcast.Template) {
					// Check there is a contact
					if (broadcastContact.Contact) {
						disabled = false;
						data = {
							message: broadcastContact.Broadcast.Message,
							contactId: broadcastContact.Contact.ContactId,
							templateId:
								broadcastContact.Broadcast.Template.TemplateId,
							account: broadcastContact.Broadcast.APIAccountId,
							attachmentId:
								broadcastContact.Broadcast.AttachmentId,
						};
					}
				}
				const time = new Date(
					broadcastContact.CreatedOn
				).toLocaleTimeString();
				const date = new Date(
					broadcastContact.CreatedOn
				).toDateString();
				let dontShow = canSeeNumbers;
				return {
					id: index,
					phoneNumber: broadcastContact.Contact
						? hideNumbers(
								broadcastContact.Contact.MobileNumber,
								dontShow
						  )
						: "Contact deleted",
					template: broadcastContact.Broadcast.Template
						? broadcastContact.Broadcast.Template.Name
						: "N/A",
					status: broadcastContact.BroadcastContactStatus.Status,
					dateSent: date + " " + time,
					name: broadcastContact.Contact
						? hideNumbers(broadcastContact.Contact.Name, dontShow)
						: "Contact deleted",
					account: apiAccount?.name,
					actions: [
						{
							name: "Go to conversation",
							icon: <Send className="far fa-comments"></Send>,
							asLink: false,
							disabled: broadcastContact.Contact === null,
							action: () => {
								$selectedFolder(conv.folderId);
								const actionFolder = inboxStore.getFolder(
									conv.folderId
								);
								$conversationFolderFilter(actionFolder.name);
								$selectedInbox(actionFolder.inboxId);
								$selectedConversation(conv.conversationId);

								history.push(`/inbox`);
							},
						},
						{
							name: "Resend",
							disabled: broadcastContact.Contact === null,
							icon: <Send className="fas fa-redo"></Send>,
							action: () => {
								if (!disabled) {
									resendNotification(data);
								}
							},
							asLink: false,
						},
					],
					data,
					disabled,
					// Message to be used in expander row
					message: broadcastContact.Broadcast.Message,
				};
			}
		);
		return [
			formattedData,
			data.msgbox_BroadcastContact_aggregate.aggregate.count,
		];
	};

	const handleFilterChange = (name, value) => {
		switch (name) {
			case "status":
				setFilters({
					...filters,
					status: value.value,
				});
				break;
			case "dateRange":
				setFilters({
					...filters,

					start: value.start,
					end: value.end,
				});
				break;
			case "search":
				setFilters({
					...filters,
					searchTerm: "%" + value + "%",
				});
				break;

			default:
				break;
		}
	};

	const handleSearchChange = (searchTerm) => {
		handleFilterChange("search", searchTerm);
	};

	const handleAccountFilterChange = (name, option) => {
		let inbox = option.value == null ? userInboxes : option.value;
		setFilters({
			...filters,
			inboxes: inbox,
		});
	};

	const disableRow = (row) => {
		if (row.disabled) {
			return true;
		} else {
			return false;
		}
	};

	const resendNotification = async (templateMessage) => {
		await sendMessage({
			variables: {
				attachmentId: templateMessage.attachmentId,
				TemplateId: templateMessage.templateId,
				Message: templateMessage.message,
				account: templateMessage.account,
				contacts: {
					data: [
						{
							ContactId: templateMessage.contactId,
						},
					],
				},
			},
		});
		addToast("Success", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
	};

	const bulkresendNotifictation = async () => {
		const promises = selectedRows.map((row) => {
			sendMessage({
				variables: {
					AttachmentURI: row.data.attachmentId,
					TemplateId: row.data.templateId,
					Message: row.data.message,
					account: row.data.account,
					contacts: {
						data: [
							{
								ContactId: row.data.contactId,
							},
						],
					},
				},
			});
		});
		await Promise.all(promises);
		addToast("Success", {
			appearance: "success",
			autoDismiss: true,
		});
		tableRef.current.refetchData();
	};

	return (
		<div>
			<SendTemplateMessage
				rootStore={rootStore}
				table={tableRef}
				isOpen={modalIsOpen}
				close={() => {
					setModalIsOpen(false);
				}}
			/>
			<MsgBoxTable
				handleSearchChange={handleSearchChange}
				searchPlaceHolder="Search template name..."
				ref={tableRef}
				title="Sent Template Messages"
				columns={columns}
				fetchRows={GET_SENT_NOTIFICATIONS}
				defaultLimit={10}
				defaultOrderBy={{
					field: "CreatedOn",
					sortDirection: "desc",
				}}
				selectedRowsChange={(selectedRows) => {
					setSelectedRows(selectedRows);
				}}
				openModal={() => {
					setModalIsOpen(true);
				}}
				customContextActions={[
					<ContextActionButton
						title="Bulk resend"
						primary
						icon="fas fa-redo"
						onClick={bulkresendNotifictation}
					/>,
				]}
				subHeaderComponent={
					<TableFiltersContainer>
						<MsgBoxSelect
							hasTooltip
							toolTipText="Filter notifications based on status"
							options={[
								{
									label: "Any",
									value: null,
								},
								{
									label: "Pending",
									value: "Pending",
								},
								{
									label: "Read",
									value: "Read",
								},
								{
									label: "Sent",
									value: "Sent",
								},
								{
									label: "Queued",
									value: "Queued",
								},
								{
									label: "Delivered",
									value: "Delivered",
								},
								{
									label: "Failed",
									value: "Failed",
								},
							]}
							onChange={(options) => {
								setStatus(options);
								handleFilterChange("status", options);
							}}
							name="status"
							label="Status"
							value={status}
							table
						/>
						<MsgBoxDatePicker
							table
							onDateRangeChange={(value) => {
								handleFilterChange("dateRange", value);
							}}
							hasTooltip
							tooltipText="Filter notifications by date"
						/>
						<MsgBoxSelect
							key={2}
							onChange={(options) => {
								handleAccountFilterChange("account", options);
							}}
							name="account"
							label="Account"
							table
							options={[
								...accounts,
								{ label: "Any", value: null },
							]}
						/>
					</TableFiltersContainer>
				}
				selectableRowDisabled={disableRow}
				rowsUpdated={modalIsOpen}
				subHeaderAlign={"left"}
				openModalButtonName="Send Template Message"
				expandOnRowClicked
				formatData={formatData}
				filters={filters}
				expandableRows
				expandableRowsComponent={<ExpandableMessageComponent />}
				hasDelete={false}
				filterIgnore={["data"]}
				paginationServer
				pagination
				selectableRows
				sortServer
				subHeader
			/>
		</div>
	);
};

export default Notifications;
