import React, {useEffect, useState} from 'react'
import { AlertsButton, Container } from "./styles";
import { autorun } from "mobx"
import { useReactiveVar } from "@apollo/client";
import {
	$unassignedConversationFilter,
	$assignedToMeConversationFilter,
	$unreadConversationFilter,
	$assignedToTeamsConversationFilter,
	$conversationInboxFilter,
} from "../../store";

const Alerts = ({ rootStore }) => {
	const handleAlertsClick = () => {};
	const conversationStore = rootStore.getConversationStore()
	const [badgeNumber, setBadgeNumber] = useState(0)
	const assignedToMeConversationFilter = useReactiveVar(
		$assignedToMeConversationFilter
	);
	const unassignedConversationFilter = useReactiveVar(
		$unassignedConversationFilter
	);
	const assignedToTeamsConversationFilter = useReactiveVar(
		$assignedToTeamsConversationFilter
	);
	const conversationInboxFilter = useReactiveVar(
		$conversationInboxFilter
	);
	const unreadConversationFilter = useReactiveVar($unreadConversationFilter)

	useEffect(() => {
		autorun(() => {
			const unreadConversations = conversationStore.getUnreadConversations();
			var amount = unreadConversations.length;
			if (unreadConversations) {
				const inboxFolders = conversationInboxFilter.flatMap(((x) => x.folders.filter((y) => y.Name === "Inbox" ))).map((z) => z.FolderId)
				amount = unreadConversations.reduce((total, conversation) => {
						if(unreadConversationFilter) return total + 1

						if (!assignedToMeConversationFilter && 
							!unassignedConversationFilter && 
							conversationInboxFilter && 
							conversationInboxFilter.length === 0 && 
							assignedToTeamsConversationFilter && 
							assignedToTeamsConversationFilter.length === 0) {
							return total + 1;
						}
			
						let assignedToMe = assignedToMeConversationFilter && conversation.assignedTo === assignedToMeConversationFilter;
					
						let unassigned = unassignedConversationFilter &&
										conversation.assignedTo == null &&
										conversation.assignedToDepartment == null;
					
						let assignedToTeams = assignedToTeamsConversationFilter && assignedToTeamsConversationFilter.length > 0 &&
											assignedToTeamsConversationFilter.find((e) => e.department == conversation.assignedToDepartment);

						let isConverationInboxFilter = conversationInboxFilter && conversationInboxFilter.length > 0 &&
							inboxFolders.find((e) => e == conversation.folderId) 
					
						if (assignedToMe || unassigned || assignedToTeams || isConverationInboxFilter) {
							return total + 1;
						}
					
						return total;
					}, 0);
			}
			setBadgeNumber(amount);
		})
	}, [unassignedConversationFilter, assignedToTeamsConversationFilter, assignedToMeConversationFilter, unreadConversationFilter])

	return (
		<Container>
			<AlertsButton
				data-tip="Alerts"
				onClick={handleAlertsClick}
				icon="fas fa-bell"
				data-for={"1"}
			/>
			{badgeNumber > 0 ? (
				<span>{badgeNumber}</span>
			) : null}
		</Container>
	);
};

export default Alerts;
