import styled, { css } from "styled-components";

export const EmojiPickerContainer = styled.div`
	position: absolute;
	bottom: 30px;
	left: 24px;
	transition: opacity 300ms ease-in-out;
`;

export const MessageIcons = styled.i`
	font-size: 1.5rem;
	margin-right: 6px;
	cursor: pointer;
	margin-bottom: 6px;
	color: ${(props) => props.active === true && props.theme.colours.primary};

	&:hover {
		color: ${(props) => props.theme.colours.primary};
	}
	${(props) =>
		props.disabled &&
		css`
			cursor: not-allowed;
			pointer-events: none;
			color: ${(props) => props.theme.colours.light_3};
		`}
`;

export const Container = styled.div`
	.pasted-container-1 {
		width: 200px;
		position: relative;
		margin-bottom: 10px;

		button {
			width: 36px;
			height: 36px;
			background-color: ${(props) => props.theme.colours.primary};
			color: ${(props) => props.theme.colours.light_0};
			border-radius: 36px;
			border: none;

			position: absolute;
			top: -10px;
			right: -10px;
		}
		div {
			background-color: ${(props) => props.theme.colours.primaryLight};
			padding: 12px;
			border-radius: 24px;
			color: black;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			i {
				font-size: 1.5rem;
				margin-right: 6px;
			}
		}
	}
	background-color: ${(props) => props.theme.colours.light_2};
	margin: 12px;
	padding: 12px;
	border-radius: 6px;
	.message {
		display: flex;
		flex-direction: column;
		padding: 12px;
		outline: solid 1px ${(props) => props.theme.colours.light_3};
		&:focus-within {
			outline: solid 2px ${(props) => props.theme.colours.primary};
		}
		input {
			border: none;
			width: 100%;
			background: transparent;
			outline: none;
		}
		button {
			width: 36px;
			height: 36px;
			background-color: ${(props) => props.theme.colours.primary};
			color: ${(props) => props.theme.colours.light_0};
			border-radius: 36px;
			border: none;
		}
	}
	.attachments-container {
		display: flex;
		& .attachment {
			display: flex;
			align-items: center;
			margin: 6px;
			padding: 3px;
			border: solid 1px;
		}
	}
	.send-message-actions {
		position: relative;
	}
`;
