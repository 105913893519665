import React from "react";
import { Container } from "./styles";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAILS } from "./query";
import { getUserId } from "../../helpers/functions";

const UserPreview = () => {
	const { loading, error, data, refetch } = useQuery(GET_USER_DETAILS, {
		variables: {
			userId: getUserId(),
		},
	});

	if (error) {
		return <p>oops... something went wrong</p>;
	}

	if (loading) return null;

	return (
		<Container>
			<div className="user-info">
				<img
					placeholder="user-profile"
					src={
						data.msgbox_User[0].signedurl
							? data.msgbox_User[0].signedurl.url
							: "/images/defaultUser.jpg"
					}
				></img>
				<div>
					<h5>
						{data.msgbox_User[0].FirstName +
							" " +
							data.msgbox_User[0].LastName}
					</h5>
				</div>
			</div>
		</Container>
	);
};

export default UserPreview;
