import { gql } from "@apollo/client";

export const GET_USERS = gql`
	query getUsers($inboxId: Int!) {
		msgbox_UserInbox(
			where: {
				InboxId: { _eq: $inboxId }
				User: { IsBot: { _eq: false } }
			}
		) {
			User {
				UserId
				FirstName
				LastName
				UserDepartments {
					Department {
						Name
						DepartmentId
						DepartmentInboxes {
							InboxId
						}
					}
				}
			}
		}
	}
`;

export const GET_ASSIGNEE = gql`
	query getAssignee($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			ConversationId
			AssignedTo
			User {
				UserId
				FirstName
				LastName
				UserDepartments {
					Department {
						Name
						DepartmentId
					}
				}
			}
			AssignedToDepartment
			Department {
				Name
				DepartmentId
			}
		}
	}
`;

export const ASSIGN_TO_CONVERSATION = gql`
	mutation assignUserToConversation(
		$conversationId: Int!
		$userId: Int
		$departmentId: Int
	) {
		update_msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
			_set: {
				AssignedTo: $userId
				AssignedToDepartment: $departmentId
				UpdatedOn: "now()"
			}
		) {
			affected_rows
			returning {
				AssignedTo
				ConversationId
				User {
					UserId
					LastName
					FirstName
					UserDepartments {
						Department {
							Name
							DepartmentId
						}
					}
				}
				AssignedToDepartment
			}
		}
		update1: update_msgbox_Folder(
			where: {
				Conversations: { ConversationId: { _eq: $conversationId } }
			}
			_set: { UpdatedOn: "now()" }
		) {
			affected_rows
		}
	}
`;

export const GET_ALL_USERS = gql`
	query getAllUsers {
		msgbox_User(where: { IsBot: { _eq: false } }) {
			LastName
			UserId
			FirstName
			UserDepartments {
				Department {
					Name
					DepartmentId
				}
			}
		}
	}
`;
