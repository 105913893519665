import { gql } from "@apollo/client";

export const GET_OPTOUT_CONFIG = gql`
	query getOptOutConfig {
		msgbox_Configuration {
			OptoutReply
			OptoutKeyword
			ConfigurationId
		}
	}
`;

export const UPDATE_OPTOUT_CONFIG = gql`
	mutation updateOptOutConfig($configId: Int!, $reply: String!) {
		update_msgbox_Configuration(
			where: { ConfigurationId: { _eq: $configId } }
			_set: { OptoutReply: $reply }
		) {
			affected_rows
		}
	}
`;
