import styled from "styled-components";

export const Container = styled.div`
	background-color: ${(props) => props.theme.colours.light_0};
	padding: 24px;
	img {
		margin-right: 12px;
		max-height: 100px;
	}
`;
