import { gql } from "@apollo/client";

export const GET_TAGS = gql`
	query getTags {
		msgbox_Tag(order_by: { Name: asc }) {
			Name
			TagId
		}
	}
`;

export const ADD_TAG = gql`
	mutation addTag($name: String!) {
		insert_msgbox_Tag(objects: { Name: $name }) {
			affected_rows
		}
	}
`;

export const DELETE_TAG = gql`
	mutation deleteTagFromUsers($tag: Int) {
		delete_msgbox_TagContact(where: { TagId: { _eq: $tag } }) {
			affected_rows
		}
		delete_msgbox_Tag(where: { TagId: { _eq: $tag } }) {
			affected_rows
		}
	}
`
