import { gql } from "@apollo/client";

export const GET_CONTACT_IN_CONVERSATION = gql`
	query getContactInConv($convId: Int) {
		msgbox_Contact(
			where: { Conversations: { ConversationId: { _eq: $convId } } }
		) {
			ContactId
			Name
		}
	}
`;

export const GET_API_ACCOUNT_ID = gql`
	query getApiAccountId($inboxId: Int!) {
		msgbox_Inbox(where: { InboxId: { _eq: $inboxId } }) {
			APIAccountId
		}
	}
`;
