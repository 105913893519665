import { gql } from "@apollo/client";

export const MARK_CONVERSATION_AS_UNREAD = gql`
	mutation markAsRead($conversationId: Int!) {
		update_msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
			_set: { IsRead: false, UpdatedOn: "now()" }
		) {
			affected_rows
			returning {
				ConversationId
				IsRead
			}
		}
	}
`;
