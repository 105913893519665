import PropTypes from "prop-types";
import React, { useEffect, forwardRef, useState } from "react";
import {
	Image,
	Container,
	ContactImage,
	StaffImage,
	MessageBody,
	SingleCheck,
	DoubleCheck,
	Paused,
	MessageButton,
	Fail,
	SmallImage,
} from "./styles";
import { format, parseISO, isToday, isYesterday, isThisYear } from "date-fns";
import AttachmentPreview from "../AttachmentPreview/attachment-preview.component";
import ViewLocation from "../ViewLocation/view-location.component";
import ContactCard from "../ContactCard/contact-card.component";
import ReactTooltip from "react-tooltip";
import { formatString } from "../../helpers/formatString";
import client from "../../Apollo";
import { GET_MESSAGE_FLAGS, GET_ATTACHMENT } from "./query.js";
import { useLazyQuery } from "@apollo/client";
import { backgrounds } from "polished";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";

const Message = forwardRef(
	(
		{
			message,
			dateTime,
			image,
			isIncoming,
			isSent,
			isRead,
			isDelivered,
			isFailed,
			sendFailureReason,
			isPending,
			contact,
			attachment,
			lat,
			long,
			contactCard,
			senderId,
			user,
			messageId,
			rowKey,
			dataIndex,
			OriginatingMessage,
			originatingAttachmentId,
		},
		ref
	) => {
		const [IsFailed, setIsFailed] = React.useState(isFailed);
		const [IsSent, setIsSent] = React.useState(isSent);
		const [IsRead, setIsRead] = React.useState(isRead);
		const [IsDelivered, setIsDelivered] = React.useState(isDelivered);
		const [IsPending, setIsPending] = React.useState(isPending);
		const [attachmentReplySrc, setAttachmentReplySrc] = useState("");
		const [attachmentReplyFileName, setAttachmentReplyFilename] =
			useState("");
		const [attachmentReplyLocation, setAttachmentReplyLocation] =
			useState("");
		const [attachmentReplyMimeType, setAttachmentReplyMimeType] =
			useState("");

		const [getAttachmentLocation] = useLazyQuery(GET_ATTACHMENT);

		useEffect(() => {
			if (originatingAttachmentId && originatingAttachmentId  !== 10) 
				fetchAttachmentPreview();
		}, [originatingAttachmentId]);

		async function fetchAttachmentPreview() {
			const res = await getAttachmentLocation({
				variables: {
					attachmentId: originatingAttachmentId,
				},
			});
			if(res?.data?.msgbox_Attachment?.length > 0){
			setAttachmentReplySrc(res.data.msgbox_Attachment[0].signedurl.url);
			setAttachmentReplyFilename(res.data.msgbox_Attachment[0].Filename);
			setAttachmentReplyLocation(res.data.msgbox_Attachment[0].Location);
			setAttachmentReplyMimeType(res.data.msgbox_Attachment[0].MimeType);
			}
		}

		const AttachmentReply = () => {
			if (attachmentReplySrc) {
				return (
					<div style={{
						// marginBottom: "25px",
						marginLeft: "25px",
						color: "lightblue",
						display: "inline-block",
						padding: 0, 
						overflow: "hidden", 
						boxSizing: "border-box",
					}}>
						<AttachmentPreview
							filename={attachmentReplyFileName}
							location={attachmentReplySrc}
							type={attachmentReplyMimeType}
							caption={""}
						/>
					</div>


				);
			} else return null;
		};

		const fetchMessageFlags = async () => {
			const { data } = await client.query({
				query: GET_MESSAGE_FLAGS,
				variables: {
					messageId,
				},
				fetchPolicy: "network-only",
			});
			if (data.msgbox_Message[0]) {
				setIsSent(data.msgbox_Message[0].IsSent);
				setIsRead(data.msgbox_Message[0].IsRead);
				setIsFailed(data.msgbox_Message[0].IsFailed);
				setIsDelivered(data.msgbox_Message[0].IsDelivered);
			}
		};

		useEffect(() => {
			// value of isIncoming appears to be the wrong way around

			let timeout1, timeout2, timeout3;

			if (dateTime && isIncoming && !IsRead) {
				var now = new Date();
				const date = parseISO(dateTime);
				var THREE_MIN = 3 * 60 * 1000;

				if (now - date <= THREE_MIN) {
					timeout1 = setTimeout(() => {
						console.log("2 sec timer");
						fetchMessageFlags();
					}, 5000);

					timeout2 = setTimeout(() => {
						console.log("10 sec timer");
						fetchMessageFlags();
					}, 15000);
					timeout3 = setTimeout(() => {
						console.log("10 sec timer");
						fetchMessageFlags();
					}, 45000);
				}
			}

			return () => {
				clearTimeout(timeout1);
				clearTimeout(timeout2);
				clearTimeout(timeout3);
			};
		}, [dateTime]);

		const renderDate = () => {
			const date = parseISO(dateTime);
			if (isToday(date)) {
				return format(date, "HH:mm");
			} else if (isYesterday(date)) {
				return "Yesterday" + " " + format(date, "HH:mm");
			} else if (isThisYear(date)) {
				return format(date, "dd/MM HH:mm");
			} else {
				return format(date, "dd/MM/yyyy HH:mm");
			}
		};

		const renderStatus = () => {
			if (IsPending) {
				return <Paused className="fas fa-pause" />;
			}
			if (IsFailed) {
				//fail icon
				return (
					<React.Fragment>
						<Fail
							data-tip={
								sendFailureReason
									? sendFailureReason
									: "Failed to Send Message"
							}
							className="fas fa-times-circle"
						/>
						<ReactTooltip />
					</React.Fragment>
				);
			}
			if (IsRead) {
				// double check (grey)
				return (
					<DoubleCheck
						isRead={true}
						className="fas fa-check-double"
					/>
				);
			}
			// double check (green)
			if (IsDelivered) {
				return (
					<DoubleCheck
						isRead={false}
						className="fas fa-check-double"
					/>
				);
			}
			// single check (grey)
			if (IsSent) {
				return <SingleCheck className="fas fa-check" />;
			}
		};

		const body = formatString(message);

		function getOriginMessage() {
			if (!OriginatingMessage) return;
			let temp = formatString(OriginatingMessage);
			return (
				<div style={{ marginBottom: 10, marginLeft: 25 }}>
					<MessageBody
						isIncoming={true}
						className="message-body-container"
					>
						<p>
							{temp.map((item, index) => {
								if (item.bold && item.italic) {
									return (
										<p
											key={index}
											style={{
												fontWeight: "bold",
												fontStyle: "italic",
											}}
										>
											{item.text}
										</p>
									);
								} else if (item.italic) {
									return (
										<p
											key={index}
											style={{ fontStyle: "italic" }}
										>
											{item.text}
										</p>
									);
								} else if (item.bold) {
									return (
										<p
											key={index}
											style={{ fontWeight: "bold" }}
										>
											{item.text}
										</p>
									);
								} else if (item.button) {
									return (
										<MessageButton>
											{item.text}
										</MessageButton>
									);
								} else {
									return <p key={index}>{item.text}</p>;
								}
							})}
						</p>
					</MessageBody>
				</div>
			);
		}

		const renderMessage = () => {
			if (lat !== null && long !== null) {
				return <ViewLocation lat={lat} long={long} />;
			}
			if (attachment !== null) {
				return (
					<AttachmentPreview
						filename={attachment.Filename}
						location={attachment.signedurl.url}
						type={attachment.MimeType}
						caption={message}
						messageId={messageId}
					/>
				);
			}
			if (contactCard !== null) {
				const details = JSON.parse(contactCard);
				return <ContactCard {...details[0]} />;
			}
			return (
				<p>
					{body.map((item, index) => {
						if (item.bold && item.italic) {
							return (
								<p
									key={index}
									style={{
										fontWeight: "bold",
										fontStyle: "italic",
									}}
								>
									{item.text}
								</p>
							);
						} else if (item.italic) {
							return (
								<p key={index} style={{ fontStyle: "italic" }}>
									{item.text}
								</p>
							);
						} else if (item.bold) {
							return (
								<p key={index} style={{ fontWeight: "bold" }}>
									{item.text}
								</p>
							);
						} else if (item.button) {
							return <MessageButton>{item.text}</MessageButton>;
						}
						{
							return <p key={index}>{item.text}</p>;
						}
					})}
				</p>
			);
		};

		return (
			<Container
				key={rowKey}
				ref={ref}
				isIncoming={isIncoming}
				data-index={dataIndex}
			>
				{senderId !== null && senderId !== undefined ? (
					image !== null && image !== undefined && image !== "" ? (
						<Image
							isIncoming={isIncoming}
							src={image ? image : "/images/defaultUser.jpg"}
						/>
					) : (
						<StaffImage
							isIncoming={isIncoming}
							contactColor={"#29278E"}
						>
							{user && user.FirstName[0] + user.LastName[0]}
						</StaffImage>
					)
				) : (
					<ContactImage isIncoming={isIncoming}>
						{contact && contact[0]}
					</ContactImage>
				)}

				<MessageBody
					isIncoming={isIncoming}
					className="message-body-container"
				>
					{{ originatingAttachmentId } && <AttachmentReply />}
					{OriginatingMessage && getOriginMessage()}
					{ (originatingAttachmentId && originatingAttachmentId!== 10)  && <p style={{fontStyle: "italic", marginBottom: "25px",
						marginLeft: "25px",}}>Replied to attachment:</p>}
					{renderMessage()}
					<small>{renderDate()}</small>
					<small>{renderStatus()}</small>
				</MessageBody>
			</Container>
		);
	}
);

Message.propTypes = {
	message: PropTypes.string,
	dateTime: PropTypes.string.isRequired,
	image: PropTypes.string,
	isIncoming: PropTypes.bool.isRequired,
	isSent: PropTypes.bool.isRequired,
	isRead: PropTypes.bool.isRequired,
	isDelivered: PropTypes.bool.isRequired,
	isFailed: PropTypes.bool.isRequired,
	contact: PropTypes.string.isRequired,
	attachment: PropTypes.object,
	user: PropTypes.object,
};

export default Message;
