import { StyledNav, Logo, TopNavButton } from "./styles";
import { useTheme } from "styled-components";
import UserPreview from "../UserPreview/user-preview.component";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import Alerts from "../Alerts/alerts.component";
import WhatsAppShare from "../WhatsAppShare/whats-app-share.component";

const TopNavigation = ({ rootStore }) => {
	const theme = useTheme();
	const history = useHistory();
	const handleSettingsClick = () => history.push("/settings/profile");
	return (
		<StyledNav>
			<Logo>
				{theme.name !== "default" ? (
					<img
						style={{ height: "35px" }}
						src={theme.images.logo}
					></img>
				) : (
					<div
						style={{ color: theme.colours.primary }}
						className="sidebar-brand-text mx-3"
					>
						MSGBOXX
					</div>
				)}
			</Logo>
			<UserPreview />
			<div className="buttons-container">
				<WhatsAppShare />
				<Alerts rootStore={rootStore}/>
				<TopNavButton
					data-for={"1"}
					data-tip="Go to settings"
					onClick={handleSettingsClick}
					icon="fas fa-cog"
				/>
			</div>
			<ReactTooltip
				place="left"
				className="tooltip"
				effect="solid"
				id={"1"}
			/>
		</StyledNav>
	);
};

export default TopNavigation;
