import React, { useState, useContext } from "react";
import Notes from "../../Components/Notes/notes.component";
import { GET_CONTACT_DETAILS } from "./query";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import {
	StyledContactDetails,
	SubHeaderContainer,
	ContactImage,
	Details,
	Activity,
	Container,
	SubHeader,
	ContentHeader,
	Status,
	NameContainer,
	EditContactButton,
} from "./styles";
import Back from "../../Components/Back/back.component";
import Tags from "../../Components/TagsFormField/tags.component";
import ContactActivity from "../../Components/ContactActivity/contact-activity.component";
import { PageHeader, ContentDivider } from "../../Styles/global";
import PropTypes from "prop-types";
import UpdateContactNameModal from "./EditContactName/edit-contact-name";
import Email from "../../Components/Email/email.component";
import HeaderContainer from "../../modules/shared/components/header-container";
import hideNumbers from "../../helpers/hideNumber";
import { UserContext } from "../../Providers/user-provider";

const ContactDetails = ({ showAudit, _contactId }) => {
	const { contactId } = useParams();
	const history = useHistory();
	const [editNameModalIsOpen, setEditNameModalOpen] = useState(false);
	const context = useContext(UserContext)
	const canSeeNumbers = context.permissions.canSeeNumbers
	const { data } = useQuery(GET_CONTACT_DETAILS, {
		variables: {
			contactId,
		},
		fetchPolicy: "network-only",
	});


	if (data) {
		const dontShow = canSeeNumbers
		return (
			<Container>
				<HeaderContainer>
					<div style={{ display: "inline-block" }}>
						<Back
							onClick={() => {
								history.push("/contacts");
							}}
							title="Back to contacts"
						/>
					</div>
					<PageHeader>Contact Details</PageHeader>
				</HeaderContainer>
				<StyledContactDetails>
					<Details>
						<div>
							<SubHeaderContainer>
								<ContactImage src="/images/defaultUser.jpg"></ContactImage>
								<div>
									<NameContainer>
										<SubHeader>
											{hideNumbers(data.msgbox_Contact[0].Name, dontShow)}
										</SubHeader>
										<EditContactButton
											onClick={() =>
												setEditNameModalOpen(true)
											}
										>
											<i className="fas fa-edit"></i>
										</EditContactButton>
									</NameContainer>
									<p>{hideNumbers(data.msgbox_Contact[0].MobileNumber, dontShow)}</p>
								</div>
							</SubHeaderContainer>
							<ContentDivider
								leftOffset={"-24px"}
								rightOffset={"-24px"}
							/>
							<div>
								<ContentHeader>Status</ContentHeader>
								<Status status={data.msgbox_Contact[0].Status}>
									{data.msgbox_Contact[0].Status}
								</Status>
								<ContentDivider
									leftOffset={"-24px"}
									rightOffset={"-24px"}
								/>
							</div>
							<div>
								<Email contactId={contactId} />
								<ContentDivider
									leftOffset={"-24px"}
									rightOffset={"-24px"}
								/>
							</div>
							<div>
								<ContentHeader>Tags</ContentHeader>
								<Tags
									tagContacts={true}
									contactId={contactId}
									label="Assign tags to this contact"
								/>
								<ContentDivider
									leftOffset={"-24px"}
									rightOffset={"-24px"}
								/>
							</div>
							<div>
								<ContentHeader>Notes</ContentHeader>
								<Notes contactId={contactId} />
							</div>
						</div>
					</Details>
					{showAudit && (
						<Activity>
							<SubHeaderContainer>
								<SubHeader>Activity Log</SubHeader>
							</SubHeaderContainer>
							<ContentDivider
								leftOffset={"-24px"}
								rightOffset={"-24px"}
							/>
							<ContactActivity contactId={contactId} />
						</Activity>
					)}
				</StyledContactDetails>
				<UpdateContactNameModal
					name={data.msgbox_Contact[0].Name}
					isOpen={editNameModalIsOpen}
					close={() => setEditNameModalOpen(false)}
					contactId={contactId}
				/>
			</Container>
		);
	} else return <p>oops</p>;
};

ContactDetails.propTypes = {
	showAudit: PropTypes.bool,
	_contactId: PropTypes.number,
};

ContactDetails.defaultProps = {
	showAudit: true,
};

export default ContactDetails;
