import { gql } from "@apollo/client";

export const GET_QUICKSEND_TEMPLATE = gql`
	query getQuickSendTemplate($apiAccountId: Int!) {
		msgbox_Template(
			where: {
				Name: { _eq: "quick_reply" }
				APIAccount: { _eq: $apiAccountId }
			}
		) {
			Name
			TemplateId
			APIAccount
			TemplateText
		}
	}
`;

export const GET_CONTACT = gql`
	query getContact($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			ConversationId
			ContactId
		}
	}
`;
