import styled, { css } from "styled-components";
import { StyledButton } from "../Button/styles";

export const Tooltip = styled.span`
	display: inline-block;
	position: absolute;
	background-color: black;
	padding: 5px 10px;
	border-radius: 5px;
	margin-top: auto;
	white-space: nowrap;
	font-size: 0.8rem;
	visibility: hidden;
	opacity: 0;
	top: -30px;
	transition: opacity 200ms ease-in-out;
	z-index: 100;
	color: ${(props) => props.theme.colours.light_0};
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		bottom: -4px;
		transform: rotate(45deg);
		width: 8px;
		height: 8px;
		background-color: inherit;
	}
`;

export const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	&:hover ${Tooltip} {
		visibility: visible;
		opacity: 0.8;
	}
`;

export const Bot = styled(StyledButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px ${(props) => props.theme.colours.light_3};
	font-size: 1.4rem;
	height: 38px;
	width: 38px;
	margin-left: 3px;
	margin-right: 3px;
	border-radius: 6px;
	padding: 3px;
	position: relative;
	background-color: transparent;
	img {
		height: 100%;
	}
	&:focus {
		outline: none;
	}
	&::before {
		${(props) =>
			props.status === false &&
			css`
				position: absolute;
				content: "";
				left: 0;
				right: 0;
				border-top: 2px solid;
				border-color: ${(props) => props.theme.colours.danger};
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			`}
	}
`;
