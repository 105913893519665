import styled from "styled-components";
import IconButton from "../IconButton/icon-button.component";

export const StaffImage = styled.div`
	height: 35px;
	width: 35px;
	background-color: ${(props) => props.contactColor};
	min-width: 35px;
	border-radius: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.theme.colours.light_0};
`;
export const StyledInputContainer = styled.div`
	width: 100%;
	display: flex;
	align-items:center;
	background-color: ${(props) => props.theme.colours.light_1};
	border-radius: 4px;
	box-sizing: border-box;
	max-height: 36px;
	padding: 6px;
	position: relative;
	
`;

export const StyledInput = styled.input`
	background: none;
	border: none;
	width: 100%;
	outline: none;
`;

export const Container = styled.div`
	margin-bottom: 18px;
`;

export const ActionButton = styled(IconButton)`
	background-color: ${(props) => props.theme.colours.light_0};
	color: ${(props) => props.theme.colours.primary};
	height: 28px;
	width: 28px;
`;


  
  
  
  
  