import { gql } from "@apollo/client";

export const GET_FOLDER_TYPE = gql`
	query getFolderType($folderId: Int!) {
		msgbox_Folder(where: { FolderId: { _eq: $folderId } }) {
			IsArchive
			IsSpam
		}
	}
`;
