import styled from "styled-components";

export const StyledTextArea = styled.textarea`
	width: 100%;
	padding: 12px;
	border-radius: 4px;
	border: none;
	background-color: ${(props) => props.theme.colours.light_1};
	&:focus {
		outline: 2px solid ${(props) => props.theme.colours.primary};
	}
`;
