import React, { useEffect, useState } from "react";
import MsgBoxSelect from "../MsgBoxSelect/msg-box-select.component";
import { GET_ALL_API_ACCOUNTS } from "./query";
import client from "../../Apollo";

const APIAccountSelect = (props) => {
	const [accounts, setAccounts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		try {
			const getAccounts = async () => {
				const accountData = await client.query({
					query: GET_ALL_API_ACCOUNTS,
				});
				const accounts = accountData.data.msgbox_APIAccount.map(
					(account) => ({
						value: account.APIAccountId,
						label: account.Name,
					})
				);
				if (props.setAccount) {
					props.setAccount(accounts);
				}
				setAccounts(accounts);
				setLoading(false);
			};
			getAccounts();
		} catch (error) {
			setError(true);
		}
	}, []);

	return (
		<MsgBoxSelect
			placeholder="Select Account"
			label="Select Account"
			options={accounts}
			{...props}
		/>
	);
};

export default APIAccountSelect;
