import { gql } from "@apollo/client";

export const GET_FOLDER = gql`
	query getInboxFolder($conversationId: Int!) {
		msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
		) {
			FolderId
			Folder {
				Inbox {
					Folders(where: { IsInbox: { _eq: true } }) {
						FolderId
					}
				}
			}
		}
	}
`;

export const MOVE_CONVERSATION_BACK_TO_INBOX = gql`
	mutation moveConversationFromArchive(
		$folderId: Int!
		$conversationId: Int!
	) {
		update1: update_msgbox_Folder(
			where: {
				Conversations: { ConversationId: { _eq: $conversationId } }
			}
			_set: { UpdatedOn: "now()" }
		) {
			affected_rows
		}
		update_msgbox_Conversation(
			where: { ConversationId: { _eq: $conversationId } }
			_set: { FolderId: $folderId, UpdatedOn: "now()" }
		) {
			affected_rows
		}
		update2: update_msgbox_Folder(
			where: { FolderId: { _eq: $folderId } }
			_set: { UpdatedOn: "now()" }
		) {
			affected_rows
		}
	}
`;
