import { Formik } from "formik";
import Input from "../../Components/Input/input.component";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { loginValidationSchema } from "./validation-schema";
import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { setUserId, setAccessToken, setBrand } from "../../helpers/functions";
import jwt_decode from "jwt-decode";
import isAuthenticated from "../../Auth/isAuthenticated";
import cache from "../../Apollo/cache";
import { IS_LOGGED_IN } from "../../Auth/query";
import { LoginButton, ForgottenPasswordButton } from "./styles";
import { useTheme } from "styled-components";
import ReactGA from "react-ga4";

const Login = () => {
	const [loading, setLoading] = useState(false);
	const { addToast } = useToasts();
	const history = useHistory();
	const theme = useTheme();

	const submit = async ({ email, password }) => {
		try {
			setLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}oauth/token`,
				{
					method: "POST",
					body: JSON.stringify({
						username: email,
						password,
					}),
					headers: {
						"Content-Type": "application/json",
					},
					credentials: "include",
				}
			);
			const data = await response.json();
			const decoded = jwt_decode(data.access_token);
			setAccessToken(data.access_token);
			// window.location.reload();
			setBrand(decoded["https://hasura.io/jwt/claims"]["x-hasura-brand"]);
			setUserId(
				decoded["https://hasura.io/jwt/claims"]["x-hasura-msgbox-id"]
			);
			ReactGA.event({
				category: "Authentication",
				action: "SuccessfulLogin",
			});
			cache.writeQuery({
				query: IS_LOGGED_IN,
				data: {
					isLoggedIn: true,
				},
			});
			setLoading(false);
			history.push("/inbox");
		} catch (error) {
			console.log("error", error);
			ReactGA.event({
				category: "Authentication",
				action: "FailedLogin",
			});
			addToast(
				"Error logging in. If the problem persists contact support",
				{
					appearance: "error",
					autoDismiss: true,
				}
			);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (isAuthenticated()) {
			cache.writeQuery({
				query: IS_LOGGED_IN,
				data: {
					isLoggedIn: true,
				},
			});
			history.push("/inbox");
		}
	}, []);

	ReactGA.send({ hitType: "pageview", page: "/login" });

	return (
		<Container>
			<div className="login-form">
				<div className="container">
					<img
						className="msgbox-logo"
						src={theme.images.titleImage}
					></img>
					<h2 style={{ marginTop: "24px" }}>
						Please log in to your Account
					</h2>
					<Formik
						initialValues={{
							email: "",
							password: "",
						}}
						onSubmit={(values, actions) => {
							submit(values, actions);
						}}
						validationSchema={loginValidationSchema}
						validateOnChange={false}
						validateOnBlur={false}
					>
						{({ handleSubmit, handleChange, values, errors }) => (
							<form id="login" onSubmit={handleSubmit}>
								<Input
									label="Email"
									onChange={handleChange}
									name="email"
									value={values.email}
									error={errors.email}
									isForm={false}
									icon={<i className="far fa-envelope"></i>}
									placeholder="Email"
								/>
								<Input
									label="Password"
									onChange={handleChange}
									name="password"
									value={values.password}
									error={errors.password}
									type="password"
									isForm={false}
									icon={<i className="fas fa-lock"></i>}
									placeholder="Password"
								/>
								<div className="login-button-container">
									<ForgottenPasswordButton
										type="button"
										buttonName="Forgotten Password"
										onClick={() => {
											history.push("/forgotten-password");
										}}
									/>
									<LoginButton
										buttonName="Login"
										primary
										type="submit"
										loading={loading}
									/>
								</div>
							</form>
						)}
					</Formik>
				</div>
				{theme.images.poweredBy && (
					<div className="powered-by">
						<a href={theme.website}>
							<>
								<p>powered by</p>
								<img
									style={{ height: "50px", padding: 0 }}
									src={theme.images.poweredBy}
								/>
							</>
						</a>
					</div>
				)}
			</div>

			<div className="image">
				<img src={theme.images.loginImage} />
				<div className="welcome-message">
					<h4>Welcome to {theme.appName}</h4>
					<h5>{theme.tagline}</h5>
				</div>
			</div>
		</Container>
	);
};

export default Login;
