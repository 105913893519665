//this function currently 

import { EMAIL_REGEX } from "../Pages/Contacts/bulk-contact-import/validation";

//changes strings into bold surronded by * 
//changes strings into italic surronded by _
//changes strings into buttons in by | [a-z] format 
//email addresses are exempt from this
//new lines resest it all

//requires seperate function to convert to text

export function formatString(text) {
    try {
        if (!text || text === "")
            return [{ text: "", bold: false, italic: false, button: false }];
        let formattedText = [{ text: "", bold: false, italic: false, button: false }];
        for (let i = 0; i < text.length; i++) {
            const char = text[i];

            const word = getWordAtIndex(text, i)
            const isEmail = EMAIL_REGEX.test(word)
            
            const len = formattedText.length - 1;
            const rest = text.substring(i + 1);
            if (char === "\n") {
                if (i == 0) {
                    formattedText[len].text += char;
                    formattedText.push({
                        text: "",
                        bold: false,
                        italic: false,
                        button: false
                    });
                } else if (text[i - 1] === "_" || text[i - 1] === "*") {
                    formattedText[len].text += char;
                } else if (text[i - 1] !== "\n") {
                    formattedText[len].text += char;
                    formattedText.push({
                        text: "",
                        bold: false,
                        italic: false,
                        button: false
                    });
                } else {
                    formattedText.push({
                        text: "",
                        bold: false,
                        italic: false,
                        button: false
                    });
                    formattedText[len + 1].text += char;
                }
            } else if (char === "*" && !isEmail) {
                if (formattedText[len].bold) {
                    formattedText.push({
                        text: "",
                        italic: formattedText[len].italic,
                        bold: false,
                        button: false
                    });
                } else {
                    if (
                        rest.includes("*") &&
                        (rest.indexOf("\n") > rest.indexOf("*") ||
                            rest.indexOf("\n") === -1)
                    ) {
                        formattedText.push({
                            text: "",
                            italic: formattedText[len].italic,
                            bold: true,
                            button: false
                        });
                    } else {
                        formattedText[len].text += char;
                    }
                }
            } else if (char === "_" && !isEmail) {
                if (formattedText[len].italic) {
                    formattedText.push({
                        text: "",
                        bold: formattedText[len].bold,
                        italic: false,
                        button: false
                    });
                } else {
                    if (
                        rest.includes("_") &&
                        (rest.indexOf("\n") > rest.indexOf("_") ||
                            rest.indexOf("\n") === -1)
                    ) {
                        formattedText.push({
                            text: "",
                            bold: formattedText[len].bold,
                            italic: true,
                            button: false
                        });
                    } else {
                        formattedText[len].text += char;
                    }
                }
            } else if (char === " ") {
                 if (
                    rest.match(/^\| \[.*\]/)
                ) {
                    formattedText.push({
                        text: "",
                        bold: false,
                        italic: false,
                        button: true
                    });
                } else {
                    formattedText[len].text += char
                }
            } else if (char === "]") {
                if(
                    formattedText[len].button 
                    &&
                    !rest.match(/^ \| \[.*\]/)
                ){
                    formattedText.push({
                        text: "",
                        bold: false,
                        italic: false,
                        button: false
                    });
                } else if (!formattedText[len].button) {
                    formattedText[len].text += char
                }
            } else if (char === "|" || char === "["){
                if (!formattedText[len].button) {
                    formattedText[len].text += char
                }
            }else {
                formattedText[len].text += char;
            }
        }
        return formattedText.filter(({ text }) => text !== "");
    } catch (e) {
        console.log("Error ", e);
        return [{ text: "", bold: false, italic: false, button: false }];
    }
}

function getWordAtIndex(text, index) {
    if (index < 0 || index >= text.length) {
        return "";
    }
    let start = index;
    while (start > 0 && text[start - 1] !== ' ' && text[start - 1] !== '\n') {
        start--;
    }
    let end = index;
    while (end < text.length && text[end] !== ' ' && text[end] !== '\n') {
        end++;
    }
    return text.slice(start, end);
}