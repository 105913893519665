import { gql } from "@apollo/client";

export const GET_CONTACT_ACTIVITY = gql`
	subscription subscribeToContactActivity($contactId: Int!) {
		msgbox_ContactAudit(
			where: { ContactId: { _eq: $contactId } }
			order_by: { CreatedOn: desc }
			limit: 10
		) {
			Description
			CreatedOn
		}
	}
`;
