import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useToasts } from "react-toast-notifications";
import client from "../../../../Apollo";
import Button from "../../../../Components/Button/button.component";
import { Spinner } from "../../../../Components/LoadingSpinner/styles";
import { getExistingContact } from "../../../../helpers/check-contact-exists";
import { ModalDescription } from "../../../../Styles/global";
import {
	ADD_CONTACT_TAGS,
	ADD_NOTE_TO_CONTACT,
	ADD_TAGS,
	CHECK_IF_TAGS_EXIST,
	CREATE_NEW_CONTACT,
	DELETE_CONTACT_TAGS,
	GET_CONTACT_TAGS,
} from "../query";
import ImportErrors from "./import-errors";
import SelectAccount, { IMPORT_OPTIONS } from "./select-account";
import { TitleWrapper } from "./styles";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		height: "90%",
		width: "50%",
		padding: "50px",
	},
	overlay: {
		backgroundColor: "rgba(0,0,0,0.7)",
	},
};

const ImportModal = ({
	importModalOpen,
	setImportModalOpen,
	fileError,
	dataErrors,
	parsedData,
	clearFile,
	rootStore
}) => {
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [importedContacts, setImportedContacts] = useState(0);
	const [omittedContacts, setOmittedContacts] = useState(0);
	const [updatedContacts, setUpdatedContacts] = useState(0);
	const [duringImport, setDuringImport] = useState(false);
	const [importFinished, setImportFinished] = useState(false);
	const [addTags] = useMutation(ADD_TAGS);
	const [addContact] = useMutation(CREATE_NEW_CONTACT);

	const [deleteAllContactTags] = useMutation(DELETE_CONTACT_TAGS);

	const [addNoteToContact] = useMutation(ADD_NOTE_TO_CONTACT);
	const [addTagsToContact] = useMutation(ADD_CONTACT_TAGS);

	const { addToast } = useToasts();

	const close = () => {
		clearFile();
		setSelectedAccount(null);
		setOmittedContacts(0);
		setImportedContacts(0);
		setUpdatedContacts(0);
		setDuringImport(false);
		setImportModalOpen(false);
		setImportFinished(false);
	};

	const handleIncreaseImportedContacts = () => {
		setImportedContacts((c) => c + 1);
	};

	const handleIncreaseOmittedContacts = () => {
		setOmittedContacts((c) => c + 1);
	};

	const handleIncreaseUpdatedContacts = () => {
		setUpdatedContacts((c) => c + 1);
	};

	const handleAddContact = async (contactData, formAccountValue) => {
		try {
		const accountId = formAccountValue.account.value;
		const contactTags = contactData.tags
			.split(",")
			.map((tag) => tag.trim())
			.filter((tag) => tag.length > 0);

		const tagsIds = await getTagsIds(contactTags);
		const formattedTags = tagsIds.map((tag) => ({ TagId: tag }));
		const number = contactData.mobile.trim().replace(" ", "").startsWith("+") ? contactData.mobile.trim().replace(" ", "") : "+"  + contactData.mobile.trim().replace(" ", "")
		const existingContact = await getExistingContact(
			number,
			accountId
		);
		if (!existingContact) {
			const { data } = await addContact({
				variables: {
					name: contactData.name,
					mobileNumber: number,
					tags: formattedTags,
					account: accountId,
					email: contactData.email ? contactData.email : null,
				},
			});
			const insertedContactId = data.insert_msgbox_Contact_one.ContactId;

			if (insertedContactId && contactData.note.length > 0) {
				await addNoteToContact({
					variables: {
						contactId: insertedContactId,
						note: contactData.note,
					},
				});
			}
			handleIncreaseImportedContacts();
			return;
		} else {
			const contactId = existingContact.ContactId;
			if (selectedAccount.importOption === IMPORT_OPTIONS.REPLACE) {
				await deleteAllContactTags({
					variables: {
						contactId: contactId,
					},
				});
				await addTagsToContact({
					variables: {
						objects: tagsIds.map((tag) => {
							return {
								TagId: tag,
								ContactId: contactId,
							};
						}),
					},
				});
				handleIncreaseUpdatedContacts();
				return;
			} else if (selectedAccount.importOption === IMPORT_OPTIONS.MERGE) {
				const response = await client.query({
					query: GET_CONTACT_TAGS,
					variables: {
						contactId: contactId,
					},
					fetchPolicy: "network-only",
				});

				const existingTags =
					response.data.msgbox_Contact[0].TagContacts.map(
						(tag) => tag.TagId
					);

				const tagsToBeAdded = tagsIds.filter(
					(x) => !existingTags.includes(x)
				);
				if (tagsToBeAdded.length > 0) {
					await addTagsToContact({
						variables: {
							objects: tagsToBeAdded.map((tag) => {
								return {
									TagId: tag,
									ContactId: contactId,
								};
							}),
						},
					});
				}
				handleIncreaseUpdatedContacts();
				return;
			} else {
				handleIncreaseOmittedContacts();
				return;
			}
		}
	} catch (e) {
		console.log(e)
		handleIncreaseOmittedContacts()
	}
	};

	function timeout(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	useEffect(() => {
		async function fetchMyAPI() {
			setDuringImport(true);
			for await (const data of parsedData) {
				await timeout(300);
				await handleAddContact(data, selectedAccount);
			}

			setDuringImport(false);
			setImportFinished(true);
			addToast("Contacts uploaded successfully!", {
				appearance: "success",
				autoDismiss: true,
			});
		}

		if (selectedAccount) {
			fetchMyAPI();
		}
	}, [selectedAccount]);

	const getTagsIds = async (contactTags) => {
		const tagIdsToInsert = [];
		const tagsToBeAdded = [];

		const response = await client.query({
			query: CHECK_IF_TAGS_EXIST,
			variables: {
				tags: contactTags,
			},
			fetchPolicy: "network-only",
		});
		const existingTags = response.data.msgbox_Tag;
		if (existingTags.length === contactTags.length) {
			tagIdsToInsert.push(...existingTags.map((tag) => tag.TagId));
		} else if (existingTags.length === 0) {
			tagsToBeAdded.push(...contactTags);
		} else {
			tagIdsToInsert.push(...existingTags.map((tag) => tag.TagId));
			const existingTagsNames = existingTags.map((tag) => tag.Name);
			contactTags.forEach((tag) => {
				if (!existingTagsNames.includes(tag)) {
					tagsToBeAdded.push(tag);
				}
			});
		}

		if (tagsToBeAdded.length > 0) {
			const result1 = await addTags({
				variables: {
					objects: tagsToBeAdded.map((tag) => {
						return {
							Name: tag,
						};
					}),
				},
			});
			tagIdsToInsert.push(
				...result1.data.insert_msgbox_Tag.returning.map(
					(tag) => tag.TagId
				)
			);
		}

		return tagIdsToInsert;
	};

	return (
		<>
			<Modal
				isOpen={importModalOpen}
				onRequestClose={close}
				style={customStyles}
				shouldCloseOnOverlayClick={!duringImport}
			>
				{fileError || dataErrors.length > 0 ? (
					<ImportErrors
						fileError={fileError}
						dataErrors={dataErrors}
						onClose={close}
					/>
				) : (
					<>
						{/* <ModalHeader></ModalHeader> */}
						<ModalDescription>
							<TitleWrapper>
								<h2>Bulk Contact Import</h2>
								{parsedData && (
									<>
										{!selectedAccount && (
											<SelectAccount
												setSelectedAccount={
													setSelectedAccount
												}
												rootStore={rootStore}
											/>
										)}

										<div>
											{parsedData.length} contacts will be
											imported{" "}
											{selectedAccount &&
												`to ${selectedAccount.account.label}`}
										</div>
									</>
								)}
								{duringImport && (
									<>
										<div>Importing contacts</div>
										<Spinner />
									</>
								)}

								{importedContacts > 0 && (
									<div>
										{importedContacts}
										{importedContacts > 1
											? " contacts were"
											: " contact was"}{" "}
										imported
									</div>
								)}
								{omittedContacts > 0 && (
									<div>
										{omittedContacts}
										{omittedContacts > 1
											? " contacts were"
											: " contact was"}{" "}
										omitted
									</div>
								)}
								{updatedContacts > 0 && (
									<div>
										{updatedContacts}
										{updatedContacts > 1
											? " contacts were"
											: " contact was"}{" "}
										updated
									</div>
								)}
								{importFinished && (
									<Button
										primary
										buttonName="OK"
										onClick={close}
									/>
								)}
							</TitleWrapper>
						</ModalDescription>
					</>
				)}
			</Modal>
		</>
	);
};

export default ImportModal;
